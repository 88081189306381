import { BuilderType, portalCacheTags } from "./portalApiHelpers";
import { legacyFulfill } from "../utils";
import {} from "../../actions/project-actions";
import {
  PORTAL_RECEIVED_GET_ASSETS,
  PORTAL_RECEIVED_UPDATE_ASSET,
  PORTAL_RECEIVED_MOVE_ASSET_LIST,
  PORTAL_RECEIVED_CREATE_ASSET,
} from "../../actions/portal-actions";
export type Asset = {};

export const assetsApi = (builder: BuilderType) => ({
  getAssets: builder.query<any, void>({
    query: () => `service/assets.json`,
    providesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_GET_ASSETS),
  }),

  createAsset: builder.mutation<any, Asset>({
    query: (asset) => ({
      method: "POST",
      url: `/service/assets.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_CREATE_ASSET),
  }),

  // prettier-ignore
  createAssetWithConfiguration: builder.mutation<any,{ asset: Asset; configuration: any }>({
    query: ({ asset, configuration }) => ({
      method: "POST",
      url: `/service/assets/with-config.json`,
      body: { ...asset, configuration },
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_CREATE_ASSET),
  }),

  updateAsset: builder.mutation<Asset, Asset>({
    query: (asset) => ({
      method: "PUT",
      url: `/service/assets/${asset.id}.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_ASSET),
  }),

  updateAssetAlias: builder.mutation<Asset, Asset>({
    query: (asset) => ({
      method: "PUT",
      url: `/service/assets/alias/${asset.id}.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_ASSET),
  }),

  updateAssetAccount: builder.mutation<Asset, Asset>({
    query: (asset) => ({
      method: "PUT",
      url: `/service/assets/account/${asset.id}.json`,
      body: asset,
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_ASSET),
  }),

  // prettier-ignore
  moveAssetList: builder.mutation<Asset,{ destinationAccountId: string; ids: string[] }>({
    query: ({ destinationAccountId, ids }) => ({
      method: "POST",
      url: "/service/assets/move-list",
      body: {
        destination_id: destinationAccountId,
        ids: ids,
      },
    }),
    invalidatesTags: [portalCacheTags.ASSETS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_MOVE_ASSET_LIST),
  })
});
