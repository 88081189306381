import _ from "lodash";
import moment from "moment-timezone";
import {
  all,
  call,
  fork,
  put,
  putResolve,
  select,
  spawn,
  take,
} from "redux-saga/effects";
import * as appActions from "../actions";
import * as configActions from "../actions/config-actions";
import * as portalActions from "../actions/portal-actions";
import * as projectActions from "../actions/project-actions";
import { strings } from "../localization";
import {
  getActiveClient,
  getActiveContact,
  getActiveFacility,
  getActiveLoggerAssignment,
  getActiveProject,
  getActiveSchedule,
  getActiveScheduleGroup,
  getActiveVehicle,
  getAddProjectFlow,
  getAppState,
  getAuth,
  getClients,
  getFleets,
  getMeasureWorkflow,
  getModelWorkflow,
  getNav,
  getPolicyDocument,
  getPowerLoggers,
  getTeams,
  getUpdates,
  getUserConfig,
} from "../reducers/selectors";
import { portalApi as PortalService } from "../services/portal/portalApi";
import { projectsApi } from "../services/projects/projectsApi";
import {
  getWorkflow,
  hasData,
  hasNewPolicy,
} from "../shared-components/src/utility";
import urlUtils from "../shared-components/src/utility/url";
import { copyObject } from "../utility";
import * as configApi from "./api-config";
import * as portalApi from "./api-portal";
import * as projectApi from "./api-projects";
import * as addProjectWorkflowFunctions from "./saga-functions/add-project-workflow-functions";
import * as clientFunctions from "./saga-functions/client-functions";
import * as clientOwnerFunctions from "./saga-functions/client-owners-functions";
import * as configFunctions from "./saga-functions/config-functions";
import * as customerGroupFunctions from "./saga-functions/customer-group-functions";
import * as fleetFunctions from "./saga-functions/fleets-functions";
import * as licenseFunctions from "./saga-functions/license-functions";
import * as loggerFunctions from "./saga-functions/logger-functions";
import * as managementFunctions from "./saga-functions/management-functions";
import * as modelWorkflowFunctions from "./saga-functions/model-workflow-functions";
import * as newTeamFunctions from "./saga-functions/new-team-functions";
import * as policyDocumentFunctions from "./saga-functions/policy-document-functions";
import * as portalFunctions from "./saga-functions/portal-functions";
import * as tagFunctions from "./saga-functions/tags-functions";
import * as teamFunctions from "./saga-functions/team-functions";
import * as thirdPartyFunctions from "./saga-functions/third-party-user-functions";
import * as viewManagerFunctions from "./saga-functions/view-manager-functions";

let workflowNames = {
  measure: strings.measure,
  model: strings.model,
  monitor: strings.monitor,
  manage: strings.manage,
};

export default function* rootSaga() {
  const sagas = [
    /* function* watchAndLog() {
      while (true) {
        const action = yield take("*");
        //const state = yield select();

        if (action.type.indexOf("@@redux-form") === -1) {
          console.log("action", action);
        }
      }
    }, */
    function* watchLogout() {
      while (true) {
        yield take(appActions.LOGOUT_USER);
        if (window.apolloClient) {
          window.apolloClient.clearStore();
        }
      }
    },
    function* watchAuthenticate() {
      while (true) {
        const { payload: auth } = yield take(portalActions.PORTAL_AUTHENTICATE);
        yield put({ type: portalActions.PORTAL_SHOW_AUTHENTICATING });
        try {
          yield call(portalApi.authenticate, auth);
        } catch (error) {
          console.log(error);
        }
        yield put({ type: portalActions.PORTAL_HIDE_AUTHENTICATING });
      }
    },
    function* watchReceiveAuthenticate() {
      while (true) {
        yield take(portalActions.PORTAL_RECEIVED_AUTHENTICATE);
        const nav = yield select(getNav);
        if (nav.index !== "userProfile") {
          yield put({
            type: appActions.SET_NAV,
            payload: { index: "dashboard", drawerOpen: false },
          });
        }
      }
    },
    function* watchSetNav() {
      while (true) {
        const { payload: nav } = yield take(appActions.SET_NAV);

        if (nav.index !== "login") {
          yield call(checkForDataUpdate);
          yield call(checkNavigation);
        }
      }
    },

    // deprecated
    function* watchSetMeasureWorkflow() {
      while (true) {
        const { payload: flow } = yield take(appActions.SET_MEASURE_WORKFLOW);

        if (flow.saveVehicleWithSchedule) {
          let copy = copyObject(flow);
          if (copy.createActiveVehicle) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });
            const vehicle = yield select(getActiveVehicle);
            yield put(projectsApi.endpoints.createVehicle.initiate(vehicle));
            // yield fork(projectApi.createVehicle, vehicle);
            copy.savedVehicleId = 0;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
            return;
          } else if (copy.createActiveSchedule) {
            const schedule = yield select(getActiveSchedule);
            let copySchedule = copyObject(schedule);
            copySchedule.vehicle_id = copy.savedVehicleId;
            yield put(
              projectsApi.endpoints.createSchedule.initiate(copySchedule)
            );
            // yield fork(projectApi.createSchedule, copySchedule);
            copy.getActiveSchedule = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
            return;
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });
            copy.saveVehicleWithSchedule = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.updateVehicleWithSchedule) {
          let copy = copyObject(flow);
          if (copy.updateActiveVehicle) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });
            const vehicle = yield select(getActiveVehicle);
            yield put(projectsApi.endpoints.updateVehicle.initiate(vehicle));
            // yield fork(projectApi.updateVehicle, vehicle);
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
            return;
          } else if (copy.updateActiveSchedule) {
            const schedule = yield select(getActiveSchedule);
            if (!schedule.id || schedule.id === "new") {
              let copySchedule = copyObject(schedule);
              copySchedule.vehicle_id = copy.savedVehicleId;
              yield put(
                projectsApi.endpoints.createSchedule.initiate(copySchedule)
              );
              // yield fork(projectApi.createSchedule, copySchedule);
            } else {
              yield put(
                projectsApi.endpoints.updateSchedule.initiate(schedule)
              );
              // yield fork(projectApi.updateSchedule, schedule);
            }
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
            return;
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });
            copy.updateVehicleWithSchedule = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.updateVehicle) {
          let copy = copyObject(flow);
          if (copy.updateActiveVehicle) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });
            const vehicle = yield select(getActiveVehicle);
            yield put(projectsApi.endpoints.updateVehicle.initiate(vehicle));
            // yield fork(projectApi.updateVehicle, vehicle);
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
            return;
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });
            copy.updateVehicle = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.createTechnicalContact) {
          let copy = copyObject(flow);
          if (copy.createActiveContact) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });
            const contact = yield select(getActiveContact);
            yield put(projectsApi.endpoints.createContact.initiate(contact));
            //yield fork(projectApi.createContact, contact);
            copy.savedContactId = 0;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          } else if (copy.updateProjectData) {
            const project = yield select(getActiveProject);
            const nav = yield select(getNav);
            let copyProject = copyObject(project);
            let workflow = getWorkflow(nav.workflowId, copyProject);
            workflow.workflow_data.technical_contact_id = copy.savedContactId;
            yield put(
              projectsApi.endpoints.updateProject.initiate(copyProject)
            );
            // yield fork(projectApi.updateProject, copyProject);
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });
            copy.createActiveContact = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.createPowerLoggerAssignment) {
          let copy = copyObject(flow);
          if (copy.updateActiveProject) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });

            const project = yield select(getActiveProject);
            yield put(projectsApi.endpoints.updateProject.initiate(project));
            // yield fork(projectApi.updateProject, project);
          } else if (copy.createActiveLoggerAssignment) {
            const assignment = yield select(getActiveLoggerAssignment);
            yield put(
              projectsApi.endpoints.assignPowerLogger.initiate(assignment)
            );
            // yield fork(projectApi.assignPowerLogger, assignment);
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });

            copy.createPowerLoggerAssignment = false;

            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.clearLoggerAssignments) {
          let copy = copyObject(flow);
          if (copy.updateActiveProject) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });

            const project = yield select(getActiveProject);
            yield put(projectsApi.endpoints.updateProject.initiate(project));
            // yield fork(projectApi.updateProject, project);
          } else if (copy.removeAllAssignments && !copy.deletedAssignments) {
            const loggers = yield select(getPowerLoggers);
            const nav = yield select(getNav);
            const assigned = yield call(
              getAssignedLoggers,
              loggers.assignments,
              nav.workflowId
            );
            copy.deletedAssignments = [];
            for (let i = 0; i < assigned.length; i++) {
              copy.deletedAssignments.push(assigned[i].id);
              yield put(
                projectsApi.endpoints.deletePowerLoggerAssignment.initiate({
                  id: assigned[i].id,
                  power_logger_id: assigned[i].power_logger_id,
                })
              );
              /* yield fork(
                projectApi.deletePowerLoggerAssignment,
                assigned[i].id,
                assigned[i].power_logger_id
              ); */
            }

            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });

            copy.clearLoggerAssignments = false;

            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.updateAllVehicles) {
          let copy = copyObject(flow);
          if (copy.doVehicleUpdates && !copy.vehicleUpdates) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });

            const activeProject = yield select(getActiveProject);
            const nav = yield select(getNav);
            copy.vehicleUpdates = [];
            for (let i = 0; i < activeProject.project_workflows.length; i++) {
              if (activeProject.project_workflows[i].id === nav.workflowId) {
                for (
                  let j = 0;
                  j < activeProject.project_workflows[i].vehicles.length;
                  j++
                ) {
                  copy.vehicleUpdates.push(
                    activeProject.project_workflows[i].vehicles[j].id
                  );
                  yield put(
                    projectsApi.endpoints.updateVehicle.initiate(
                      activeProject.project_workflows[i].vehicles[j]
                    )
                  );
                  /* yield fork(
                    projectApi.updateVehicle,
                    activeProject.project_workflows[i].vehicles[j]
                  ); */
                }
              }
            }

            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });

            copy.updateAllVehicles = false;

            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        } else if (flow.getSummaryDataAndNavigateToReport) {
          let copy = copyObject(flow);
          if (copy.updateActiveProject) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });
            const project = yield select(getActiveProject);
            yield put(projectsApi.endpoints.updateProject.initiate(project));
            // yield fork(projectApi.updateProject, project);
          } else if (copy.getVehicleSummaries) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false, calculating: true },
            });
            for (let i = 0; i < copy.vehicleSummaryRequests.length; i++) {
              let req = copy.vehicleSummaryRequests[i];
              if (!req.summaryRequested) {
                yield fork(
                  projectApi.getOperationsSummary,
                  req.projectId,
                  req.vehicleId
                );
                req.summaryRequested = true;
              }
            }

            copy.getVehicleSummaries = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          } else if (copy.navigateToSummary) {
            if (!copy.isLocal) {
              const nav = yield select(getNav);
              let copyNav = copyObject(nav);
              copyNav.index = "projects";
              copyNav.reportId = 1;
              //copyNav.drawerOpen = false;
              yield put({ type: appActions.SET_NAV, payload: copyNav });
            }
            copy.getSummaryDataAndNavigateToReport = false;
            yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
          }
        }
      }
    },

    function* watchGenerateMeasureReport() {
      while (true) {
        yield take(projectActions.PROJECTS_GENERATE_MEASURE_REPORT);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false, calculating: true },
        });
        const prj = yield select(getActiveProject);
        const wrkflow = prj.project_workflows.find((wf) => {
          return wf.workflow_type === "measure";
        });
        const measureFlow = yield select(getMeasureWorkflow);
        let copyMeasureFlow = copyObject(measureFlow);
        copyMeasureFlow = {
          CompletedReportVehicleIds: [],
        };
        yield put({
          type: appActions.SET_MEASURE_WORKFLOW,
          payload: copyMeasureFlow,
        });
        for (let i = 0; i < wrkflow.vehicles.length; i++) {
          yield fork(
            projectApi.getOperationsSummary,
            prj.id,
            wrkflow.vehicles[i].id
          );
        }
      }
    },

    function* watchGetOperationsSummary() {
      while (true) {
        //eslint-disable-next-line
        const {
          payload: { projectId, vehicleId },
        } = yield take(projectActions.PROJECTS_GET_OPERATIONS_SUMMARY);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `summary-${vehicleId}`,
        });
        yield fork(projectApi.getOperationsSummary, projectId, vehicleId);
      }
    },
    function* watchReceivedOperationsSummary() {
      while (true) {
        //eslint-disable-next-line
        const {
          payload: { summary, vehicleId },
        } = yield take(projectActions.PROJECTS_RECEIVED_OPERATIONS_SUMMARY);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `summary-${vehicleId}`,
        });
        const measureFlow = yield select(getMeasureWorkflow);
        if (measureFlow.CompletedReportVehicleIds) {
          const prj = yield select(getActiveProject);
          const workflow = prj.project_workflows.find((wf) => {
            return wf.type === "measure";
          });
          if (
            measureFlow.CompletedReportVehicleIds.length ===
            workflow.vehicles.length - 1
          ) {
            // last
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { calculating: false },
            });
            const nav = yield select(getNav);
            let copyNav = copyObject(nav);
            copyNav.index = "projects";
            copyNav.reportId = 1;
            yield put({ type: appActions.SET_NAV, payload: copyNav });
          } else {
            let copyMeasureFlow = copyObject(measureFlow);
            copyMeasureFlow.CompletedReportVehicleIds.push(vehicleId);
            yield put({
              type: appActions.SET_MEASURE_WORKFLOW,
              payload: copyMeasureFlow,
            });
          }
        } else if (measureFlow.getSummaryDataAndNavigateToReport) {
          let copy = copyObject(measureFlow);
          const last = updateSummaryRequest(
            copy.vehicleSummaryRequests,
            parseInt(vehicleId, 10)
          );
          if (last) {
            copy.navigateToSummary = true;
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { calculating: false },
            });
          }
          yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
        }
        const modelFlow = yield select(getModelWorkflow);
        if (modelFlow.checkSummaryAndFetch && modelFlow.hasSummary) {
          yield put({
            type: appActions.SET_APP_STATE,
            payload: { calculating: false },
          });
          let copy = copyObject(modelFlow);
          copy.checkSummaryAndFetch = false;
          yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
        }
      }
    },

    function* watchSetModelWorkflow() {
      while (true) {
        const { payload: flow } = yield take(appActions.SET_MODEL_WORKFLOW);
        if (flow.createVehicleWithScheduleGroup) {
          let copy = copyObject(flow);
          if (copy.createActiveScheduleGroup) {
            const scheduleGroup = yield select(getActiveScheduleGroup);

            yield fork(projectApi.createScheduleGroup, scheduleGroup);

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });
            return;
          } else if (copy.createActiveVehicle) {
            const vehicle = yield select(getActiveVehicle);

            yield put(projectsApi.endpoints.createVehicle.initiate(vehicle));
            // yield fork(projectApi.createVehicle, vehicle);
            copy.savedVehicleId = 0;

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
            return;
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });

            copy.createVehicleWithScheduleGroup = false;

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
          }
        } else if (flow.updateVehicleWithSchedule) {
          let copy = copyObject(flow);
          if (copy.updateActiveVehicle) {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: true },
            });

            const vehicle = yield select(getActiveVehicle);

            yield put(projectsApi.endpoints.updateVehicle.initiate(vehicle));
            // yield fork(projectApi.updateVehicle, vehicle);

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
            return;
          } else if (copy.updateActiveSchedule) {
            const schedule = yield select(getActiveSchedule);

            if (!schedule.id || schedule.id === "new") {
              let copySchedule = copyObject(schedule);
              copySchedule.vehicle_id = copy.savedVehicleId;
              yield put(
                projectsApi.endpoints.createSchedule.initiate(copySchedule)
              );
              // yield fork(projectApi.createSchedule, copySchedule);
            } else {
              yield put(
                projectsApi.endpoints.updateSchedule.initiate(schedule)
              );
              // yield fork(projectApi.updateSchedule, schedule);
            }

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
            return;
          } else {
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { saving: false },
            });

            copy.updateVehicleWithSchedule = false;

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
          }
        } else if (flow.checkSummaryAndFetch) {
          let copy = copyObject(flow);
          if (copy.checkSummary) {
            yield fork(
              projectApi.checkOperationsSummary,
              copy.projectId,
              copy.vehicleId
            );
          } else if (copy.hasSummary) {
            yield fork(
              projectApi.getOperationsSummary,
              copy.projectId,
              copy.vehicleId
            );
            yield put({
              type: appActions.SET_APP_STATE,
              payload: { calculating: true },
            });
            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
          } else {
            copy.checkSummaryAndFetch = false;
            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
          }
        }
      }
    },
    function* watchGetClients() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_CLIENTS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "clients" });

        yield put(projectsApi.endpoints.getClients.initiate());
        // yield fork(projectApi.getClients);
      }
    },
    function* watchReceivedClients() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_CLIENTS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "clients" });
        yield call(checkNavigation);
        yield call(checkForDataUpdate);
      }
    },
    function* watchCreateClient() {
      while (true) {
        const { payload: client } = yield take(
          projectActions.PROJECTS_CREATE_CLIENT
        );
        yield put(projectsApi.endpoints.createClient.initiate(client));
        // yield fork(projectApi.createClient, client);
      }
    },
    function* watchReceivedCreateClient() {
      while (true) {
        const { payload: client } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_CLIENT
        );

        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });

        // Update the policy document to pickup this new client
        yield put({ type: portalActions.PORTAL_GET_POLICY_DOCUMENT });
      }
    },

    function* watchUpdateClient() {
      while (true) {
        const { payload: client } = yield take(
          projectActions.PROJECTS_UPDATE_CLIENT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.updateClient.initiate(client));
        // yield fork(projectApi.updateClient, client);
      }
    },
    function* watchReceivedUpdateClient() {
      while (true) {
        const { payload: client } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_CLIENT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
      }
    },

    // CONTACTS
    function* watchCreateContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_CREATE_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        const { data: result } = yield putResolve(
          projectsApi.endpoints.createContact.initiate(contact)
        );
        // const result = yield call(projectApi.createContact, contact);
        if (contact.resource_id) {
          const teams = yield select(getTeams);
          const resource = teams.resources.find(
            (r) => r.id === contact.resource_id
          );
          if (resource) {
            let copy = copyObject(resource);
            copy.portal_contact_id = result.portal_contact_id;
            yield call(projectApi.updateResource, copy);
          }
        }
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        const client = yield call(findClient, result.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        yield put({ type: appActions.SET_ACTIVE_CONTACT, payload: contact });
      }
    },

    function* watchCreateWorkflowContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_CREATE_WORKFLOW_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.createWorkflowContact, contact);
      }
    },
    function* watchRecivedCreateWFContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        const client = yield call(findClient, contact.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        yield put({ type: appActions.SET_ACTIVE_CONTACT, payload: contact });

        const prj = yield select(getActiveProject);
        let prjCopy = copyObject(prj);
        let mwf = prjCopy.project_workflows.find((wf) => {
          return wf.workflow_type === "measure";
        });
        if (!mwf.workflow_data) mwf.workflow_data = {};
        if (!mwf.workflow_data.workflow_contacts)
          mwf.workflow_data.workflow_contacts = [];
        mwf.workflow_data.workflow_contacts.push(contact.id);
        yield fork(projectApi.updateProject, prjCopy);
      }
    },

    function* watchCreateFacilityContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_CREATE_FACILITY_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(
          projectsApi.endpoints.createFacilityContact.initiate(contact)
        );
        // yield fork(projectApi.createFacilityContact, contact);
      }
    },
    function* watchReceivedCreateFacilityContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_FACILITY_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        yield put({
          type: projectActions.PROJECTS_RECEIVED_CREATE_CONTACT,
          payload: contact,
        });
        const activeFacility = yield select(getActiveFacility);
        let facilityCopy = copyObject(activeFacility);
        facilityCopy.facility_contacts.push(contact.id);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.updateFacility.initiate(facilityCopy));
        // yield fork(projectApi.updateFacility, facilityCopy);
      }
    },

    function* watchCreateFacilityAddress() {
      while (true) {
        const { payload: address } = yield take(
          projectActions.PROJECTS_CREATE_FACILITY_ADDRESS
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        const { data: updatedAddress } = yield putResolve(
          projectsApi.endpoints.createFacilityAddress.initiate(address)
        );
        /* const updatedAddress = yield call(
          projectApi.createFacilityAddress,
          address
        ); */
        const activeFacility = yield select(getActiveFacility);
        let facilityCopy = copyObject(activeFacility);
        facilityCopy.address_id = updatedAddress.id;
        yield putResolve(
          projectsApi.endpoints.updateFacility.initiate(facilityCopy)
        );
        //yield call(projectApi.updateFacility, facilityCopy);
        yield call(updateActiveClient);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },

    function* watchUpdateContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_UPDATE_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.updateContact.initiate(contact));
        // yield fork(projectApi.updateContact, contact);
      }
    },
    function* watchReceivedUpdateContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        const { data: client } = yield putResolve(
          projectsApi.endpoints.getClient.initiate(contact.client_id)
        );
        // const client = yield call(findClient, contact.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        yield put({ type: appActions.SET_ACTIVE_CONTACT, payload: contact });
      }
    },

    function* watchDeleteContact() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_DELETE_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.deleteContact.initiate(id));
        // yield fork(projectApi.deleteContact, id);
      }
    },
    function* watchReceivedDeleteContact() {
      while (true) {
        const { payload: contact } = yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_CONTACT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        const client = yield call(findClient, contact.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
      }
    },

    //FACILITIES
    function* watchCreateFacility() {
      while (true) {
        const { payload: facility } = yield take(
          projectActions.PROJECTS_CREATE_FACILITY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.createFacility.initiate(facility));
        // yield fork(projectApi.createFacility, facility);
      }
    },
    function* watchReceivedCreateFacility() {
      while (true) {
        const { payload: facility } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_FACILITY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });

        const flow = yield select(getAddProjectFlow);

        if (flow.flowProjectFacility && flow.savedFacilityId === 0) {
          let copyFlow = copyObject(flow);
          copyFlow.savedFacilityId = facility.id;
          yield put({
            type: appActions.SET_ADD_PROJECT_FLOW,
            payload: copyFlow,
          });
        }
        const { data: client } = yield putResolve(
          projectsApi.endpoints.getClient.initiate(facility.client_id)
        );
        //  const client = yield call(findClient, facility.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        yield put({ type: appActions.SET_ACTIVE_FACILITY, payload: facility });
      }
    },

    function* watchUpdateFacility() {
      const { payload: facility } = yield take(
        projectActions.PROJECTS_UPDATE_FACILITY
      );
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
      yield put(projectsApi.endpoints.updateFacility.initiate(facility));
      // yield call(projectApi.updateFacility, facility);
      const client = yield call(findClient, facility.client_id);
      yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
      yield put({ type: appActions.SET_ACTIVE_FACILITY, payload: facility });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    },

    function* watchDeleteFacility() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_DELETE_FACILITY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.deleteFacility.initiate(id));
        // yield fork(projectApi.deleteFacility, id);
      }
    },
    function* watchDeleteFacilityReceived() {
      while (true) {
        const { payload: facility } = yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_FACILITY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        const client = yield call(findClient, facility.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
      }
    },

    function* watchCreateProject() {
      while (true) {
        const { payload: project } = yield take(
          projectActions.PROJECTS_CREATE_PROJECT
        );
        yield put(projectsApi.endpoints.createProject.initiate(project));
        // yield fork(projectApi.createProject, project);
      }
    },
    function* watchReceivedCreateProject() {
      while (true) {
        const { payload: project } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_PROJECT
        );

        const flow = yield select(getAddProjectFlow);
        if (flow.flowProjectSave) {
          let copyFlow = copyObject(flow);
          copyFlow.saveActiveProject = false;

          yield put({
            type: appActions.SET_ADD_PROJECT_FLOW,
            payload: copyFlow,
          });
        }

        yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: project });
      }
    },
    function* watchUpdateProject() {
      while (true) {
        const { payload: project } = yield take(
          projectActions.PROJECTS_UPDATE_PROJECT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield put(projectsApi.endpoints.updateProject.initiate(project));
        // yield fork(projectApi.updateProject, project);
      }
    },
    function* watchReceivedUpdateProject() {
      while (true) {
        const { payload: project } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_PROJECT
        );
        yield call(updateActiveProject, project.id);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        const flow = yield select(getMeasureWorkflow);
        //   if (flow.updateProjectData) {
        //     let copy = copyObject(flow);
        //     copy.updateProjectData = false;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   } else if (flow.createPowerLoggerAssignment && flow.updateActiveProject) {
        //     let copy = copyObject(flow);
        //     copy.updateActiveProject = false;
        //     copy.createActiveLoggerAssignment = true;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   } else if (flow.clearLoggerAssignments && flow.updateActiveProject) {
        //     let copy = copyObject(flow);
        //     copy.updateActiveProject = false;
        //     copy.removeAllAssignments = true;
        //     copy.currentAssignment = 0;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   }
        if (
          flow.getSummaryDataAndNavigateToReport &&
          flow.updateActiveProject
        ) {
          let copy = copyObject(flow);
          copy.updateActiveProject = false;
          copy.getVehicleSummaries = true;
          yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
        }
      }
    },
    function* watchGetPowerLoggers() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_POWER_LOGGERS);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "powerLoggers",
        });
        yield put(projectsApi.endpoints.getPowerLoggers.initiate());
      }
    },
    function* watchReceivedGetPowerLoggers() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_POWER_LOGGERS);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "powerLoggers",
        });
      }
    },
    function* watchCreateVehicle() {
      while (true) {
        const { payload: vehicle } = yield take(
          projectActions.PROJECTS_CREATE_VEHICLE
        );
        console.log("workflow id of vehicle to be is", vehicle.workflow_id);
        yield put(projectsApi.endpoints.createVehicle.initiate(vehicle));
        // yield fork(projectApi.createVehicle, vehicle);
      }
    },

    function* watchReceivedCreateVehicle() {
      while (true) {
        const { payload: vehicle } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_VEHICLE
        );
        //   let flow = yield select(getMeasureWorkflow);
        //   if (flow.createActiveVehicle) {
        //     let copy = copyObject(flow);
        //     copy.savedVehicleId = vehicle.id;
        //     copy.createActiveVehicle = false;
        //     copy.createActiveSchedule = true;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   }
        let flow = yield select(getModelWorkflow);
        if (flow.createVehicleWithScheduleGroup) {
          if (flow.createActiveVehicle) {
            let copy = copyObject(flow);

            copy.savedVehicleId = vehicle.id;
            copy.createActiveVehicle = false;

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
          }
        }
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
      }
    },

    function* watchReceivedCreateVehicle() {
      while (true) {
        const { payload: vehicle } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_VEHICLE
        );
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
        //   let flow = yield select(getMeasureWorkflow);
        //   if (flow.createActiveVehicle) {
        //     let copy = copyObject(flow);
        //     copy.savedVehicleId = vehicle.id;
        //     copy.createActiveVehicle = false;
        //     copy.createActiveSchedule = true;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   }
        let flow = yield select(getModelWorkflow);
        if (flow.createActiveVehicle) {
          let copy = copyObject(flow);

          copy.savedVehicleId = vehicle.id;
          copy.createActiveVehicle = false;
          copy.createScheduleGroup = true;

          yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
        }
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
      }
    },

    function* watchUpdateVehicle() {
      while (true) {
        const { payload: vehicle } = yield take(
          projectActions.PROJECTS_UPDATE_VEHICLE
        );
        yield put(projectsApi.endpoints.updateVehicle.initiate(vehicle));
        // yield fork(projectApi.updateVehicle, vehicle);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateVehicle() {
      while (true) {
        const { payload: vehicle } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_VEHICLE
        );
        let flow = yield select(getMeasureWorkflow);
        let appState = yield select(getAppState);
        let activeVehicle = yield select(getActiveVehicle);
        if (flow.updateActiveVehicle) {
          let copy = copyObject(flow);

          copy.updateActiveVehicle = false;
          if (copy.updateVehicleWithSchedule) {
            copy.updateActiveSchedule = true;
            copy.savedVehicleId = vehicle.id;
          }

          yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
        } else if (flow.updateAllVehicles) {
          let copy = copyObject(flow);

          const index = copy.vehicleUpdates.indexOf(vehicle.id);
          if (index !== -1) {
            copy.vehicleUpdates.splice(index, 1);
          } else {
            copy.updateAllVehicles = false;
          }

          yield put({ type: appActions.SET_MEASURE_WORKFLOW, payload: copy });
        }
        flow = yield select(getModelWorkflow);
        if (flow.updateActiveVehicle) {
          let copy = copyObject(flow);

          copy.updateActiveVehicle = false;
          if (copy.updateVehicleWithSchedule) {
            copy.updateActiveSchedule = true;
            copy.savedVehicleId = vehicle.id;
          }

          yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
        }

        if (!appState.blockActiveVehicleUpdate) {
          yield call(updateActiveProject);
          if (activeVehicle && activeVehicle.id === vehicle.id) {
            yield put({
              type: appActions.SET_ACTIVE_VEHICLE,
              payload: vehicle,
            });
          }
        }

        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteVehicle() {
      while (true) {
        const { payload: vehicleId } = yield take(
          projectActions.PROJECTS_DELETE_VEHICLE
        );
        yield fork(projectApi.deleteVehicle, vehicleId);
      }
    },
    function* watchReceivedDeleteVehicle() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_VEHICLE);
        yield call(updateActiveProject);
      }
    },
    function* watchCreateScheduleGroup() {
      while (true) {
        const { payload: scheduleGroup } = yield take(
          projectActions.PROJECTS_CREATE_SCHEDULE_GROUP
        );
        yield fork(projectApi.createScheduleGroup, scheduleGroup);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateScheduleGroup() {
      while (true) {
        const { payload: scheduleGroup } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP
        );
        let flow = yield select(getModelWorkflow);
        if (
          flow.createVehicleWithScheduleGroup &&
          flow.createActiveScheduleGroup
        ) {
          let copy = copyObject(flow);
          let vehicle = yield select(getActiveVehicle);

          vehicle.schedule_group_id = scheduleGroup.id;
          yield put({ type: appActions.SET_ACTIVE_VEHICLE, payload: vehicle });

          copy.createActiveScheduleGroup = false;
          copy.createActiveVehicle = true;

          yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
        }
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateScheduleGroup() {
      while (true) {
        const { payload: scheduleGroup } = yield take(
          projectActions.PROJECTS_UPDATE_SCHEDULE_GROUP
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.updateScheduleGroup, scheduleGroup);
      }
    },
    function* watchReceivedUpdateScheduleGroup() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
      }
    },
    function* watchDeleteScheduleGroup() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_DELETE_SCHEDULE_GROUP
        );
        yield fork(projectApi.deleteScheduleGroup, id);
      }
    },
    function* watchReceivedDeleteScheduleGroup() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP);
        yield call(updateActiveProject);
      }
    },
    function* watchCreateSchedule() {
      while (true) {
        const { payload: schedule } = yield take(
          projectActions.PROJECTS_CREATE_SCHEDULE
        );
        yield fork(projectApi.createSchedule, schedule);
      }
    },
    function* watchReceivedCreateSchedule() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_SCHEDULE);
        //   let flow = yield select(getMeasureWorkflow);
        //   if (flow.createActiveSchedule) {
        //     let copy = copyObject(flow);
        //     copy.createActiveSchedule = false;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   } else if (flow.updateActiveSchedule) {
        //     let copy = copyObject(flow);
        //     copy.updateActiveSchedule = false;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   }

        let flow = yield select(getModelWorkflow);
        if (flow.createActiveSchedule) {
          let copy = copyObject(flow);

          copy.createActiveSchedule = false;

          yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
        }
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
      }
    },
    function* watchUpdateSchedule() {
      while (true) {
        const { payload: schedule } = yield take(
          projectActions.PROJECTS_UPDATE_SCHEDULE
        );
        yield put(projectsApi.endpoints.updateSchedule.initiate(schedule));
        // yield fork(projectApi.updateSchedule, schedule);
      }
    },
    function* watchReceivedUpdateSchedule() {
      while (true) {
        const { payload: schedule } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_SCHEDULE
        );
        //   let flow = yield select(getMeasureWorkflow);
        //   if (flow.updateActiveSchedule) {
        //     let copy = copyObject(flow);
        //     copy.updateActiveSchedule = false;
        //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
        //   }

        let flow = yield select(getModelWorkflow);
        if (flow.updateActiveSchedule) {
          let copy = copyObject(flow);

          copy.updateActiveSchedule = false;

          yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
        }
        yield call(updateActiveProject);
        const activeVehicle = yield select(getActiveVehicle);
        if (activeVehicle.id === schedule.vehicle_id) {
          yield call(updateActiveVehicle);
        }
      }
    },
    function* watchDeleteSchedule() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_DELETE_SCHEDULE
        );
        yield fork(projectApi.deleteSchedule, id);
      }
    },
    function* watchReceivedDeleteSchedule() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_SCHEDULE);
        yield call(updateActiveProject);
        yield call(updateActiveVehicle);
      }
    },
    function* watchCreateLoggerAssignment() {
      while (true) {
        const { payload: assignment } = yield take(
          projectActions.PROJECTS_CREATE_LOGGER_ASSIGNMENT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.assignPowerLogger, assignment);
      }
    },
    function* watchReceivedCreateLoggerAssignment() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeletePowerLoggerAssignment() {
      while (true) {
        const { payload: keys } = yield take(
          projectActions.PROJECTS_DELETE_LOGGER_ASSIGNMENT
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(
          projectApi.deletePowerLoggerAssignment,
          keys.id,
          keys.power_logger_id
        );
      }
    },
    function* watchReceivedDeleteLoggerAssignment() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    //     function* watchReceivedCreateLoggerAssignment() {
    //       while (true) {
    //         yield take(projectActions.PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT);
    //         //   const flow = yield select(getMeasureWorkflow);
    //       //   if (flow.createActiveLoggerAssignment) {
    //       //     let copy = copyObject(flow);
    //       //     copy.createActiveLoggerAssignment = false;
    //       //     yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
    //       //   }
    //       }
    //     },
    //     function* watchReceivedDeleteLoggerAssignment() {
    //       while (true) {
    //         const {payload: assign} = yield take(projectActions.PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT);
    //         const flow = yield select(getMeasureWorkflow);
    //         if (flow.clearLoggerAssignments) {
    //           let copy = copyObject(flow);

    //           const index = copy.deletedAssignments.indexOf(assign.id);
    //           if (index !== -1) {
    //             copy.deletedAssignments.splice(index, 1);
    //           } else {
    //             copy.removeAllAssignments = false;
    //           }

    //           yield put({type: appActions.SET_MEASURE_WORKFLOW, payload: copy});
    //         }
    //       }
    //     },

    function* watchCreateWorkflow() {
      while (true) {
        const {
          payload: { projectId, workflowType, workflowData },
        } = yield take(projectActions.PROJECTS_CREATE_WORKFLOW);
        yield fork(
          projectApi.createWorkflow,
          projectId,
          workflowType,
          workflowData
        );
      }
    },
    function* watchReceivedCreateWorkflow() {
      while (true) {
        const { payload: workflow } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_WORKFLOW
        );
        yield call(updateActiveProject, workflow.project_id);
        if (workflow.workflow_type === "monitor") {
          yield call(projectApi.getFleets);
          yield call(updateActiveFleet);
        }
      }
    },
    function* watchSetActiveProject() {
      while (true) {
        const { payload: project } = yield take(appActions.SET_ACTIVE_PROJECT);
        const client = yield call(findClient, project.client_id);
        yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
      }
    },
    function* watchGetOperationsDetail() {
      while (true) {
        //eslint-disable-next-line
        const {
          payload: { projectId, vehicleId, date },
        } = yield take(projectActions.PROJECTS_GET_OPERATIONS_DETAIL);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `detail-${vehicleId}-${date}`,
        });
        yield fork(projectApi.getOperationsDetail, projectId, vehicleId, date);
      }
    },
    function* watchReceivedOperationsDetail() {
      while (true) {
        //eslint-disable-next-line
        const {
          payload: { vehicle_id, date },
        } = yield take(projectActions.PROJECTS_RECEIVED_OPERATIONS_DETAIL);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `detail-${vehicle_id}-${date}`,
        });
      }
    },
    function* watchGetBatteries() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_BATTERIES);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "batteries",
        });
        yield fork(projectApi.getBatteries);
      }
    },
    function* watchReceivedBatteries() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_BATTERIES);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "batteries" });
      }
    },
    function* watchCreateBattery() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_CREATE_BATTERY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.createBattery, data);
      }
    },
    function* watchReceivedCreateBattery() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_BATTERY);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateBattery() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_UPDATE_BATTERY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.updateBattery, data);
      }
    },
    function* watchReceivedUpdateBattery() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_BATTERY);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteBattery() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_DELETE_BATTERY
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.deleteBattery, id);
      }
    },
    function* watchReceivedDeleteBattery() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_BATTERY);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetChargers() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_CHARGERS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "chargers" });
        yield fork(projectApi.getChargers);
      }
    },
    function* watchReceivedChargers() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_CHARGERS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "chargers" });
      }
    },
    function* watchCreateCharger() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_CREATE_CHARGER
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.createCharger, data);
      }
    },
    function* watchReceivedCreateCharger() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_CHARGER);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateCharger() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_UPDATE_CHARGER
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.updateCharger, data);
      }
    },
    function* watchReceivedUpdateCharger() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_CHARGER);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteCharger() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_DELETE_CHARGER
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.deleteCharger, id);
      }
    },
    function* watchReceivedDeleteCharger() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_CHARGER);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetTeams() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_TEAMS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "teams" });
        yield fork(projectApi.getTeams);
      }
    },
    function* watchReceivedTeams() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_TEAMS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "teams" });
      }
    },
    function* watchCreateTeam() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_CREATE_TEAM
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.createTeam, data);
      }
    },
    function* watchReceivedCreateTeam() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_TEAM);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateTeam() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_UPDATE_TEAM
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.updateTeam, data);
      }
    },
    function* watchReceivedUpdateTeam() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_TEAM);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteTeam() {
      while (true) {
        const { payload: id } = yield take(projectActions.PROJECTS_DELETE_TEAM);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.deleteTeam, id);
      }
    },
    function* watchReceivedDeleteTeam() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_TEAM);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetMotivePower() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_MOTIVE_POWER);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "motivePower",
        });
        yield fork(projectApi.getMotivePower);
      }
    },
    function* watchReceivedGetMotivePower() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_MOTIVE_POWER);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "motivePower",
        });
      }
    },
    function* watchGetTaskData() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_TASK_DATA);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "taskData" });
        yield fork(projectApi.getTaskData);
      }
    },
    function* watchReceivedGetTaskData() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_TASK_DATA);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "taskData" });
      }
    },
    function* watchGetUsers() {
      while (true) {
        yield take(portalActions.PORTAL_GET_USERS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "users" });
        // yield fork(portalApi.getUsers);
        yield put(PortalService.endpoints.getUsers.initiate());
      }
    },
    function* watchReceivedGetUsers() {
      while (true) {
        yield take(portalActions.PORTAL_RECEIVED_GET_USERS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "users" });
      }
    },
    function* watchUpdateUser() {
      while (true) {
        const { payload: user } = yield take(portalActions.PORTAL_UPDATE_USER);
        yield put({ type: appActions.SET_APP_STATE, saving: true });
        yield call(portalApi.api_updateUser, user);
        if (user.password) {
          yield put({
            type: appActions.SHOW_ALERT,
            payload: { msg: strings.passwordSuccessfullyUpdated, type: "info" },
          });
        }
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "teams" });
        yield call(projectApi.getTeams);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "teams" });

        yield put({ type: appActions.SET_APP_STATE, saving: false });
      }
    },
    function* watchGetAssets() {
      while (true) {
        yield take(portalActions.PORTAL_GET_ASSETS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "assets" });
        yield put(PortalService.endpoints.getAssets.initiate());
        // yield fork(portalApi.getAssets);
      }
    },
    function* watchReceivedGetAssets() {
      while (true) {
        yield take(portalActions.PORTAL_RECEIVED_GET_ASSETS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "assets" });
        yield call(checkForDataUpdate);
      }
    },
    function* watchGetTasks() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_TASKS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "tasks" });
        yield fork(projectApi.getAllTasks);
      }
    },
    function* watchReceivedGetTasks() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_TASKS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "tasks" });
      }
    },
    function* watchCreateTask() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_CREATE_TASK
        );
        yield fork(projectApi.createTask, data);
      }
    },
    function* watchUpdateTask() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_UPDATE_TASK
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.updateTask, data);
      }
    },
    function* watchReceivedUpdateTask() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_TASK);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchCompleteTask() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_COMPLETE_TASK
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.completeTask, id);
      }
    },
    function* watchReceivedCompleteTask() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_COMPLETE_TASK);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteTask() {
      while (true) {
        const { payload: id } = yield take(projectActions.PROJECTS_DELETE_TASK);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
        yield fork(projectApi.deleteTask, id);
      }
    },
    function* watchReceivedDeleteTask() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_TASK);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetFleets() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_FLEETS);
        yield put({ type: appActions.SET_DATA_REQUESTED, payload: "fleets" });
        //yield fork(projectApi.getFleets);
        const fleets = yield call(projectsApi.endpoints.getFleets.initiate);
        yield put(fleets);
      }
    },
    function* watchReceivedGetFleets() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_FLEETS);
        yield put({ type: appActions.SET_DATA_RECEIVED, payload: "fleets" });
      }
    },
    function* watchGetFleetData() {
      while (true) {
        const { payload: id } = yield take(
          projectActions.PROJECTS_GET_FLEET_DATA
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `fleets-${id}`,
        });
        yield fork(projectApi.getFleet, id);
      }
    },
    function* watchReceivedGetFleetData() {
      while (true) {
        const { payload: fleet } = yield take(
          projectActions.PROJECTS_RECEIVED_GET_FLEET_DATA
        );
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `fleets-${fleet.id}`,
        });
        const activeProject = yield select(getActiveProject);

        if (activeProject && activeProject.id === fleet.project_id) {
          yield put({ type: appActions.SET_ACTIVE_FLEET, payload: fleet });
        }
      }
    },
    function* watchCreateFleet() {
      while (true) {
        const { payload: fleet } = yield take(
          projectActions.PROJECTS_CREATE_FLEET
        );
        yield fork(projectApi.createFleet, fleet);
      }
    },
    function* watchReceivedCreateFleet() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_FLEET);
      }
    },
    function* watchGetFleetZones() {
      while (true) {
        const { payload: fleetId } = yield take(
          projectActions.PROJECTS_GET_FLEET_ZONES
        );
        yield fork(projectApi.getZones, fleetId);
      }
    },
    function* watchReceivedGetFleetZones() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_FLEET_ZONES);
      }
    },
    function* watchCreateFleetZone() {
      while (true) {
        const {
          payload: { fleetId, zone },
        } = yield take(projectActions.PROJECTS_CREATE_FLEET_ZONE);
        yield fork(projectApi.createZone, fleetId, zone);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateFleetZone() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ZONE);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateFleetZone() {
      while (true) {
        const {
          payload: { fleetId, zone },
        } = yield take(projectActions.PROJECTS_UPDATE_FLEET_ZONE);
        yield fork(projectApi.updateZone, fleetId, zone);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateFleetZone() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ZONE);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteFleetZone() {
      while (true) {
        const {
          payload: { fleetId, zoneId },
        } = yield take(projectActions.PROJECTS_DELETE_FLEET_ZONE);
        yield fork(projectApi.deleteZone, fleetId, zoneId);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedDeleteFleetZone() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ZONE);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetFleetAssetTemplates() {
      while (true) {
        const { payload: fleetId } = yield take(
          projectActions.PROJECTS_GET_FLEET_ASSET_TEMPLATES
        );
        yield fork(projectApi.getAssetTemplates, fleetId);
      }
    },
    function* watchReceivedGetFleetAssetTemplates() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSET_TEMPLATES);
      }
    },
    function* watchCreateFleetAssetTemplate() {
      while (true) {
        const {
          payload: { fleetId, assetTemplate },
        } = yield take(projectActions.PROJECTS_CREATE_FLEET_ASSET_TEMPLATE);
        yield fork(projectApi.createAssetTemplate, fleetId, assetTemplate);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateFleetAssetTemplate() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSET_TEMPLATE
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateFleetAssetTemplate() {
      while (true) {
        const {
          payload: { fleetId, assetTemplate },
        } = yield take(projectActions.PROJECTS_UPDATE_FLEET_ASSET_TEMPLATE);
        yield fork(projectApi.updateAssetTemplate, fleetId, assetTemplate);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateFleetAssetTemplate() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSET_TEMPLATE
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteFleetAssetTemplate() {
      while (true) {
        const {
          payload: { fleetId, assetTemplateId },
        } = yield take(projectActions.PROJECTS_DELETE_FLEET_ASSET_TEMPLATE);
        yield fork(projectApi.deleteAssetTemplate, fleetId, assetTemplateId);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedDeleteFleetAssetTemplate() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSET_TEMPLATE
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetFleetAssociationTemplates() {
      while (true) {
        const { payload: fleetId } = yield take(
          projectActions.PROJECTS_GET_FLEET_ASSOCIATION_TEMPLATES
        );
        yield fork(projectApi.getAssociationTemplates, fleetId);
      }
    },
    function* watchReceivedGetFleetAssociationTemplates() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSOCIATION_TEMPLATES
        );
      }
    },
    function* watchCreateFleetAssociationTemplate() {
      while (true) {
        const {
          payload: { fleetId, associationTemplate },
        } = yield take(
          projectActions.PROJECTS_CREATE_FLEET_ASSOCIATION_TEMPLATE
        );
        yield fork(
          projectApi.createAssociationTemplate,
          fleetId,
          associationTemplate
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateFleetAssociationTemplate() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION_TEMPLATE
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateFleetAssociationTemplate() {
      while (true) {
        const {
          payload: { fleetId, associationTemplate },
        } = yield take(
          projectActions.PROJECTS_UPDATE_FLEET_ASSOCIATION_TEMPLATE
        );
        yield fork(
          projectApi.updateAssociationTemplate,
          fleetId,
          associationTemplate
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateFleetAssociationTemplate() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION_TEMPLATE
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteFleetAssociationTemplate() {
      while (true) {
        const {
          payload: { fleetId, associationTemplateId },
        } = yield take(
          projectActions.PROJECTS_DELETE_FLEET_ASSOCIATION_TEMPLATE
        );
        yield fork(
          projectApi.deleteAssociationTemplate,
          fleetId,
          associationTemplateId
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedDeleteFleetAssociationTemplate() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION_TEMPLATE
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetFleetAssociation() {
      while (true) {
        const { payload: fleetId } = yield take(
          projectActions.PROJECTS_GET_FLEET_ASSOCIATIONS
        );
        yield fork(projectApi.getAssociations, fleetId);
      }
    },
    function* watchReceivedGetFleetAssociation() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSOCIATIONS);
      }
    },
    function* watchCreateFleetAssociation() {
      while (true) {
        const {
          payload: { fleetId, association },
        } = yield take(projectActions.PROJECTS_CREATE_FLEET_ASSOCIATION);
        yield fork(projectApi.createAssociation, fleetId, association);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateFleetAssociation() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateFleetAssociation() {
      while (true) {
        const {
          payload: { fleetId, association },
        } = yield take(projectActions.PROJECTS_UPDATE_FLEET_ASSOCIATION);
        yield fork(projectApi.updateAssociation, fleetId, association);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateFleetAssociation() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteFleetAssociation() {
      while (true) {
        const {
          payload: { fleetId, associationId },
        } = yield take(projectActions.PROJECTS_DELETE_FLEET_ASSOCIATION);
        yield fork(projectApi.deleteAssociation, fleetId, associationId);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedDeleteFleetAssociation() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetFleetAssets() {
      while (true) {
        const { payload: fleetId } = yield take(
          projectActions.PROJECTS_GET_FLEET_ASSETS
        );
        yield fork(projectApi.getFleetAssets, fleetId);
      }
    },
    function* watchReceivedGetFleetAsset() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSETS);
      }
    },
    function* watchCreateFleetAsset() {
      while (true) {
        const {
          payload: { fleetId, asset },
        } = yield take(projectActions.PROJECTS_CREATE_FLEET_ASSET);
        yield fork(projectApi.createFleetAsset, fleetId, asset);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateFleetAsset() {
      while (true) {
        const { payload: asset } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSET
        );
        if (
          asset.asset_type === "power_logger_cellular" ||
          asset.asset_type === "power_logger_bluetooth"
        ) {
          yield put({ type: projectActions.PROJECTS_GET_POWER_LOGGERS });
        }
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateFleetAsset() {
      while (true) {
        const {
          payload: { fleetId, asset },
        } = yield take(projectActions.PROJECTS_UPDATE_FLEET_ASSET);
        yield fork(projectApi.updateFleetAsset, fleetId, asset);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateFleetAsset() {
      while (true) {
        const { payload: asset } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSET
        );
        if (
          asset.asset_type === "power_logger_cellular" ||
          asset.asset_type === "power_logger_bluetooth"
        ) {
          yield put({ type: projectActions.PROJECTS_GET_POWER_LOGGERS });
        }
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchRetireFleetAsset() {
      while (true) {
        const {
          payload: { fleetId, assetId, date },
        } = yield take(projectActions.PROJECTS_RETIRE_FLEET_ASSET);
        yield fork(projectApi.retireFleetAsset, fleetId, assetId, date);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedRetireFleetAsset() {
      while (true) {
        const { payload: asset } = yield take(
          projectActions.PROJECTS_RECEIVED_RETIRE_FLEET_ASSET
        );
        if (
          asset.asset_type === "power_logger_cellular" ||
          asset.asset_type === "power_logger_bluetooth"
        ) {
          yield put({ type: projectActions.PROJECTS_GET_POWER_LOGGERS });
        }
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteFleetAsset() {
      while (true) {
        const {
          payload: { fleetId, assetId },
        } = yield take(projectActions.PROJECTS_DELETE_FLEET_ASSET);
        yield fork(projectApi.deleteFleetAsset, fleetId, assetId);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedDeleteFleetAsset() {
      while (true) {
        const { payload: asset } = yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSET
        );
        if (
          asset.asset_type === "power_logger_cellular" ||
          asset.asset_type === "power_logger_bluetooth"
        ) {
          yield put({ type: projectActions.PROJECTS_GET_POWER_LOGGERS });
        }
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetTelemetryAssociation() {
      while (true) {
        const { payload: fleetId } = yield take(
          projectActions.PROJECTS_GET_TELEMETRY_ASSOCIATIONS
        );
        yield fork(projectApi.getTelemetryAssociations, fleetId);
      }
    },
    function* watchReceivedGetTelemetryAssociation() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_TELEMETRY_ASSOCIATIONS);
      }
    },
    function* watchCreateTelemetryAssociation() {
      while (true) {
        const {
          payload: { fleetId, association },
        } = yield take(projectActions.PROJECTS_CREATE_TELEMETRY_ASSOCIATION);
        yield fork(projectApi.createTelemetryAssociation, fleetId, association);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateTelemetryAssociation() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_TELEMETRY_ASSOCIATION
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateTelemetryAssociation() {
      while (true) {
        const {
          payload: { fleetId, association },
        } = yield take(projectActions.PROJECTS_UPDATE_TELEMETRY_ASSOCIATION);
        yield fork(projectApi.updateTelemetryAssociation, fleetId, association);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateTelemetryAssociation() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_TELEMETRY_ASSOCIATION
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchEndTelemetryAssociation() {
      while (true) {
        const {
          payload: { fleetId, associationId, date },
        } = yield take(projectActions.PROJECTS_END_TELEMETRY_ASSOCIATION);
        yield fork(
          projectApi.endTelemetryAssociation,
          fleetId,
          associationId,
          date
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedEndTelemetryAssociation() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_END_TELEMETRY_ASSOCIATION);
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchDeleteTelemetryAssociation() {
      while (true) {
        const {
          payload: { fleetId, associationId },
        } = yield take(projectActions.PROJECTS_DELETE_TELEMETRY_ASSOCIATION);
        yield fork(
          projectApi.deleteTelemetryAssociation,
          fleetId,
          associationId
        );
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedDeleteTelemetryAssociation() {
      while (true) {
        yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_TELEMETRY_ASSOCIATION
        );
        yield call(updateActiveFleet);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchGetUserConfig() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_USER_CONFIG);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "userConfig",
        });
        yield put(projectsApi.endpoints.getUserConfigs.initiate());
        // yield fork(projectApi.getUserConfig);
      }
    },
    function* watchReceivedGetUserConfig() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_USER_CONFIG);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "userConfig",
        });

        const userConfig = yield select(getUserConfig);
        const nav = yield select(getNav);
        if (nav.lastPath && nav.lastPath !== "/") {
          const params = urlUtils.getURLParams(
            userConfig.appConfig,
            nav.lastPath
          );
          yield put({
            type: appActions.SET_NAV,
            payload: params,
          });
        } else {
          yield put({
            type: appActions.SET_NAV,
            payload: {
              index: userConfig.appConfig.fallbackRoute.index,
              drawerOpen: false,
            },
          });
        }
      }
    },
    function* watchGetAlertConfigurations() {
      while (true) {
        yield take(projectActions.PROJECTS_GET_ALERT_CONFIGURATIONS);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "alertConfigurations",
        });
        // yield fork(projectApi.getAlertConfigurations);
        yield put(projectsApi.endpoints.getAlertConfigurations.initiate());
      }
    },
    function* watchReceivedGetAlertConfigurations() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "alertConfigurations",
        });
      }
    },
    function* watchCheckSummary() {
      while (true) {
        const {
          payload: { projectId, vehicleId },
        } = yield take(projectActions.PROJECTS_CHECK_OPERATIONS_SUMMARY);
        yield fork(projectApi.checkOperationsSummary, projectId, vehicleId);
      }
    },
    function* watchReceivedGetUsers() {
      while (true) {
        const { payload: result } = yield take(
          projectActions.PROJECTS_RECEIVED_CHECK_OPERATIONS_SUMMARY
        );
        const flow = yield select(getModelWorkflow);
        if (flow.checkSummaryAndFetch) {
          let copy = copyObject(flow);
          if (copy.checkSummary) {
            if (result.has_summary) {
              copy.checkSummary = false;
              copy.hasSummary = true;
            } else {
              copy.checkSummary = false;
              copy.hasSummary = false;
            }

            yield put({ type: appActions.SET_MODEL_WORKFLOW, payload: copy });
          }
        }
      }
    },
    function* watchSetActiveFleet() {
      while (true) {
        const { payload: fleet } = yield take(appActions.SET_ACTIVE_FLEET);
        const project = yield call(findProject, fleet.project_id);
        yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: project });
      }
    },
    function* watchCreateAlertConfiguration() {
      while (true) {
        const { payload: config } = yield take(
          projectActions.PROJECTS_CREATE_ALERT_CONFIGURATION
        );
        yield fork(projectApi.createAlertConfiguration, config);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedCreateAlertConfiguration() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUpdateAlertConfiguration() {
      while (true) {
        const { payload: config } = yield take(
          projectActions.PROJECTS_UPDATE_ALERT_CONFIGURATION
        );
        yield fork(projectApi.updateAlertConfiguration, config);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUpdateAlertConfiguration() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchUploadFleetData() {
      while (true) {
        const {
          payload: { fleetId, fleetData },
        } = yield take(projectActions.PROJECTS_BULK_UPLOAD_FLEET);
        yield fork(projectApi.uploadFleetData, fleetId, fleetData);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: true },
        });
      }
    },
    function* watchReceivedUploadFleetData() {
      while (true) {
        yield take(projectActions.PROJECTS_RECEIVED_BULK_UPLOAD_FLEET);
        yield put({
          type: appActions.SET_APP_STATE,
          payload: { saving: false },
        });
      }
    },
    function* watchCreateNote() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_CREATE_NOTE
        );
        yield put(projectsApi.endpoints.createNote.initiate(data));
        // yield fork(projectApi.createNote, data);
      }
    },
    function* watchReceivedCreateNote() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_RECEIVED_CREATE_NOTE
        );
        const active = yield select(getActiveClient);
        if (data.client_id === active.id) {
          const client = yield call(findClient, data.client_id);
          yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        }
      }
    },
    function* watchUpdateNote() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_UPDATE_NOTE
        );
        yield put(projectsApi.endpoints.updateNote.initiate(data));
        // yield fork(projectApi.updateNote, data);
      }
    },
    function* watchReceivedUpdateNote() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_RECEIVED_UPDATE_NOTE
        );
        const active = yield select(getActiveClient);
        if (data.client_id === active.id) {
          const client = yield call(findClient, data.client_id);
          yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        }
      }
    },
    function* watchDeleteNote() {
      while (true) {
        const { payload: id } = yield take(projectActions.PROJECTS_DELETE_NOTE);
        yield put(projectsApi.endpoints.deleteNote.initiate(id));
        // yield fork(projectApi.deleteNote, id);
      }
    },
    function* watchReceivedDeleteNote() {
      while (true) {
        const { payload: data } = yield take(
          projectActions.PROJECTS_RECEIVED_DELETE_NOTE
        );
        const active = yield select(getActiveClient);
        if (data.client_id === active.id) {
          const client = yield call(findClient, data.client_id);
          yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        }
      }
    },
    function* watchGetDeviceConfigs() {
      while (true) {
        yield take(configActions.CONFIG_GET_DEVICE_CONFIG);
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "deviceConfigFury",
        });
        yield fork(configApi.getDeviceConfig, "furyChargers");
      }
    },
    function* watchReceivedGetDeviceConfigs() {
      while (true) {
        yield take(configActions.CONFIG_RECEIVED_GET_DEVICE_CONFIG);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "deviceConfigFury",
        });
      }
    },
    function* watchGetChargerConfigs() {
      while (true) {
        const { payload: ids } = yield take(
          configActions.CONFIG_GET_CHARGER_CONFIGS
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "chargerConfigs",
        });
        yield call(configApi.getChargerConfigs, ids);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "chargerConfigs",
        });
      }
    },
    function* watchGetChargerConfig() {
      while (true) {
        const { payload: id } = yield take(
          configActions.CONFIG_GET_CHARGER_CONFIG
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `chargerConfig-${id}`,
        });
        yield call(configApi.getChargerConfig, id);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `chargerConfig-${id}`,
        });
      }
    },
    function* watchGetGatewayConfigs() {
      while (true) {
        const { payload: ids } = yield take(
          configActions.CONFIG_GET_GATEWAY_CONFIGS
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "gatewayConfigs",
        });
        yield call(configApi.getGatewayConfigs, ids);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "gatewayConfigs",
        });
      }
    },
    function* watchGetGatewayConfig() {
      while (true) {
        const { payload: id } = yield take(
          configActions.CONFIG_GET_GATEWAY_CONFIG
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `gatewayConfig-${id}`,
        });
        yield call(configApi.getGatewayConfig, id);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `gatewayConfig-${id}`,
        });
      }
    },
    function* watchGetBatteryConfigs() {
      while (true) {
        const { payload: ids } = yield take(
          configActions.CONFIG_GET_BATTERY_CONFIGS
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "batteryConfigs",
        });
        yield call(configApi.getBatteryConfigs, ids);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "batteryConfigs",
        });
      }
    },
    function* watchGetBatteryConfig() {
      while (true) {
        const { payload: id } = yield take(
          configActions.CONFIG_GET_BATTERY_CONFIG
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `batteryConfig-${id}`,
        });
        yield call(configApi.getBatteryConfig, id);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `batteryConfig-${id}`,
        });
      }
    },
    function* watchGetVanadiumBESSConfigs() {
      while (true) {
        const { payload: ids } = yield take(
          configActions.CONFIG_GET_VANADIUM_BESS_CONFIGS
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "vanadiumBESSConfigs",
        });
        yield call(configApi.getVanadiumBESSConfigs, ids);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "vanadiumBESSConfigs",
        });
      }
    },
    function* watchGetVanadiumBESSConfig() {
      while (true) {
        const { payload: id } = yield take(
          configActions.CONFIG_GET_VANADIUM_BESS_CONFIG
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `vanadiumBESSConfig-${id}`,
        });
        yield call(configApi.getVanadiumBESSConfig, id);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `vanadiumBESSConfig-${id}`,
        });
      }
    },
    function* watchGetLeadAcidBESSConfigs() {
      while (true) {
        const { payload: ids } = yield take(
          configActions.CONFIG_GET_LEADACID_BESS_CONFIGS
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: "leadAcidBESSConfigs",
        });
        yield call(configApi.getLeadAcidBESSConfigs, ids);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: "leadAcidBESSConfigs",
        });
      }
    },
    function* watchGetLeadAcidBESSConfig() {
      while (true) {
        const { payload: id } = yield take(
          configActions.CONFIG_GET_LEADACID_BESS_CONFIG
        );
        yield put({
          type: appActions.SET_DATA_REQUESTED,
          payload: `leadAcidBESSConfig-${id}`,
        });
        yield call(configApi.getLeadAcidBESSConfig, id);
        yield put({
          type: appActions.SET_DATA_RECEIVED,
          payload: `leadAcidBESSConfig-${id}`,
        });
      }
    },
    modelWorkflowFunctions.checkModelVehicleForPowerStudy,
    modelWorkflowFunctions.getModelDetailData,
    modelWorkflowFunctions.createModelVehicleWithScheduleGroup,
    modelWorkflowFunctions.updateModelScheduleGroup,
    modelWorkflowFunctions.updateModelVehicleArray,
    policyDocumentFunctions.watchGetPolicyDocument,
    configFunctions.watchUploadVersionFile,
    configFunctions.watchGetVersionMetas,
    teamFunctions.watchUpdateTeamStructure,
    configFunctions.watchUpdateChargerFactorySettings,
    configFunctions.watchUpdateChargerUserSettings,
    configFunctions.watchUpdateChargerSettings,
    configFunctions.watchCreateAssetAndConfiguration,
    configFunctions.watchUpdateVanadiumBESSFactorySettings,
    configFunctions.watchUpdateVanadiumBESSUserSettings,
    configFunctions.watchUpdateVanadiumBESSSettings,
    configFunctions.watchUpdateLeadAcidBESSFactorySettings,
    configFunctions.watchUpdateLeadAcidBESSUserSettings,
    configFunctions.watchUpdateLeadAcidBESSSettings,
    teamFunctions.watchUpdateClientGoals,
    addProjectWorkflowFunctions.watchSetAddProjectFlow,
    portalFunctions.watchUpdateUserProfileImage,
    portalFunctions.watchUpdateUserPassword,
    portalFunctions.watchUpdateUserSettings,
    portalFunctions.watchGetGroupDefinitions,
    portalFunctions.watchGetRoleDefinitions,
    portalFunctions.watchResetUserPassword,
    portalFunctions.watchMoveAssetList,
    clientFunctions.watchCreateClientWithOwner,
    clientFunctions.watchCreateFacilityWithAddress,
    clientFunctions.watchUpdateFacilityWithAddress,
    clientFunctions.watchUpdateClientWithOwner,
    clientFunctions.watchCreateContactWithFacility,
    clientFunctions.watchUpdateContactWithFacility,
    clientFunctions.watchDeleteContactWithFacility,
    clientFunctions.watchCreateLocationWithFacility,
    clientFunctions.watchUpdateAddress,
    tagFunctions.watchGetTags,
    tagFunctions.watchCreateTag,
    tagFunctions.watchDeleteTag,
    tagFunctions.watchCreateTagType,
    tagFunctions.watchUpdateTagType,
    tagFunctions.watchDeleteTagType,
    tagFunctions.watchCreateTagValue,
    tagFunctions.watchUpdateTagValue,
    tagFunctions.watchDeleteTagValue,
    tagFunctions.watchDoBatchTags,
    loggerFunctions.watchGetLoggerMostRecent,
    configFunctions.watchGetEncryptedGatewayPackage,
    configFunctions.watchUpdateGatewayFactorySettings,
    configFunctions.watchUpdateGatewayUserSettings,
    configFunctions.watchUpdateGatewaySettings,
    configFunctions.watchUpdateBatteryFactorySettings,
    configFunctions.watchUpdateBatteryUserSettings,
    configFunctions.watchUpdateBatterySettings,
    configFunctions.watchDeleteVersionMeta,
    newTeamFunctions.watchGetNewTeams,
    newTeamFunctions.watchCreateTeam,
    newTeamFunctions.watchUpdateTeam,
    newTeamFunctions.watchDeleteTeam,
    newTeamFunctions.watchCreateTeamResource,
    newTeamFunctions.watchDeleteTeamResource,
    viewManagerFunctions.watchGetUserViews,
    viewManagerFunctions.watchCreateUserInterface,
    viewManagerFunctions.watchUpdateUserInterface,
    viewManagerFunctions.watchCreateUserDashboard,
    viewManagerFunctions.watchDeleteUserDashboard,
    viewManagerFunctions.watchUpdateUserDashboards,
    viewManagerFunctions.watchCreateClientInterface,
    viewManagerFunctions.watchDeleteClientInterface,
    clientOwnerFunctions.watchGetClientOwners,
    clientOwnerFunctions.watchCreateClientOwner,
    clientOwnerFunctions.watchUpdateClientOwner,
    clientOwnerFunctions.watchDeleteClientOwner,
    customerGroupFunctions.watchGetCustomerGroups,
    customerGroupFunctions.watchCreateCustomerGroup,
    customerGroupFunctions.watchUpdateCustomerGroup,
    customerGroupFunctions.watchDeleteCustomerGroup,
    customerGroupFunctions.watchCreateCustomerGroupMember,
    customerGroupFunctions.watchDeleteCustomerGroupMember,
    thirdPartyFunctions.watchCreateThirdPartyUser,
    thirdPartyFunctions.watchUpdateThirdPartyUser,
    thirdPartyFunctions.watchDeleteThirdPartyUser,
    managementFunctions.watchSendResetEmail,
    fleetFunctions.watchIgnoreAMAssetList,
    fleetFunctions.watchUnIgnoreAMAssetList,
    fleetFunctions.watchClearAMUnexpectedAssetList,
    licenseFunctions.watchConsumeLicenses,
    licenseFunctions.watchGetLicenseReport,
    licenseFunctions.watchGetLicenseReportList,
    licenseFunctions.watchUpdateLicenses,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}

// ********************** Saga Functions ***********************
export function* checkForDataUpdate() {
  const auth = yield select(getAuth);
  if (!auth.auth_token) {
    yield put({
      type: appActions.SET_NAV,
      payload: { index: "login", drawerOpen: false, activeStepperDepth: 0 },
    });
    return;
  }
  const updates = yield select(getUpdates);
  const policyDocument = yield select(getPolicyDocument);

  let initial = true;

  yield putResolve(projectsApi.endpoints.getCacheTags.initiate());

  // TODO: Add timed based updates as well
  if (!updates.userConfig || !updates.userConfig.requested) {
    yield put({ type: projectActions.PROJECTS_GET_USER_CONFIG });
    if (!initial) {
      /* yield delay(1000); */
    }
  }
  if (!updates.clients || !updates.clients.requested) {
    yield put({ type: projectActions.PROJECTS_GET_CLIENTS });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (
    !updates.powerLoggers ||
    !updates.powerLoggers.requested ||
    moment().diff(updates.powerLoggers.lastReceived, "hours") > 1
  ) {
    yield put({ type: projectActions.PROJECTS_GET_POWER_LOGGERS });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.motivePower || !updates.motivePower.requested) {
    yield put({ type: projectActions.PROJECTS_GET_MOTIVE_POWER });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.tasks || !updates.tasks.requested) {
    yield put({ type: projectActions.PROJECTS_GET_TASKS });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.users || !updates.users.requested) {
    yield put({ type: portalActions.PORTAL_GET_USERS });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.alertConfigurations || !updates.alertConfigurations.requested) {
    yield put({ type: projectActions.PROJECTS_GET_ALERT_CONFIGURATIONS });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.fleets || !updates.fleets.requested) {
    yield put({ type: projectActions.PROJECTS_GET_FLEETS });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.policyDocument || !updates.policyDocument.requested) {
    yield put({ type: portalActions.PORTAL_GET_POLICY_DOCUMENT });
    if (!initial) {
      /* yield delay(1000); */
      initial = true;
    }
  }

  if (!updates.assets || !updates.assets.requested) {
    yield put({ type: portalActions.PORTAL_GET_ASSETS });
  }

  if (!updates.tags || !updates.tags.requested) {
    yield put({ type: projectActions.PROJECTS_GET_TAGS });
  }

  if (!updates.newTeams || !updates.newTeams.requested) {
    yield put({ type: projectActions.PROJECTS_GET_NEW_TEAMS });
  }

  if (!updates.userViews || !updates.userViews.requested) {
    yield put({ type: projectActions.PROJECTS_GET_USER_VIEWS });
  }

  if (!updates.customerGroups || !updates.customerGroups.requested) {
    yield put({ type: projectActions.PROJECTS_GET_CUSTOMER_GROUPS });
  }

  if (
    !updates.portalRoleDefinitions ||
    !updates.portalRoleDefinitions.requested
  ) {
    yield put({ type: portalActions.PORTAL_GET_ROLE_DEFINITIONS });
  }

  if (
    !updates.portalGroupDefinitions ||
    !updates.portalGroupDefinitions.requested
  ) {
    yield put({ type: portalActions.PORTAL_GET_GROUP_DEFINITIONS });
  }

  if (!updates.versionMetas || !updates.versionMetas.requested) {
    yield put({ type: configActions.CONFIG_GET_VERSIONS_META });
  }

  if (
    (!updates.deviceConfigFury || !updates.deviceConfigFury.requested) &&
    updates.policyDocument &&
    updates.policyDocument.received
  ) {
    if (hasNewPolicy(policyDocument, "Config_CanReadDeviceConfigs")) {
      yield put({
        type: configActions.CONFIG_GET_DEVICE_CONFIG,
        payload: { device: "furyChargers" },
      });
    }
  }
}

function* checkNavigation() {
  const nav = yield select(getNav);
  const activeProject = yield select(getActiveProject);
  const updates = yield select(getUpdates);
  let workflowName = null;
  if (nav.index === "monitor") {
    let project = null;
    if (nav.projectId && nav.projectId > 0) {
      if (_.isEmpty(activeProject) || activeProject.id !== nav.projectId) {
        project = yield call(findProject, nav.projectId);
      } else {
        project = activeProject;
      }
    }
    if (project && project.project_workflows) {
      const workflow = project.project_workflows.find(
        (wf) => wf.workflow_type === "monitor"
      );
      if (workflow) {
        workflowName = workflowNames[workflow.workflow_type];
        yield put({
          type: projectActions.PROJECTS_GET_FLEET_DATA,
          payload: workflow.workflow_data.fleet_id,
        });
      }
    }
  }
  if (
    (nav.index === "measureModel" || nav.index === "monitor") &&
    nav.projectId !== "new"
  ) {
    if (nav.projectId && nav.projectId > 0) {
      if (_.isEmpty(activeProject) || activeProject.id !== nav.projectId) {
        const project = yield call(findProject, nav.projectId);

        if (!_.isEmpty(project)) {
          yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: project });

          const client = yield call(findClient, project.client_id);
          yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
        }

        if (nav.reportId && nav.reportId > 0) {
          // See if we have selected summaries
          const workflow = yield call(
            findWorkflow,
            nav.projectId,
            nav.workflowId
          );

          if (!_.isEmpty(workflow)) {
            let requested = true;

            if (_.isEmpty(updates)) {
              requested = false;
            } else {
              workflow.vehicles.forEach((vehicle) => {
                if (
                  !updates[`summary-${vehicle.id}`] ||
                  !updates[`summary-${vehicle.id}`].requested
                ) {
                  requested = false;
                }
              });
            }
            if (!requested) {
              for (let i = 0; i < workflow.vehicles.length; i++) {
                yield fork(
                  projectApi.getOperationsSummary,
                  workflow.project_id,
                  workflow.vehicles[i].id
                );
              }
            }
          }
        }
      }
    }
  } else if (nav.index === "admin") {
    if (!updates.batteries || !updates.batteries.requested) {
      yield put({ type: projectActions.PROJECTS_GET_BATTERIES });
    }

    if (!updates.chargers || !updates.chargers.requested) {
      yield put({ type: projectActions.PROJECTS_GET_CHARGERS });
    }
  } else if (
    (nav.index === "customers" || nav.index === "dealers") &&
    nav.clientId
  ) {
    const selectedClient = yield call(findClient, nav.clientId);
    yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: selectedClient });
  } else if (nav.index === "audit" && nav.clientId && nav.facilityId) {
    const clients = yield getClients();
    if (!clients) {
      // yield put(projectApi.getClients); // need to wait for this
      yield put(projectsApi.endpoints.getClients.initiate());
    }
    const selectedClient = yield call(findClient, nav.clientId);
    yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: selectedClient });
    const selectedFacility = selectedClient.facilities.find(
      (facility) => facility.id === nav.facilityId
    );
    yield put({
      type: appActions.SET_ACTIVE_FACILITY,
      payload: selectedFacility,
    });
  }

  yield put({
    type: appActions.SET_APP_STATE,
    payload: { workflowName: workflowName },
  });
}

function* updateActiveClient() {
  const client = yield select(getActiveClient);
  if (!_.isEmpty(client)) {
    yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
    const facility = yield select(getActiveFacility);
    if (hasData(facility)) {
      console.log("Active facility found");
      yield put({ type: appActions.SET_ACTIVE_FACILITY, payload: facility });
    }
  }
}

function* updateActiveProject(id = null) {
  const nav = yield select(getNav);
  const project = yield call(findProject, id || nav.projectId);
  if (!_.isEmpty(project)) {
    const client = yield call(findClient, project.client_id);
    yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
    yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: project });
  }
}

export function* updateActiveFleet() {
  const nav = yield select(getNav);
  const project = yield call(findProject, nav.projectId);

  const workflow = project.project_workflows.find(
    (wf) => wf.workflow_type === "monitor"
  );

  if (workflow && workflow.workflow_data?.fleet_id) {
    const fleets = yield select(getFleets);
    const fleet = fleets.fleets[workflow.workflow_data.fleet_id];

    if (!_.isEmpty(fleet)) {
      yield put({
        type: appActions.SET_ACTIVE_FLEET,
        payload: fleet,
      });
    }
  }
}

function* updateActiveVehicle() {
  const nav = yield select(getNav);
  const workflow = yield call(findWorkflow, nav.projectId, nav.workflowId);

  if (!_.isEmpty(workflow)) {
    const activeVehicle = yield select(getActiveVehicle);

    if (!_.isEmpty(activeVehicle)) {
      const vehicle = findVehicle(workflow, activeVehicle.id);

      if (!_.isEmpty(vehicle)) {
        yield put({ type: appActions.SET_ACTIVE_VEHICLE, payload: vehicle });
      }
    }
  }
}

function findVehicle(workflow, id) {
  let vehicle = {};

  workflow.vehicles.forEach((veh) => {
    if (veh.id === id) {
      vehicle = veh;
    }
  });

  return vehicle;
}

function* findProject(id) {
  const clients = yield select(getClients);
  let theProject = {};
  clients.rawData.forEach((client) => {
    client.projects.forEach((project) => {
      if (project.id === id) {
        theProject = project;
      }
    });
  });

  return theProject;
}

function* findClient(id) {
  const clients = yield select(getClients);
  let theClient = {};
  clients.rawData.forEach((client) => {
    if (client.id === id) {
      theClient = client;
    }
  });

  return theClient;
}

function* findWorkflow(projectId, workflowId) {
  const project = yield call(findProject, projectId);
  let theWorkflow = {};
  if (project && project.project_workflows) {
    project.project_workflows.forEach((workflow) => {
      if (workflow.id === workflowId) {
        theWorkflow = workflow;
      }
    });
  }
  return theWorkflow;
}

function getAssignedLoggers(assignments, workflowId) {
  let arr = [];

  assignments.forEach((assign) => {
    if (assign.workflow_id === workflowId) {
      arr.push(assign);
    }
  });

  return arr;
}

function updateSummaryRequest(requests, vehicleId) {
  let complete = true;
  requests.forEach((request) => {
    if (request.vehicleId === vehicleId) {
      request.summaryReceived = true;
    }
    if (!request.summaryReceived) {
      complete = false;
    }
  });
  return complete;
}
