import { BuilderType, portalCacheTags } from "./portalApiHelpers";
import { legacyFulfill } from "../utils";
import { PORTAL_RECEIVED_GET_GROUP_DEFINITIONS } from "../../actions/portal-actions";

export type GroupDefinition = {};

export const groupDefinitionsApi = (builder: BuilderType) => ({
  getGroupDefinitions: builder.query<GroupDefinition, void>({
    query: () => `service/group-definitions.json`,
    providesTags: [portalCacheTags.GROUP_DEFINITIONS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_GET_GROUP_DEFINITIONS),
  }),
});
