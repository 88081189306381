import {
  PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS,
  PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION,
  PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION,
} from '../actions/project-actions';
import {copyObject} from "../shared-components/src/utility";

const initialState = {
  alert_configurations: [],
  alert_settings: [],
};

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS:
      return action.payload;
      
    case PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION:
      return createConfiguration(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION:
      return updateConfiguration(copyObject(state), action.payload);
    default:
      return state;
  }
}

function createConfiguration(state, data) {
  state.alert_configurations.push(data.alert_configuration);
  
  data.alert_settings.forEach(setting => {
    state.alert_settings.push(setting);
  });
  
  return state;
}

function updateConfiguration(state, data) {

  state.alert_configurations.forEach((config, idx) => {
    if(config.id === data.alert_configuration.id) {
      state.alert_configurations[idx] =  data.alert_configuration;
    }
  });

  data.alert_settings.forEach(setting => {
    state.alert_settings.forEach((set, idx) => {
      if(setting.id === set.id) {
        state.alert_settings[idx] = setting;
      }
    });
  });

  return state;
}