
export const PROJECTS_GET_CLIENTS = 'projects_get_clients';
export const PROJECTS_RECEIVED_GET_CLIENTS = 'projects_received_get_clients';

export const PROJECTS_CREATE_CLIENT = 'project_create_client';
export const PROJECTS_RECEIVED_CREATE_CLIENT = 'project_received_create_client';

export const PROJECTS_UPDATE_CLIENT = 'project_update_client';
export const PROJECTS_RECEIVED_UPDATE_CLIENT = 'project_received_update_client';

export const PROJECTS_DELETE_CLIENT = 'project_delete_client';
export const PROJECTS_RECEIVED_DELETE_CLIENT = 'project_received_delete_client';

export const PROJECTS_CREATE_CONTACT = 'project_create_contact';
export const PROJECTS_RECEIVED_CREATE_CONTACT = 'project_received_create_contact';

export const PROJECTS_CREATE_FACILITY_CONTACT = 'project_create_facility_contact';
export const PROJECTS_RECEIVED_CREATE_FACILITY_CONTACT = 'project_received_create_facility_contact';

export const PROJECTS_CREATE_WORKFLOW_CONTACT = 'project_create_workflow_contact';
export const PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT = 'project_received_create_workflow_contact';

export const PROJECTS_CREATE_FACILITY_ADDRESS = 'project_create_facility_address';
export const PROJECTS_RECEIVED_CREATE_FACILITY_ADDRESS = 'project_received_create_facility_address';
export const PROJECTS_RECEIVED_CREATE_ADDRESS = 'project_received_create_address';



export const PROJECTS_UPDATE_CONTACT = 'project_update_contact';
export const PROJECTS_RECEIVED_UPDATE_CONTACT = 'project_received_update_contact';

export const PROJECTS_DELETE_CONTACT = 'project_delete_contact';
export const PROJECTS_RECEIVED_DELETE_CONTACT = 'project_received_delete_contact';

export const PROJECTS_CREATE_PROJECT = 'project_create_project';
export const PROJECTS_RECEIVED_CREATE_PROJECT = 'project_received_create_project';

export const PROJECTS_UPDATE_PROJECT = 'project_update_project';
export const PROJECTS_RECEIVED_UPDATE_PROJECT = 'project_received_update_project';

export const PROJECTS_DELETE_PROJECT = 'project_delete_project';
export const PROJECTS_RECEIVED_DELETE_PROJECT = 'project_received_delete_project';

export const PROJECTS_CREATE_FACILITY = 'project_create_facility';
export const PROJECTS_RECEIVED_CREATE_FACILITY = 'project_received_create_facility';

export const PROJECTS_UPDATE_FACILITY = 'project_update_facility';
export const PROJECTS_RECEIVED_UPDATE_FACILITY = 'project_received_update_facility';

export const PROJECTS_DELETE_FACILITY = 'project_delete_facility';
export const PROJECTS_RECEIVED_DELETE_FACILITY = 'project_received_delete_facility';

export const PROJECTS_UPDATE_ADDRESS = 'project_update_address';
export const PROJECTS_RECEIVED_UPDATE_ADDRESS = 'project_received_update_address';

export const PROJECTS_DELETE_ADDRESS = 'project_delete_address';
export const PROJECTS_RECEIVED_DELETE_ADDRESS = 'project_received_delete_address';

export const PROJECTS_GET_POWER_LOGGERS = 'projects_get_power_loggers';
export const PROJECTS_RECEIVED_GET_POWER_LOGGERS = 'projects_received_get_power_loggers';

export const PROJECTS_CREATE_VEHICLE = 'projects_create_vehicle';
export const PROJECTS_RECEIVED_CREATE_VEHICLE = 'projects_received_create_vehicle';

export const PROJECTS_UPDATE_VEHICLE = 'projects_update_vehicle';
export const PROJECTS_RECEIVED_UPDATE_VEHICLE = 'projects_received_update_vehicle';

export const PROJECTS_DELETE_VEHICLE = 'projects_delete_vehicle';
export const PROJECTS_RECEIVED_DELETE_VEHICLE = 'projects_received_delete_vehicle';

export const PROJECTS_CREATE_SCHEDULE = 'projects_create_schedule';
export const PROJECTS_RECEIVED_CREATE_SCHEDULE = 'projects_received_create_schedule';

export const PROJECTS_UPDATE_SCHEDULE = 'projects_update_schedule';
export const PROJECTS_RECEIVED_UPDATE_SCHEDULE = 'projects_received_update_schedule';

export const PROJECTS_DELETE_SCHEDULE = 'projects_delete_schedule';
export const PROJECTS_RECEIVED_DELETE_SCHEDULE = 'projects_received_delete_schedule';

export const PROJECTS_CREATE_SCHEDULE_GROUP = 'projects_create_schedule_group';
export const PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP = 'projects_received_create_schedule_group';

export const PROJECTS_UPDATE_SCHEDULE_GROUP = 'projects_update_schedule_group';
export const PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP = 'projects_received_update_schedule_group';

export const PROJECTS_DELETE_SCHEDULE_GROUP = 'projects_delete_schedule_group';
export const PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP = 'projects_received_delete_schedule_group';

export const PROJECTS_CREATE_LOGGER_ASSIGNMENT = 'projects_create_logger_assignment';
export const PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT = 'projects_received_create_logger_assignment';

export const PROJECTS_UPDATE_LOGGER_ASSIGNMENT = 'projects_update_logger_assignment';
export const PROJECTS_RECEIVED_UPDATE_LOGGER_ASSIGNMENT = 'projects_received_update_logger_assignment';

export const PROJECTS_DELETE_LOGGER_ASSIGNMENT = 'projects_delete_logger_assignment';
export const PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT = 'projects_received_delete_logger_assignment';

export const PROJECTS_GET_OPERATIONS_SUMMARY = 'projects_get_operations_summary';
export const PROJECTS_RECEIVED_OPERATIONS_SUMMARY = 'projects_received_operation_summary';

export const PROJECTS_CHECK_OPERATIONS_SUMMARY = 'projects_check_operations_summary';
export const PROJECTS_RECEIVED_CHECK_OPERATIONS_SUMMARY = 'projects_received_check_operation_summary';

export const PROJECTS_GET_OPERATIONS_DETAIL = 'projects_get_operations_detail';
export const PROJECTS_RECEIVED_OPERATIONS_DETAIL = 'projects_received_operations_detail';

export const PROJECTS_CLEAR_OPERATIONS_SUMMARY = 'projects_clear_operations_summary';

export const PROJECTS_CREATE_WORKFLOW = 'projects_create_workflow';
export const PROJECTS_RECEIVED_CREATE_WORKFLOW = 'projects_received_create_workflow';

export const PROJECTS_GET_BATTERIES = 'projects_get_batteries';
export const PROJECTS_RECEIVED_GET_BATTERIES = 'projects_received_get_batteries';

export const PROJECTS_CREATE_BATTERY = 'projects_create_battery';
export const PROJECTS_RECEIVED_CREATE_BATTERY = 'projects_received_create_battery';

export const PROJECTS_UPDATE_BATTERY = 'projects_update_battery';
export const PROJECTS_RECEIVED_UPDATE_BATTERY = 'projects_received_update_battery';

export const PROJECTS_DELETE_BATTERY = 'projects_delete_battery';
export const PROJECTS_RECEIVED_DELETE_BATTERY = 'projects_received_delete_battery';

export const PROJECTS_GET_CHARGERS = 'projects_get_chargers';
export const PROJECTS_RECEIVED_GET_CHARGERS = 'projects_received_get_chargers';

export const PROJECTS_CREATE_CHARGER = 'projects_create_charger';
export const PROJECTS_RECEIVED_CREATE_CHARGER = 'projects_received_create_charger';

export const PROJECTS_UPDATE_CHARGER = 'projects_update_charger';
export const PROJECTS_RECEIVED_UPDATE_CHARGER = 'projects_received_update_charger';

export const PROJECTS_DELETE_CHARGER = 'projects_delete_charger';
export const PROJECTS_RECEIVED_DELETE_CHARGER = 'projects_received_delete_charger';

export const PROJECTS_GET_TEAMS = 'projects_get_teams';
export const PROJECTS_RECEIVED_GET_TEAMS = 'projects_received_get_teams';

export const PROJECTS_CREATE_TEAM = 'projects_create_team';
export const PROJECTS_RECEIVED_CREATE_TEAM = 'projects_received_create_team';

export const PROJECTS_UPDATE_TEAM = 'projects_update_team';
export const PROJECTS_RECEIVED_UPDATE_TEAM = 'projects_received_update_team';

export const PROJECTS_DELETE_TEAM = 'projects_delete_team';
export const PROJECTS_RECEIVED_DELETE_TEAM = 'projects_received_delete_team';

export const PROJECTS_GET_MOTIVE_POWER = 'projects_get_motive_power';
export const PROJECTS_RECEIVED_GET_MOTIVE_POWER = 'projects_received_get_motive_power';

export const PROJECTS_GET_TASK_DATA = 'projects_get_task_data';
export const PROJECTS_RECEIVED_GET_TASK_DATA = 'projects_received_get_task_data';

export const PROJECTS_GET_USER_CONFIG = 'projects_get_user_config';
export const PROJECTS_RECEIVED_GET_USER_CONFIG = 'projects_received_get_user_config';

export const PROJECTS_GET_TASKS = 'projects_get_tasks';
export const PROJECTS_RECEIVED_GET_TASKS = 'projects_received_get_tasks';

export const PROJECTS_CREATE_TASK = 'projects_create_task';
export const PROJECTS_RECEIVED_CREATE_TASK = 'projects_received_create_task';

export const PROJECTS_UPDATE_TASK = 'projects_update tasks';
export const PROJECTS_RECEIVED_UPDATE_TASK = 'projects_received_update_task';

export const PROJECTS_COMPLETE_TASK = 'projects_complete_task';
export const PROJECTS_RECEIVED_COMPLETE_TASK = 'projects_received_complete_task';

export const PROJECTS_DELETE_TASK = 'projects_delete_task';
export const PROJECTS_RECEIVED_DELETE_TASK = 'projects_received_delete_task';

export const PROJECTS_GET_FLEETS = 'projects_get_fleets';
export const PROJECTS_RECEIVED_GET_FLEETS = 'projects_received_get_fleets';

export const PROJECTS_CREATE_FLEET = 'projects_create_fleet';
export const PROJECTS_RECEIVED_CREATE_FLEET = 'projects_received_create_fleet';

export const PROJECTS_GET_FLEET_DATA = 'projects_get_fleet_data';
export const PROJECTS_RECEIVED_GET_FLEET_DATA = 'projects_received_get_fleet_data';

export const PROJECTS_GET_FLEET_ZONES = 'projects_get_fleet_zones';
export const PROJECTS_RECEIVED_GET_FLEET_ZONES = 'projects_received_get_fleet_zones';

export const PROJECTS_CREATE_FLEET_ZONE = 'projects_create_fleet_zone';
export const PROJECTS_RECEIVED_CREATE_FLEET_ZONE = 'projects_received_create_fleet_zone';

export const PROJECTS_UPDATE_FLEET_ZONE = 'projects_update_fleet_zone';
export const PROJECTS_RECEIVED_UPDATE_FLEET_ZONE = 'projects_received_update_fleet_zone';

export const PROJECTS_DELETE_FLEET_ZONE = 'projects_delete_fleet_zone';
export const PROJECTS_RECEIVED_DELETE_FLEET_ZONE = 'projects_received_delete_fleet_zone';

export const PROJECTS_GET_FLEET_ASSET_TEMPLATES = 'projects_get_fleet_asset_templates';
export const PROJECTS_RECEIVED_GET_FLEET_ASSET_TEMPLATES = 'projects_received_get_fleet_asset_templates';

export const PROJECTS_CREATE_FLEET_ASSET_TEMPLATE = 'projects_create_fleet_asset_template';
export const PROJECTS_RECEIVED_CREATE_FLEET_ASSET_TEMPLATE = 'projects_received_create_fleet_asset_template';

export const PROJECTS_UPDATE_FLEET_ASSET_TEMPLATE = 'projects_update_fleet_asset_template';
export const PROJECTS_RECEIVED_UPDATE_FLEET_ASSET_TEMPLATE = 'projects_received_update_fleet_asset_template';

export const PROJECTS_DELETE_FLEET_ASSET_TEMPLATE = 'projects_delete_fleet_asset_template';
export const PROJECTS_RECEIVED_DELETE_FLEET_ASSET_TEMPLATE = 'projects_received_delete_fleet_asset_template';

export const PROJECTS_GET_FLEET_ASSOCIATION_TEMPLATES = 'projects_get_fleet_association_templates';
export const PROJECTS_RECEIVED_GET_FLEET_ASSOCIATION_TEMPLATES = 'projects_received_get_fleet_association_templates';

export const PROJECTS_CREATE_FLEET_ASSOCIATION_TEMPLATE = 'projects_create_fleet_association_template';
export const PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION_TEMPLATE = 'projects_received_create_fleet_association_template';

export const PROJECTS_UPDATE_FLEET_ASSOCIATION_TEMPLATE = 'projects_update_fleet_association_template';
export const PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION_TEMPLATE = 'projects_received_update_fleet_association_template';

export const PROJECTS_DELETE_FLEET_ASSOCIATION_TEMPLATE = 'projects_delete_fleet_association_template';
export const PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION_TEMPLATE = 'projects_received_delete_fleet_association_template';

export const PROJECTS_GET_FLEET_ASSOCIATIONS = 'projects_get_fleet_association';
export const PROJECTS_RECEIVED_GET_FLEET_ASSOCIATIONS = 'projects_received_get_fleet_association';

export const PROJECTS_CREATE_FLEET_ASSOCIATION = 'projects_create_fleet_association';
export const PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION = 'projects_received_create_fleet_association';

export const PROJECTS_UPDATE_FLEET_ASSOCIATION = 'projects_update_fleet_association';
export const PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION = 'projects_received_update_fleet_association';

export const PROJECTS_DELETE_FLEET_ASSOCIATION = 'projects_delete_fleet_association';
export const PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION = 'projects_received_delete_fleet_association';

export const PROJECTS_GET_FLEET_ASSETS = 'projects_get_fleet_assets';
export const PROJECTS_RECEIVED_GET_FLEET_ASSETS = 'projects_received_get_fleet_assets';

export const PROJECTS_CREATE_FLEET_ASSET = 'projects_create_fleet_asset';
export const PROJECTS_RECEIVED_CREATE_FLEET_ASSET = 'projects_received_create_fleet_asset';

export const PROJECTS_UPDATE_FLEET_ASSET = 'projects_update_fleet_asset';
export const PROJECTS_RECEIVED_UPDATE_FLEET_ASSET = 'projects_received_update_fleet_asset';

export const PROJECTS_RETIRE_FLEET_ASSET = 'projects_retire_fleet_asset';
export const PROJECTS_RECEIVED_RETIRE_FLEET_ASSET = 'projects_received_retire_fleet_asset';

export const PROJECTS_DELETE_FLEET_ASSET = 'projects_delete_fleet_asset';
export const PROJECTS_RECEIVED_DELETE_FLEET_ASSET = 'projects_received_delete_fleet_asset';

export const PROJECTS_GET_TELEMETRY_ASSOCIATIONS = 'projects_get_telemetry_association';
export const PROJECTS_RECEIVED_GET_TELEMETRY_ASSOCIATIONS = 'projects_received_get_telemetry_association';

export const PROJECTS_CREATE_TELEMETRY_ASSOCIATION = 'projects_create_telemetry_association';
export const PROJECTS_RECEIVED_CREATE_TELEMETRY_ASSOCIATION = 'projects_received_create_telemetry_association';

export const PROJECTS_UPDATE_TELEMETRY_ASSOCIATION = 'projects_update_telemetry_association';
export const PROJECTS_RECEIVED_UPDATE_TELEMETRY_ASSOCIATION = 'projects_received_update_telemetry_association';

export const PROJECTS_END_TELEMETRY_ASSOCIATION = 'projects_end_telemetry_association';
export const PROJECTS_RECEIVED_END_TELEMETRY_ASSOCIATION = 'projects_received_end_telemetry_association';

export const PROJECTS_DELETE_TELEMETRY_ASSOCIATION = 'projects_delete_telemetry_association';
export const PROJECTS_RECEIVED_DELETE_TELEMETRY_ASSOCIATION = 'projects_received_delete_telemetry_association';

export const PROJECTS_GET_ALERT_CONFIGURATIONS = 'projects_get_alert_configurations';
export const PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS = 'projects_received_get_alert_configurations';

export const PROJECTS_CREATE_ALERT_CONFIGURATION = 'projects_create_alert_configuration';
export const PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION = 'projects_received_create_alert_configuration';

export const PROJECTS_UPDATE_ALERT_CONFIGURATION = 'projects_update_alert_configuration';
export const PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION = 'projects_received_update_alert_configuration';

export const PROJECTS_BULK_UPLOAD_FLEET = 'projects_bulk_upload_fleet';
export const PROJECTS_RECEIVED_BULK_UPLOAD_FLEET = 'projects_received_bulk_upload_fleet';

export const PROJECTS_GENERATE_MEASURE_REPORT = 'projects_generate_measure_report';

export const PROJECTS_CREATE_NOTE = 'projects_create_note';
export const PROJECTS_RECEIVED_CREATE_NOTE = 'projects_received_create_note';

export const PROJECTS_UPDATE_NOTE = 'projects_update notes';
export const PROJECTS_RECEIVED_UPDATE_NOTE = 'projects_received_update_note';

export const PROJECTS_DELETE_NOTE = 'projects_delete_note';
export const PROJECTS_RECEIVED_DELETE_NOTE = 'projects_received_delete_note';

export const PROJECTS_MODEL_CHECK_FOR_POWER_STUDY = 'projects_model_check_for_power_study';
export const PROJECTS_MODEL_GET_DETAIL_DATA = 'projects_model_get_detail_data';
export const PROJECTS_MODEL_CREATE_VEHICLE_WITH_SCHEDULE_GROUP = 'projects_model_create_vehicle_with_schedule_group';
export const PROJECTS_MODEL_UPDATE_SCHEDULE_GROUP = 'projects_model_update_schedule_group';

export const PROJECTS_UPDATE_TEAM_STRUCTURE = 'projects_update_team_structure';
export const PROJECTS_RECEIVED_UPDATE_TEAM_STRUCTURE = 'projects_received_update_team_structure';

export const PROJECTS_UPDATE_RESOURCE = 'projects_update_resource';
export const PROJECTS_RECEIVED_UPDATE_RESOURCE = 'projects_received_update_resource';

export const PROJECTS_CREATE_GOAL = 'projects_create_goal';
export const PROJECTS_RECEIVED_CREATE_GOAL = 'projects_received_create_goal';

export const PROJECTS_UPDATE_GOAL = 'projects_update_goal';
export const PROJECTS_RECEIVED_UPDATE_GOAL = 'projects_received_update_goal';

export const PROJECTS_DELETE_GOAL = 'projects_delete_goal';
export const PROJECTS_RECEIVED_DELETE_GOAL = 'projects_received_delete_goal';

export const PROJECTS_UPDATE_CLIENT_GOALS = 'projects_update_client_goals';

export const PROJECTS_CREATE_CLIENT_WITH_OWNER = 'projects_create_client_with_owner';
export const PROJECTS_UPDATE_CLIENT_WITH_OWNER = 'projects_update_client_with_owner';

export const PROJECTS_CREATE_FACILITY_WITH_ADDRESS = 'projects_create_facility_with_address';
export const PROJECTS_UPDATE_FACILITY_WITH_ADDRESS = 'projects_update_facility_with_address';

export const PROJECTS_UPDATE_VEHICLE_ARRAY = 'projects_update_vehicle_array';

export const PROJECTS_GET_TAGS = 'projects_get_tags';
export const PROJECTS_RECEIVED_GET_TAGS = 'projects_received_get_tags';

export const PROJECTS_CREATE_TAG_TYPE = 'projects_create_tag_type';
export const PROJECTS_RECEIVED_CREATE_TAG_TYPE = 'projects_received_create_tag_type';

export const PROJECTS_UPDATE_TAG_TYPE = 'projects_update_tag_type';
export const PROJECTS_RECEIVED_UPDATE_TAG_TYPE = 'projects_received_update_tag_type';

export const PROJECTS_DELETE_TAG_TYPE = 'projects_delete_tag_type';
export const PROJECTS_RECEIVED_DELETE_TAG_TYPE = 'projects_received_delete_tag_type';

export const PROJECTS_CREATE_TAG_VALUE = 'projects_create_tag_value';
export const PROJECTS_RECEIVED_CREATE_TAG_VALUE = 'projects_received_create_tag_value';

export const PROJECTS_UPDATE_TAG_VALUE = 'projects_update_tag_value';
export const PROJECTS_RECEIVED_UPDATE_TAG_VALUE = 'projects_received_update_tag_value';

export const PROJECTS_DELETE_TAG_VALUE = 'projects_delete_tag_value';
export const PROJECTS_RECEIVED_DELETE_TAG_VALUE = 'projects_received_delete_tag_value';

export const PROJECTS_CREATE_TAG = 'projects_create_tag';
export const PROJECTS_RECEIVED_CREATE_TAG = 'projects_received_create_tag';

export const PROJECTS_DELETE_TAG = 'projects_delete_tag';
export const PROJECTS_RECEIVED_DELETE_TAG = 'projects_received_delete_tag';

export const PROJECTS_DO_BATCH_TAGS = 'projects_do_batch_tags';

export const PROJECTS_CREATE_CONTACT_WITH_FACILITY = 'projects_create_contact_with_facility';
export const PROJECTS_UPDATE_CONTACT_WITH_FACILITY = 'projects_update_contact_with_facility';
export const PROJECTS_DELETE_CONTACT_WITH_FACILITY = 'projects_delete_contact_with_facility';

export const PROJECTS_GET_LOGGER_MOST_RECENT = 'projects_get_logger_most_recent';
export const PROJECTS_RECEIVED_GET_LOGGER_MOST_RECENT = 'projects_received_get_logger_most_recent';

export const PROJECTS_GET_NEW_TEAMS = 'projects_get_new_teams';
export const PROJECTS_RECEIVED_GET_NEW_TEAMS = 'projects_received_get_new_teams';

export const PROJECTS_CREATE_NEW_TEAM = 'projects_create_new_team';
export const PROJECTS_RECEIVED_CREATE_NEW_TEAM = 'projects_received_create_new_team';

export const PROJECTS_UPDATE_NEW_TEAM = 'projects_update_new_team';
export const PROJECTS_RECEIVED_UPDATE_NEW_TEAM = 'projects_received_update_new_team';

export const PROJECTS_DELETE_NEW_TEAM = 'projects_delete_new_team';
export const PROJECTS_RECEIVED_DELETE_NEW_TEAM = 'projects_received_delete_new_team';

export const PROJECTS_CREATE_NEW_TEAM_RESOURCE = 'projects_create_new_team_resource';
export const PROJECTS_RECEIVED_CREATE_NEW_TEAM_RESOURCE = 'projects_received_create_new_team_resource';

export const PROJECTS_DELETE_NEW_TEAM_RESOURCE = 'projects_delete_new_team_resource';
export const PROJECTS_RECEIVED_DELETE_NEW_TEAM_RESOURCE = 'projects_received_delete_new_team_resource';

export const PROJECTS_GET_USER_VIEWS = 'projects_get_user_views';
export const PROJECTS_RECEIVED_GET_USER_VIEWS = 'projects_received_get_user_views';

export const PROJECTS_CREATE_USER_INTERFACE = 'projects_create_user_interface';
export const PROJECTS_RECEIVED_CREATE_USER_INTERFACE = 'projects_received_create_user_interface';

export const PROJECTS_UPDATE_USER_INTERFACE = 'projects_update_user_interface';
export const PROJECTS_RECEIVED_UPDATE_USER_INTERFACE = 'projects_received_update_user_interface';

export const PROJECTS_CREATE_USER_DASHBOARD = 'projects_create_user_dashboard';
export const PROJECTS_RECEIVED_CREATE_USER_DASHBOARD = 'projects_received_create_user_dashboard';

export const PROJECTS_UPDATE_USER_DASHBOARDS = 'projects_update_user_dashboards';
export const PROJECTS_RECEIVED_UPDATE_USER_DASHBOARDS = 'projects_received_update_user_dashboards';

export const PROJECTS_DELETE_USER_DASHBOARD = 'projects_delete_user_dashboard';
export const PROJECTS_RECEIVED_DELETE_USER_DASHBOARD = 'projects_received_delete_user_dashboard';

export const PROJECTS_CREATE_CLIENT_INTERFACE = 'projects_create_client_interface';
export const PROJECTS_RECEIVED_CREATE_CLIENT_INTERFACE = 'projects_received_create_client_interface';

export const PROJECTS_DELETE_CLIENT_INTERFACE = 'projects_delete_client_interface';
export const PROJECTS_RECEIVED_DELETE_CLIENT_INTERFACE = 'projects_received_delete_client_interface';

export const PROJECTS_GET_CLIENT_OWNERS = 'projects_get_client_owners';
export const PROJECTS_RECEIVED_GET_CLIENT_OWNERS = 'projects_received_get_client_owners';

export const PROJECTS_CREATE_CLIENT_OWNER = 'projects_create_client_owner';
export const PROJECTS_RECEIVED_CREATE_CLIENT_OWNER = 'projects_received_create_client_owner';

export const PROJECTS_UPDATE_CLIENT_OWNER = 'projects_update_client_owner';
export const PROJECTS_RECEIVED_UPDATE_CLIENT_OWNER = 'projects_received_update_client_owner';

export const PROJECTS_DELETE_CLIENT_OWNER = 'projects_delete_client_owner';
export const PROJECTS_RECEIVED_DELETE_CLIENT_OWNER = 'projects_received_delete_client_owner';

export const PROJECTS_GET_CUSTOMER_GROUPS = 'projects_get_customer_groups';
export const PROJECTS_RECEIVED_GET_CUSTOMER_GROUPS = 'projects_received_get_customer_groups';

export const PROJECTS_CREATE_CUSTOMER_GROUP = 'projects_create_customer_group';
export const PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP = 'projects_received_create_customer_group';

export const PROJECTS_UPDATE_CUSTOMER_GROUP = 'projects_update_customer_group';
export const PROJECTS_RECEIVED_UPDATE_CUSTOMER_GROUP = 'projects_received_update_customer_group';

export const PROJECTS_DELETE_CUSTOMER_GROUP = 'projects_delete_customer_group';
export const PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP = 'projects_received_delete_customer_group';

export const PROJECTS_CREATE_CUSTOMER_GROUP_MEMBER = 'projects_create_customer_group_member';
export const PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP_MEMBER = 'projects_received_create_customer_group_member';

export const PROJECTS_DELETE_CUSTOMER_GROUP_MEMBER = 'projects_delete_customer_group_member';
export const PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP_MEMBER = 'projects_received_delete_customer_group_member';

export const PROJECTS_CREATE_THIRD_PARTY_USER = 'projects_create_third_party_user';
export const PROJECTS_RECEIVED_CREATE_THIRD_PARTY_USER = 'projects_received_create_third_party_user';

export const PROJECTS_UPDATE_THIRD_PARTY_USER = 'projects_update_third_party_user';
export const PROJECTS_RECEIVED_UPDATE_THIRD_PARTY_USER = 'projects_received_update_third_party_user';

export const PROJECTS_DELETE_THIRD_PARTY_USER = 'projects_delete_third_party_user';
export const PROJECTS_RECEIVED_DELETE_THIRD_PARTY_USER = 'projects_received_delete_third_party_user';

export const PROJECTS_SEND_PASSWORD_RESET_EMAIL = 'projects_send_password_reset_email';
export const PROJECTS_RECEIVED_SEND_PASSWORD_RESET_EMAIL = 'projects_received_send_password_reset_email';

export const PROJECTS_IGNORE_AM_ASSET_LIST = 'projects_ignore_am_asset_list';
export const PROJECTS_RECEIVED_IGNORE_AM_ASSET_LIST = 'projects_received_ignore_am_asset_list';

export const PROJECTS_UN_IGNORE_AM_ASSET_LIST = 'projects_un_ignore_am_asset_list';
export const PROJECTS_RECEIVED_UN_IGNORE_AM_ASSET_LIST = 'projects_received_un_ignore_am_asset_list';

export const PROJECTS_CLEAR_AM_UNEXPECTED_ASSET_LIST = 'projects_clear_unexpected_asset_list';
export const PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST = 'projects_received_clear_unexpected_asset_list';

export const PROJECTS_CREATE_LOCATION_WITH_FACILITY = 'projects_create_location_with_facility';
export const PROJECTS_RECEIVED_CREATE_LOCATION_WITH_FACILITY = 'projects_received_create_location_with_facility';

