export const PORTAL_AUTHENTICATE = "portal_authenticate";
export const PORTAL_SHOW_AUTHENTICATING = "portal_show_authenticating";
export const PORTAL_HIDE_AUTHENTICATING = "portal_hide_authenticating";

export const PORTAL_GET_USERS = "portal_get_users";
export const PORTAL_CREATE_USER = "portal_create_user";
export const PORTAL_UPDATE_USER = "portal_update_user";
export const PORTAL_DELETE_USER = "portal_delete_user";

export const PORTAL_GET_COMPARTMENTS = "portal_get_compartments";
export const PORTAL_CREATE_COMPARTMENT = "portal_create_compartment";
export const PORTAL_UPDATE_COMPARTMENT = "portal_update_compartment";
export const PORTAL_DELETE_COMPARTMENT = "portal_delete_compartment";

export const PORTAL_GET_ASSETS = "portal_get_assets";
export const PORTAL_CREATE_ASSET = "portal_create_asset";
export const PORTAL_UPDATE_ASSET = "portal_update_asset";
export const PORTAL_DELETE_ASSET = "portal_delete_asset";
export const PORTAL_MOVE_ASSET_LIST = "portal_move_asset_list";

export const PORTAL_GET_ROLE_DEFINITIONS = "portal_get_role_definitions";
export const PORTAL_CREATE_ROLE_DEFINITION = "portal_create_role_definition";
export const PORTAL_UPDATE_ROLE_DEFINITION = "portal_update_role_definition";
export const PORTAL_DELETE_ROLE_DEFINITION = "portal_delete_role_definition";

export const PORTAL_GET_POLICY_DEFINITIONS = "portal_get_policy_definitions";
export const PORTAL_CREATE_POLICY_DEFINITION =
  "portal_create_policy_definition";
export const PORTAL_UPDATE_POLICY_DEFINITION =
  "portal_update_policy_definition";
export const PORTAL_DELETE_POLICY_DEFINITION =
  "portal_delete_policy_definition";

export const PORTAL_RECEIVED_AUTHENTICATE = "portal_received_authenticate";

export const PORTAL_RECEIVED_GET_USERS = "portal_received_get_users";
export const PORTAL_RECEIVED_CREATE_USER = "portal_received_create_user";
export const PORTAL_RECEIVED_UPDATE_USER = "portal_received_update_user";
export const PORTAL_RECEIVED_DELETE_USER = "portal_received_delete_user";

export const PORTAL_RECEIVED_GET_COMPARTMENTS =
  "portal_received_get_compartments";
export const PORTAL_RECEIVED_CREATE_COMPARTMENT =
  "portal_received_create_compartment";
export const PORTAL_RECEIVED_UPDATE_COMPARTMENT =
  "portal_received_update_compartment";
export const PORTAL_RECEIVED_DELETE_COMPARTMENT =
  "portal_received_delete_compartment";

export const PORTAL_RECEIVED_GET_ASSETS = "portal_received_get_assets";
export const PORTAL_RECEIVED_CREATE_ASSET = "portal_received_create_asset";
export const PORTAL_RECEIVED_UPDATE_ASSET = "portal_received_update_asset";
export const PORTAL_RECEIVED_DELETE_ASSET = "portal_received_delete_asset";
export const PORTAL_RECEIVED_MOVE_ASSET_LIST =
  "portal_recieved_move_asset_list";

export const PORTAL_RECEIVED_GET_ROLE_DEFINITIONS =
  "portal_received_get_role_definitions";
export const PORTAL_RECEIVED_CREATE_ROLE_DEFINITION =
  "portal_received_create_role_definition";
export const PORTAL_RECEIVED_UPDATE_ROLE_DEFINITION =
  "portal_received_update_role_definition";
export const PORTAL_RECEIVED_DELETE_ROLE_DEFINITION =
  "portal_received_delete_role_definition";

export const PORTAL_RECEIVED_GET_POLICY_DEFINITIONS =
  "portal_received_get_policy_definitions";
export const PORTAL_RECEIVED_CREATE_POLICY_DEFINITION =
  "portal_received_create_policy_definition";
export const PORTAL_RECEIVED_UPDATE_POLICY_DEFINITION =
  "portal_received_update_policy_definition";
export const PORTAL_RECEIVED_DELETE_POLICY_DEFINITION =
  "portal_received_delete_policy_definition";

export const PORTAL_GET_POLICY_DOCUMENT = "portal_get_policy_document";
export const PORTAL_RECEIVED_GET_POLICY_DOCUMENT =
  "portal_received_get_policy_document";

export const PORTAL_UPDATE_USER_PROFILE_IMAGE =
  "portal_update_user_profile_image";
export const PORTAL_RECEIVED_UPDATE_USER_PROFILE_IMAGE =
  "portal_received_update_user_profile_image";

export const PORTAL_UPDATE_USER_PASSWORD = "portal_update_user_password";
export const PORTAL_UPDATE_USER_SETTINGS = "portal_update_user_settings";

export const PORTAL_GET_GROUP_DEFINITIONS = "portal_get_group_definitions";
export const PORTAL_RECEIVED_GET_GROUP_DEFINITIONS =
  "portal_received_get_group_definitions";

export const PORTAL_CREATE_THIRD_PARTY_USER = "portal_create_third_party_user";
export const PORTAL_RECEIVED_CREATE_THIRD_PARTY_USER =
  "portal_received_create_third_party_user";

export const PORTAL_UPDATE_THIRD_PARTY_USER = "portal_update_third_party_user";
export const PORTAL_RECEIVED_UPDATE_THIRD_PARTY_USER =
  "portal_received_update_third_party_user";

export const PORTAL_DELETE_THIRD_PARTY_USER = "portal_delete_third_party_user";
export const PORTAL_RECEIVED_DELETE_THIRD_PARTY_USER =
  "portal_received_delete_third_party_user";

export const PORTAL_RESET_USER_PASSWORD = "portal_reset_user_password";

function action(type, payload = {}) {
  return { type, payload };
}

export const getUsers = () => action(PORTAL_GET_USERS, {});
export const createUser = (user) => action(PORTAL_CREATE_USER, user);
export const updateUser = (user) => action(PORTAL_UPDATE_USER, user);
export const deleteUser = (id) => action(PORTAL_DELETE_USER, id);

export const getCompartments = () => action(PORTAL_GET_COMPARTMENTS, {});
export const createCompartment = (compartment) =>
  action(PORTAL_CREATE_COMPARTMENT, compartment);
export const updateCompartment = (compartment) =>
  action(PORTAL_UPDATE_COMPARTMENT, compartment);
export const deleteCompartment = (id) => action(PORTAL_DELETE_COMPARTMENT, id);

export const getAssets = () => action(PORTAL_GET_ASSETS, {});
export const createAsset = (asset) => action(PORTAL_CREATE_ASSET, asset);
export const updateAsset = (asset) => action(PORTAL_UPDATE_ASSET, asset);
export const deleteAsset = (id) => action(PORTAL_DELETE_ASSET, id);
export const moveAssetList = (destinationAccountId, ids) =>
  action(PORTAL_MOVE_ASSET_LIST, { destinationAccountId, ids });

export const getRoleDefinitions = () => action(PORTAL_GET_ROLE_DEFINITIONS, {});
export const createRoleDefinition = (roleDefinition) =>
  action(PORTAL_CREATE_ROLE_DEFINITION, roleDefinition);
export const updateRoleDefinition = (roleDefinition) =>
  action(PORTAL_UPDATE_ROLE_DEFINITION, roleDefinition);
export const deleteRoleDefinition = (id) =>
  action(PORTAL_DELETE_ROLE_DEFINITION, id);

export const getPolicyDefinitions = () =>
  action(PORTAL_GET_POLICY_DEFINITIONS, {});
export const createPolicyDefinition = (policyDefinition) =>
  action(PORTAL_CREATE_POLICY_DEFINITION, policyDefinition);
export const updatePolicyDefinition = (policyDefinition) =>
  action(PORTAL_UPDATE_POLICY_DEFINITION, policyDefinition);
export const deletePolicyDefinition = (id) =>
  action(PORTAL_DELETE_POLICY_DEFINITION, id);

export const getPolicyDocument = () => action(PORTAL_GET_POLICY_DOCUMENT, {});

export const updateUserProfileImage = (id, image) =>
  action(PORTAL_UPDATE_USER_PROFILE_IMAGE, { id, image });
export const updateUserPassword = (user) =>
  action(PORTAL_UPDATE_USER_PASSWORD, user);
export const updateUserSettings = (user) =>
  action(PORTAL_UPDATE_USER_SETTINGS, user);

export const resetUserPassword = (resetData) =>
  action(PORTAL_RESET_USER_PASSWORD, resetData);
