import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { legacyFulfill } from "../utils";
import {
  PROJECTS_RECEIVED_CREATE_GOAL,
  PROJECTS_RECEIVED_DELETE_GOAL,
  PROJECTS_RECEIVED_UPDATE_GOAL,
} from "../../actions/project-actions";
export type NewGoal = {};

export const newGoalsApi = (builder: BuilderType) => ({
  createNewGoal: builder.mutation<NewGoal, any>({
    query: (goal) => ({
      url: `service/new-goals`,
      method: "POST",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.NEW_GOALS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_GOAL),
  }),

  updateNewGoal: builder.mutation<NewGoal, any>({
    query: (goal) => ({
      url: `service/new-goals/${goal.id}`,
      method: "PUT",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.NEW_GOALS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_GOAL),
  }),

  deleteNewGoal: builder.mutation<NewGoal, number>({
    query: (id) => ({
      url: `service/new-goals/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.NEW_GOALS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_GOAL),
  }),
});
