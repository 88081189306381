import {
  PROJECTS_RECEIVED_GET_TASKS,
  PROJECTS_RECEIVED_CREATE_TASK,
  PROJECTS_RECEIVED_UPDATE_TASK,
  PROJECTS_RECEIVED_COMPLETE_TASK,
  PROJECTS_RECEIVED_DELETE_TASK,
} from '../actions/project-actions';
import {copyObject} from "../utility";

const initialState = [];

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_TASKS:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_TASK:
      return createTask(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_TASK:
      return updateTask(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_COMPLETE_TASK:
      return updateTask(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_TASK:
      return deleteTask(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateTask(state, data) {
  state.forEach((task, idx) => {
    if (task.id === data.id) {
      state[idx] = data;
    }
  });

  return state;
}

function createTask(state, data) {

  state.push(data);

  return state;
}

function deleteTask(state, data) {
  state.forEach((task, idx) => {
    if (task.id === data.id) {
      state.splice(idx, 1);
    }
  });

  return state;
}