import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo, useState } from 'react';
import { registerSW } from 'virtual:pwa-register';
import { strings } from './localization';

const ServiceWorkerPrompt = () => {
  const [promptOpen, setPromptOpen] = useState(false);

  const updateSW = useMemo(
    () =>
      registerSW({
        onNeedRefresh() {
          setPromptOpen(true);
        },
        onOfflineReady() {
          console.log('offline ready');
        },
      }),
    []
  );

  const handleClose = () => {
    setPromptOpen(false);
  };
  const handleRefresh = () => {
    setPromptOpen(false);
    updateSW();
  };
  /* console.log('promptOpen', promptOpen); */

  if (!promptOpen) {
    return null;
  }
  return (
    <Dialog open>
      <DialogTitle>{strings.buildUpdateNotification}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A new version of this software is available. Refresh to update.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRefresh} variant="contained" color="primary">
          Update
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceWorkerPrompt;
