import _ from 'lodash';
import UrlPattern from 'url-pattern';
import Nav from '../providers/types/redux-states/Nav';

export default class urlUtils {
  static getActiveURL(navConfig: any, nav: Nav) {
    if (!_.isEmpty(nav)) {
      return this.makeURL(navConfig, nav, '');
    } else {
      const url = window.location.pathname;
      const search = window.location.search;

      const params = this.getURLParams(navConfig, url, search);

      return this.makeURL(navConfig, params, search);
    }
  }

  static makeURL(navConfig: any, nav: any, search: string) {
    // Iterate through each pattern and look for a match
    // to the set of parameters in the map
    let foundRoute: any = null;
    const paramCount = this.getParamCount(nav);

    navConfig.routes.forEach((route: any) => {
      if (route.index === nav.index && Object.keys(route.params).length === paramCount) {
        let matchCount = this.getParamCount(nav);
        for (let i = 0; i < route.params.length; i++) {
          if (nav[route.params[i]] > 0 || nav[route.params[i]] === 'new') {
            matchCount--;
          }
        }

        if (matchCount === 0) {
          foundRoute = route;
        }
      }
    }, this);

    if (foundRoute) {
      const pattern = new UrlPattern(foundRoute.route);
      let params = {};

      for (let i = 0; i < foundRoute.params.length; i++) {
        // @ts-ignore
        params[foundRoute.params[i]] = nav[foundRoute.params[i]];
      }

      return pattern.stringify(params) + (search || '');
    } else {
      return navConfig.fallbackRoute.route;
    }
  }

  static getURLParams(navConfig: any, url: string, search: string) {
    let ret = {
      index: navConfig.fallbackRoute.index,
      drawerOpen: false,
      lastPath: url,
      search: search,
    };

    navConfig.routes.forEach((route: any) => {
      let pattern = new UrlPattern(route.route);
      let match = pattern.match(url);

      if (match) {
        ret.index = route.index;

        for (let key in match) {
          // eslint-disable-next-line no-prototype-builtins
          if (match.hasOwnProperty(key)) {
            if (match[key] === 'new') {
              // @ts-ignore
              ret[key] = 'new';
            } else {
              // @ts-ignore
              ret[key] = parseInt(match[key], 10);
            }
          }
        }
      }
    });

    return ret;
  }

  static getParamCount(nav: any) {
    let count = 0;
    const valid = ['activeNavId', 'activeStepperDepth', 'drawerOpen', 'index'];
    for (let key in nav) {
      // eslint-disable-next-line no-prototype-builtins
      if (nav.hasOwnProperty(key) && !valid.includes(key)) {
        if (nav[key] > 0 || nav[key] === 'new') {
          count++;
        }
      }
    }

    return count;
  }
}
