import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    // Main
    mainTitle: 'Prince',

    // Nav
    clients: 'Clients',
    list: 'List',
    projects: 'Projects',
    addNew: 'Create New',
    dashboard: 'Dashboard',
    training: 'Training',
    admin: 'Administration',

    adminTitle: 'GNB Cloud Administration',
    userNotAuthorized: 'User is not authorized for Administrative Operations',

    batteryModels: 'Battery Models',
    chargerModels: 'Charger Models',
    teams: 'Teams',
    teamResources: 'Team Resources',

    measure: 'Measure',
    model: 'Model',
    monitor: 'Monitor',
    manage: 'Manage',

    loginFailed: 'Your login credentials are not valid',
    loginRequired: 'Your login has expired, please login again',
    serverError: 'There was a server error on your last request',

    batteries: 'Batteries',
    battery: 'Battery',
    series: 'Series',
    batteryModelName: 'Model Name',
    cellType: 'Cell Type',
    batteryCapacity: 'Battery Capacity',
    has24V: '24V',
    has36V: '36V',
    has48V: '48V',
    has72V: '72V',
    has80V: '80V',
    isFast: 'Fast',
    height: 'Height',
    listPrice24V: 'List Price 24V',
    listPrice36V: 'List Price 36V',
    listPrice48V: 'List Price 48V',
    listPrice72V: 'List Price 72V',
    listPrice80V: 'List Price 80V',

    chargers: 'Chargers',
    charger: 'Charger',
    chargerModelName: 'Charger Name',
    chargerSeries: 'Charger Series',
    voltage: 'Voltage',
    ahrLow: 'AHr Low',
    ahrHigh: 'AHr High',
    startRateLow: 'Start Rate Low',
    startRateHigh: 'Start Rate High',
    maxOutput24V: 'Max Out 24V',
    maxOutput36V: 'Max Out 36V',
    maxOutput48V: 'Max Out 48V',
    maxOutput72V: 'Max Out 72V',
    maxOutput80V: 'Max Out 80V',
    maxPower: 'Max Power',
    inputAmps208: 'Input Amps 208VAC',
    inputAmps240: 'Input Amps 240VAC',
    inputAmps480: 'Input Amps 480VAC',
    inputAmps600: 'Input Amps 600VAC',
    input208: 'Input 208VAC',
    input240: 'Input 240VAC',
    input480: 'Input 480VAC',
    input600: 'Input 600VAC',
    phase: 'Phase',
    cecApproved: 'CEC Approved',
    listPrice: 'List Price',

    team: 'Team',
    teamType: 'Team Type',
    teamName: 'Team Name',

    teamResource: 'Team Resource',
    portalUser: 'Portal User',

    teamBuilder: 'Team Builder',
    viewManager: 'View Manager',
    selectAccount: 'Select Account',
    newClientBaseAccount: 'New Client Base Account',
    saveBaseAccount: 'Save Base Account',
    account: 'Account',

    passwordSuccessfullyUpdated: 'Password Successfully Updated!',

    tagManager: 'Tag Manager',

    passwordResetSuccessful: 'Password Reset Successful',
    newUserMessage1: 'Welcome to GNB Cloud',
    newUserMessage2: '. Please setup your password in order to continue. Be sure to use the email address to which the message was sent.',
    passwordResetMessage: 'Please select a new password to continue.',

    onlyAdmins: 'Only Account Administrators can access this functionality.',

    emailHasBeenSent: 'Email has been sent',

    name: 'Name',
    id: 'Id',
  }
});