import {
  PROJECTS_RECEIVED_GET_TAGS,
  PROJECTS_RECEIVED_CREATE_TAG_VALUE,
  PROJECTS_RECEIVED_UPDATE_TAG_VALUE,
  PROJECTS_RECEIVED_DELETE_TAG_VALUE,
  PROJECTS_RECEIVED_CREATE_TAG_TYPE,
  PROJECTS_RECEIVED_UPDATE_TAG_TYPE,
  PROJECTS_RECEIVED_DELETE_TAG_TYPE,
  PROJECTS_RECEIVED_CREATE_TAG,
  PROJECTS_RECEIVED_DELETE_TAG,
} from '../actions/project-actions';
import {copyObject} from "../utility";

const initialState = {
  tag_types: [],
  tag_values: [],
  tags: [],
};

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_TAGS:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_TAG_TYPE:
      return createType(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_TAG_TYPE:
      return updateType(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_TAG_TYPE:
      return deleteType(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_TAG_VALUE:
      return createValue(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_TAG_VALUE:
      return updateValue(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_TAG_VALUE:
      return deleteValue(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_TAG:
      return createTag(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_TAG:
      return deleteTag(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateType(state, data) {
  state.tag_types.forEach((type, idx) => {
    if (type.id === data.id) {
      state.tag_types[idx] = data;
    }
  });

  return state;
}

function createType(state, data) {

  state.tag_types.push(data);

  return state;
}

function deleteType(state, data) {
  state.tag_types.forEach((type, idx) => {
    if (type.id === data.id) {
      state.tag_types.splice(idx, 1);
    }
  });

  return state;
}

function updateValue(state, data) {
  state.tag_values.forEach((value, idx) => {
    if (value.id === data.id) {
      state.tag_values[idx] = data;
    }
  });

  return state;
}

function createValue(state, data) {

  state.tag_values.push(data);

  return state;
}

function deleteValue(state, data) {
  state.tag_values.forEach((value, idx) => {
    if (value.id === data.id) {
      state.tag_values.splice(idx, 1);
    }
  });

  return state;
}

function createTag(state, data) {

  state.tags.push(data);

  return state;
}

function deleteTag(state, data) {
  state.tags.forEach((tag, idx) => {
    if (tag.id === data.id) {
      state.tags.splice(idx, 1);
    }
  });

  return state;
}