import {
  PROJECTS_RECEIVED_GET_CUSTOMER_GROUPS,
  PROJECTS_RECEIVED_UPDATE_CUSTOMER_GROUP,
  PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP,
  PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP,
  PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP_MEMBER,
  PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP_MEMBER,
} from '../actions/project-actions';
import {copyObject} from "../utility";

const initialState = {
  customer_groups: [],
  customer_group_members: [],
};

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_CUSTOMER_GROUPS:
      return action.payload;

    case   PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP:
      return createCustomerGroup(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_CUSTOMER_GROUP:
      return updateCustomerGroup(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP:
      return deleteCustomerGroup(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP_MEMBER:
      return createCustomerGroupMember(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP_MEMBER:
      return deleteCustomerGroupMember(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateCustomerGroup(state, data) {
  state.customer_groups.forEach((customerGroup, idx) => {
    if (customerGroup.id === data.id) {
      state.customer_groups[idx] = data;
    }
  });

  return state;
}

function createCustomerGroup(state, data) {

  state.customer_groups.push(data);

  return state;
}

function deleteCustomerGroup(state, data) {
  state.customer_groups.forEach((customerGroup, idx) => {
    if (customerGroup.id === data.id) {
      state.customer_groups.splice(idx, 1);
    }
  });

  return state;
}

function createCustomerGroupMember(state, data) {

  state.customer_group_members.push(data);

  return state;
}

function deleteCustomerGroupMember(state, data) {
  state.customer_group_members.forEach((customerGroupMember, idx) => {
    if (customerGroupMember.id === data.id) {
      state.customer_group_members.splice(idx, 1);
    }
  });

  return state;
}