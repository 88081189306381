import {
  PROJECTS_RECEIVED_GET_FLEETS,
  PROJECTS_RECEIVED_CREATE_FLEET,
  PROJECTS_RECEIVED_GET_FLEET_DATA,
  PROJECTS_RECEIVED_CREATE_FLEET_ZONE,
  PROJECTS_RECEIVED_UPDATE_FLEET_ZONE,
  PROJECTS_RECEIVED_DELETE_FLEET_ZONE,
  PROJECTS_RECEIVED_CREATE_FLEET_ASSET_TEMPLATE,
  PROJECTS_RECEIVED_UPDATE_FLEET_ASSET_TEMPLATE,
  PROJECTS_RECEIVED_DELETE_FLEET_ASSET_TEMPLATE,
  PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION_TEMPLATE,
  PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION_TEMPLATE,
  PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION_TEMPLATE,
  PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION,
  PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION,
  PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION,
  PROJECTS_RECEIVED_CREATE_FLEET_ASSET,
  PROJECTS_RECEIVED_UPDATE_FLEET_ASSET,
  PROJECTS_RECEIVED_RETIRE_FLEET_ASSET,
  PROJECTS_RECEIVED_DELETE_FLEET_ASSET,
  PROJECTS_RECEIVED_CREATE_TELEMETRY_ASSOCIATION,
  PROJECTS_RECEIVED_UPDATE_TELEMETRY_ASSOCIATION,
  PROJECTS_RECEIVED_END_TELEMETRY_ASSOCIATION,
  PROJECTS_RECEIVED_DELETE_TELEMETRY_ASSOCIATION,
  PROJECTS_RECEIVED_BULK_UPLOAD_FLEET,
  PROJECTS_RECEIVED_IGNORE_AM_ASSET_LIST,
  PROJECTS_RECEIVED_UN_IGNORE_AM_ASSET_LIST,
  PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST,
} from "../actions/project-actions";
import { copyObject } from "../utility";

const initialState = {
  fleets: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_RECEIVED_GET_FLEETS:
      return fleetsUpdate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_GET_FLEET_DATA:
    case PROJECTS_RECEIVED_BULK_UPLOAD_FLEET:
      return updateFleetData(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FLEET:
      return createFleet(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FLEET_ZONE:
      return createZone(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_FLEET_ZONE:
      return updateZone(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_FLEET_ZONE:
      return deleteZone(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FLEET_ASSET_TEMPLATE:
      return createAssetTemplate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_FLEET_ASSET_TEMPLATE:
      return updateAssetTemplate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_FLEET_ASSET_TEMPLATE:
      return deleteAssetTemplate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION_TEMPLATE:
      return createAssociationTemplate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION_TEMPLATE:
      return updateAssociationTemplate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION_TEMPLATE:
      return deleteAssociationTemplate(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION:
      return createAssociation(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION:
      return updateAssociation(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION:
      return deleteAssociation(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FLEET_ASSET:
      return createAsset(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_RETIRE_FLEET_ASSET:
      return retireAsset(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_FLEET_ASSET:
      return updateAsset(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_FLEET_ASSET:
      return deleteAsset(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_TELEMETRY_ASSOCIATION:
      return createTelemetryAssociation(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_TELEMETRY_ASSOCIATION:
    case PROJECTS_RECEIVED_END_TELEMETRY_ASSOCIATION:
      return updateTelemetryAssociation(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_TELEMETRY_ASSOCIATION:
      return deleteTelemetryAssociation(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_IGNORE_AM_ASSET_LIST:
    case PROJECTS_RECEIVED_UN_IGNORE_AM_ASSET_LIST:
      return updateAMAssets(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST:
      return clearAMUnexpectedAssetList(copyObject(state), action.payload);

    default:
      return state;
  }
}

function fleetsUpdate(state, data) {
  const updatedFleets = { ...state.fleets }; // Create a shallow copy of fleets

  data.forEach((fleet) => {
    // Create a new object for the fleet with all the necessary properties
    updatedFleets[fleet.id] = {
      ...fleet,
      zones: [],
      asset_templates: [],
      association_templates: [],
      fleet_associations: [],
      fleet_assets: [],
      telemetry_associations: [],
      alert_configurations: [],
      alert_settings: [],
      maintenance_configurations: [],
      maintenance_settings: [],
      maintenance_tasks: [],
      am_location: null,
      am_assets: [],
      am_unexpected_assets: [],
    };
  });

  return { ...state, fleets: updatedFleets };
}

function updateFleetData(state, fleet) {
  state.fleets[fleet.id] = fleet;

  return state;
}

function createFleet(state, fleet) {
  state.fleets[fleet.id] = fleet;
  state.fleets[fleet.id].zones = [];
  state.fleets[fleet.id].asset_templates = [];
  state.fleets[fleet.id].association_templates = [];
  state.fleets[fleet.id].fleet_associations = [];
  state.fleets[fleet.id].fleet_assets = [];
  state.fleets[fleet.id].telemetry_associations = [];
  state.fleets[fleet.id].alert_configurations = [];
  state.fleets[fleet.id].alert_settings = [];
  state.fleets[fleet.id].maintenance_configurations = [];
  state.fleets[fleet.id].maintenance_settings = [];
  state.fleets[fleet.id].maintenance_tasks = [];
  state.fleets[fleet.id].am_location = null;
  state.fleets[fleet.id].am_assets = [];
  state.fleets[fleet.id].am_unexpected_assets = [];

  return state;
}

function createZone(state, zone) {
  if (state.fleets[zone.fleet_id]) {
    state.fleets[zone.fleet_id].zones.push(zone);
  }

  return state;
}

function updateZone(state, zone) {
  if (state.fleets[zone.fleet_id]) {
    state.fleets[zone.fleet_id].zones.forEach((zn, idx) => {
      if (zn.id === zone.id) {
        state.fleets[zone.fleet_id].zones[idx] = zone;
      }
    });
  }

  return state;
}

function deleteZone(state, zone) {
  if (state.fleets[zone.fleet_id]) {
    state.fleets[zone.fleet_id].zones.forEach((zn, idx) => {
      if (zn.id === zone.id) {
        state.fleets[zone.fleet_id].zones.splice(idx, 1);
      }
    });
  }

  return state;
}

function createAssetTemplate(state, assetTemplate) {
  if (state.fleets[assetTemplate.fleet_id]) {
    state.fleets[assetTemplate.fleet_id].asset_templates.push(assetTemplate);
  }

  return state;
}

function updateAssetTemplate(state, assetTemplate) {
  if (state.fleets[assetTemplate.fleet_id]) {
    state.fleets[assetTemplate.fleet_id].asset_templates.forEach((at, idx) => {
      if (at.id === assetTemplate.id) {
        state.fleets[assetTemplate.fleet_id].asset_templates[
          idx
        ] = assetTemplate;
      }
    });
  }

  return state;
}

function deleteAssetTemplate(state, assetTemplate) {
  if (state.fleets[assetTemplate.fleet_id]) {
    state.fleets[assetTemplate.fleet_id].asset_templates.forEach((at, idx) => {
      if (at.id === assetTemplate.id) {
        state.fleets[assetTemplate.fleet_id].asset_templates.splice(idx, 1);
      }
    });
  }

  return state;
}

function createAssociationTemplate(state, associationTemplate) {
  if (state.fleets[associationTemplate.fleet_id]) {
    state.fleets[associationTemplate.fleet_id].association_templates.push(
      associationTemplate
    );
  }

  return state;
}

function updateAssociationTemplate(state, associationTemplate) {
  if (state.fleets[associationTemplate.fleet_id]) {
    state.fleets[associationTemplate.fleet_id].association_templates.forEach(
      (at, idx) => {
        if (at.id === associationTemplate.id) {
          state.fleets[associationTemplate.fleet_id].association_templates[
            idx
          ] = associationTemplate;
        }
      }
    );
  }

  return state;
}

function deleteAssociationTemplate(state, associationTemplate) {
  if (state.fleets[associationTemplate.fleet_id]) {
    state.fleets[associationTemplate.fleet_id].association_templates.forEach(
      (at, idx) => {
        if (at.id === associationTemplate.id) {
          state.fleets[
            associationTemplate.fleet_id
          ].association_templates.splice(idx, 1);
        }
      }
    );
  }

  return state;
}

function createAssociation(state, association) {
  if (state.fleets[association.fleet_id]) {
    state.fleets[association.fleet_id].fleet_associations.push(association);
  }

  return state;
}

function updateAssociation(state, association) {
  if (state.fleets[association.fleet_id]) {
    state.fleets[association.fleet_id].fleet_associations.forEach((as, idx) => {
      if (as.id === association.id) {
        state.fleets[association.fleet_id].fleet_associations[
          idx
        ] = association;
      }
    });
  }

  return state;
}

function deleteAssociation(state, association) {
  if (state.fleets[association.fleet_id]) {
    state.fleets[association.fleet_id].fleet_associations.forEach((as, idx) => {
      if (as.id === association.id) {
        state.fleets[association.fleet_id].fleet_associations.splice(idx, 1);
      }
    });
  }

  return state;
}

function createAsset(state, asset) {
  if (state.fleets[asset.fleet_id]) {
    state.fleets[asset.fleet_id].fleet_assets.push(asset);
  }

  return state;
}

function retireAsset(state, asset) {
  if (state.fleets[asset.fleet_id]) {
    state.fleets[asset.fleet_id].fleet_assets.forEach((as, idx) => {
      if (as.id === asset.id) {
        state.fleets[asset.fleet_id].fleet_assets[idx] = asset;
      }
    });
    state.fleets[asset.fleet_id].fleet_telemetry_associations.forEach((ass) => {
      if (ass.fleet_asset_id === asset.id && !ass.end_date) {
        ass.end_date = asset.retired_date;
      }
      if (ass.soft_fleet_asset_id === asset.id && !ass.end_date) {
        ass.end_date = asset.retired_date;
      }
    });
  }

  return state;
}

function updateAsset(state, asset) {
  if (state.fleets[asset.fleet_id]) {
    state.fleets[asset.fleet_id].fleet_assets.forEach((as, idx) => {
      if (as.id === asset.id) {
        state.fleets[asset.fleet_id].fleet_assets[idx] = asset;
      }
    });
  }

  return state;
}

function deleteAsset(state, asset) {
  if (state.fleets[asset.fleet_id]) {
    state.fleets[asset.fleet_id].fleet_assets.forEach((as, idx) => {
      if (as.id === asset.id) {
        state.fleets[asset.fleet_id].fleet_assets.splice(idx, 1);
      }
    });

    state.fleets[asset.fleet_id].fleet_telemetry_associations.forEach(
      (ta, idx) => {
        if (ta.fleet_asset_id === asset.id) {
          state.fleets[asset.fleet_id].fleet_telemetry_associations.splice(
            idx,
            1
          );
        }
      }
    );
  }

  return state;
}

function createTelemetryAssociation(state, association) {
  if (state.fleets[association.fleet_id]) {
    state.fleets[association.fleet_id].fleet_telemetry_associations.push(
      association
    );
  }

  return state;
}

function updateTelemetryAssociation(state, association) {
  if (state.fleets[association.fleet_id]) {
    state.fleets[association.fleet_id].fleet_telemetry_associations.forEach(
      (ta, idx) => {
        if (ta.id === association.id) {
          state.fleets[association.fleet_id].fleet_telemetry_associations[
            idx
          ] = association;
        }
      }
    );
  }

  return state;
}

function deleteTelemetryAssociation(state, association) {
  if (state.fleets[association.fleet_id]) {
    state.fleets[association.fleet_id].fleet_telemetry_associations.forEach(
      (ta, idx) => {
        if (ta.id === association.id) {
          state.fleets[
            association.fleet_id
          ].fleet_telemetry_associations.splice(idx, 1);
        }
      }
    );
  }

  return state;
}

function updateAMAssets(state, amAssets) {
  amAssets.forEach((amAsset) => {
    if (
      state.fleets[amAsset.fleet_id] &&
      state.fleets[amAsset.fleet_id].am_assets
    ) {
      const index = state.fleets[amAsset.fleet_id].am_assets.findIndex(
        (a) => a.id === amAsset.id
      );
      if (index > -1) {
        state.fleets[amAsset.fleet_id].am_assets[index] = amAsset;
      }
    }
  });

  return state;
}

function clearAMUnexpectedAssetList(state, amUnexpectedAssets) {
  amUnexpectedAssets.forEach((amUnexpectedAsset) => {
    for (let f in state.fleets) {
      let fleet = state.fleets[f];
      if (fleet.is_auto_monitored) {
        if (
          fleet.am_location &&
          fleet.am_location.id === amUnexpectedAsset.am_location_id
        ) {
          const index = fleet.am_unexpected_assets.findIndex(
            (a) => a.id === amUnexpectedAsset.id
          );
          if (index > -1) {
            fleet.am_unexpected_assets.splice(index, 1);
          }
        }
      }
    }
  });

  return state;
}
