import React, {useContext} from 'react';
// @ts-ignore
import {Oval} from 'react-loader-spinner';
import {Backdrop} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {SEICThemeContext} from "../../providers/SEICThemeProvider";

const useStyles = makeStyles((_theme) => ({
  backdrop: {
    zIndex: 2500,
    color: '#fff',
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();
  const {palette} = useContext(SEICThemeContext);

  return (
      <Backdrop className={classes.backdrop} open={true}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginLeft: -45,
                marginTop: -45,
                zIndex: 5000,
              }}
          >
            <Oval color={palette.primary} secondaryColor={palette.primary200} height={90} />
          </div>
        </div>
      </Backdrop>
  )
}

export default LoadingSpinner;
