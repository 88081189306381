import * as projectActions from "../../actions/project-actions";
import {
  getActiveProject,
  getActiveScheduleGroup,
  getActiveVehicle,
  getUpdates,
} from "../../reducers/selectors";
import * as appActions from "../../actions";
import * as projectApi from "../api-projects";
import { take, put, call, select, putResolve } from "redux-saga/effects";
import {
  copyObject,
  getScheduleGroup,
  getVehicle,
  getWorkflow,
} from "../../shared-components/src/utility";
import { cloneSchedules } from "../../shared-components/src/utility/schedule-utilities";
import { projectsApi } from "../../services/projects/projectsApi";

export function* checkModelVehicleForPowerStudy() {
  while (true) {
    yield take(projectActions.PROJECTS_MODEL_CHECK_FOR_POWER_STUDY);
    const activeVehicle = yield select(getActiveVehicle);
    const activeProject = yield select(getActiveProject);

    if (
      activeVehicle.vehicle_data.model_data.daily_energy_usage.has_power_study
    ) {
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
      const { data: result } = yield putResolve(
        projectsApi.endpoints.checkOperationsSummary.initiate({
          projectId: activeProject.id,
          vehicleId: activeVehicle.import_id,
        })
      );
      // const result = yield call(projectApi.checkOperationsSummary, activeProject.id, activeVehicle.import_id);
      if (result.has_summary) {
        // yield putResolve(projectsApi.endpoints.getOperationsSummary.initiate())
        yield call(
          projectApi.getOperationsSummary,
          activeProject.id,
          activeVehicle.import_id
        );
      }
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    }
  }
}

export function* getModelDetailData() {
  while (true) {
    const {
      payload: { projectId, vehicleId, date },
    } = yield take(projectActions.PROJECTS_MODEL_GET_DETAIL_DATA);
    const updates = yield select(getUpdates);

    if (
      !updates[`detail-${vehicleId}-${date}`] ||
      (updates[`detail-${vehicleId}-${date}`] &&
        !updates[`detail-${vehicleId}-${date}`].requested)
    ) {
      yield put({
        type: appActions.SET_DATA_REQUESTED,
        payload: `detail-${vehicleId}-${date}`,
      });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
      yield putResolve(
        projectsApi.endpoints.getOperationsDetail.initiate({
          projectId,
          vehicleId,
          date,
        })
      );
      // yield call(projectApi.getOperationsDetail, projectId, vehicleId, date);
      yield put({
        type: appActions.SET_DATA_RECEIVED,
        payload: `detail-${vehicleId}-${date}`,
      });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    }
  }
}

export function* createModelVehicleWithScheduleGroup() {
  while (true) {
    yield take(
      projectActions.PROJECTS_MODEL_CREATE_VEHICLE_WITH_SCHEDULE_GROUP
    );
    const vehicle = yield select(getActiveVehicle);
    const scheduleGroup = yield select(getActiveScheduleGroup);
    const activeProject = yield select(getActiveProject);

    if (vehicle && scheduleGroup) {
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
      const { data: sgResult } = yield putResolve(
        projectsApi.endpoints.createScheduleGroup.initiate(scheduleGroup)
      );
      // const sgResult = yield call(projectApi.createScheduleGroup, scheduleGroup);
      let copy = copyObject(vehicle);
      copy.schedule_group_id = sgResult.id;
      // Check for power study
      if (copy.import_id && activeProject) {
        const { data: result } = yield putResolve(
          projectsApi.endpoints.checkOperationsSummary.initiate({
            projectId: activeProject.id,
            vehicleId: copy.import_id,
          })
        );
        // const result = yield call(projectApi.checkOperationsSummary, activeProject.id, copy.import_id);
        if (result.has_summary) {
          copy.vehicle_data.model_data.daily_energy_usage.has_power_study = true;
        }
        let sgCopy = copyObject(sgResult);
        let importedVehicle = getVehicle(copy.import_id, activeProject);
        if (importedVehicle) {
          let workflow = getWorkflow(
            importedVehicle.project_workflow_id,
            activeProject
          );
          let sgImport = getScheduleGroup(
            importedVehicle.schedule_group_id,
            workflow
          );
          sgCopy.schedules = cloneSchedules(sgImport);
          sgCopy.schedules.forEach((schedule) => {
            schedule.schedule_group_id = sgCopy.id;
          });
          const { data: sgUpdateResult } = yield putResolve(
            projectsApi.endpoints.updateScheduleGroup.initiate(sgCopy)
          );
          // const sgUpdateResult = yield call(projectApi.updateScheduleGroup, sgCopy);

          sgUpdateResult.schedules.forEach((schedule) => {
            copy.vehicle_data.model_data.daily_energy_usage.schedules[
              schedule.id
            ] = {
              calculation_parameters: {
                voltage: copy.vehicle_voltage,
                peak: {},
                avg: {},
              },
            };
          });
        }
      }

      const { data: newVehicle } = yield putResolve(
        projectsApi.endpoints.createVehicle.initiate(copy)
      );
      // const newVehicle = yield call(projectApi.createVehicle, copy);
      yield put({ type: appActions.SET_ACTIVE_VEHICLE, payload: newVehicle });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    }
  }
}

export function* updateModelScheduleGroup() {
  while (true) {
    yield take(projectActions.PROJECTS_MODEL_UPDATE_SCHEDULE_GROUP);
    const vehicle = yield select(getActiveVehicle);
    const scheduleGroup = yield select(getActiveScheduleGroup);

    if (vehicle && scheduleGroup) {
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
      const { data: newScheduleGroup } = yield putResolve(
        projectsApi.endpoints.updateScheduleGroup.initiate(scheduleGroup)
      );
      //const newScheduleGroup = yield call(projectApi.updateScheduleGroup, scheduleGroup);
      let copy = copyObject(vehicle);
      let isUpdated = false;
      newScheduleGroup.schedules.forEach((schedule) => {
        if (
          !copy.vehicle_data.model_data.daily_energy_usage.schedules[
            schedule.id
          ]
        ) {
          copy.vehicle_data.model_data.daily_energy_usage.schedules[
            schedule.id
          ] = {
            calculation_parameters: {
              voltage: copy.vehicle_voltage,
              peak: {},
              avg: {},
            },
          };

          isUpdated = true;
        }
      });
      Object.keys(
        copy.vehicle_data.model_data.daily_energy_usage.schedules
      ).forEach((scheduleId) => {
        const found = newScheduleGroup.schedules.find(
          (sch) => sch.id === Number(scheduleId)
        );

        if (!found) {
          delete copy.vehicle_data.model_data.daily_energy_usage.schedules[
            scheduleId
          ];
          isUpdated = true;
        }
      });

      if (isUpdated) {
        const { data: newVehicle } = yield putResolve(
          projectsApi.endpoints.updateVehicle.initiate(copy)
        );
        // const newVehicle = yield call(projectApi.updateVehicle, copy);
        yield put({ type: appActions.SET_ACTIVE_VEHICLE, payload: newVehicle });
      }
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    }
  }
}

export function* updateModelVehicleArray() {
  while (true) {
    const { payload: vehicleArray } = yield take(
      projectActions.PROJECTS_UPDATE_VEHICLE_ARRAY
    );

    yield put({
      type: appActions.SET_APP_STATE,
      payload: { blockActiveVehicleUpdate: true },
    });
    for (let i = 0; i < vehicleArray.length; i++) {
      const vehicle = vehicleArray[i];
      yield putResolve(projectsApi.endpoints.updateVehicle.initiate(vehicle));
      // yield call(projectApi.updateVehicle, vehicle);
    }
    yield put({
      type: appActions.SET_APP_STATE,
      payload: { blockActiveVehicleUpdate: false },
    });
  }
}
