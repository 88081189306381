import {
  SET_NAV
} from '../actions';

/**
 * @name navState
 * @param {Object} nav
 * @param {string} nav.index current url index
 * @param {string} nav.activeNavId current navigation id for the sidebar navigation
 * @param {number} nav.activeStepperDepth If the current nav item id is a stepper root then
 * this is the current zero-based index of what step we are currently in.
 * @param {boolean} nav.drawerOpen Set to true to force the drawer open, false to close the drawer
 */



const defaultState = {
  // index: 'clients',
  // activeNavId: 'clients.list',
  // activeStepperDepth: 0,
  // drawerOpen: true
};

export default function (state = defaultState, action) {

  switch(action.type) {
    case SET_NAV:
      return action.payload;

    default:
      return state;
  }
}
