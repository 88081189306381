import {
  PROJECTS_RECEIVED_GET_TASK_DATA
} from '../actions/project-actions';
import {copyObject} from "../utility";

const initialState = {
  workflow_task_definitions: {

  },
  workflow_task_lists: {

  },
  workflow_phases: {

  },
};

export default function (state = initialState, action) {

  switch(action.type) {
    case PROJECTS_RECEIVED_GET_TASK_DATA:
      return parseTaskData(action.payload);

    default:
      return state;
  }
}

function parseTaskData(data) {
  let obj = {
    workflow_task_definitions: {

    },
    workflow_task_lists: {

    },
    workflow_phases: {

    },
  };

  data.workflow_task_definitions.forEach(taskDef => {
    obj.workflow_task_definitions[taskDef.id] = copyObject(taskDef);
  });

  data.workflow_task_lists.forEach(taskList => {
    obj.workflow_task_lists[taskList.id] = copyObject(taskList);
  });

  data.workflow_phases.forEach(phase => {
    obj.workflow_phases[phase.id] = phase;
  });

  return obj;
}