import { cloneDeep } from "lodash";
import {
  PROJECTS_RECEIVED_CREATE_VEHICLE,
  PROJECTS_RECEIVED_DELETE_VEHICLE,
  PROJECTS_RECEIVED_UPDATE_VEHICLE,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

type Vehicle = {};

export const vehicleApi = (builder: BuilderType) => ({
  createVehicle: builder.mutation<Vehicle, Partial<Vehicle>>({
    query: (vehicle) => ({
      url: `service/vehicles`,
      method: "POST",
      body: fixupVehicleData(vehicle),
    }),
    invalidatesTags: [projectsCacheTags.VEHICLES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_VEHICLE),
  }),

  updateVehicle: builder.mutation<Vehicle, Partial<Vehicle>>({
    query: (vehicle) => ({
      url: `service/vehicles/${vehicle.id}`,
      method: "PUT",
      body: fixupVehicleData(vehicle),
    }),
    invalidatesTags: [projectsCacheTags.VEHICLES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_VEHICLE),
  }),

  deleteVehicle: builder.mutation<Vehicle, number>({
    query: (vehicleId) => ({
      url: `service/vehicles/${vehicleId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.VEHICLES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_VEHICLE),
  }),
});

export function fixupVehicleData(vehicle) {
  const copy = cloneDeep(vehicle);
  copy.vehicle_data = JSON.stringify(vehicle.vehicle_data);
  return copy;
}
