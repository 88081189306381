import { call, putResolve, take } from "redux-saga/effects";
import * as projectActions from "../../actions/project-actions";
/* import {
  ignoreAMAssetList,
  unIgnoreAMAssetList,
  clearAMUnexpectedAssetList,
} from "../api-projects"; */
import { projectsApi } from "../../services/projects/projectsApi";
import { updateActiveFleet } from "../index";

export function* watchIgnoreAMAssetList() {
  while (true) {
    const { payload: ids } = yield take(
      projectActions.PROJECTS_IGNORE_AM_ASSET_LIST
    );
    yield putResolve(projectsApi.endpoints.ignoreAMAssetList.initiate(ids));
    //yield call(ignoreAMAssetList, ids);
    yield call(updateActiveFleet);
  }
}

export function* watchUnIgnoreAMAssetList() {
  while (true) {
    const { payload: ids } = yield take(
      projectActions.PROJECTS_UN_IGNORE_AM_ASSET_LIST
    );
    yield putResolve(projectsApi.endpoints.unIgnoreAMAssetList.initiate(ids));
    //yield call(unIgnoreAMAssetList, ids);
    yield call(updateActiveFleet);
  }
}

export function* watchClearAMUnexpectedAssetList() {
  while (true) {
    const { payload: ids } = yield take(
      projectActions.PROJECTS_CLEAR_AM_UNEXPECTED_ASSET_LIST
    );
    yield putResolve(
      projectsApi.endpoints.clearAMUnexpectedAssetList.initiate(ids)
    );
    //yield call(clearAMUnexpectedAssetList, ids);
    yield call(updateActiveFleet);
  }
}
