import { cloneDeep } from "lodash";
import {
  PROJECTS_RECEIVED_CREATE_SCHEDULE,
  PROJECTS_RECEIVED_DELETE_SCHEDULE,
  PROJECTS_RECEIVED_UPDATE_SCHEDULE,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

type Schedule = {};

export const schedulesApi = (builder: BuilderType) => ({
  createSchedule: builder.mutation<Schedule, Partial<Schedule>>({
    query: (schedule) => ({
      url: `service/schedules.json`,
      method: "POST",
      body: fixupScheduleData(schedule),
    }),
    invalidatesTags: [projectsCacheTags.SCHEDULES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_SCHEDULE),
  }),

  updateSchedule: builder.mutation<Schedule, Partial<Schedule>>({
    query: (schedule) => ({
      url: `service/schedules/${schedule.id}.json`,
      method: "PUT",
      body: fixupScheduleData(schedule),
    }),
    invalidatesTags: [projectsCacheTags.SCHEDULES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_SCHEDULE),
  }),

  deleteSchedule: builder.mutation<Schedule, number>({
    query: (scheduleId) => ({
      url: `service/schedules/${scheduleId}.json`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.SCHEDULES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_SCHEDULE),
  }),
});

export function fixupScheduleData(values) {
  const schedule = cloneDeep(values);

  schedule.shifts_attributes = schedule.shifts;
  delete schedule.shifts;
  schedule.shifts_attributes.forEach((shift) => {
    shift.breaks_attributes = shift.breaks;
    delete shift.breaks;
  });

  return schedule;
}
