import {
  PROJECTS_RECEIVED_GET_MOTIVE_POWER
} from '../actions/project-actions';

export default function (state = {}, action) {

  switch(action.type) {
    case PROJECTS_RECEIVED_GET_MOTIVE_POWER:
      return action.payload;

    default:
      return state;
  }
}