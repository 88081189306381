import {call, put, select, take} from "redux-saga/effects";
import * as configActions from "../../actions/config-actions";
import {
  uploadVersionFile,
  getVersionMetas,
  getEncryptedChargerPackage,
  updateChargerFactorySettings,
  updateChargerUserSettings,
  updateGatewayFactorySettings,
  updateGatewayUserSettings,
  getEncryptedGatewayPackage,
  updateBatteryFactorySettings,
  updateBatteryUserSettings,
  updateVanadiumBESSUserSettings,
  updateVanadiumBESSFactorySettings,
  updateLeadAcidBESSUserSettings,
  updateLeadAcidBESSFactorySettings,
  deleteVersionMeta,
} from "../api-config";
import * as appActions from "../../actions";
import {
  getAssets,
  getChargerConfigs,
  getGatewayConfigs,
  getBatteryConfigs,
  getVanadiumBESSConfigs,
  getLeadAcidBESSConfigs,
} from "../../reducers/selectors";
import {copyObject} from "../../shared-components/src/utility";
import { updateAssetAlias, api_createAssetWithConfiguration} from "../api-portal";
import {updateActiveFleet} from "../index";

export function* watchUploadVersionFile() {
  while (true) {
    const {payload: file} = yield take(
      configActions.CONFIG_UPLOAD_VERSION_FILE
    );
    //console.log('In watchUploadVersionFile', file);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(uploadVersionFile, file);
    yield put({type: appActions.SET_DATA_REQUESTED, payload: "versionMetas"});
    yield call(getVersionMetas);
    yield put({type: appActions.SET_DATA_RECEIVED, payload: "versionMetas"});
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchGetVersionMetas() {
  while (true) {
    yield take(configActions.CONFIG_GET_VERSIONS_META);
    yield put({type: appActions.SET_DATA_REQUESTED, payload: "versionMetas"});
    yield call(getVersionMetas);
    yield put({type: appActions.SET_DATA_RECEIVED, payload: "versionMetas"});
  }
}

export function* watchDeleteVersionMeta() {
  while (true) {
    const {
      payload: {versionId}
    } = yield take(configActions.CONFIG_DELETE_VERSIONS_META);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(deleteVersionMeta, versionId);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchGetEncryptedChargerPackage() {
  while (true) {
    const {payload: chargerSerial} = yield take(
      configActions.CONFIG_GET_CHARGER_ENCRYPTED_PACKAGE
    );
    yield call(getEncryptedChargerPackage, chargerSerial);
  }
}

export function* watchUpdateChargerSettings() {
  while (true) {
    const {
      payload: {chargerSerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_CHARGER_SETTINGS);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    if (settings.factory) {
      yield call(
        updateChargerFactorySettings,
        {factory: settings.factory},
        chargerSerial
      );
    }
    if (settings.user) {
      const configs = yield select(getChargerConfigs);
      const config = configs.chargers.find(
        c => c.chargerSerial === chargerSerial
      );
      if (config) {
        if (config.config.user.name !== settings.user.name) {
          const assets = yield select(getAssets);
          const asset = assets.find(a => a.name === chargerSerial);
          if (asset) {
            let copy = copyObject(asset);
            copy.alias = settings.user.name;
            yield call(updateAssetAlias, copy);
          }
        }
        yield call(
          updateChargerUserSettings,
          {user: settings.user},
          chargerSerial
        );
      }
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateChargerFactorySettings() {
  while (true) {
    const {
      payload: {chargerSerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_CHARGER_FACTORY);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(updateChargerFactorySettings, settings, chargerSerial);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateChargerUserSettings() {
  while (true) {
    const {
      payload: {chargerSerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_CHARGER_USER);
    const configs = yield select(getChargerConfigs);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    const config = configs.chargers.find(
      c => c.chargerSerial === chargerSerial
    );
    if (config) {
      if (config.config.user.name !== settings.user.name) {
        const assets = yield select(getAssets);
        const asset = assets.find(a => a.name === chargerSerial);
        if (asset) {
          let copy = copyObject(asset);
          copy.alias = settings.user.name;
          yield call(updateAssetAlias, copy);
        }
      }
    }
    yield call(updateChargerUserSettings, settings, chargerSerial);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateGatewaySettings() {
  while (true) {
    const {
      payload: {gatewaySerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_GATEWAY_SETTINGS);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    if (settings.factory) {
      yield call(
        updateGatewayFactorySettings,
        {factory: settings.factory},
        gatewaySerial
      );
    }
    if (settings.user) {
      const configs = yield select(getGatewayConfigs);
      const config = configs.gateways.find(
        c => c.gatewaySerial === gatewaySerial
      );
      if (config) {
        yield call(
          updateGatewayUserSettings,
          {user: settings.user},
          gatewaySerial
        );
        if (config.config.user.name !== settings.user.name) {
          const assets = yield select(getAssets);
          const asset = assets.find(a => a.name === gatewaySerial);
          if (asset) {
            let copy = copyObject(asset);
            copy.alias = settings.user.name;
            yield call(updateAssetAlias, copy);
          }
        }
      }
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateGatewayFactorySettings() {
  while (true) {
    const {
      payload: {gatewaySerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_GATEWAY_FACTORY);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(updateGatewayFactorySettings, settings, gatewaySerial);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateGatewayUserSettings() {
  while (true) {
    const {
      payload: {gatewaySerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_GATEWAY_USER);
    const configs = yield select(getGatewayConfigs);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    const config = configs.gateways.find(
      c => c.gatewaySerial === gatewaySerial
    );
    yield call(updateGatewayUserSettings, settings, gatewaySerial);
    if (config) {
      if (config.config.user.name !== settings.user.name) {
        const assets = yield select(getAssets);
        const asset = assets.find(a => a.name === gatewaySerial);
        if (asset) {
          let copy = copyObject(asset);
          copy.alias = settings.user.name;
          yield call(updateAssetAlias, copy);
        }
      }
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchGetEncryptedGatewayPackage() {
  while (true) {
    const {payload: gatewaySerial} = yield take(
      configActions.CONFIG_GET_GATEWAY_ENCRYPTED_PACKAGE
    );
    yield call(getEncryptedGatewayPackage, gatewaySerial);
  }
}

export function* watchUpdateBatterySettings() {
  while (true) {
    const {
      payload: {batterySerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_BATTERY_SETTINGS);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    if (settings.factory) {
      const configs = yield select(getBatteryConfigs);
      const config = configs.batteries.find(
        c => c.batterySerial === batterySerial
      );
      if (config) {
        if (config.config.factory.name !== settings.factory.name) {
          const assets = yield select(getAssets);
          const asset = assets.find(a => a.name === batterySerial);
          if (asset) {
            let copy = copyObject(asset);
            copy.alias = settings.factory.name;
            yield call(updateAssetAlias, copy);
          }
        }
      }

      yield call(
        updateBatteryFactorySettings,
        {factory: settings.factory},
        batterySerial
      );
    }
    if (settings.user) {
      yield call(
        updateBatteryUserSettings,
        {user: settings.user},
        batterySerial
      );
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateBatteryFactorySettings() {
  while (true) {
    const {
      payload: {batterySerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_BATTERY_FACTORY);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    const configs = yield select(getBatteryConfigs);
    const config = configs.gateways.find(
      c => c.batterySerial === batterySerial
    );
    yield call(updateBatteryFactorySettings, settings, batterySerial);
    if (config) {
      if (config.config.factory.name !== settings.factory.name) {
        const assets = yield select(getAssets);
        const asset = assets.find(a => a.name === batterySerial);
        if (asset) {
          let copy = copyObject(asset);
          copy.alias = settings.factory.name;
          yield call(updateAssetAlias, copy);
        }
      }
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateBatteryUserSettings() {
  while (true) {
    const {
      payload: {batterySerial, settings}
    } = yield take(configActions.CONFIG_UPDATE_BATTERY_USER);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(updateBatteryUserSettings, settings, batterySerial);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateVanadiumBESSSettings() {
  while (true) {
    const {
      payload: {serial, settings}
    } = yield take(configActions.CONFIG_UPDATE_VANADIUM_BESS_SETTINGS);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    if (settings.factory) {
      yield call(
        updateVanadiumBESSFactorySettings,
        {factory: settings.factory},
        serial
      );
    }
    if (settings.user) {
      const configs = yield select(getVanadiumBESSConfigs);
      const config = configs.vanadiumBESS.find(
        c => c.serial === serial
      );
      if (config) {
        if (config.config.user.name !== settings.user.name) {
          const assets = yield select(getAssets);
          const asset = assets.find(a => a.name === serial);
          if (asset) {
            let copy = copyObject(asset);
            copy.alias = settings.user.name;
            yield call(updateAssetAlias, copy);
          }
        }
      }
      yield call(
        updateVanadiumBESSUserSettings,
        {user: settings.user},
        serial
      );
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateLeadAcidBESSSettings() {
  while (true) {
    const {
      payload: {serial, settings}
    } = yield take(configActions.CONFIG_UPDATE_LEADACID_BESS_SETTINGS);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    if (settings.factory) {
      yield call(
        updateLeadAcidBESSFactorySettings,
        {factory: settings.factory},
        serial
      );
    }
    if (settings.user) {
      const configs = yield select(getLeadAcidBESSConfigs);
      const config = configs.leadAcidBESS.find(
        c => c.serial === serial
      );
      if (config) {
        if (config.config.user.name !== settings.user.name) {
          const assets = yield select(getAssets);
          const asset = assets.find(a => a.name === serial);
          if (asset) {
            let copy = copyObject(asset);
            copy.alias = settings.user.name;
            yield call(updateAssetAlias, copy);
          }
        }
      }
      yield call(
        updateLeadAcidBESSUserSettings,
        {user: settings.user},
        serial
      );
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateVanadiumBESSFactorySettings() {
  while (true) {
    const {
      payload: {serial, settings}
    } = yield take(configActions.CONFIG_UPDATE_VANADIUM_BESS_FACTORY);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    const configs = yield select(getVanadiumBESSConfigs);
    const config = configs.vanadiumBESSs.find(
      c => c.serial === serial
    );
    yield call(updateVanadiumBESSFactorySettings, settings, serial);
    if (config) {
      if (config.config.factory.name !== settings.factory.name) {
        const assets = yield select(getAssets);
        const asset = assets.find(a => a.name === serial);
        if (asset) {
          let copy = copyObject(asset);
          copy.alias = settings.factory.name;
          yield call(updateAssetAlias, copy);
        }
      }
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateLeadAcidBESSFactorySettings() {
  while (true) {
    const {
      payload: {serial, settings}
    } = yield take(configActions.CONFIG_UPDATE_LEADACID_BESS_FACTORY);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    const configs = yield select(getLeadAcidBESSConfigs);
    const config = configs.leadAcidBESSs.find(
      c => c.serial === serial
    );
    yield call(updateLeadAcidBESSFactorySettings, settings, serial);
    if (config) {
      if (config.config.factory.name !== settings.factory.name) {
        const assets = yield select(getAssets);
        const asset = assets.find(a => a.name === serial);
        if (asset) {
          let copy = copyObject(asset);
          copy.alias = settings.factory.name;
          yield call(updateAssetAlias, copy);
        }
      }
    }
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateVanadiumBESSUserSettings() {
  while (true) {
    const {
      payload: {serial, settings}
    } = yield take(configActions.CONFIG_UPDATE_VANADIUM_BESS_USER);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(updateVanadiumBESSUserSettings, settings, serial);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchUpdateLeadAcidBESSUserSettings() {
  while (true) {
    const {
      payload: {serial, settings}
    } = yield take(configActions.CONFIG_UPDATE_LEADACID_BESS_USER);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield call(updateVanadiumBESSUserSettings, settings, serial);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}

export function* watchCreateAssetAndConfiguration() {
  while (true) {
    const {
      payload: {asset, settings}
    } = yield take(configActions.CONFIG_CREATE_ASSET_AND_CONFIGURATION);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    const config = {factory: settings.factory || {}, user: settings.user || {}}
    yield call(api_createAssetWithConfiguration, asset, config);
    yield call(updateActiveFleet);
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}
