import {
  PROJECTS_RECEIVED_GET_BATTERIES,
  PROJECTS_RECEIVED_UPDATE_BATTERY,
  PROJECTS_RECEIVED_CREATE_BATTERY,
  PROJECTS_RECEIVED_DELETE_BATTERY,
} from '../actions/project-actions';
import {copyObject} from "../utility";

export default function (state = [], action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_BATTERIES:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_BATTERY:
      return createBattery(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_BATTERY:
      return updateBattery(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_BATTERY:
      return deleteBattery(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateBattery(state, data) {
  state.forEach((battery, idx) => {
    if(battery.id === data.id) {
      state[idx] = data;
    }
  });

  return state;
}

function createBattery(state, data) {

  state.push(data);

  return state;
}

function deleteBattery(state, data) {
  state.forEach((battery, idx) => {
    if(battery.id === data.id) {
      state.splice(idx, 1);
    }
  });

  return state;
}