import {
  PORTAL_RECEIVED_GET_USERS
} from '../actions/portal-actions';

export default function (state = [], action) {

  switch(action.type) {
    case PORTAL_RECEIVED_GET_USERS:
      return action.payload;

    default:
      return state;
  }
}