import { PROJECTS_RECEIVED_GET_TASK_DATA } from "../../actions/project-actions";
import { cacheTag, legacyFulfill } from "./../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type TaskDataApi = {};

export const taskDataApi = (builder: BuilderType) => ({
  getTaskData: builder.query<TaskData, void>({
    query: () => `service/task-data`,
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_TASK_DATA),
    providesTags: [projectsCacheTags.TASK_DATA],
  }),
});
