import * as projectActions from "../../actions/project-actions";
import { take, put, call, putResolve } from "redux-saga/effects";
import * as appActions from "../../actions";
import { projectsApi } from "../../services/projects/projectsApi";
const {
  createCustomerGroupMember,
  deleteCustomerGroupMember,
  getCustomerGroups,
  createCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup,
} = projectsApi.endpoints;

export function* watchGetCustomerGroups() {
  while (true) {
    yield take(projectActions.PROJECTS_GET_CUSTOMER_GROUPS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put({
      type: appActions.SET_DATA_REQUESTED,
      payload: `customerGroups`,
    });
    yield putResolve(getCustomerGroups.initiate());
    //yield call(getCustomerGroups);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({
      type: appActions.SET_DATA_RECEIVED,
      payload: `customerGroups`,
    });
  }
}

export function* watchCreateCustomerGroup() {
  while (true) {
    const { payload: customerGroup } = yield take(
      projectActions.PROJECTS_CREATE_CUSTOMER_GROUP
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createCustomerGroup.initiate(customerGroup));
    //yield call(createCustomerGroup, customerGroup);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateCustomerGroup() {
  while (true) {
    const { payload: customerGroup } = yield take(
      projectActions.PROJECTS_UPDATE_CUSTOMER_GROUP
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(updateCustomerGroup.initiate(customerGroup));
    //yield call(updateCustomerGroup, customerGroup);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteCustomerGroup() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_CUSTOMER_GROUP
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteCustomerGroup.initiate(id));
    //yield call(deleteCustomerGroup, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateCustomerGroupMember() {
  while (true) {
    const { payload: customerGroupMember } = yield take(
      projectActions.PROJECTS_CREATE_CUSTOMER_GROUP_MEMBER
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createCustomerGroupMember.initiate(customerGroupMember));
    // yield call(createCustomerGroupMember, customerGroupMember);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteCustomerGroupMember() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_CUSTOMER_GROUP_MEMBER
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteCustomerGroupMember.initiate(id));
    //yield call(deleteCustomerGroupMember, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
