import * as projectActions from "../../actions/project-actions";
import { take, put } from "redux-saga/effects";
import * as appActions from "../../actions";
/* import {
  getClientOwners,
  createClientOwner,
    updateClientOwner,
    deleteClientOwner,
} from "../api-projects"; */

import { projectsApi } from "../../services/projects/projectsApi";

const {
  createClientOwner,
  updateClientOwner,
  deleteClientOwner,
  getClientOwners,
} = projectsApi.endpoints;

export function* watchGetClientOwners() {
  while (true) {
    yield take(projectActions.PROJECTS_GET_CLIENT_OWNERS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put({ type: appActions.SET_DATA_REQUESTED, payload: `clientOwners` });
    yield put(getClientOwners.initiate());
    //yield call(getClientOwners);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({ type: appActions.SET_DATA_RECEIVED, payload: `clientOwners` });
  }
}

export function* watchCreateClientOwner() {
  while (true) {
    const { payload: clientOwner } = yield take(
      projectActions.PROJECTS_CREATE_CLIENT_OWNER
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put(createClientOwner.initiate(clientOwner));
    // yield call(createClientOwner, clientOwner);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateClientOwner() {
  while (true) {
    const { payload: clientOwner } = yield take(
      projectActions.PROJECTS_UPDATE_CLIENT_OWNER
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put(updateClientOwner.initiate(clientOwner));
    //yield call(updateClientOwner, clientOwner);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteClientOwner() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_CLIENT_OWNER
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put(deleteClientOwner.initiate(id));
    // yield call(deleteClientOwner, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
