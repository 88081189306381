import * as projectActions from "../../actions/project-actions";
import { take, put, call, putResolve } from "redux-saga/effects";
import * as appActions from "../../actions";
import { projectsApi } from "../../services/projects/projectsApi";
const {
  createUserInterface,
  updateUserInterface,
  createUserDashboard,
  updateUserDashboards,
  deleteUserDashboard,
  getUserViews,
  createClientInterface,
  deleteClientInterface,
} = projectsApi.endpoints;

export function* watchGetUserViews() {
  while (true) {
    yield take(projectActions.PROJECTS_GET_USER_VIEWS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put({ type: appActions.SET_DATA_REQUESTED, payload: `userViews` });
    yield putResolve(getUserViews.initiate());
    // yield call(getUserViews);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({ type: appActions.SET_DATA_RECEIVED, payload: `userViews` });
  }
}

export function* watchCreateUserInterface() {
  while (true) {
    const { payload: userInterface } = yield take(
      projectActions.PROJECTS_CREATE_USER_INTERFACE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createUserInterface.initiate(userInterface));
    //yield call(createUserInterface, userInterface);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateUserInterface() {
  while (true) {
    const { payload: userInterface } = yield take(
      projectActions.PROJECTS_UPDATE_USER_INTERFACE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(updateUserInterface.initiate(userInterface));
    //yield call(updateUserInterface, userInterface);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateUserDashboard() {
  while (true) {
    const { payload: userDashboard } = yield take(
      projectActions.PROJECTS_CREATE_USER_DASHBOARD
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createUserDashboard.initiate(userDashboard));
    //yield call(createUserDashboard, userDashboard);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateUserDashboards() {
  while (true) {
    const { payload: update } = yield take(
      projectActions.PROJECTS_UPDATE_USER_DASHBOARDS
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(updateUserDashboards.initiate(update));
    //yield call(updateUserDashboards, update);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteUserDashboard() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_USER_DASHBOARD
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteUserDashboard.initiate(id));
    // yield call(deleteUserDashboard, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateClientInterface() {
  while (true) {
    const { payload: clientInterface } = yield take(
      projectActions.PROJECTS_CREATE_CLIENT_INTERFACE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createClientInterface.initiate(clientInterface));
    //yield call(createClientInterface, clientInterface);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteClientInterface() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_CLIENT_INTERFACE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteClientInterface.initiate(id));
    // yield call(deleteClientInterface, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
