import { strings } from './localization';

export const contactTypeOptions = [
  { value: 'corporate', label: strings.corporate },
  { value: 'facility', label: strings.facility },
];

export const decisionMakerOptions = [
  { value: 'decision_maker', label: strings.decisionMaker },
  { value: 'decision_influencer', label: strings.decisionInfluencer },
  { value: 'technical_expert', label: strings.technicalExpert },
];

export const facilityTypeOptions = [
  { value: 'headquarters', label: strings.headquarters },
  { value: 'distribution_center', label: strings.distributionCenter },
  { value: 'manufacturing', label: strings.manufacturing },
  { value: 'cold_storage', label: strings.coldStorage },
  { value: 'warehouse', label: strings.warehouse },
  { value: 'other', label: strings.other },
];

export const vehicleTypeOptions = [
  { value: 'order_stock_picker', label: strings.orderStockPicker },
  { value: 'pallet_truck', label: strings.palletTruck },
  { value: 'reach_truck', label: strings.reachTruck },
  { value: 'sit_down_counterbalanced', label: strings.sitDownCounterbalanced },
  { value: 'stand_up_counterbalanced', label: strings.standUpCounterbalanced },
  { value: 'tow_tractor', label: strings.towTractor },
  { value: 'turret_truck', label: strings.turretTruck },
  { value: 'walkie_stacker', label: strings.walkieStacker },
  { value: 'other', label: strings.other },
];

export const vehicleMakeOptions = [
  { value: 'advance', label: strings.advance },
  { value: 'a_g_mercury', label: strings.aGMercury },
  { value: 'aisle_master', label: strings.aisleMaster },
  { value: 'auto_guide', label: strings.autoGuide },
  { value: 'barrett', label: strings.barrett },
  { value: 'bendi_landoll', label: strings.bendiLandoll },
  { value: 'big_joe', label: strings.bigJoe },
  { value: 'blue_giant', label: strings.blueGiant },
  { value: 'b_t_prime_mover', label: strings.bTPrimeMover },
  { value: 'caterpillar', label: strings.caterpillar },
  { value: 'clark', label: strings.clark },
  { value: 'crown', label: strings.crown },
  { value: 'dockstocker', label: strings.dockstocker },
  { value: 'doosan_daewoo', label: strings.doosanDaewoo },
  { value: 'drexel', label: strings.drexel },
  { value: 'elwell_parker', label: strings.elwellParker },
  { value: 'flexi', label: strings.flexi },
  { value: 'gregory', label: strings.gregory },
  { value: 'heli', label: strings.heli },
  { value: 'hoist', label: strings.hoist },
  { value: 'hubtex', label: strings.hubtex },
  { value: 'hyster', label: strings.hyster },
  { value: 'hyundai', label: strings.hyundai },
  { value: 'jungheinrich', label: strings.jungheinrich },
  { value: 'kalmar', label: strings.kalmar },
  { value: 'komatsu', label: strings.komatsu },
  { value: 'linde', label: strings.linde },
  { value: 'lift_a_lot', label: strings.liftALot },
  { value: 'mariotti', label: strings.mariotti },
  { value: 'mitsubishi', label: strings.mitsubishi },
  { value: 'motrec', label: strings.motrec },
  { value: 'multiton', label: strings.multiton },
  { value: 'nissan', label: strings.nissan },
  { value: 'raymond', label: strings.raymond },
  { value: 'rico', label: strings.rico },
  { value: 'royal', label: strings.royal },
  { value: 'savant-automation', label: strings.savantAutomation },
  { value: 'schaeff', label: strings.schaeff },
  { value: 'schreck', label: strings.schreck },
  { value: 'sroka', label: strings.sroka },
  { value: 'steinbock', label: strings.steinbock },
  { value: 'taylor_dunn', label: strings.taylorDunn },
  { value: 't_c_m', label: strings.tCM },
  { value: 'toyota', label: strings.toyota },
  { value: 'yale', label: strings.yale },
  { value: 'tusk', label: strings.tusk },
  { value: 'uni-carrier', label: strings.uniCarrier },
  { value: 'white', label: strings.white },
  { value: 'world', label: strings.world },
  { value: 'other', label: strings.other },
];

export const vehicleVoltageOptions = [
  { value: 24, label: '24V' },
  { value: 36, label: '36V' },
  { value: 48, label: '48V' },
  { value: 72, label: '72V' },
  { value: 80, label: '80V' },
  { value: 96, label: '96V' },
];

export const batteryManufacturerOptions = [
  { value: 'battery_builders', label: strings.batteryBuilders },
  { value: 'bulldog', label: strings.bulldog },
  { value: 'crown', label: strings.crown },
  { value: 'deka', label: strings.deka },
  { value: 'douglas', label: strings.douglas },
  { value: 'enersys', label: strings.enerSys },
  { value: 'gnb', label: strings.gNB },
  { value: 'hawker', label: strings.hawker },
  { value: 'hoppecke', label: strings.hoppecke },
  { value: 'other', label: strings.other },
];

export const competitorBatteryManufacturerOptions = [
  { value: 'battery_builders', label: strings.batteryBuilders },
  { value: 'bulldog', label: strings.bulldog },
  { value: 'crown', label: strings.crown },
  { value: 'deka', label: strings.deka },
  { value: 'douglas', label: strings.douglas },
  { value: 'enersys', label: strings.enerSys },
  { value: 'hawker', label: strings.hawker },
  { value: 'hoppecke', label: strings.hoppecke },
  { value: 'other', label: strings.other },
];

export const chargerManufacturerOptions = [
  { value: 'act', label: strings.aCT },
  { value: 'aes', label: strings.aES },
  { value: 'ametek', label: strings.ametek },
  { value: 'bassi', label: strings.bassi },
  { value: 'crown_powerhouse', label: strings.crownPowerhouse },
  { value: 'crown_v_force', label: strings.crownVForce },
  { value: 'douglas', label: strings.douglas },
  { value: 'ecotec', label: strings.ecotec },
  { value: 'enatel', label: strings.enatel },
  { value: 'enersys', label: strings.enerSys },
  { value: 'geci', label: strings.gECI },
  { value: 'gnb', label: strings.gNB },
  { value: 'hawker', label: strings.hawker },
  { value: 'posicharge', label: strings.posicharge },
  { value: 'power_designers', label: strings.powerDesigners },
  { value: 'stanbury', label: strings.stanbury },
  { value: 'other', label: strings.other },
];

export const competitorChargerManufacturerOptions = [
  { value: 'act', label: strings.aCT },
  { value: 'aes', label: strings.aES },
  { value: 'ametek', label: strings.ametek },
  { value: 'bassi', label: strings.bassi },
  { value: 'crown_powerhouse', label: strings.crownPowerhouse },
  { value: 'crown_v_force', label: strings.crownVForce },
  { value: 'douglas', label: strings.douglas },
  { value: 'ecotec', label: strings.ecotec },
  { value: 'enatel', label: strings.enatel },
  { value: 'enersys', label: strings.enerSys },
  { value: 'geci', label: strings.gECI },
  { value: 'hawker', label: strings.hawker },
  { value: 'posicharge', label: strings.posicharge },
  { value: 'power_designers', label: strings.powerDesigners },
  { value: 'stanbury', label: strings.stanbury },
  { value: 'other', label: strings.other },
];

export const chargingStrategyOptions = [
  { value: 'fast', label: strings.fastCharge },
  { value: 'opportunity', label: strings.opportunityCharge },
  { value: 'conventional', label: strings.conventionalCharge },
  { value: 'lithium', label: strings.lithium },
];

export const chargingStrategyOptionsShort = [
  { value: 'fast', label: strings.fast },
  { value: 'opportunity', label: strings.opportunity },
  { value: 'conventional', label: strings.conventional },
  { value: 'lithium', label: strings.lithium },
];

export const chargingStrategyOptionsShortModel = [
  { value: 'opportunity_fast', label: strings.opportunityFast },
  { value: 'conventional', label: strings.conventional },
  { value: 'lithium', label: strings.lithium },
];

export const chargingStrategyOptionsShortModelFix = [
  { value: 'opportunity_fast', label: strings.opportunityFast },
  { value: 'conventional', label: strings.conventional },
  { value: 'lithium', label: strings.lithium },
  { value: 'opportunity', label: strings.opportunity },
  { value: 'fast', label: strings.fast },
];

export const acInputOptions = [
  { value: '208_240_1', label: strings.ac208Single },
  { value: '208_240_3', label: strings.ac208Three },
  { value: '480_3', label: strings.ac480Three },
  { value: '600_3', label: strings.ac600Three },
];

export const connectorOptions = [
  { value: 175, label: strings.sb175 },
  { value: 350, label: strings.sb350 },
  { value: 320, label: strings.rema320 },
  { value: 640, label: strings.rema640 },
];

export const warrantiedDailyLimitOptions = [
  { value: '2_ebu', label: strings.twoEBU },
  { value: '1_5_ebu', label: strings.onePointFiveEBU },
];

export const timeZoneOptions = [
  { value: 'America/New_York', label: 'Eastern Time (ET)' },
  { value: 'America/Chicago', label: 'Central Time (CT)' },
  { value: 'America/Denver', label: 'Mountain Time (MT)' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
  { value: 'America/Anchorage', label: 'Alaskan Time (AKT)' },
  { value: 'Pacific/Honolulu', label: 'Hawaiian Time (HT)' },
  { value: 'Etc/GMT', label: 'Greenwich Mean Time (GMT)' },
  { value: 'Europe/Paris', label: 'Central European Time (CET)' },
  { value: 'Europe/Kiev', label: 'Eastern European Time (EET)' },
  { value: 'Europe/Moscow', label: 'Moscow Time (MSK)' },
  { value: 'Etc/GMT+1', label: 'GMT-1' },
  { value: 'Etc/GMT+2', label: 'GMT-2' },
  { value: 'Etc/GMT+3', label: 'GMT-3' },
  { value: 'Etc/GMT+4', label: 'GMT-4' },
  { value: 'Etc/GMT+5', label: 'GMT-5' },
  { value: 'Etc/GMT+6', label: 'GMT-6' },
  { value: 'Etc/GMT+7', label: 'GMT-7' },
  { value: 'Etc/GMT+8', label: 'GMT-8' },
  { value: 'Etc/GMT+9', label: 'GMT-9' },
  { value: 'Etc/GMT+10', label: 'GMT-10' },
  { value: 'Etc/GMT+11', label: 'GMT-11' },
  { value: 'Etc/GMT+12', label: 'GMT-12' },
  { value: 'Etc/GMT-1', label: 'GMT+1' },
  { value: 'Etc/GMT-2', label: 'GMT+2' },
  { value: 'Etc/GMT-3', label: 'GMT+3' },
  { value: 'Etc/GMT-4', label: 'GMT+4' },
  { value: 'Etc/GMT-5', label: 'GMT+5' },
  { value: 'Etc/GMT-6', label: 'GMT+6' },
  { value: 'Etc/GMT-7', label: 'GMT+7' },
  { value: 'Etc/GMT-8', label: 'GMT+8' },
  { value: 'Etc/GMT-9', label: 'GMT+9' },
  { value: 'Etc/GMT-10', label: 'GMT+10' },
  { value: 'Etc/GMT-11', label: 'GMT+11' },
  { value: 'Etc/GMT-12', label: 'GMT+12' },
];

export const cableConfigurationOptions = [
  { value: 'single_charge', label: 'Single Charge, Single Discharge' },
  { value: 'dual_charge_single', label: 'Dual Charge, Single Discharge' },
  { value: 'dual_charge_dual', label: 'Dual Charge, Dual Discharge' },
];

export const batteryChemistryOptions = [
  { value: 'flooded_lead_acid', label: strings.floodedLeadAcid },
  { value: 'sealed_lead_acid', label: strings.sealedLeadAcid },
  { value: 'lithium', label: strings.lithium },
];

export const monitorBatteryChemistryOptions = [
  { value: 'lead_acid', label: strings.leadAcid },
  { value: 'lithium', label: strings.lithium },
  { value: 'agm', label: strings.agm },
];

export const addressTypes = [
  { value: 'mail', label: strings.mail },
  { value: 'shipping', label: strings.shipping },
  { value: 'billing', label: strings.billing },
];

export const taskTypes = [
  { value: 'general', label: strings.general },
  { value: 'installation', label: strings.installation },
];
export const fuelConversionMethods = [
  { value: 'gallons_LPG', label: strings.gallonsLPG },
  { value: 'liters_LPG', label: strings.litersLPG },
  { value: 'gallons_diesel', label: strings.gallonsDiesel },
  { value: 'liters_diesel', label: strings.litersDiesel },
  { value: 'cubic_feet_CNG', label: strings.cubicFeetCNG },
  { value: 'cubic_meters_CNG', label: strings.cubicMetersCNG },
];

export const powerLoggerTypes = [
  { value: 'power_logger_cellular', label: strings.cellular },
  { value: 'power_logger_bluetooth', label: strings.bluetooth },
];

export const installationLocations = [
  { value: 'battery_mounted', label: strings.batteryMounted },
  { value: 'vehicle_mounted', label: strings.vehicleMounted },
];

export const solutionTypeOptions = [
  { value: 'battery_change', label: strings.change },
  { value: 'single_battery', label: strings.singleBattery },
];

export const batteryStrategyTypeOptions = [
  { value: 'multiple_batteries', label: strings.multipleBatteries },
  { value: 'single_battery', label: strings.singleBattery },
];

export const batterySeriesOptions = {
  battery_builders: [
    { value: 'fplm', label: strings.fplm },
    { value: 'other', label: strings.other },
  ],
  bulldog: [
    { value: 'elite_power', label: strings.elite_power },
    { value: 'bull_pak', label: strings.bull_pak },
    { value: 'other', label: strings.other },
  ],
  crown: [
    { value: 'crown_industrial_batteries', label: strings.crown_industrial_batteries },
    { value: 'h2go_low_maintenance', label: strings.h2go_low_maintenance },
    { value: 'max_capacity', label: strings.max_capacity },
    { value: 'other', label: strings.other },
  ],
  deka: [
    { value: 'd_series', label: strings.d_series },
    { value: 'maxpower', label: strings.maxpower },
    { value: 'hydrasaver', label: strings.hydrasaver },
    { value: 'fastcharge', label: strings.fastcharge },
    { value: 'dominator', label: strings.dominator },
    { value: 'maintenancesaver', label: strings.maintenancesaver },
    { value: 'chargemate', label: strings.chargemate },
    { value: 'powrmate', label: strings.powrmate },
    { value: 'gel_mate', label: strings.gel_mate },
    { value: 'ready_power_lion', label: strings.ready_power_lion },
    { value: 'other', label: strings.other },
  ],
  douglas: [
    { value: 'legacy_high_performance', label: strings.legacy_high_performance },
    { value: 'legacy_opportunity_charge', label: strings.legacy_opportunity_charge },
    { value: 'legacy_rapid_charge', label: strings.legacy_rapid_charge },
    { value: 'legacy_low_maintenance', label: strings.legacy_low_maintenance },
    { value: 'legacy_platinum', label: strings.legacy_platinum },
    { value: 'legacy_arctic', label: strings.legacy_arctic },
    { value: 'other', label: strings.other },
  ],
  enersys: [
    { value: 'workhog', label: strings.workhog },
    { value: 'deserthog', label: strings.deserthog },
    { value: 'loadhog', label: strings.loadhog },
    { value: 'superhog', label: strings.superhog },
    { value: 'smarthog', label: strings.smarthog },
    { value: 'smartpack', label: strings.smartpack },
    { value: 'hup', label: strings.hup },
    { value: 'nexsys_2v_cells', label: strings.nexsys_2v_cells },
    { value: 'nexsys_blocks', label: strings.nexsys_blocks },
    { value: 'other', label: strings.other },
  ],
  gnb: [{ value: 'other', label: strings.other }],
  hawker: [
    { value: 'energy_plus', label: strings.energy_plus },
    { value: 'envirlink', label: strings.envirlink },
    { value: 'flexpak', label: strings.flexpak },
    { value: 'powerline', label: strings.powerline },
    { value: 'water_less', label: strings.water_less },
    { value: 'other', label: strings.other },
  ],
  hoppecke: [
    { value: 'uplift', label: strings.uplift },
    { value: 'uplift_air', label: strings.uplift_air },
    { value: 'uplift_save', label: strings.uplift_save },
    { value: 'other', label: strings.other },
  ],
  other: [{ value: 'other', label: strings.other }],
};

export const channelTypeOptions = [
  { value: 'direct_customer', label: strings.directCustomer },
  { value: 'battery_dealer', label: strings.batteryDealer },
  { value: 'truck_dealer', label: strings.truckDealer },
  { value: 'oem', label: strings.oem },
  { value: 'third_party_logistics', label: strings.thirdPartyLogistics },
];

/*
{unknown crown deka enersys c_and_d douglas bulldog exide gould varta hawker Yuasa trojan interstate us_battery}
 */
export const powerLoggerBatteryManufacturers = [
  { value: 'unknown', label: strings.unknown },
  { value: 'crown', label: strings.crown },
  { value: 'deka', label: strings.deka },
  { value: 'enersys', label: strings.enerSys },
  { value: 'c_and_d', label: strings.cAndD },
  { value: 'douglas', label: strings.douglas },
  { value: 'bulldog', label: strings.bulldog },
  { value: 'exide', label: strings.stryten },
  { value: 'gould', label: strings.gould },
  { value: 'varta', label: strings.varta },
  { value: 'hawker', label: strings.hawker },
  { value: 'Yuasa', label: strings.yuasa },
  { value: 'trojan', label: strings.trojan },
  { value: 'interstate', label: strings.interstate },
  { value: 'us_battery', label: strings.usBattery },
]
