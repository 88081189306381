import {
  PROJECTS_RECEIVED_COMPLETE_TASK,
  PROJECTS_RECEIVED_CREATE_TASK,
  PROJECTS_RECEIVED_DELETE_TASK,
  PROJECTS_RECEIVED_GET_TASKS,
  PROJECTS_RECEIVED_UPDATE_TASK,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Task = {};

export const tasksApi = (builder: BuilderType) => ({
  getAllTasks: builder.query<Task, void>({
    query: () => `service/tasks`,
    providesTags: [projectsCacheTags.TASKS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_TASKS),
  }),

  createTask: builder.mutation<Task, Partial<Task>>({
    query: (task) => ({
      url: `service/tasks`,
      method: "POST",
      body: task,
    }),
    invalidatesTags: [projectsCacheTags.TASKS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_TASK),
  }),

  updateTask: builder.mutation<Task, Partial<Task>>({
    query: (task) => ({
      url: `service/tasks/${task.id}`,
      method: "PUT",
      body: task,
    }),
    invalidatesTags: [projectsCacheTags.TASKS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_TASK),
  }),

  completeTask: builder.mutation<Task, number>({
    query: (id) => ({
      url: `service/tasks/complete/${id}`,
      method: "PUT",
    }),
    invalidatesTags: [projectsCacheTags.TASKS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_COMPLETE_TASK),
  }),

  deleteTask: builder.mutation<Task, number>({
    query: (id) => ({
      url: `service/tasks/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.TASKS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_TASK),
  }),
});
