import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CacheTagsState {
  [key: string]: string;
}

const initialState: CacheTagsState = {};

const cacheTagSlice = createSlice({
  name: "cacheTags",
  initialState,
  reducers: {
    updateCacheTags: (
      state,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      Object.assign(state, action.payload);
      // state.something =action.payload
    },
  },
});

export const { updateCacheTags } = cacheTagSlice.actions;
export default cacheTagSlice.reducer;
