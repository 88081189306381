import React, { useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { hideAlert, logoutUser, setDimensions, setNav } from "../../actions";
import { connect, useSelector } from "react-redux";
import DynamicAppV3 from "../../shared-components/src/ts-components/DynamicAppV3";
import { hasOneNewPolicy } from "../../shared-components/src/utility";
import { _tempUnit } from "../../shared-components/src/atoms";
import { useGetUserConfigsQuery } from "../../services/projects/projectsApi";
import UserSnap from "../../shared-components/src/ts-components/UserSnap";
import { de } from "date-fns/locale";

/**
 * @class DynamicAppContainer
 * @classdesc This is state wrapper for the shared DynamicApp component
 */

const DynamicAppContainer = (props) => {
  const setTempUnit = useSetRecoilState(_tempUnit);
  const { isLoading, isError } = useGetUserConfigsQuery();
  const { email } = useSelector((state) => state.policyDocument);
  const { softwareVersion } = useSelector((state) => state.userConfig);

  const canSendFeedback = /^.+@(motrexllc\.com|stryten\.com)$/.test(
    email ?? ""
  );

  useEffect(() => {
    if (props.auth && props.auth.userInfo && props.auth.userInfo.user) {
      setTempUnit(props.auth.userInfo.user.temp_unit || "f");
    }
  }, [props.auth, setTempUnit]);

  function handleLogoutUser() {
    props.logoutUser();
    props.setNav({
      index: "login",
      activeNavId: "login",
      activeStepperDepth: 0,
      drawerOpen: props.nav.drawerOpen,
    });
    localStorage.removeItem("state");
  }

  function handleGoToAdmin() {
    props.setNav({
      index: "admin",
      activeNavId: "admin",
      drawerOpen: props.nav.drawerOpen,
    });
  }

  function handleGoToSettings() {
    props.setNav({
      index: "userProfile",
      activeNavId: "userProfile",
      drawerOpen: props.nav.drawerOpen,
    });
  }

  const isProjectsAdmin = hasOneNewPolicy(
    props.policyDocument,
    [
      "Projects_Admin",
      "Projects_MotivePowerAdmin",
      "Projects_TagAdmin",
      "Projects_ViewAdmin",
      "Projects_TeamAdmin",
    ],
    "all"
  );

  return (
    <>
      <DynamicAppV3
        {...props}
        updates={isLoading && !isError}
        appConfig={props.userConfig.appConfig}
        softwareVersion={props.userConfig.softwareVersion}
        logoutUser={handleLogoutUser}
        subTitle={props.appState.workflowName}
        buildIdentifier={import.meta.env.VITE_APP_BUILD_NUMBER || "local"}
        goToAdmin={isProjectsAdmin ? handleGoToAdmin : () => {}}
        goToSettings={handleGoToSettings}
        isProjectsAdmin={isProjectsAdmin}
      />
      {canSendFeedback && (
        <UserSnap
          globalApiKey="6f32d02d-0a90-495c-b5af-0bcccc27c9a2"
          initialValues={{
            visitor: email,
          }}
          customValues={{ softwareVersion }}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    dimensions: state.dimensions,
    alerts: state.alerts,
    nav: state.nav,
    auth: state.authenticate,
    appState: state.appState,
    userConfig: state.userConfig,
    policyDocument: state.policyDocument,
  };
}

export default connect(mapStateToProps, {
  setDimensions,
  setNav,
  hideAlert,
  logoutUser,
})(DynamicAppContainer);
