import {
  PROJECTS_RECEIVED_CREATE_CONTACT,
  PROJECTS_RECEIVED_CREATE_FACILITY_CONTACT,
  PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT,
  PROJECTS_RECEIVED_DELETE_CONTACT,
  PROJECTS_RECEIVED_UPDATE_CONTACT,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

type Contact = {};

export const contactApi = (builder: BuilderType) => ({
  createContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts`,
      method: "POST",
      body,
    }),
    providesTags: [projectsCacheTags.CONTACTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_CONTACT),
  }),

  createFacilityContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts`,
      method: "POST",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_FACILITY_CONTACT),
  }),

  createWorkflowContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts`,
      method: "POST",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT),
  }),

  updateContact: builder.mutation<Contact, Partial<Contact>>({
    query: (body) => ({
      url: `service/contacts/${body.id}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_CONTACT),
  }),

  deleteContact: builder.mutation<Contact, number>({
    query: (id) => ({
      url: `service/contacts/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.CONTACTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_CONTACT),
  }),
});
