import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import {
  PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION,
  PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS,
  PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
export type AlertConfiguration = {};

export const alertConfigurationsApi = (builder: BuilderType) => ({
  getAlertConfigurations: builder.query<AlertConfiguration, void>({
    query: () => `service/alert-configurations`,
    providesTags: [projectsCacheTags.ALERT_CONFIGURATIONS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS),
  }),

  createAlertConfiguration: builder.mutation<AlertConfiguration, any>({
    query: (config) => ({
      url: `service/alert-configurations`,
      method: "POST",
      body: config,
    }),
    invalidatesTags: [projectsCacheTags.ALERT_CONFIGURATIONS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION),
  }),

  updateAlertConfiguration: builder.mutation<AlertConfiguration, any>({
    query: (config) => ({
      url: `service/alert-configurations/${config.alert_configuration.id}`,
      method: "PUT",
      body: config,
    }),
    invalidatesTags: [projectsCacheTags.ALERT_CONFIGURATIONS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION),
  }),
});
