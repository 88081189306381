import * as licenseActions from "../../actions/license-actions";
import * as appActions from "../../actions";
import {
  consumeLicense,
  getLocationReport,
  getLocationReportList,
  updateLicenses,
} from "../api-license";
import { call, put, take, select } from "redux-saga/effects";
import { getLicenses } from "../../reducers/selectors";

export function* watchConsumeLicenses() {
  while (true) {
    const { payload: licenses } = yield take(
      licenseActions.LICENSE_CONSUME_LICENSE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield call(consumeLicense, licenses);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    const licensesState = yield select(getLicenses);
    let locationIds = [];
    Object.keys(licensesState.locations).forEach((id) => {
      locationIds.push(id);
    });

    yield put({
      type: licenseActions.LICENSE_GET_LICENSE_REPORT_LIST,
      payload: locationIds,
    });
  }
}

export function* watchGetLicenseReport() {
  while (true) {
    const { payload: locationId } = yield take(
      licenseActions.LICENSE_GET_LICENSE_REPORT
    );

    yield put({
      type: appActions.SET_DATA_REQUESTED,
      payload: `licenseReport-${locationId}`,
    });
    yield call(getLocationReport, locationId);
    yield put({
      type: appActions.SET_DATA_RECEIVED,
      payload: `licenseReport-${locationId}`,
    });
  }
}

export function* watchGetLicenseReportList() {
  while (true) {
    const { payload: locationIds } = yield take(
      licenseActions.LICENSE_GET_LICENSE_REPORT_LIST
    );

    yield put({
      type: appActions.SET_DATA_REQUESTED,
      payload: `licenseReport-${locationIds.toString()}`,
    });
    yield call(getLocationReportList, locationIds);
    yield put({
      type: appActions.SET_DATA_RECEIVED,
      payload: `licenseReport-${locationIds.toString()}`,
    });
  }
}

export function* watchUpdateLicenses() {
  while (true) {
    const { payload: licenses } = yield take(
      licenseActions.LICENSE_UPDATE_LICENSES
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield call(updateLicenses, licenses);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });

    const licensesState = yield select(getLicenses);
    let locationIds = [];
    Object.keys(licensesState.locations).forEach((id) => {
      locationIds.push(id);
    });

    yield put({
      type: licenseActions.LICENSE_GET_LICENSE_REPORT_LIST,
      payload: locationIds,
    });
  }
}
