import { BuilderType, portalCacheTags } from "./portalApiHelpers";
import { legacyFulfill } from "../utils";
import {
  PORTAL_RECEIVED_CREATE_THIRD_PARTY_USER,
  PORTAL_RECEIVED_DELETE_THIRD_PARTY_USER,
  PORTAL_RECEIVED_UPDATE_THIRD_PARTY_USER,
} from "../../actions/portal-actions";
export type ThirdPartyUser = {};

export const thirdPartyUsersApi = (builder: BuilderType) => ({
  createThirdPartyUser: builder.mutation<ThirdPartyUser, ThirdPartyUser>({
    query: (thirdPartyUser) => ({
      method: "POST",
      url: `/service/third-party-users.json`,
      body: thirdPartyUser,
    }),
    invalidatesTags: [portalCacheTags.THIRD_PARTY_USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_CREATE_THIRD_PARTY_USER),
  }),

  updateThirdPartyUser: builder.mutation<ThirdPartyUser, ThirdPartyUser>({
    query: (thirdPartyUser) => ({
      method: "PUT",
      url: `/service/third-party-users/${thirdPartyUser.id}`,
      body: thirdPartyUser,
    }),
    invalidatesTags: [portalCacheTags.THIRD_PARTY_USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_THIRD_PARTY_USER),
  }),

  deleteThirdPartyUser: builder.mutation<ThirdPartyUser, string>({
    query: (thirdPartyUserId) => ({
      method: "DELETE",
      url: `/service/third-party-users/${thirdPartyUserId}`,
    }),
    invalidatesTags: [portalCacheTags.THIRD_PARTY_USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_DELETE_THIRD_PARTY_USER),
  }),
});
