import {
  SET_DIMENSIONS
} from '../actions';
/**
 * This state is used by the NavFrame component to correctly render the components of the Frame as well
 * as set the content area of the screen. These values are automatically updated when the window size
 * changes
 * @name dimensionsState
 * @param {Object} dimensions
 * @param {number} dimensions.topbarHeight Height in pixels of the top bar
 * @param {number} dimensions.sidebarWidth Width in pixels of the navigation sidebar
 * @param {number} dimensions.sidebarHeight Height in pixels of the navigation sidebar
 * @param {number} dimensions.mainWidth Width in pixels of the content area of the Nav Frame
 * @param {number} dimensions.mainHeight Height in pixels of the content area of the Nav Frame
 *
 */

export default function (state = {}, action) {

  switch(action.type) {
    case SET_DIMENSIONS:
      return action.payload;

    default:
      return state;
  }
}
