import {
  CONFIG_RECEIVED_GET_GATEWAY_CONFIGS,
  CONFIG_RECEIVED_GET_GATEWAY_CONFIG,
  CONFIG_RECEIVED_UPDATE_GATEWAY_FACTORY,
  CONFIG_RECEIVED_UPDATE_GATEWAY_USER,
} from '../actions/config-actions';
import {copyObject} from "../shared-components/src/utility";

export default function (state = {gateways: []}, action) {

  switch (action.type) {
    case CONFIG_RECEIVED_GET_GATEWAY_CONFIGS:
      return action.payload;

    case CONFIG_RECEIVED_GET_GATEWAY_CONFIG:
      return updateConfig(state, action.payload);

    case CONFIG_RECEIVED_UPDATE_GATEWAY_FACTORY:
    case CONFIG_RECEIVED_UPDATE_GATEWAY_USER:
      return updateSetting(state, action.payload);

    default:
      return state;
  }
}

function updateSetting(state, data) {
  let copy = copyObject(state);

  copy.gateways.forEach(gateway => {
    if (gateway.gatewaySerial === data.gatewaySerial) {
      if (data.user) {
        gateway.config.user = data.user;
        gateway.config.userHistory.push(data);
      } else if (data.factory) {
        gateway.config.factory = data.factory;
        gateway.config.factoryHistory.push(data);
      }
    }
  });

  return copy;
}

function updateConfig(state, data) {
  if (!data.gateways[0]) {
    return state;
  }
  let copy = copyObject(state);
  let found = false;
  copy.gateways.forEach((gateway, idx) => {
    if (gateway.gatewaySerial === data.gateways[0].gatewaySerial) {
      copy.gateways[idx] = data.gateways[0];
      found = true;
    }
  });
  if (!found) {
    copy.gateways.push(data.gateways[0]);
  }

  return copy;
}