import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { strings } from './localization';
import { FlexRow } from '../layouts';
import ErrorIcon from '@mui/icons-material/Error';
import StackTrace from 'stacktrace-js';
import moment from 'moment-timezone';

import './bouncy-arrow.css';
const callback = (stackframes: any) => {
  const stringifiedStack = stackframes
    .map((sf: any, idx: number) => {
      return `(${idx}) ${sf.toString()}`;
    })
    .join('\n');
  console.log(`====                Stack Frame                  ====`);
  console.log(`====           Date: ${moment().format(`YYYY-MM-DD:hh:mm`)}             ====`);
  console.log(stringifiedStack);
  console.log('======================================================');
};

const errback = (err: any) => {
  console.log(err.message);
};

window.onerror = function(msg, file, line, col, error) {
  // callback is called with an Array[StackFrame]
  console.log(`====                Error Captured                ====`);
  console.log(`===> ${msg} <===`);
  if (error) {
    StackTrace.fromError(error)
      .then(callback)
      .catch(errback);
  }
};

export interface ErrorBoundaryProps {
  children: any;
}
class ErrorBoundary extends Component {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, _info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    console.log(`====                Error Captured                ====`);
    console.log(`===> ${error.message} <===`);
    StackTrace.fromError(error)
      .then(callback)
      .catch(errback);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.2)',
            zIndex: 2000,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '45%',
                marginLeft: -45,
                marginTop: -45,
              }}
            >
              <FlexRow centerBox>
                <ErrorIcon style={{ fontSize: 50, color: 'red', marginRight: 10 }} />
                <Typography variant="h5">{strings.errorMessage}</Typography>
              </FlexRow>
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '3%',
                right: 400,
              }}
            >
              <FlexRow>
                <Typography variant="h6" style={{ marginRight: -40 }}>
                  {strings.pleaseGiveFeedback}
                </Typography>
                <div className="icon">
                  <div className="arrow" />
                </div>
              </FlexRow>
            </div>
          </div>
        </div>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}

// @ts-ignore
ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ErrorBoundary;
