import { cloneDeep } from "lodash";
import {
  PROJECTS_RECEIVED_CHECK_OPERATIONS_SUMMARY,
  PROJECTS_RECEIVED_CREATE_PROJECT,
  PROJECTS_RECEIVED_CREATE_WORKFLOW,
  PROJECTS_RECEIVED_OPERATIONS_DETAIL,
  PROJECTS_RECEIVED_UPDATE_PROJECT,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Project = {};

export const serviceProjectsApi = (builder: BuilderType) => ({
  createProject: builder.mutation<any, Partial<Project>>({
    query: (project) => ({
      url: `service/projects`,
      method: "POST",
      body: fixupProjectData(project),
    }),
    invalidatesTags: [projectsCacheTags.PROJECTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_PROJECT),
  }),

  updateProject: builder.mutation<any, Partial<Project>>({
    query: (project) => ({
      url: `service/projects/${project.id}`,
      method: "PUT",
      body: fixupProjectData(project),
    }),
    invalidatesTags: [projectsCacheTags.PROJECTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_PROJECT),
  }),

  // prettier-ignore
  getOperationsDetail: builder.query<any, { projectId: number; vehicleId: number; date: string }>({
    query: ({ projectId, vehicleId, date }) =>
      `service/projects/${projectId}/vehicles/${vehicleId}/power_logger_detail/${date}.json`,
    providesTags: [projectsCacheTags.PROJECTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_OPERATIONS_DETAIL),
  }),
  // prettier-ignore
  createWorkflow: builder.mutation< any, { projectId: number; workflowType: string; workflowData: any }>({
    query: ({ projectId, workflowType, workflowData }) => ({
      url: `service/projects/${projectId}/workflow.json`,
      method: "POST",
      invalidatesTags: [projectsCacheTags.PROJECTS],
      body: fixupWorkflowData(workflowType, workflowData),
    }),
    onQueryStarted: legacyFulfill(
      PROJECTS_RECEIVED_CREATE_WORKFLOW
    ),
  }),

  // prettier-ignore
  checkOperationsSummary: builder.query<any,{ projectId: number; vehicleId: number }>({
    query: ({ projectId, vehicleId }) =>
      `service/projects/${projectId}/vehicles/${vehicleId}/has_summary_data`,
    providesTags: [projectsCacheTags.PROJECTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CHECK_OPERATIONS_SUMMARY),
  })
});

export function fixupProjectData(project) {
  const copy = cloneDeep(project);
  if (copy.is_archived === undefined) copy.is_archived = false;

  copy.project_data = JSON.stringify(project.project_data);
  copy.project_workflows_attributes = copy.project_workflows;
  delete copy.project_workflows;
  copy.project_workflows_attributes.forEach((workflow) => {
    workflow.workflow_data = JSON.stringify(workflow.workflow_data);
  });

  return copy;
}

export function fixupWorkflowData(workflowType, workflowData) {
  let workflow_data_string = JSON.stringify(workflowData);

  return {
    workflow_data_string: workflow_data_string,
    workflow_type: workflowType,
  };
}
