import UrlPattern from "url-pattern";
import _, { cloneDeep } from "lodash";

export function getURLParams(url) {
  let ret = { index: "", userId: 0, compartmentId: 0, assetId: 0, roleId: 0 };
  let pattern = new UrlPattern("/users");
  let match = pattern.match(url);

  if (match) {
    ret.index = "users";
    pattern = new UrlPattern("/users/:id");
    match = pattern.match(url);
    if (match) {
      ret.userId = parseInt(match.id, 10);
    }

    return ret;
  }

  pattern = new UrlPattern("/compartments");
  match = pattern.match(url);

  if (match) {
    ret.index = "compartments";
    pattern = new UrlPattern("/compartments/:id");
    match = pattern.match(url);
    if (match) {
      ret.compartmentId = parseInt(match.id, 10);
    }

    return ret;
  }

  pattern = new UrlPattern("/assets");
  match = pattern.match(url);

  if (match) {
    ret.index = "assets";
    pattern = new UrlPattern("/assets/:id");
    match = pattern.match(url);
    if (match) {
      ret.assetId = parseInt(match.id, 10);
    }

    return ret;
  }

  pattern = new UrlPattern("/roles");
  match = pattern.match(url);

  if (match) {
    ret.index = "roles";
    pattern = new UrlPattern("/role/:id");
    match = pattern.match(url);
    if (match) {
      ret.roleId = parseInt(match.id, 10);
    }

    return ret;
  }

  ret.index = "dashboard";

  return ret;
}

export function findObject(array, id) {
  return _.find(array, (obj) => {
    return obj.id === parseInt(id, 10);
  });
}

export function copyObject(a) {
  return cloneDeep(a);
}

export function copyObjectArray(a) {
  cloneDeep(a);
}

export function hasPolicy(authObject, policy) {
  let found = false;
  if (authObject.userInfo && authObject.userInfo.policies) {
    authObject.userInfo.policies.forEach((p) => {
      if (p.name === policy) {
        found = true;
      }
    });
  }

  return found;
}

export function compartmentHasChildren(compartments, compartmentId) {
  return _.find(compartments, (comp) => {
    return comp.parent_id === compartmentId;
  });
}

export function compartmentHasUsersOrAssets(users, assets, compartmentId) {
  let ret = false;

  users.forEach((user) => {
    if (user.compartment_id === compartmentId) {
      ret = true;
    }
  });

  if (ret) {
    return true;
  }

  assets.forEach((asset) => {
    if (asset.compartment_id === compartmentId) {
      ret = true;
    }
  });

  return ret;
}

export function filterObject(object, validKeys) {
  return Object.keys(object)
    .filter((key) => validKeys.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key],
      };
    }, {});
}
