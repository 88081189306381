import React, { useCallback, useEffect, useState } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { useNavigate, Route, Routes } from 'react-router-dom';
import NavFrameV3 from '../NavFrameV3/NavFrameV3';
import { FlexRow, FlexColumn } from '../layouts';
import urlUtils from '../../ts-utility/url';
import Dimensions from '../../providers/types/redux-states/Dimensions';
import Alert from '../../providers/types/redux-states/Alert';
import Nav from '../../providers/types/redux-states/Nav';
import Authenticate from '../../providers/types/redux-states/Authenticate';
import Update from '../../providers/types/redux-states/Update';
import AppState from '../../providers/types/redux-states/AppState';
import UserConfig, { UserConfigRoute } from '../../providers/types/redux-states/UserConfig';
import PolicyDocument from '../../providers/types/redux-states/PolicyDocument';
import { isEmpty } from '../../ts-utility';
import { debounce } from 'lodash';

const TOPBAR_HEIGHT = 74;
const SIDEBAR_WIDTH_CLOSED = 65;
const SIDEBAR_WIDTH_OPEN = 225;
const BOTTOMBAR_HEIGHT = 0;
const MAIN_MIN_WIDTH = 1375;
const MAIN_MAX_WIDTH = 1800;
let ROOT_WIDTH = 0;

export interface DynamicAppV3Props {
  dimensions: Dimensions;
  alerts: Alert;
  nav: Nav;
  auth: Authenticate;
  updates: Update[];
  appState: AppState;
  userConfig: UserConfig;
  policyDocument: PolicyDocument;
  isProjectsAdmin: boolean;
  softwareVersion: string;
  buildIdentifier: string;
  setDimensions(_dimensions: Dimensions): void;
  setNav(_nav: Nav): void;
  hideAlert(): void;
  logoutUser(): void;
  goToAdmin(): void;
  goToSettings(): void;
}

const DynamicAppV3 = (props: DynamicAppV3Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const updateDimensions = useCallback(
    debounce(() => {
      const updateWidth =
        window.innerWidth - (drawerOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED) - 10;
      const updateHeight = window.innerHeight - BOTTOMBAR_HEIGHT;
      const updateMainHeight = window.innerHeight - BOTTOMBAR_HEIGHT - TOPBAR_HEIGHT;
      ROOT_WIDTH = updateWidth;
      props.setDimensions({
        topbarHeight: TOPBAR_HEIGHT,
        sidebarWidth: drawerOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED,
        mainWidth: Math.min(Math.max(updateWidth, MAIN_MIN_WIDTH), MAIN_MAX_WIDTH),
        sidebarHeight: updateHeight,
        mainHeight: updateMainHeight,
      });
    }, 200),
    [drawerOpen]
  );

  /**
   * Add event listener
   */
  useEffect(() => {
    updateDimensions();
    checkNav(props);
    window.addEventListener('resize', updateDimensions);
  }, [props.nav]);

  useEffect(() => {
    updateDimensions();
  }, [drawerOpen]);

  const renderRoutes = () => {
    let arr: any[] = [];

    props.userConfig.appConfig.routes.forEach((route: UserConfigRoute) => {
      arr.push(
        <Route
          key={`${route.index}`}
          path={route.route}
          element={route.component}
          errorElement={
            <ErrorBoundary>
              <div />
            </ErrorBoundary>
          }
        />
      );
    });

    return <Routes>{arr}</Routes>;
  };

  const handleDrawer = (opened: boolean) => {
    setDrawerOpen(opened);
  };

  const checkNav = (props: DynamicAppV3Props) => {
    const {
      nav,
      userConfig: { appConfig },
    } = props;

    if (!isEmpty(nav)) {
      const url = urlUtils.makeURL(appConfig, nav, '');
      navigate(url);
    } else {
      const url = window.location.pathname;
      const search = window.location.search;

      const params = urlUtils.getURLParams(appConfig, url, search);
      props.setNav(params);
    }
  };

  const updateSoftware =
    props.softwareVersion &&
    props.buildIdentifier !== props.softwareVersion &&
    props.nav.index !== 'login';

  return (
    <div>
      <FlexColumn fullWidth>
        <ErrorBoundary>
          <FlexRow fullWidth>
            <NavFrameV3
              height={props.dimensions.sidebarHeight}
              width={ROOT_WIDTH}
              mainHeight={props.dimensions.mainHeight}
              nav={props.nav}
              updates={props.updates}
              alerts={props.alerts}
              setNav={props.setNav}
              hideAlert={props.hideAlert}
              logoutUser={props.logoutUser}
              user={props.auth.userInfo.user}
              onChange={handleDrawer.bind(this)}
              saving={props.appState.saving || false}
              buildIdentifier={props.buildIdentifier}
              updateSoftwareVersion={updateSoftware || false}
              goToAdmin={props.isProjectsAdmin ? props.goToAdmin : () => {}}
              goToSettings={props.goToSettings}
              isProjectsAdmin={props.isProjectsAdmin}
              userConfig={props.userConfig}
              authenticate={props.auth}
            >
              <FlexColumn style={{ width: '100%', height: '100%' }}>
                <React.StrictMode>{renderRoutes()}</React.StrictMode>
              </FlexColumn>
            </NavFrameV3>
          </FlexRow>
        </ErrorBoundary>
      </FlexColumn>
    </div>
  );
};

export default DynamicAppV3;
