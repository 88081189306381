export const getAuth = (state) => state.authenticate;
export const getClients = (state) => state.clients;
export const getActiveClient = (state) => state.activeClient;
export const getActiveContact = (state) => state.activeContact;
export const getActiveProject = (state) => state.activeProject;
export const getActiveFacility = (state) => state.activeFacility;
export const getActiveVehicle = (state) => state.activeVehicle;
export const getActiveSchedule = (state) => state.activeSchedule;
export const getActiveScheduleGroup = (state) => state.activeScheduleGroup;
export const getActiveLoggerAssignment = (state) => state.activeLoggerAssignment;
export const getUpdates = (state) => state.updates;
export const getAddProjectFlow = (state) => state.addProjectFlow;
export const getMeasureWorkflow = (state) => state.measureWorkflow;
export const getModelWorkflow = (state) => state.modelWorkflow;
export const getAppState = (state) => state.appState;
export const getPowerLoggers = (state) => state.powerLoggers;
export const getNav = (state) => state.nav;
export const getUserConfig = (state) => state.userConfig;
export const getActiveFleet = (state) => state.activeFleet;
export const getFleets = (state) => state.fleets;
export const getAlertConfigFlow = (state) => state.alertConfigFlow;
export const getProjectMeasureWF=(state)=>state.activeProject.project_workflows.find(wf=>{return wf.workflow_type==="measure"})
export const getAssets = (state) => state.assets;
export const getPolicyDocument = (state) => state.policyDocument;
export const getChargerConfigs = (state) => state.chargerConfigs;
export const getGatewayConfigs = (state) => state.gatewayConfigs;
export const getBatteryConfigs = (state) => state.batteryConfigs;
export const getVanadiumBESSConfigs = (state) => state.vanadiumBESSConfigs;
export const getLeadAcidBESSConfigs = (state) => state.leadAcidBESSConfigs;
export const getActiveResource = (state) => state.activeResource;
export const getTeams = (state) => state.teams;
export const getNewTeams = (state) => state.newTeams;
export const getActiveAddress = (state) => state.activeAddress;
export const getTags = (state) => state.tags;
export const getViews = (state) => state.views;
export const getCacheTags = (state) => state.cacheTags;

export const getLicenses = (state) => state.licenses;
