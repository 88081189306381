import {
  PROJECTS_RECEIVED_GET_LOGGER_MOST_RECENT,
} from '../actions/project-actions';

export default function (state = {
  loggers: {}
}, action) {
  let copy = Object.assign({}, state);
  switch (action.type) {
    case PROJECTS_RECEIVED_GET_LOGGER_MOST_RECENT:
      // Add an empty entry so that we do not try and get it
      // multiple times
      return addMostRecent(copy, action.payload);

    default:
      return state;
  }
}

function addMostRecent(state, data) {

  state.loggers[data.device_id] = data

  return state;
}
