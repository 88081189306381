import {
  SET_DATA_REQUESTED,
  SET_DATA_RECEIVED,
  CLEAR_DATA_REQUESTED,
  CLEAR_ALL_UPDATES
} from '../actions';
import {copyObject} from "../utility";
import moment from 'moment-timezone';

export default function (state = {}, action) {

  switch (action.type) {
    case SET_DATA_REQUESTED:
      return addRequest(copyObject(state), action.payload);

    case SET_DATA_RECEIVED:
      return addReceived(copyObject(state), action.payload);

    case CLEAR_DATA_REQUESTED:
      return removeRequest(copyObject(state), action.payload);

    case CLEAR_ALL_UPDATES:
      return {};

    default:
      return state;
  }
}

function addRequest(state, key) {
  if (!state[key]) {
    state[key] = {requested: false, received: false, lastReceived: null};
  }

  state[key].received = false;
  state[key].requested = true;
  return state;
}

function removeRequest(state, key) {
  if (!state[key]) {
    state[key] = {requested: false, received: false, lastReceived: null};
  }

  state[key].requested = false;
  return state;
}

function addReceived(state, key) {
  if (!state[key]) {
    state[key] = {requested: true, received: false, lastReceived: null};
  }

  state[key].received = true;
  state[key].lastReceived = moment();
  return state;
}