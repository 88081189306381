import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { PROJECTS_RECEIVED_UPDATE_RESOURCE } from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
export type Resource = {};
export const resourcesApi = (builder: BuilderType) => ({
  updateResource: builder.mutation<any, any>({
    query: (resource) => ({
      url: `service/resources/${resource.id}`,
      method: "PUT",
      body: resource,
    }),
    invalidatesTags: [projectsCacheTags.RESOURCES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_RESOURCE),
  }),
});
