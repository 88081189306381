import React from 'react';

function Icon(props: { width?: number; height?: number }) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 435.87 59.19"
      width={props.width || 435}
      height={props.height || 59}
    >
      <path
        fill={'#002d72'}
        d="m189.57,50.1l11.82-20.47-11.82-20.47h-5.77l11.82,20.47-11.82,20.47h5.77Z"
      />
      <g>
        <polygon
          fill="#002d72"
          points="168.57 9.16 168.56 19.2 162.32 9.16 161.97 9.16 157.39 9.16 157.39 9.89 157.38 27.37 161.95 27.37 161.97 17.24 168.26 27.37 168.55 27.37 173.13 27.37 173.13 26.55 173.15 9.16 168.57 9.16 168.57 9.16"
        />
        <g>
          <path
            fill="#002d72"
            d="m60.34,22h4.4c0,1.14.7,1.87,2.45,1.87s2.45-.72,2.45-1.87c0-1.2-1.39-1.48-3.06-1.78-2.59-.47-5.9-1.09-5.9-5.51,0-3.45,2.62-5.9,6.55-5.9s6.41,2.31,6.55,5.77h-4.23c-.03-1.06-.7-1.89-2.26-1.89s-2.26.92-2.26,1.87c0,1.2,1.39,1.48,3.09,1.78,2.62.47,5.93,1.09,5.93,5.52,0,3.62-2.73,5.9-6.85,5.9s-6.77-2.2-6.85-5.74Z"
          />
          <path fill="#002d72" d="m80.27,13.33h-4.96v-4.18h14.46v4.18h-4.99v14.04h-4.51v-14.04Z" />
          <path
            fill="#002d72"
            d="m91.65,9.16h7.38c4.82,0,6.96,2.62,6.96,6.24,0,2.56-1.09,4.65-3.43,5.63l4.09,6.35h-5.35l-3.23-5.71h-1.92v5.71h-4.51V9.16Zm7.44,8.33c1.81,0,2.34-.89,2.34-2.09s-.53-2.06-2.34-2.06h-2.92v4.15h2.92Z"
          />
          <path
            fill="#002d72"
            d="m112.74,19.82l-6.66-10.67h5.32l3.59,6.41,3.59-6.41h5.21l-6.55,10.53v7.69h-4.51v-7.55Z"
          />
          <path fill="#002d72" d="m129.92,13.33h-4.96v-4.18h14.46v4.18h-4.99v14.04h-4.51v-14.04Z" />
          <path
            fill="#002d72"
            d="m141.31,9.16h13.45v4.18h-8.94v2.98h7.33v3.62h-7.33v3.26h8.94v4.18h-13.45V9.16Z"
          />
        </g>
        <g>
          <path
            fill="#002d72"
            d="m60.5,31.91h13.37v1.84h-11.36v6.21h9.75v1.84h-9.75v6.49h11.36v1.84h-13.37v-18.22Z"
          />
          <path
            fill="#002d72"
            d="m98.82,31.91h13.37v1.84h-11.36v6.21h9.75v1.84h-9.75v6.49h11.36v1.84h-13.37v-18.22Z"
          />
          <path
            fill="#002d72"
            d="m117,31.91h8.05c4.29,0,6.18,2.4,6.18,5.63,0,3.01-1.62,5.01-4.6,5.57l5.26,7.02h-2.42l-4.93-6.91h-5.54v6.91h-2.01v-18.22Zm8.08,9.47c3.04,0,4.12-1.67,4.12-3.84s-1.09-3.79-4.12-3.79h-6.07v7.63h6.07Z"
          />
          <path
            fill="#002d72"
            d="m135.84,41.01c0-5.46,4.01-9.47,9.69-9.47,4.4,0,7.85,2.42,9.14,6.07h-2.2c-1.17-2.56-3.7-4.23-6.94-4.23-4.54,0-7.69,3.23-7.69,7.63s3.15,7.63,7.69,7.63c3.96,0,6.82-2.23,7.55-5.85h-8.69v-1.84h10.81v.95c-.42,4.99-4.29,8.58-9.66,8.58-5.68,0-9.69-4.01-9.69-9.47Z"
          />
          <path
            fill="#002d72"
            d="m163.95,42.13l-7.49-10.22h2.42l6.1,8.55,6.07-8.55h2.37l-7.46,10.19v8.02h-2.01v-7.99Z"
          />
        </g>
        <polygon
          fill="#002d72"
          fillRule="evenodd"
          points="91.69 31.91 91.69 47.99 80.98 31.91 78.43 31.91 78.41 50.12 80.44 50.12 80.39 34.61 90.88 50.12 93.74 50.12 93.76 31.91 91.69 31.91"
        />
        <g>
          <path
            fill="#002d72"
            d="m9.89,32.1H0V14.8L25.63,0l25.64,14.8v7.26h-5.02v-4.38l-10.31-5.96-10.3-5.95L5.02,17.68v9.4h4.86v5.02Zm26.48-5.82,5,.8h9.91v17.3l-25.64,14.8L0,44.38v-7.26h5.02v4.38l20.61,11.9,20.62-11.9v-9.4h-4.89v-5.02Z"
          />
          <polygon
            fill="#00b2a9"
            points="41.36 27.08 41.36 20.51 38.85 19.06 35.92 20.75 35.92 20.75 33.84 21.95 25.61 26.7 17.38 21.95 12.38 19.06 9.86 20.51 9.86 27.08 9.86 27.08 9.86 32.1 9.86 32.1 9.86 38.68 23.1 46.32 23.1 40.54 14.88 35.79 14.88 26.29 25.61 32.48 36.34 26.29 36.34 35.79 28.12 40.54 28.12 46.32 41.36 38.68 41.36 32.1 41.36 32.1 41.36 27.08 41.36 27.08"
          />
          <polygon
            fill="#00b2a9"
            points="17.38 16.17 22.38 19.06 25.61 17.19 28.85 19.06 33.84 16.17 26.86 12.14 25.61 11.42 17.38 16.17"
          />
        </g>
      </g>
      <g>
        <path
          fill="#002d72"
          d="m213.45,19.18c-.34-.34-.52-.76-.52-1.24s.17-.86.52-1.21c.34-.35.77-.52,1.28-.52s.93.17,1.28.5c.35.33.52.73.52,1.19,0,.51-.17.93-.52,1.28-.34.34-.77.52-1.28.52s-.93-.17-1.28-.52Zm.03,4.48h2.45v18.15h-2.45v-18.15Z"
        />
        <path
          fill="#002d72"
          d="m236.41,25.52c1.34,1.32,2.02,3.25,2.02,5.78v10.52h-2.45v-10.28c0-1.89-.47-3.32-1.42-4.31-.94-.99-2.29-1.48-4.04-1.48-1.96,0-3.5.58-4.64,1.74-1.14,1.16-1.71,2.77-1.71,4.81v9.52h-2.45v-18.15h2.35v3.35c.67-1.1,1.59-1.96,2.78-2.57,1.18-.61,2.56-.91,4.12-.91,2.28,0,4.09.66,5.43,1.98Z"
        />
        <path
          fill="#002d72"
          d="m249.14,40.44c-1.92-1.06-3.43-2.52-4.52-4.4-1.09-1.88-1.64-3.97-1.64-6.3s.55-4.42,1.64-6.3c1.09-1.87,2.6-3.34,4.54-4.4,1.93-1.06,4.09-1.59,6.49-1.59,1.79,0,3.45.3,4.97.9,1.52.6,2.81,1.47,3.86,2.62l-1.62,1.62c-1.89-1.91-4.27-2.86-7.14-2.86-1.91,0-3.65.44-5.21,1.31-1.56.87-2.79,2.07-3.67,3.59-.89,1.52-1.33,3.22-1.33,5.11s.44,3.59,1.33,5.11c.89,1.52,2.11,2.71,3.67,3.59,1.56.87,3.3,1.31,5.21,1.31,2.9,0,5.28-.97,7.14-2.9l1.62,1.62c-1.06,1.15-2.35,2.03-3.88,2.64-1.53.61-3.19.91-4.99.91-2.39,0-4.55-.53-6.47-1.59Z"
        />
        <path
          fill="#002d72"
          d="m272.85,40.42c-1.94-1.07-3.46-2.54-4.55-4.4-1.09-1.86-1.64-3.96-1.64-6.28s.55-4.42,1.64-6.28c1.09-1.86,2.61-3.33,4.55-4.4s4.11-1.6,6.5-1.6,4.55.53,6.47,1.59c1.92,1.06,3.43,2.52,4.54,4.4,1.1,1.88,1.66,3.97,1.66,6.3s-.55,4.42-1.66,6.3c-1.1,1.87-2.62,3.34-4.54,4.4-1.92,1.06-4.08,1.59-6.47,1.59s-4.56-.54-6.5-1.6Zm11.68-1.97c1.54-.86,2.75-2.06,3.62-3.59.87-1.53,1.31-3.24,1.31-5.12s-.44-3.59-1.31-5.12c-.87-1.53-2.08-2.73-3.62-3.59s-3.27-1.29-5.17-1.29-3.64.43-5.19,1.29-2.77,2.06-3.66,3.59c-.89,1.53-1.33,3.24-1.33,5.12s.44,3.59,1.33,5.12c.89,1.53,2.1,2.73,3.66,3.59,1.55.86,3.28,1.29,5.19,1.29s3.63-.43,5.17-1.29Z"
        />
        <path
          fill="#002d72"
          d="m322.27,17.67v24.15h-2.45v-19.32l-9.49,16.25h-1.21l-9.49-16.15v19.22h-2.45v-24.15h2.1l10.49,17.91,10.38-17.91h2.1Z"
        />
        <path
          fill="#002d72"
          d="m354.32,17.67v24.15h-2.45v-19.32l-9.49,16.25h-1.21l-9.49-16.15v19.22h-2.45v-24.15h2.1l10.49,17.91,10.38-17.91h2.1Z"
        />
        <path
          fill="#002d72"
          d="m376.83,35.36h-13.46l-2.9,6.45h-2.66l11.04-24.15h2.52l11.04,24.15h-2.69l-2.9-6.45Zm-.93-2.07l-5.8-12.97-5.8,12.97h11.59Z"
        />
        <path
          fill="#002d72"
          d="m406.11,17.67v24.15h-2.1l-15.52-19.6v19.6h-2.55v-24.15h2.1l15.56,19.6v-19.6h2.52Z"
        />
        <path
          fill="#002d72"
          d="m413.1,17.67h9.83c2.55,0,4.81.51,6.76,1.54,1.96,1.02,3.47,2.45,4.55,4.28,1.08,1.83,1.62,3.92,1.62,6.26s-.54,4.43-1.62,6.26c-1.08,1.83-2.6,3.25-4.55,4.28s-4.21,1.54-6.76,1.54h-9.83v-24.15Zm9.7,21.94c2.12,0,3.97-.42,5.55-1.26s2.82-2,3.69-3.49c.87-1.48,1.31-3.19,1.31-5.12s-.44-3.64-1.31-5.12-2.1-2.64-3.69-3.48-3.44-1.26-5.55-1.26h-7.14v19.73h7.14Z"
        />
      </g>
    </svg>
  );
}

export default Icon;
