import * as licenseActions from "../actions/license-actions";
import { makeAPICall } from "./api-builder";
console.log(import.meta.env);
const LICENSE_URL = import.meta.env.VITE_APP_LICENSE_SERVICE;

// ********************** API **********************************

export function* consumeLicense(licenses) {
  yield makeAPICall(
    "put",
    LICENSE_URL,
    "/consume-license",
    licenses,
    licenseActions.LICENSE_RECEIVED_CONSUME_LICENSE
  );
}

export function* getLocationReport(locationId) {
  yield makeAPICall(
    "get",
    LICENSE_URL,
    `/location-report/${locationId}`,
    {},
    licenseActions.LICENSE_RECEIVED_GET_LICENSE_REPORT
  );
}

export function* getLocationReportList(list) {
  yield makeAPICall(
    "put",
    LICENSE_URL,
    "/location-report",
    { location_ids: list },
    licenseActions.LICENSE_RECEIVED_GET_LICENSE_REPORT_LIST
  );
}

export function* updateLicenses(list) {
  yield makeAPICall(
    "put",
    LICENSE_URL,
    "/update-license",
    { licenses: list },
    licenseActions.LICENSE_RECEIVED_UPDATE_LICENSES
  );
}
