import React, { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountBox from '@mui/icons-material/Person';
import { FlexRow } from '../layouts';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { strings } from './localization';
import withStyles from '@mui/styles/withStyles';
import { SEICThemeContext } from '../../providers/SEICThemeProvider';
import User from '../../providers/types/redux-states/User';
import { isEmpty } from '../../ts-utility';

export interface ProfileProps {
  logoutUser(): void;
  user: User;
  isProjectsAdmin: boolean;
  goToSettings(): void;
  goToAdmin(): void;
}
const Profile = (props: ProfileProps) => {
  const { logoutUser, user, isProjectsAdmin, goToSettings, goToAdmin } = props;
  const { palette } = useContext(SEICThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const ProfileMenu = withStyles({
    paper: {
      backgroundColor: palette.surface2,
    },
  })(Menu);

  function logout() {
    logoutUser();
    setAnchorEl(null);
  }

  function handleGoToSettings() {
    setAnchorEl(null);
    goToSettings();
  }

  function handleGoToAdmin() {
    setAnchorEl(null);
    goToAdmin();
  }

  function handleClick(event: any) {
    if (user && user.email) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const initials =
    user && !isEmpty(user)
      ? `${user.first_name.substring(0, 1)}${user.last_name.substring(0, 1)}`
      : '';
  const PORTAL_URL = import.meta.env.VITE_APP_PORTAL_SERVICE;
  const profile_image_url =
    user && user.profile_image_url ? `${PORTAL_URL}${user.profile_image_url}` : undefined;

  return (
    <FlexRow>
      <div
        style={{
          marginTop: 10,
          color: palette.textHighEmphasis,
        }}
      >
        <IconButton aria-label="More" aria-haspopup="true" onClick={handleClick} size="large">
          {user && user.email ? (
            <Avatar
              style={{
                backgroundColor: palette.secondary,
                color: palette.textMediumEmphasis,
              }}
              src={profile_image_url}
            >
              {!profile_image_url && initials}
            </Avatar>
          ) : (
            <AccountBox style={{ color: 'white' }} />
          )}
        </IconButton>
        {user && user.email && (
          <ProfileMenu id="long-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem key="logout" onClick={logout}>
              {`${strings.logOut} ${user.email}`}
            </MenuItem>
            {!!goToSettings && (
              <MenuItem key="settings" onClick={handleGoToSettings}>
                {strings.settings}
              </MenuItem>
            )}
            {isProjectsAdmin && (
              <MenuItem key="admin" onClick={handleGoToAdmin}>
                {strings.adminConsole}
              </MenuItem>
            )}
          </ProfileMenu>
        )}
      </div>
    </FlexRow>
  );
};

export default Profile;
