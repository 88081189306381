import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { FlexRow } from '../layouts';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  })
);

export interface AlertProps {
  onClose(): void;
  msg: string;
  type: string;
  open: boolean;
}

const Alert = (props: AlertProps) => {
  const { onClose, type, msg, open } = props;

  const classes = useStyles();

  const handleClose = (_event: any) => {
    onClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <FlexRow
            style={{
              alignContent: 'center',
            }}
          >
            {type === 'error' ? <ErrorIcon color="secondary" /> : ''}
            <FlexRow padTop={3} padLeft={5}>
              <span id="message-id">{msg}</span>
            </FlexRow>
          </FlexRow>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
};

export default Alert;
