import React, { useState } from 'react';
import themes from 'devextreme/ui/themes';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import { gnbLightTheme, gnbDarkTheme, seicDark, seicLight } from '../gnb-theme-ts';

export type SEICPalette = {
  background: string;
  onBackground: string;
  surface1: string;
  onSurface1: string;
  surface2: string;
  onSurface2: string;
  surface3: string;
  onSurface3: string;
  inputSurface: string;
  menuSurface: string;
  outline: string;
  outlineAlt: string;
  textHighEmphasis: string;
  textMediumEmphasis: string;
  textLowEmphasis: string;
  disabled: string;

  primary: string;
  onPrimary: string;
  primary600: string;
  primary500: string;
  primary400: string;
  primary300: string;
  primary200: string;
  primary100: string;
  primaryHover: string;

  secondary: string;
  onSecondary: string;
  secondary600: string;
  secondary500: string;
  secondary400: string;
  secondary300: string;
  secondary200: string;
  secondary100: string;

  critical: string;
  atRisk: string;
  good: string;
  noData: string;

  chart1: string;
  chart2: string;
  chart3: string;
  chart4: string;
  chart5: string;
  chart6: string;
  chart7: string;
  chart8: string;
  chart9: string;
  chart10: string;

  pdfBackground: string;
  pdfSurface1: string;
  pdfSurface2: string;
  pdfOutline: string;
  pdfTextHighEmphasis: string;
  pdfTextMediumEmphasis: string;
  pdfTextOnColor: string;
  pdfPrimary: string;
  pdfPrimaryDark: string;
  pdfPrimaryLight: string;
  pdfSecondary: string;
  pdfSecondaryDark: string;
  pdfSecondaryLight: string;
  pdfCritical: string;
  pdfAtRisk: string;
  pdfGood: string;
  pdfNoData: string;
  pdfChartPurple: string;
  pdfChartPink: string;
  pdfChartOrange: string;
  pdfChartBlue: string;

  level6: string;
  level8: string;
};
export interface IThemeContext {
  setUITheme(_newTheme: 'dark' | 'light'): void;
  palette: SEICPalette;
  currentTheme: 'dark' | 'light' | undefined;
}

export interface ThemeProviderProps {
  defaultTheme: 'dark' | 'light';
  onThemeChanged?(_theme: 'dark' | 'light'): void;
  children: any;
}

const SEICThemeContext = React.createContext<IThemeContext>({
  setUITheme(_newTheme: 'dark' | 'light') {},
  palette: seicLight,
  currentTheme: undefined,
});

interface ThemeMap {
  [_key: string]: { material: any; devExtreme: any };
}

const themeMap: ThemeMap = {
  dark: {
    material: gnbDarkTheme,
    devExtreme: 'generic.dark',
  },
  light: {
    material: gnbLightTheme,
    devExtreme: 'seic.light',
  },
};
const SEICThemeProvider = (props: ThemeProviderProps) => {
  const { children, defaultTheme, onThemeChanged } = props;

  const [theme, setTheme] = useState(defaultTheme);
  const [palette, setPalette] = useState(defaultTheme === 'dark' ? seicDark : seicLight);

  const muiTheme = createTheme(themeMap[theme].material as ThemeOptions);
  if (themes.current() !== themeMap[theme].devExtreme) {
    themes.current(themeMap[theme].devExtreme);
  }

  const setUITheme = (newTheme: 'dark' | 'light') => {
    setTheme(newTheme);
    setPalette(newTheme === 'dark' ? seicDark : seicLight);
    if (onThemeChanged) {
      onThemeChanged(newTheme);
    }
  };

  return (
    <SEICThemeContext.Provider
      value={{
        setUITheme,
        palette,
        currentTheme: theme,
      }}
    >
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </SEICThemeContext.Provider>
  );
};

export { SEICThemeContext, SEICThemeProvider };
