import { call, put, putResolve, take } from "redux-saga/effects";
import * as appActions from "../../actions";
import * as portalActions from "../../actions/portal-actions";
import { strings } from "../../localization";
import { portalApi as portalApiV2 } from "../../services/portal/portalApi";
import { projectsApi } from "../../services/projects/projectsApi";
import {
  api_resetUserPassword,
  api_updateChargerUserSettings,
  api_updateUserPassword,
  authenticate,
} from "../api-portal";

export function* watchUpdateUserProfileImage() {
  while (true) {
    const {
      payload: { id, image },
    } = yield take(portalActions.PORTAL_UPDATE_USER_PROFILE_IMAGE);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(
      portalApiV2.endpoints.updateUserProfileImage.initiate({ id, image })
    );
    // yield call(api_updateUserProfileImage, id, image);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateUserPassword() {
  while (true) {
    const { payload: user } = yield take(
      portalActions.PORTAL_UPDATE_USER_PASSWORD
    );
    yield put({ type: appActions.SET_APP_STATE, saving: true });
    yield putResolve(portalApiV2.endpoints.updateUserPassword.initiate(user));
    // yield call(api_updateUserPassword, user);
    yield put({ type: appActions.SET_DATA_REQUESTED, payload: "teams" });
    yield putResolve(projectsApi.endpoints.getTeams.initiate());
    // yield call(projectApi.getTeams);
    yield put({ type: appActions.SET_DATA_RECEIVED, payload: "teams" });
    yield put({ type: appActions.SET_APP_STATE, saving: false });
  }
}

export function* watchUpdateUserSettings() {
  while (true) {
    const { payload: user } = yield take(
      portalActions.PORTAL_UPDATE_USER_SETTINGS
    );
    yield put({ type: appActions.SET_APP_STATE, saving: true });
    yield putResolve(
      portalApiV2.endpoints.updateChargerUserSettings.initiate(user)
    );
    yield call(api_updateChargerUserSettings, user);
    // yield put({ type: appActions.SET_DATA_REQUESTED, payload: "teams" });
    yield putResolve(projectsApi.endpoints.getTeams.initiate());
    // yield call(projectApi.getTeams);
    yield put({ type: appActions.SET_DATA_RECEIVED, payload: "teams" });
    yield put({ type: appActions.SET_APP_STATE, saving: false });
  }
}

export function* watchGetRoleDefinitions() {
  while (true) {
    yield take(portalActions.PORTAL_GET_ROLE_DEFINITIONS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put({
      type: appActions.SET_DATA_REQUESTED,
      payload: `portalRoleDefinitions`,
    });
    yield putResolve(portalApiV2.endpoints.getRoleDefinitions.initiate());
    //yield call(api_getRoleDefinitions);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({
      type: appActions.SET_DATA_RECEIVED,
      payload: `portalRoleDefinitions`,
    });
  }
}

export function* watchGetGroupDefinitions() {
  while (true) {
    yield take(portalActions.PORTAL_GET_GROUP_DEFINITIONS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put({
      type: appActions.SET_DATA_REQUESTED,
      payload: `portalGroupDefinitions`,
    });
    // yield call(api_getGroupDefinitions);
    yield put(portalApiV2.endpoints.getGroupDefinitions.initiate());
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({
      type: appActions.SET_DATA_RECEIVED,
      payload: `portalGroupDefinitions`,
    });
  }
}

export function* watchResetUserPassword() {
  while (true) {
    const { payload: resetData } = yield take(
      portalActions.PORTAL_RESET_USER_PASSWORD
    );
    yield put({ type: appActions.SET_APP_STATE, saving: true });
    const { data: resultUser } = yield putResolve(
      portalApiV2.endpoints.resetUserPassword.initiate(resetData)
    );
    // const resultUser = yield call(api_resetUserPassword, resetData);
    yield call(authenticate, {
      email: resultUser.email,
      password: resetData.password,
      rememberMe: resetData.rememberMe,
    });
    yield put({
      type: appActions.SHOW_ALERT,
      payload: { msg: strings.passwordResetSuccessful, type: "info" },
    });
    yield put({ type: appActions.SET_APP_STATE, saving: false });
    yield put({
      type: appActions.SET_NAV,
      payload: { index: "dashboard", drawerOpen: false, activeStepperDepth: 0 },
    });
  }
}

export function* watchMoveAssetList() {
  while (true) {
    const {
      payload: { destinationAccountId, ids },
    } = yield take(portalActions.PORTAL_MOVE_ASSET_LIST);
    yield put({ type: appActions.SET_APP_STATE, saving: true });
    yield putResolve(
      portalApiV2.endpoints.moveAssetList.initiate({
        destinationAccountId,
        ids,
      })
    );
    // yield call(moveAssetList, destinationAccountId, ids);
    yield put({ type: appActions.SET_APP_STATE, saving: false });
  }
}
