import { cloneDeep } from "lodash";
import {
  PROJECTS_RECEIVED_GET_CLIENTS,
  PROJECTS_RECEIVED_CREATE_CLIENT,
  PROJECTS_RECEIVED_UPDATE_CLIENT,
  PROJECTS_RECEIVED_DELETE_CLIENT,
  PROJECTS_RECEIVED_CREATE_CONTACT,
  PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT,
  PROJECTS_RECEIVED_UPDATE_CONTACT,
  PROJECTS_RECEIVED_DELETE_CONTACT,
  PROJECTS_RECEIVED_CREATE_FACILITY,
  PROJECTS_RECEIVED_UPDATE_FACILITY,
  PROJECTS_RECEIVED_DELETE_FACILITY,
  PROJECTS_RECEIVED_CREATE_VEHICLE,
  PROJECTS_RECEIVED_UPDATE_VEHICLE,
  PROJECTS_RECEIVED_DELETE_VEHICLE,
  PROJECTS_RECEIVED_CREATE_SCHEDULE,
  PROJECTS_RECEIVED_UPDATE_SCHEDULE,
  PROJECTS_RECEIVED_DELETE_SCHEDULE,
  PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP,
  PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP,
  PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP,
  PROJECTS_RECEIVED_CREATE_PROJECT,
  PROJECTS_RECEIVED_UPDATE_PROJECT,
  PROJECTS_RECEIVED_DELETE_PROJECT,
  PROJECTS_RECEIVED_CREATE_WORKFLOW,
  PROJECTS_RECEIVED_CREATE_ADDRESS,
  PROJECTS_RECEIVED_UPDATE_ADDRESS,
  PROJECTS_RECEIVED_CREATE_FACILITY_ADDRESS,
  PROJECTS_RECEIVED_CREATE_NOTE,
  PROJECTS_RECEIVED_UPDATE_NOTE,
  PROJECTS_RECEIVED_DELETE_NOTE,
} from "../actions/project-actions";
import { copyObject } from "../utility";

/**
 * @name clientsState this is the missing workflow reducer thanks
 * @param {Array} clients
 */

const initialState = {
  rawData: [],
  clientsById: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_RECEIVED_GET_CLIENTS:
      return updateClients(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_CLIENT:
      return addClient(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_CLIENT:
      return updateClient(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CLIENT:
      return deleteClient(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_CONTACT:
    case PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT:
      return addContact(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_CONTACT:
      return updateContact(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CONTACT:
      return deleteContact(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_FACILITY:
      return addFacility(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_FACILITY:
      return updateFacility(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_FACILITY:
      return deleteFacility(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_ADDRESS:
    case PROJECTS_RECEIVED_CREATE_FACILITY_ADDRESS:
      return createAddress(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_ADDRESS:
      return updateAddress(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_VEHICLE:
      return addVehicle(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_VEHICLE:
      return updateVehicle(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_VEHICLE:
      return deleteVehicle(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP:
      return addScheduleGroup(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP:
      return updateScheduleGroup(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP:
      return deleteScheduleGroup(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_SCHEDULE:
      return addSchedule(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_SCHEDULE:
      return updateSchedule(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_SCHEDULE:
      return deleteSchedule(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_PROJECT:
      return addProject(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_PROJECT:
      return updateProject(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_PROJECT:
      return deleteProject(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_WORKFLOW:
      return addWorkflow(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_NOTE:
      return addNote(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_NOTE:
      return updateNote(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_NOTE:
      return deleteNote(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateClients(state, data) {
  let newState = {};
  newState.rawData = data;
  newState.clientsById = createClientHash(data);
  return newState;
}

function addClient(state, data) {
  state.rawData.push(data);
  state.clientsById = createClientHash(state.rawData);
  return state;
}

function updateClient(state, data) {
  if (!data.is_archived) {
    state.rawData.forEach((client, index) => {
      if (client.id === data.id) {
        Object.assign(state.rawData[index], data);
      }
    });
    state.clientsById = createClientHash(state.rawData);
  } else {
    state.rawData.forEach((client, index) => {
      if (client.id === data.id) {
        state.rawData.splice(index, 1);
      }
    });
    state.clientsById = createClientHash(state.rawData);
  }
  return state;
}

function deleteClient(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.id) {
      state.rawData.splice(index, 1);
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function addContact(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].contacts.push(data);
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function updateContact(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].contacts.forEach((contact, index2) => {
        if (contact.id === data.id) {
          state.rawData[index].contacts[index2] = data;
        }
      });
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteContact(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].contacts.forEach((contact, index2) => {
        if (contact.id === data.id) {
          state.rawData[index].contacts.splice(index2, 1);
        }
      });
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function addFacility(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].facilities.push(data);
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function updateFacility(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].facilities.forEach((facility, index2) => {
        if (facility.id === data.id) {
          state.rawData[index].facilities[index2] = data;
        }
      });
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteFacility(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].facilities.forEach((facility, index2) => {
        if (facility.id === data.id) {
          state.rawData[index].facilities.splice(index2, 1);
        }
      });
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function createAddress(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].addresses.push(data);
    }
  });
  state.clientsById = createClientHash(state.rawData);
  return state;
}

function updateAddress(state, data) {
  state.rawData.forEach((client, index) => {
    if (client.id === data.client_id) {
      state.rawData[index].addresses.forEach((address, index2) => {
        if (address.id === data.id) {
          state.rawData[index].addresses[index2] = data;
        }
      });
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function addVehicle(state, data) {
  const results = getWorkflowData(data.project_workflow_id, state.rawData);
  results.workflow.vehicles.push(data);
  state.clientsById = createClientHash(state.rawData);
  return state;
}

function updateVehicle(state, data) {
  const results = getWorkflowData(data.project_workflow_id, state.rawData);

  results.workflow.vehicles.forEach((vehicle, index) => {
    if (vehicle.id === data.id) {
      results.workflow.vehicles[index] = data;
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteVehicle(state, data) {
  const results = getWorkflowData(data.project_workflow_id, state.rawData);

  results.workflow.vehicles.forEach((vehicle, index) => {
    if (vehicle.id === data.id) {
      results.workflow.vehicles.splice(index, 1);
    }
  });

  state.clientsById = createClientHash(state.rawData);
  return state;
}

function addScheduleGroup(state, data) {
  const results = getWorkflowData(data.project_workflow_id, state.rawData);

  results.workflow.schedule_groups.push(data);

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function updateScheduleGroup(state, data) {
  const results = getWorkflowData(data.project_workflow_id, state.rawData);

  results.workflow.schedule_groups.forEach((schedulGroup, index) => {
    if (schedulGroup.id === data.id) {
      results.workflow.schedule_groups[index] = data;
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteScheduleGroup(state, data) {
  const results = getWorkflowData(data.project_workflow_id, state.rawData);
  results.workflow.schedule_groups.forEach((scheduleGroup, index) => {
    if (scheduleGroup.id === data.id) {
      results.workflow.schedule_groups.splice(index, 1);
    }
  });
  state.clientsById = createClientHash(state.rawData);
  return state;
}

function addSchedule(state, data) {
  const results = getScheduleGroupData(data.schedule_group_id, state.rawData);

  results.schedules.push(data);

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function updateSchedule(state, data) {
  const results = getScheduleGroupData(data.schedule_group_id, state.rawData);

  results.schedules.forEach((schedule, index) => {
    if (schedule.id === data.id) {
      results.schedules[index] = data;
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteSchedule(state, data) {
  const results = getVehicleData(data.vehicle_id, state.rawData);

  results.vehicle.schedules.forEach((schedule, index) => {
    if (schedule.id === data.id) {
      results.vehicle.schedules.splice(index, 1);
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function addProject(state, data) {
  const results = getClientData(data.client_id, state.rawData);

  results.client.projects.push(data);

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function updateProject(state, data) {
  const _data = structuredClone(data);
  const results = getClientData(_data.client_id, state.rawData);
  results.client.projects.forEach((project, index) => {
    if (project.id === _data.id) {
      project.project_workflows.forEach((workflow) => {
        _data.project_workflows.forEach((wf) => {
          if (wf.id === workflow.id) {
            wf.workflow_tasks = cloneDeep(workflow.workflow_tasks);
            wf.vehicles = cloneDeep(workflow.vehicles);
          }
        });
      });
      results.client.projects[index] = data;
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteProject(state, data) {
  const results = getClientData(data.client_id, state.rawData);

  results.clients.projects.forEach((project, index) => {
    if (project.id === data.id) {
      results.client.projects.splice(index, 1);
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function addNote(state, data) {
  const results = getClientData(data.client_id, state.rawData);

  results.client.notes.push(data);

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function updateNote(state, data) {
  const results = getClientData(data.client_id, state.rawData);
  results.client.notes.forEach((note, index) => {
    if (note.id === data.id) {
      results.client.notes[index] = data;
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function deleteNote(state, data) {
  const results = getClientData(data.client_id, state.rawData);

  results.client.notes.forEach((note, index) => {
    if (note.id === data.id) {
      results.client.notes.splice(index, 1);
    }
  });

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function addWorkflow(state, data) {
  const results = getProjectData(data.project_id, state.rawData);

  results.project.project_workflows.push(data);

  state.clientsById = createClientHash(state.rawData);

  return state;
}

function createClientHash(data) {
  let obj = {};

  data.forEach((client) => {
    obj[client.id] = copyObject(client);

    let projects = {};

    obj[client.id].projects.forEach((project) => {
      projects[project.id] = copyObject(project);
      projects[project.id].project_workflows = {};

      project.project_workflows.forEach((workflow) => {
        projects[project.id].project_workflows[workflow.id] = copyObject(
          workflow
        ); //????
        projects[project.id].project_workflows[
          workflow.id
        ].schedule_groups = {};

        workflow.vehicles.forEach((vehicle) => {
          projects[project.id].project_workflows[workflow.id].vehicles[
            vehicle.id
          ] = copyObject(vehicle);
        });

        if (workflow.schedule_groups)
          workflow.schedule_groups.forEach((group) => {
            projects[project.id].project_workflows[workflow.id].schedule_groups[
              group.id
            ] = copyObject(group);
            projects[project.id].project_workflows[workflow.id].schedule_groups[
              group.id
            ].schedules = {};
            group.schedules.forEach((schedule) => {
              projects[project.id].project_workflows[
                workflow.id
              ].schedule_groups[group.id].schedules[schedule.id] = schedule;
            });
          });
      }); // end for each workflow
    }); // end for each project

    let contacts = {};
    obj[client.id].contacts.forEach((contact) => {
      contacts[contact.id] = contact;
    });
    let facilities = {};
    obj[client.id].facilities.forEach((facility) => {
      facilities[facility.id] = facility;
    });
    let addresses = {};
    obj[client.id].addresses.forEach((address) => {
      addresses[address.id] = address;
    });
    let notes = {};
    obj[client.id].notes.forEach((note) => {
      notes[note.id] = note;
    });

    obj[client.id].contacts = contacts;
    obj[client.id].facilities = facilities;
    obj[client.id].addresses = addresses;
    obj[client.id].projects = projects;
    obj[client.id].notes = notes;
  });

  return obj;
}

function getClientData(clientId, clients) {
  let obj = {
    client: {},
    clientId: 0,
  };

  clients.forEach((client) => {
    if (client.id === clientId) {
      obj.client = client;
      obj.clientId = client.id;
    }
  });

  return obj;
}

function getWorkflowData(workflowId, clients) {
  let obj = {
    workflow: {},
    workflowId: 0,
    clientId: 0,
    projectId: 0,
  };

  clients.forEach((client) => {
    client.projects.forEach((project) => {
      project.project_workflows.forEach((workflow) => {
        if (workflow.id === workflowId) {
          obj.workflow = workflow;
          obj.workflowId = workflow.id;
          obj.projectId = project.id;
          obj.clientId = client.id;
        }
      });
    });
  });

  return obj;
}

function getProjectData(projectId, clients) {
  let obj = {
    project: {},
    clientId: 0,
    projectId: 0,
  };

  clients.forEach((client) => {
    client.projects.forEach((project) => {
      if (project.id === projectId) {
        obj.project = project;
        obj.projectId = project.id;
        obj.clientId = client.id;
      }
    });
  });

  return obj;
}

function getVehicleData(vehicleId, clients) {
  let obj = {
    vehicle: {},
    clientId: 0,
    projectId: 0,
    workflowId: 0,
    vehicleId: 0,
  };

  clients.forEach((client) => {
    client.projects.forEach((project) => {
      project.project_workflows.forEach((workflow) => {
        workflow.vehicles.forEach((vehicle) => {
          if (vehicle.id === vehicleId) {
            obj.vehicle = vehicle;
            obj.workflowId = workflow.id;
            obj.projectId = project.id;
            obj.clientId = client.id;
            obj.vehicleId = vehicle.id;
          }
        });
      });
    });
  });

  return obj;
}

function getScheduleGroupData(scheduleGroupId, clients) {
  let obj = {
    project_workflow_id: 0,
    name: "",
    schedules: [],
  };

  clients.forEach((client) => {
    client.projects.forEach((project) => {
      project.project_workflows.forEach((workflow) => {
        workflow.schedule_groups.forEach((scheduleGroup) => {
          if (scheduleGroup.id === scheduleGroupId) {
            obj.project_workflow_id = scheduleGroup.project_workflow_id;
            obj.name = scheduleGroup.name;
            obj.schedules = scheduleGroup.schedules;
          }
        });
      });
    });
  });

  return obj;
}
