import * as portalActions from "../actions/portal-actions";
import { makeAPICall } from "./api-builder";

const PORTAL_URL = import.meta.env.VITE_APP_PORTAL_SERVICE;

// ********************** API **********************************
export function* authenticate(auth) {
  yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/authenticate",
    auth,
    portalActions.PORTAL_RECEIVED_AUTHENTICATE,
    false,
    true
  );
}

export function* getUsers() {
  yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/users.json",
    {},
    portalActions.PORTAL_RECEIVED_GET_USERS
  );
}

export function* getAssets() {
  yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/assets.json",
    {},
    portalActions.PORTAL_RECEIVED_GET_ASSETS
  );
}

export function* updateAsset(asset) {
  yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/assets/${asset.id}.json`,
    asset,
    portalActions.PORTAL_RECEIVED_UPDATE_ASSET
  );
}

export function* updateAssetAlias(asset) {
  yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/assets/alias/${asset.id}.json`,
    asset,
    portalActions.PORTAL_RECEIVED_UPDATE_ASSET
  );
}

export function* updateAssetAccount(asset) {
  yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/assets/account/${asset.id}.json`,
    asset,
    portalActions.PORTAL_RECEIVED_UPDATE_ASSET
  );
}

export function* moveAssetList(destinationAccountId, ids) {
  yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/assets/move-list",
    {
      destination_id: destinationAccountId,
      ids: ids,
    },
    portalActions.PORTAL_RECEIVED_MOVE_ASSET_LIST
  );
}

export function* getPolicyDocument() {
  yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/policy-documents.json",
    {},
    portalActions.PORTAL_RECEIVED_GET_POLICY_DOCUMENT
  );
}

export function* api_updateUserProfileImage(id, image) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    `/service/user-profile-images/${id}`,
    { profile_image: image },
    portalActions.PORTAL_RECEIVED_UPDATE_USER_PROFILE_IMAGE
  );
}

export function* api_updateUser(user) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/users/${user.id}.json`,
    user,
    portalActions.PORTAL_RECEIVED_UPDATE_USER
  );
}

export function* api_updateUserPassword(user) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/users/${user.id}/update-password.json`,
    user,
    portalActions.PORTAL_RECEIVED_UPDATE_USER
  );
}

export function* api_updateChargerUserSettings(user) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/users/${user.id}/update-settings.json`,
    user,
    portalActions.PORTAL_RECEIVED_UPDATE_USER
  );
}

export function* api_getRoleDefinitions() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/role-definitions.json",
    {},
    portalActions.PORTAL_RECEIVED_GET_ROLE_DEFINITIONS
  );
}

export function* api_getGroupDefinitions() {
  return yield makeAPICall(
    "get",
    PORTAL_URL,
    "/service/group-definitions.json",
    {},
    portalActions.PORTAL_RECEIVED_GET_GROUP_DEFINITIONS
  );
}

export function* api_createThirdPartyUser(thirdPartyUser) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/third-party-users.json",
    thirdPartyUser,
    portalActions.PORTAL_RECEIVED_CREATE_THIRD_PARTY_USER
  );
}

export function* api_updateThirdPartyUser(thirdPartyUser) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/third-party-users/${thirdPartyUser.id}`,
    thirdPartyUser,
    portalActions.PORTAL_RECEIVED_UPDATE_THIRD_PARTY_USER
  );
}

export function* api_deleteThirdPartyUser(thirdPartyUserId) {
  return yield makeAPICall(
    "delete",
    PORTAL_URL,
    `/service/third-party-users/${thirdPartyUserId}`,
    {},
    portalActions.PORTAL_RECEIVED_DELETE_THIRD_PARTY_USER
  );
}

export function* api_resetUserPassword(resetData) {
  return yield makeAPICall(
    "put",
    PORTAL_URL,
    `/service/reset-password.json`,
    resetData,
    portalActions.PORTAL_RECEIVED_UPDATE_USER
  );
}

export function* api_createAsset(asset) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/assets.json",
    asset,
    portalActions.PORTAL_RECEIVED_CREATE_ASSET
  );
}

export function* api_createAssetWithConfiguration(asset, configuration) {
  return yield makeAPICall(
    "post",
    PORTAL_URL,
    "/service/assets/with-config.json",
    { ...asset, configuration },
    portalActions.PORTAL_RECEIVED_CREATE_ASSET
  );
}
