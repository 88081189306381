import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    loggedInAs: 'Logged in as',
    logout: 'Logout',
    logIn: 'Login',
    myProfile: 'My Profile',
    news: 'News',
    whatsNew: "What's New",
    notifications: 'Notifications',
    currentTasks: 'Current Tasks',
    gnb: 'Stryten',
    app: 'CLOUD',
    buildUpdateNotification: 'New Release Available',
    buildUpdateText: 'A new version of this software is available. You must close all tabs that are running the application and restart.',
    ok: 'OK',

    yourSessionIsEnding: 'Your Session is Ending Soon',
    yourSessionHasExpired: 'Your Session has Expired',

    logOut: 'Log Out',
    adminConsole: 'Admin Console',
    settings: 'Settings',
  }
});
