import { BuilderType, portalCacheTags } from "./portalApiHelpers";
import { legacyFulfill } from "../utils";
import { PORTAL_RECEIVED_GET_ROLE_DEFINITIONS } from "../../actions/portal-actions";
export type RoleDefinition = {};

export const roleDefinitionsApi = (builder: BuilderType) => ({
  getRoleDefinitions: builder.query<RoleDefinition[], void>({
    query: () => `service/role-definitions.json`,
    providesTags: [portalCacheTags.ROLE_DEFINITIONS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_GET_ROLE_DEFINITIONS),
  }),
});
