import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { legacyFulfill } from "../utils";
import {
  PROJECTS_RECEIVED_CREATE_GOAL,
  PROJECTS_RECEIVED_DELETE_GOAL,
  PROJECTS_RECEIVED_UPDATE_GOAL,
} from "../../actions/project-actions";
export type Goal = {};

export const goalsApi = (builder: BuilderType) => ({
  createGoal: builder.mutation<Goal, any>({
    query: (goal) => ({
      url: `service/goals`,
      method: "POST",
      body: goal,
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_GOAL),
    invalidatesTags: [projectsCacheTags.GOALS],
  }),

  updateGoal: builder.mutation<Goal, any>({
    query: (goal) => ({
      url: `service/goals/${goal.id}`,
      method: "PUT",
      body: goal,
    }),
    invalidatesTags: [projectsCacheTags.GOALS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_GOAL),
  }),

  deleteGoal: builder.mutation<Goal, number>({
    query: (id) => ({
      url: `service/goals/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.GOALS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_GOAL),
  }),
});
