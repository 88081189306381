import CloseDrawerIcon from '@mui/icons-material/ChevronLeft';
import OpenDrawerIcon from '@mui/icons-material/ChevronRight';
import { ListItemButton } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { SEICThemeContext } from '../../providers/SEICThemeProvider';
import SystemAlert from '../../providers/types/redux-states/Alert';
import Authenticate from '../../providers/types/redux-states/Authenticate';
import Nav from '../../providers/types/redux-states/Nav';
import Update from '../../providers/types/redux-states/Update';
import User from '../../providers/types/redux-states/User';
import UserConfig from '../../providers/types/redux-states/UserConfig';
import { isEmpty } from '../../ts-utility';
import { copyObject } from '../../utility';
import LoadingSpinner from '../LoadingSpinner';
import SEICLogoDark from '../SEICLogo/SEICLogoDark';
import SEICLogoLight from '../SEICLogo/SEICLogoLight';
import { FlexColumn, FlexRow } from '../layouts';
import Alert from './Alert';
import ExpiratationCheck from './ExpirationCheck';
import Profile from './Profile';
import ServiceWorkerPrompt from './ServiceWorkerPrompt';
import ThemeModeSwitch from './ThemeModeSwitch';

const drawerWidth = 225;
const drawerWidthClosed = 60;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'auto',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: props =>
      !props.drawerClosed ? `${drawerWidth}px 1fr` : `${drawerWidthClosed}px 1fr`,
    gridTemplateRows: '74px max-content',
  },
  appBar: {
    zIndex: 1300,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: (props: any) => props.palette.surface1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    color: 'black',
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    paddingTop: 65,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: props => props.palette.surface1,
    boxSizing: 'border-box',
    borderRightColor: 'transparent',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 50,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    //backgroundImage: 'linear-gradient(-225deg, #212126 0%, #2D2D34 59%)',
    background: props => props.palette.background,
    //marginTop: 70,
    flex: 1,
    overflowY: 'auto',
    overflowX: 'auto',
    marginLeft: props => (props.drawerClosed ? -5 : undefined),
    height: props => props.mainHeight,
    //width: props => props.width,
    display: 'flex',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 2,
    gridRowEnd: 3,
    //paddingBottom: 30,
  },
  flex: {
    flex: 1,
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: -12,
    color: props => props.palette.textHighEmphasis,
  },
  iconSelected: {
    width: 30,
    height: 30,
    paddingLeft: 0,
    marginRight: -12,
    color: props => props.palette.textMediumEmphasis,
  },
  iconSmall: {
    width: 10,
    height: 10,
    marginRight: 6,
  },
  iconSmallSelected: {
    width: 10,
    height: 10,
    paddingLeft: 0,
    marginRight: 6,
    color: 'black',
  },
  listItem: {
    paddingLeft: '16px !important',
    paddingRight: '16px !important',
  },
  listItemButton: {
    borderColor: 'transparent',
  },
  listItemText: {
    paddingLeft: 3,
  },
  listItemTextSelected: {
    paddingLeft: 3,
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidthClosed,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const defaultParamMap = {
  index: '',
  activeNavId: '',
  activeStepperDepth: 0,
  drawerOpen: false,
};

export interface NavFrameV3Props {
  height: number;
  width: number;
  mainHeight: number;
  nav: Nav;
  userConfig: UserConfig;
  alerts: SystemAlert;
  updates: Update[];
  user: User;
  updateSoftwareVersion: boolean;
  buildIdentifier: string;
  saving: boolean;
  authenticate: Authenticate;
  children: any;
  setNav(_nav: Nav): void;
  hideAlert(): void;
  logoutUser(): void;
  goToAdmin(): void;
  goToSettings(): void;
  isProjectsAdmin: boolean;
  onChange(_open: boolean): void;
}

const NavFrameV3 = (props: NavFrameV3Props) => {
  const {
    nav,
    userConfig,
    setNav,
    onChange,
    hideAlert,
    height,
    width,
    updates,
    alerts,
    saving,
    buildIdentifier,
    logoutUser,
    goToAdmin,
    goToSettings,
    isProjectsAdmin,
    user,
    children,
    updateSoftwareVersion,
    authenticate,
    mainHeight,
  } = props;

  const { palette, currentTheme } = useContext(SEICThemeContext);

  const classes = useStyles({ palette: palette, drawerClosed: !nav.drawerOpen, mainHeight, width });

  const href = window?.location?.href;
  const isDevOrStaging = href ? href.includes('dev.') || href.includes('staging.') : false;

  const handleDrawerOpen = () => {
    let copy = copyObject(nav);
    copy.drawerOpen = true;
    setNav(copy);
    onChange(true);
  };

  const handleDrawerClose = () => {
    let copy = copyObject(nav);
    copy.drawerOpen = false;
    setNav(copy);
    onChange(false);
  };

  const handleAlertClosed = () => {
    hideAlert();
  };

  const handleClick = (
    name: string,
    id: string,
    drawerOpen: boolean,
    depth: number = 0,
    parameters: any = {}
  ) => {
    let nav = copyObject(defaultParamMap);
    nav.index = name;
    nav.activeNavId = id;
    nav.activeStepperDepth = depth;
    nav.drawerOpen = drawerOpen;
    if (!isEmpty(parameters)) {
      for (let key in parameters) {
        // eslint-disable-next-line no-prototype-builtins
        if (parameters.hasOwnProperty(key)) {
          nav[key] = parameters[key];
        }
      }
    }

    setNav(nav);
  };
  const renderNavItems = () => {
    let arr = userConfig.appConfig.menu.map((item, idx: number) => {
      if (item.type === 'group') {
        return (
          <FlexColumn key={`nav-fram-item-${item.nav}-${item.label}`}>
            <FlexRow
              fullWidth
              alignLeftCenter
              style={{
                width: '95%',
                height: 40,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                backgroundColor: nav.index === item.nav ? palette.primaryHover : 'transparent',
                alignItems: 'center',
                paddingTop: 3,
                paddingLeft: 5,
              }}
            >
              <ListItemButton
                onClick={() => handleClick(item.nav, item.navId, nav.drawerOpen as boolean, 0, {})}
                disableGutters={true}
                divider={false}
                sx={{ backgroundColor: 'transparent', borderColor: 'transparent', height: 40 }}
                classes={{
                  root: classes.listItem,
                }}
              >
                <FlexRow alignLeftCenter>
                  <ListItemIcon
                    className={nav.index === item.nav ? classes.iconSelected : classes.icon}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    disableTypography={true}
                    primary={<Typography variant="subtitle1">{item.label}</Typography>}
                  />
                </FlexRow>
              </ListItemButton>
            </FlexRow>
          </FlexColumn>
        );
      } else if (item.type === 'spacer') {
        return (
          <FlexColumn key={`nav-fram-item-${item.nav}-${idx}`}>
            <FlexRow
              fullWidth
              style={{
                width: '95%',
                height: 40,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                backgroundColor: 'transparent',
                alignItems: 'center',
                paddingTop: 3,
              }}
            />
          </FlexColumn>
        );
      }
    });

    arr.push(
      <ListItemButton
        sx={{ backgroundColor: palette.background }}
        onClick={nav.drawerOpen ? handleDrawerClose : handleDrawerOpen}
        disableGutters={true}
        className={classes.listItem}
        divider={false}
        key="open-drawer-button"
      >
        {nav.drawerOpen ? (
          <FlexRow fullWidth right>
            <ListItemIcon>
              {nav.drawerOpen ? (
                <CloseDrawerIcon color="primary" />
              ) : (
                <OpenDrawerIcon color="primary" />
              )}
            </ListItemIcon>
          </FlexRow>
        ) : (
          <FlexRow fullWidth padLeft={12}>
            <ListItemIcon>
              {nav.drawerOpen ? (
                <CloseDrawerIcon color="primary" />
              ) : (
                <OpenDrawerIcon color="primary" />
              )}
            </ListItemIcon>
          </FlexRow>
        )}
      </ListItemButton>
    );

    return arr;
  };

  return (
    <div className={classes.root} style={{ height: height }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          backgroundColor: palette.surface1,
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <FlexRow fullWidth style={{ alignItems: 'center' }}>
            <FlexRow style={{ width: '30%' }}>
              {currentTheme === 'dark' ? (
                <SEICLogoDark width={348} height={48} />
              ) : (
                <SEICLogoLight width={348} height={48} />
              )}
              {/*<GNBCLogo height={32} width={250} leftFill={'#AFAFB2'} />*/}
            </FlexRow>
            <FlexRow right style={{ width: '70%' }} alignLeftCenter>
              {/* <FetchingIndicator updates={updates} /> */}
              {updates && <ThreeDots color={palette.secondary} height={35} />}
              <ThemeModeSwitch />
              <Profile
                logoutUser={logoutUser}
                goToAdmin={goToAdmin}
                goToSettings={goToSettings}
                isProjectsAdmin={isProjectsAdmin}
                user={user}
              />
            </FlexRow>
          </FlexRow>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: nav.drawerOpen ? drawerWidth : drawerWidthClosed,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: nav.drawerOpen ? drawerWidth : drawerWidthClosed,
            boxSizing: 'border-box',
            backgroundColor: palette.surface1,
          },
          [`& .MuiDrawer-docked`]: {
            width: drawerWidthClosed,
            boxSizing: 'border-box',
            backgroundColor: palette.surface1,
          },
          gridRowStart: 1,
          gridRowEnd: 3,
        }}
        open={nav.drawerOpen}
      >
        <Toolbar />
        <Divider />
        <List>{renderNavItems()}</List>

        {nav.drawerOpen && (
          <div style={{ position: 'absolute', bottom: 10, left: 0 }}>
            <Typography variant="caption" style={{ paddingLeft: 10, marginTop: 30 }}>
              {buildIdentifier ? `Version ${buildIdentifier}` : ''}
            </Typography>
          </div>
        )}
      </Drawer>
      <main className={classes.content}>{children}</main>
      <Alert
        open={alerts.show || false}
        msg={alerts.msg}
        type={alerts.type}
        onClose={handleAlertClosed}
      />
      <ExpiratationCheck authenticate={authenticate} onLogin={logoutUser} />
      <ServiceWorkerPrompt />
      {/* {process.env.NODE_ENV === 'production' && updateSoftwareVersion && <BuildUpdate />} */}
      {saving ? <LoadingSpinner /> : ''}
    </div>
  );
};

export default NavFrameV3;
