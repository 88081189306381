import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  globalApiKey: string;
  initialValues?: { [key in 'assignee' | 'labels' | 'visitor']: string | string[] };
  customValues?: { [key: string]: string };
};

const UserSnap = ({ globalApiKey, initialValues, customValues }: Props) => {
  const [userSnapApi, setUserSnapApi] = useState<any>(null);
  const initialized = useRef(false);

  const loadSpace = useCallback((apiKey: string) => {
    return new Promise(resolve => {
      // @ts-ignore
      window.onUsersnapLoad = function(api) {
        api.init({
          fields: {
            priority: {
              type: 'select',
              options: ['Low', 'Medium', 'High'],
              value: 'Medium', // Set initial value here
            },
          },
        });
        resolve(api);
      };
      const script = document.createElement('script');
      script.defer = 1;
      script.src = `https://widget.usersnap.com/global/load/${apiKey}?onload=onUsersnapLoad`;
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }, []);

  // Initialize the userSnap widget
  useEffect(() => {
    if (!initialized.current && globalApiKey) {
      console.log('Initializing UserSnap widget');
      initialized.current = true;
      loadSpace(globalApiKey).then(api => {
        setUserSnapApi(api);
        console.log(api);
      });
    }
  }, [globalApiKey]);

  // Set initial and hidden values for userSnap widget
  useEffect(() => {
    const handleOpenWidget = (event: any) => {
      const initial = Object.entries(initialValues ?? {});
      initial.forEach(([key, value]) => {
        event.api.setValue(key, value);
      });
      if (Object.keys(customValues ?? {}).length) {
        event.api.setValue('custom', customValues);
      }
    };
    userSnapApi?.on('open', handleOpenWidget);
    return () => userSnapApi?.off('open', handleOpenWidget);
  }, [userSnapApi, initialValues, customValues]);
  return <></>;
};

export default UserSnap;
