import {
  CONFIG_RECEIVED_GET_BATTERY_CONFIGS,
  CONFIG_RECEIVED_GET_BATTERY_CONFIG,
  CONFIG_RECEIVED_UPDATE_BATTERY_FACTORY,
  CONFIG_RECEIVED_UPDATE_BATTERY_USER,
} from '../actions/config-actions';
import {copyObject} from "../shared-components/src/utility";

export default function (state = {batteries: []}, action) {

  switch (action.type) {
    case CONFIG_RECEIVED_GET_BATTERY_CONFIGS:
      return action.payload;

    case CONFIG_RECEIVED_GET_BATTERY_CONFIG:
      return updateConfig(state, action.payload);

    case CONFIG_RECEIVED_UPDATE_BATTERY_FACTORY:
    case CONFIG_RECEIVED_UPDATE_BATTERY_USER:
      return updateSetting(state, action.payload);

    default:
      return state;
  }
}

function updateSetting(state, data) {
  let copy = copyObject(state);

  copy.batteries.forEach(battery => {
    if (battery.batterySerial === data.batterySerial) {
      if (data.user) {
        battery.config.user = data.user;
        battery.config.userHistory.push(data);
      } else if (data.factory) {
        battery.config.factory = data.factory;
        battery.config.factoryHistory.push(data);
      }
    }
  });

  return copy;
}

function updateConfig(state, data) {
  if (!data.batteries[0]) {
    return state;
  }
  let copy = copyObject(state);
  let found = false;
  copy.batteries.forEach((battery, idx) => {
    if (battery.batterySerial === data.batteries[0].batterySerial) {
      copy.batteries[idx] = data.batteries[0];
      found = true;
    }
  });
  if (!found) {
    copy.batteries.push(data.batteries[0])
  }

  return copy;
}