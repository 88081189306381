import {
  PROJECTS_RECEIVED_GET_CHARGERS,
  PROJECTS_RECEIVED_UPDATE_CHARGER,
  PROJECTS_RECEIVED_CREATE_CHARGER,
  PROJECTS_RECEIVED_DELETE_CHARGER,
} from '../actions/project-actions';
import {copyObject} from "../utility";

export default function (state = [], action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_CHARGERS:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_CHARGER:
      return createCharger(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_CHARGER:
      return updateCharger(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CHARGER:
      return deleteCharger(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateCharger(state, data) {
  state.forEach((charger, idx) => {
    if(charger.id === data.id) {
      state[idx] = data;
    }
  });

  return state;
}

function createCharger(state, data) {

  state.push(data);

  return state;
}

function deleteCharger(state, data) {
  state.forEach((charger, idx) => {
    if(charger.id === data.id) {
      state.splice(idx, 1);
    }
  });

  return state;
}