import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { legacyFulfill } from "../utils";
import {
  PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST,
  PROJECTS_RECEIVED_SEND_PASSWORD_RESET_EMAIL,
} from "../../actions/project-actions";

export const miscApi = (builder: BuilderType) => ({
  sendPasswordResetEmail: builder.mutation<any, string>({
    query: (email) => ({
      url: `service/send-password-reset`,
      method: "PUT",
      body: { email },
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_SEND_PASSWORD_RESET_EMAIL),
  }),

  clearAMUnexpectedAssetList: builder.mutation<any, void>({
    query: (ids) => ({
      url: "/service/am-unexpected-assets/clear-list",
      method: "DELETE",
      body: { ids },
    }),
    onQueryStarted: legacyFulfill(
      PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST
    ),
  }),

  ignoreAMAssetList: builder.mutation<any, number>({
    query: (ids) => ({
      url: "/service/am-assets/ignore-list",
      method: "POST",
      body: { ids },
    }),
    onQueryStarted: legacyFulfill(
      PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST
    ),
  }),
});
