import {
  SET_APP_STATE
} from '../actions';
import {copyObject} from "../utility";

export default function (state = {}, action) {

  switch(action.type) {
    case SET_APP_STATE:
      return {...copyObject(state), ...action.payload};

    default:
      return state;
  }
}