import {
  CONFIG_RECEIVED_GET_VERSIONS_META,
  CONFIG_RECEIVED_DELETE_VERSIONS_META,
} from '../actions/config-actions';
import {copyObject} from "../utility";

export default function (state = [], action) {

  switch (action.type) {
    case CONFIG_RECEIVED_GET_VERSIONS_META:
      return action.payload;

    case CONFIG_RECEIVED_DELETE_VERSIONS_META:
      return deleteVersionMeta(copyObject(state), action.payload);

    default:
      return state;
  }
}

function deleteVersionMeta(state, data) {
  const idx = state.findIndex(v => v.id === data.versionId);

  if(idx > -1) {
    state.splice(idx, 1);
  }

  return state;
}