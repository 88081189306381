import {
  PROJECTS_GET_OPERATIONS_SUMMARY,
  PROJECTS_CLEAR_OPERATIONS_SUMMARY,
  PROJECTS_RECEIVED_OPERATIONS_SUMMARY,
  PROJECTS_GET_OPERATIONS_DETAIL,
  PROJECTS_RECEIVED_OPERATIONS_DETAIL
} from '../actions/project-actions';
import moment from 'moment';

export default function (state = {
  summary: {},
  detail: {}
}, action) {
  let copy = Object.assign({}, state);
  switch (action.type) {
    case PROJECTS_GET_OPERATIONS_SUMMARY:
      // Add an empty entry so that we do not try and get it
      // multiple times
      return addSummary(copy, action.payload.vehicleId, []);

    case PROJECTS_CLEAR_OPERATIONS_SUMMARY:
      // Remove the summary
      return removeSummary(copy, action.payload.vehicleId);

    case PROJECTS_RECEIVED_OPERATIONS_SUMMARY:
      // Add the data
      return addSummary(copy, action.payload.vehicleId, JSON.parse(action.payload.summary));

    case PROJECTS_GET_OPERATIONS_DETAIL:
      // Add a blank array
      return addDetail(copy, action.payload.vehicleId, action.payload.date, {});

    case PROJECTS_RECEIVED_OPERATIONS_DETAIL:
      // Add the data
      return addDetail(copy, action.payload.vehicle_id, action.payload.date, action.payload.detail);

    default:
      return state;
  }
}

function addSummary(state, vehicleId, summary) {

  // Sort by date
  function compare(a, b) {
    if (a.date < b.date)
      return -1;
    if (a.date > b.date)
      return 1;
    return 0;
  }

  if(summary.length !== 0) {
    summary.sort(compare);
  }
  state.summary[vehicleId] = summary;

  return state;
}

function removeSummary(state, vehicleId) {

  state.summary[vehicleId] = null;

  return state;
}

function addDetail(state, vehicleId, date, detail) {
  if (!state.detail[vehicleId]) {
    state.detail[vehicleId] = {}
  }

  state.detail[vehicleId][moment(date).format('YYYY-MM-DD')] = detail;
  return state;
}