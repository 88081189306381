import axios from "axios";
import { put, call, select, delay } from "redux-saga/effects";
import * as projectActions from "../actions/project-actions";
import * as appActions from "../actions";
import { copyObject } from "../utility";
import { getAuth } from "../reducers/selectors";
import moment from "moment-timezone";
import { makeAPICall, handleAPIError } from "./api-builder";
import * as portalActions from "../actions/portal-actions";

const PROJECTS_URL = import.meta.env.VITE_APP_PROJECTS_SERVICE;

// ********************** API **********************************
export function* getClients() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/clients",
    {},
    projectActions.PROJECTS_RECEIVED_GET_CLIENTS
  );
}

export function* createClient(client) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/clients",
    client,
    projectActions.PROJECTS_RECEIVED_CREATE_CLIENT
  );
}

export function* updateClient(client) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/clients/${client.id}`,
    client,
    projectActions.PROJECTS_RECEIVED_UPDATE_CLIENT
  );
}

export function* createContact(contact) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/contacts",
    contact,
    projectActions.PROJECTS_RECEIVED_CREATE_CONTACT
  );
}

export function* createFacilityContact(contact) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/contacts",
    contact,
    projectActions.PROJECTS_RECEIVED_CREATE_FACILITY_CONTACT
  );
}

export function* createWorkflowContact(contact) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/contacts",
    contact,
    projectActions.PROJECTS_RECEIVED_CREATE_WORKFLOW_CONTACT
  );
}

export function* updateContact(contact) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/contacts/${contact.id}`,
    contact,
    projectActions.PROJECTS_RECEIVED_UPDATE_CONTACT
  );
}

export function* deleteContact(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/contacts/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_CONTACT
  );
}

export function* createFacility(facility) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/facilities",
    fixupFacilityData(facility),
    projectActions.PROJECTS_RECEIVED_CREATE_FACILITY
  );
}

export function* updateFacility(facility) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/facilities/${facility.id}`,
    fixupFacilityData(facility),
    projectActions.PROJECTS_RECEIVED_UPDATE_FACILITY
  );
}

export function* deleteFacility(facilityId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/facilities/${facilityId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_FACILITY
  );
}

export function* createFacilityAddress(address) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/addresses",
    address,
    projectActions.PROJECTS_RECEIVED_CREATE_FACILITY_ADDRESS
  );
}

export function* updateFacilityAddress(address) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/addresses/${address.id}`,
    address,
    projectActions.PROJECTS_RECEIVED_UPDATE_ADDRESS
  );
}

export function* deleteFacilityAddress(addressId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/addresses/${addressId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_ADDRESS
  );
}

export function* createProject(project) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/projects",
    fixupProjectData(project),
    projectActions.PROJECTS_RECEIVED_CREATE_PROJECT
  );
}

export function* updateProject(project) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/projects/${project.id}`,
    fixupProjectData(project),
    projectActions.PROJECTS_RECEIVED_UPDATE_PROJECT
  );
}

export function* getPowerLoggers() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/power-loggers",
    {},
    projectActions.PROJECTS_RECEIVED_GET_POWER_LOGGERS
  );
}

export function* assignPowerLogger(assignment) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/power-loggers/${assignment.power_logger_id}/assign`,
    assignment,
    projectActions.PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT
  );
}

export function* updatePowerLoggerAssignment(assignment) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/power-loggers/${assignment.power_logger_id}/assign/${assignment.id}`,
    assignment,
    projectActions.PROJECTS_RECEIVED_UPDATE_LOGGER_ASSIGNMENT
  );
}

export function* deletePowerLoggerAssignment(id, powerLoggerId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/power-loggers/${powerLoggerId}/assign/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT
  );
}

export function* createVehicle(vehicle) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/vehicles",
    fixupVehicleData(vehicle),
    projectActions.PROJECTS_RECEIVED_CREATE_VEHICLE
  );
}

export function* updateVehicle(vehicle) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/vehicles/${vehicle.id}`,
    fixupVehicleData(vehicle),
    projectActions.PROJECTS_RECEIVED_UPDATE_VEHICLE
  );
}

export function* deleteVehicle(vehicleId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/vehicles/${vehicleId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_VEHICLE
  );
}

export function* createScheduleGroup(scheduleGroup) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/schedule-groups.json",
    fixupScheduleGroupData(scheduleGroup),
    projectActions.PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP
  );
}

export function* updateScheduleGroup(scheduleGroup) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/schedule-groups/${scheduleGroup.id}.json`,
    fixupScheduleGroupData(scheduleGroup),
    projectActions.PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP
  );
}

export function* deleteScheduleGroup(scheduleGroupId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/schedule-groups/${scheduleGroupId}.json`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP
  );
}

export function* createSchedule(schedule) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/schedules.json",
    fixupScheduleData(schedule),
    projectActions.PROJECTS_RECEIVED_CREATE_SCHEDULE
  );
}

export function* updateSchedule(schedule) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/schedules/${schedule.id}.json`,
    fixupScheduleData(schedule),
    projectActions.PROJECTS_RECEIVED_UPDATE_SCHEDULE
  );
}

export function* deleteSchedule(scheduleId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/schedules/${scheduleId}.json`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_SCHEDULE
  );
}

export function* getOperationsDetail(projectId, vehicleId, date) {
  const day = moment(date).format("YYYY-MM-DD");
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/projects/${projectId}/vehicles/${vehicleId}/power_logger_detail/${day}.json`,
    {},
    projectActions.PROJECTS_RECEIVED_OPERATIONS_DETAIL
  );
}

export function* createWorkflow(projectId, workflowType, workflowData) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/projects/${projectId}/workflow.json`,
    fixupWorkflowData(workflowType, workflowData),
    projectActions.PROJECTS_RECEIVED_CREATE_WORKFLOW
  );
}

export function* getBatteries() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/motive-power/batteries",
    {},
    projectActions.PROJECTS_RECEIVED_GET_BATTERIES
  );
}

export function* createBattery(battery) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/motive-power/batteries",
    battery,
    projectActions.PROJECTS_RECEIVED_CREATE_BATTERY
  );
}

export function* updateBattery(battery) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/motive-power/batteries/${battery.id}`,
    battery,
    projectActions.PROJECTS_RECEIVED_UPDATE_BATTERY
  );
}

export function* deleteBattery(batteryId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/motive-power/batteries/${batteryId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_BATTERY
  );
}

export function* getChargers() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/motive-power/chargers",
    {},
    projectActions.PROJECTS_RECEIVED_GET_CHARGERS
  );
}

export function* createCharger(charger) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/motive-power/chargers",
    charger,
    projectActions.PROJECTS_RECEIVED_CREATE_CHARGER
  );
}

export function* updateCharger(charger) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/motive-power/chargers/${charger.id}`,
    charger,
    projectActions.PROJECTS_RECEIVED_UPDATE_CHARGER
  );
}

export function* deleteCharger(chargerId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/motive-power/chargers/${chargerId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_CHARGER
  );
}

export function* getTeams() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/teams",
    {},
    projectActions.PROJECTS_RECEIVED_GET_TEAMS
  );
}

export function* createTeam(team) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/teams",
    team,
    projectActions.PROJECTS_RECEIVED_CREATE_TEAM
  );
}

export function* updateTeam(team) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/teams/${team.id}`,
    team,
    projectActions.PROJECTS_RECEIVED_UPDATE_TEAM
  );
}

export function* deleteTeam(teamId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/teams/${teamId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_TEAM
  );
}

export function* getMotivePower() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/motive-power",
    {},
    projectActions.PROJECTS_RECEIVED_GET_MOTIVE_POWER
  );
}

export function* getTaskData() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/task-data",
    {},
    projectActions.PROJECTS_RECEIVED_GET_TASK_DATA
  );
}

export function* getUserConfig() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/user-configs",
    {},
    projectActions.PROJECTS_RECEIVED_GET_USER_CONFIG
  );
}

export function* getAllTasks() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/tasks",
    {},
    projectActions.PROJECTS_RECEIVED_GET_TASKS
  );
}

export function* createTask(task) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/tasks",
    task,
    projectActions.PROJECTS_RECEIVED_CREATE_TASK
  );
}

export function* updateTask(task) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/tasks/${task.id}`,
    task,
    projectActions.PROJECTS_RECEIVED_UPDATE_TASK
  );
}

export function* completeTask(id) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/tasks/complete/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_COMPLETE_TASK
  );
}

export function* deleteTask(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/tasks/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_TASK
  );
}

export function* getFleets() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEETS
  );
}

export function* getFleet(id) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEET_DATA
  );
}

export function* createFleet(fleet) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/`,
    fleet,
    projectActions.PROJECTS_RECEIVED_CREATE_FLEET
  );
}

export function* getZones(fleetId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/zones`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEET_ZONES
  );
}

export function* createZone(fleetId, zone) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/zones`,
    zone,
    projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ZONE
  );
}

export function* updateZone(fleetId, zone) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/zones/${zone.id}`,
    zone,
    projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ZONE
  );
}

export function* deleteZone(fleetId, zoneId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/zones/${zoneId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ZONE
  );
}

export function* getAssetTemplates(fleetId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/asset-templates`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSET_TEMPLATES
  );
}

export function* createAssetTemplate(fleetId, assetTemplate) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/asset-templates`,
    fixupAssetTemplateData(assetTemplate),
    projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSET_TEMPLATE
  );
}

export function* updateAssetTemplate(fleetId, assetTemplate) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/asset-templates/${assetTemplate.id}`,
    fixupAssetTemplateData(assetTemplate),
    projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSET_TEMPLATE
  );
}

export function* deleteAssetTemplate(fleetId, assetTemplateId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/asset-templates/${assetTemplateId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSET_TEMPLATE
  );
}

export function* getAssociationTemplates(fleetId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/association-templates`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSOCIATION_TEMPLATES
  );
}

export function* createAssociationTemplate(fleetId, associationTemplate) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/association-templates`,
    associationTemplate,
    projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION_TEMPLATE
  );
}

export function* updateAssociationTemplate(fleetId, associationTemplate) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/association-templates/${associationTemplate.id}`,
    associationTemplate,
    projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION_TEMPLATE
  );
}

export function* deleteAssociationTemplate(fleetId, associationTemplateId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/association-templates/${associationTemplateId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION_TEMPLATE
  );
}

export function* getAssociations(fleetId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/fleet-associations`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSOCIATIONS
  );
}

export function* createAssociation(fleetId, association) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/fleet-associations`,
    association,
    projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSOCIATION
  );
}

export function* updateAssociation(fleetId, association) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/fleet-associations/${association.id}`,
    association,
    projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSOCIATION
  );
}

export function* deleteAssociation(fleetId, associationId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/fleet-associations/${associationId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSOCIATION
  );
}

export function* getFleetAssets(fleetId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/assets`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_FLEET_ASSETS
  );
}

export function* createFleetAsset(fleetId, asset) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/assets`,
    asset,
    projectActions.PROJECTS_RECEIVED_CREATE_FLEET_ASSET
  );
}

export function* updateFleetAsset(fleetId, asset) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/assets/${asset.id}`,
    asset,
    projectActions.PROJECTS_RECEIVED_UPDATE_FLEET_ASSET
  );
}

export function* retireFleetAsset(fleetId, assetId, date) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/assets/${assetId}/retire`,
    { retire_date: date },
    projectActions.PROJECTS_RECEIVED_RETIRE_FLEET_ASSET
  );
}

export function* deleteFleetAsset(fleetId, assetId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/assets/${assetId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_FLEET_ASSET
  );
}

export function* getTelemetryAssociations(fleetId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/telemetry-associations`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_TELEMETRY_ASSOCIATIONS
  );
}

export function* createTelemetryAssociation(fleetId, association) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/telemetry-associations`,
    association,
    projectActions.PROJECTS_RECEIVED_CREATE_TELEMETRY_ASSOCIATION
  );
}

export function* updateTelemetryAssociation(fleetId, association) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/telemetry-associations/${association.id}`,
    association,
    projectActions.PROJECTS_RECEIVED_UPDATE_TELEMETRY_ASSOCIATION
  );
}

export function* endTelemetryAssociation(fleetId, associationId, endDate) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/telemetry-associations/${associationId}/end-job`,
    { end_date: endDate },
    projectActions.PROJECTS_RECEIVED_END_TELEMETRY_ASSOCIATION
  );
}

export function* deleteTelemetryAssociation(fleetId, associationId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/telemetry-associations/${associationId}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_TELEMETRY_ASSOCIATION
  );
}

export function* getAlertConfigurations() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/alert-configurations",
    {},
    projectActions.PROJECTS_RECEIVED_GET_ALERT_CONFIGURATIONS
  );
}

export function* createAlertConfiguration(config) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/alert-configurations",
    config,
    projectActions.PROJECTS_RECEIVED_CREATE_ALERT_CONFIGURATION
  );
}

export function* updateAlertConfiguration(config) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/alert-configurations/${config.alert_configuration.id}`,
    config,
    projectActions.PROJECTS_RECEIVED_UPDATE_ALERT_CONFIGURATION
  );
}

export function* uploadFleetData(fleetId, data) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/fleets/${fleetId}/bulk-upload`,
    data,
    projectActions.PROJECTS_RECEIVED_BULK_UPLOAD_FLEET
  );
}

export function* createNote(note) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/notes",
    note,
    projectActions.PROJECTS_RECEIVED_CREATE_NOTE
  );
}

export function* updateNote(note) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/notes/${note.id}`,
    note,
    projectActions.PROJECTS_RECEIVED_UPDATE_NOTE
  );
}

export function* deleteNote(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/notes/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_NOTE
  );
}

export function* updateTeamStructure(structure, structureId) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/structures/${structureId}`,
    structure,
    projectActions.PROJECTS_RECEIVED_UPDATE_TEAM_STRUCTURE
  );
}

export function* updateResource(resource) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/resources/${resource.id}`,
    resource,
    projectActions.PROJECTS_RECEIVED_UPDATE_RESOURCE
  );
}

export function* createGoal(goal) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/goals",
    goal,
    projectActions.PROJECTS_RECEIVED_CREATE_GOAL
  );
}

export function* updateGoal(goal) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/goals/${goal.id}`,
    goal,
    projectActions.PROJECTS_RECEIVED_UPDATE_GOAL
  );
}

export function* deleteGoal(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/goals/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_GOAL
  );
}

export function* getTags() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/tags`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_TAGS
  );
}

export function* createTagType(type) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/tags/types",
    fixupTagData(type),
    projectActions.PROJECTS_RECEIVED_CREATE_TAG_TYPE
  );
}

export function* updateTagType(type) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/tags/types/${type.id}`,
    fixupTagData(type),
    projectActions.PROJECTS_RECEIVED_UPDATE_TAG_TYPE
  );
}

export function* deleteTagType(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/tags/types/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_TAG_TYPE
  );
}

export function* createTagValue(value) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/tags/values",
    fixupTagData(value),
    projectActions.PROJECTS_RECEIVED_CREATE_TAG_VALUE
  );
}

export function* updateTagValue(value) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/tags/values/${value.id}`,
    fixupTagData(value),
    projectActions.PROJECTS_RECEIVED_UPDATE_TAG_VALUE
  );
}

export function* deleteTagValue(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/tags/values/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_TAG_VALUE
  );
}

export function* createTag(tag) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/tags",
    tag,
    projectActions.PROJECTS_RECEIVED_CREATE_TAG
  );
}

export function* deleteTag(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/tags/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_TAG
  );
}

export function* getLoggerMostRecent(id, timeZoneOffset) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/power-loggers/${id}/most-recent/${timeZoneOffset}`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_LOGGER_MOST_RECENT
  );
}

export function* checkOperationsSummary(projectId, vehicleId) {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/projects/${projectId}/vehicles/${vehicleId}/has_summary_data`,
    {},
    projectActions.PROJECTS_RECEIVED_CHECK_OPERATIONS_SUMMARY
  );
}
export function* getNewTeams() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/new-teams`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_NEW_TEAMS
  );
}

export function* createNewTeam(team) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/new-teams`,
    team,
    projectActions.PROJECTS_RECEIVED_CREATE_NEW_TEAM
  );
}

export function* updateNewTeam(team) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/new-teams/${team.id}`,
    team,
    projectActions.PROJECTS_RECEIVED_UPDATE_NEW_TEAM
  );
}

export function* deleteNewTeam(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/new-teams/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_NEW_TEAM
  );
}

export function* createNewTeamResource(teamResource) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/new-teams/resources`,
    teamResource,
    projectActions.PROJECTS_RECEIVED_CREATE_NEW_TEAM_RESOURCE
  );
}

export function* deleteNewTeamResource(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/new-teams/resources/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_NEW_TEAM_RESOURCE
  );
}

export function* getUserViews() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/views`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_USER_VIEWS
  );
}

export function* createUserInterface(userInterface) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/views/user-interfaces`,
    userInterface,
    projectActions.PROJECTS_RECEIVED_CREATE_USER_INTERFACE
  );
}

export function* updateUserInterface(userInterface) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/views/user-interfaces/${userInterface.id}`,
    userInterface,
    projectActions.PROJECTS_RECEIVED_UPDATE_USER_INTERFACE
  );
}

export function* createUserDashboard(userDashboard) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/views/user-dashboards`,
    userDashboard,
    projectActions.PROJECTS_RECEIVED_CREATE_USER_DASHBOARD
  );
}

export function* updateUserDashboards(update) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/views/user-dashboards`,
    update,
    projectActions.PROJECTS_RECEIVED_UPDATE_USER_DASHBOARDS
  );
}

export function* deleteUserDashboard(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/views/user-dashboards/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_USER_DASHBOARD
  );
}

export function* createClientInterface(clientInterface) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/views/client-interfaces`,
    clientInterface,
    projectActions.PROJECTS_RECEIVED_CREATE_CLIENT_INTERFACE
  );
}

export function* deleteClientInterface(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/views/client-interfaces/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_CLIENT_INTERFACE
  );
}

export function* createNewGoal(goal) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/new-goals",
    goal,
    projectActions.PROJECTS_RECEIVED_CREATE_GOAL
  );
}

export function* updateNewGoal(goal) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/new-goals/${goal.id}`,
    goal,
    projectActions.PROJECTS_RECEIVED_UPDATE_GOAL
  );
}

export function* deleteNewGoal(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/new-goals/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_GOAL
  );
}

export function* getClientOwners() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    `/service/client-owners`,
    {},
    projectActions.PROJECTS_RECEIVED_GET_CLIENT_OWNERS
  );
}

export function* createClientOwner(owner) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/client-owners`,
    owner,
    projectActions.PROJECTS_RECEIVED_CREATE_CLIENT_OWNER
  );
}

export function* updateClientOwner(owner) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/client-owners/${owner.id}`,
    owner,
    projectActions.PROJECTS_RECEIVED_UPDATE_CLIENT_OWNER
  );
}

export function* deleteClientOwner(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/client-owners/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_CLIENT_OWNER
  );
}

export function* getCustomerGroups() {
  return yield makeAPICall(
    "get",
    PROJECTS_URL,
    "/service/customer-groups",
    {},
    projectActions.PROJECTS_RECEIVED_GET_CUSTOMER_GROUPS
  );
}

export function* createCustomerGroup(customerGroup) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/customer-groups`,
    customerGroup,
    projectActions.PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP
  );
}

export function* updateCustomerGroup(customerGroup) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/customer-groups/${customerGroup.id}`,
    customerGroup,
    projectActions.PROJECTS_RECEIVED_UPDATE_CUSTOMER_GROUP
  );
}

export function* deleteCustomerGroup(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/customer-groups/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP
  );
}

export function* createCustomerGroupMember(customerGroupMember) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    `/service/customer-groups/members`,
    customerGroupMember,
    projectActions.PROJECTS_RECEIVED_CREATE_CUSTOMER_GROUP_MEMBER
  );
}

export function* deleteCustomerGroupMember(id) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/customer-groups/members/${id}`,
    {},
    projectActions.PROJECTS_RECEIVED_DELETE_CUSTOMER_GROUP_MEMBER
  );
}

export function* createThirdPartyUser(thirdPartyUser) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/third-party-users",
    thirdPartyUser,
    portalActions.PORTAL_RECEIVED_CREATE_THIRD_PARTY_USER
  );
}

export function* updateThirdPartyUser(thirdPartyUser) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    `/service/third-party-users/${thirdPartyUser.id}`,
    thirdPartyUser,
    portalActions.PORTAL_RECEIVED_UPDATE_THIRD_PARTY_USER
  );
}

export function* deleteThirdPartyUser(thirdPartyUserId) {
  return yield makeAPICall(
    "delete",
    PROJECTS_URL,
    `/service/third-party-users/${thirdPartyUserId}`,
    {},
    portalActions.PORTAL_RECEIVED_DELETE_THIRD_PARTY_USER
  );
}

export function* sendPasswordResetEmail(email) {
  return yield makeAPICall(
    "put",
    PROJECTS_URL,
    "/service/send-password-reset",
    { email: email },
    projectActions.PROJECTS_RECEIVED_SEND_PASSWORD_RESET_EMAIL
  );
}

export function* ignoreAMAssetList(ids) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/am-assets/ignore-list",
    { ids: ids },
    projectActions.PROJECTS_RECEIVED_IGNORE_AM_ASSET_LIST
  );
}

export function* clearAMUnexpectedAssetList(ids) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/am-unexpected-assets/clear-list",
    { ids: ids },
    projectActions.PROJECTS_RECEIVED_CLEAR_AM_UNEXPECTED_ASSET_LIST
  );
}

export function* unIgnoreAMAssetList(ids) {
  return yield makeAPICall(
    "post",
    PROJECTS_URL,
    "/service/am-assets/un-ignore-list",
    { ids: ids },
    projectActions.PROJECTS_RECEIVED_UN_IGNORE_AM_ASSET_LIST
  );
}

export function* getOperationsSummary(projectId, vehicleId) {
  try {
    const auth = yield select(getAuth);
    // Request the summary update
    const init = yield call(
      axios.get,
      `${PROJECTS_URL}/service/projects/${projectId}/vehicles/${vehicleId}/update_summary.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer: ${auth.auth_token}`,
        },

        // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
        xsrfCookieName: "XSRF-TOKEN", // default

        // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
        xsrfHeaderName: "X-XSRF-TOKEN", // default
      }
    );

    if (!init.data.job_id || init.data.status !== "ok") {
      yield put({
        type: appActions.SET_APP_STATE,
        payload: { calculating: false },
      });
      yield put({
        type: appActions.SHOW_ALERT,
        payload: { msg: "Job Failure...Please try again later", type: "error" },
      });
      return;
    }

    let checkRes = yield call(
      axios.get,
      `${PROJECTS_URL}/service/projects/${projectId}/vehicles/${vehicleId}/update_summary/${init.data.job_id}.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer: ${auth.auth_token}`,
        },

        // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
        xsrfCookieName: "XSRF-TOKEN", // default

        // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
        xsrfHeaderName: "X-XSRF-TOKEN", // default
      }
    );

    let pollDelay = 1000;
    while (checkRes.data.inprogress) {
      yield delay(pollDelay);

      pollDelay = Math.min(3000, pollDelay + 250);

      checkRes = yield call(
        axios.get,
        `${PROJECTS_URL}/service/projects/${projectId}/vehicles/${vehicleId}/update_summary/${init.data.job_id}.json`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer: ${auth.auth_token}`,
          },

          // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
          xsrfCookieName: "XSRF-TOKEN", // default

          // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
          xsrfHeaderName: "X-XSRF-TOKEN", // default
        }
      );
    }

    const request = yield call(
      axios.get,
      `${PROJECTS_URL}/service/projects/${projectId}/vehicles/${vehicleId}/summary_data.json`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer: ${auth.auth_token}`,
        },

        // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
        xsrfCookieName: "XSRF-TOKEN", // default

        // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
        xsrfHeaderName: "X-XSRF-TOKEN", // default
      }
    );
    yield put({
      type: projectActions.PROJECTS_RECEIVED_OPERATIONS_SUMMARY,
      payload: request.data,
    });
    return request.data;
  } catch (error) {
    yield put({
      type: appActions.SET_APP_STATE,
      payload: { calculating: false },
    });
    yield handleAPIError(error);
    return error;
  }
}

export function fixupFacilityData(facility) {
  let copy = copyObject(facility);
  copy.facility_audit_data = JSON.stringify(facility.facility_audit_data);
  copy.facility_size_data = JSON.stringify(facility.facility_size_data);
  copy.facility_environment_data = JSON.stringify(
    facility.facility_environment_data
  );
  copy.facility_electrical_data = JSON.stringify(
    facility.facility_electrical_data
  );
  copy.facility_workforce_data = JSON.stringify(
    facility.facility_workforce_data
  );
  copy.facility_infrastructure_data = JSON.stringify(
    facility.facility_infrastructure_data
  );

  return copy;
}

export function fixupProjectData(project) {
  let copy = copyObject(project);
  if (copy.is_archived === undefined) copy.is_archived = false;

  copy.project_data = JSON.stringify(project.project_data);
  copy.project_workflows_attributes = copy.project_workflows;
  delete copy.project_workflows;
  copy.project_workflows_attributes.forEach((workflow) => {
    workflow.workflow_data = JSON.stringify(workflow.workflow_data);
  });

  return copy;
}

export function fixupVehicleData(vehicle) {
  let copy = copyObject(vehicle);
  copy.vehicle_data = JSON.stringify(vehicle.vehicle_data);
  return copy;
}

export function fixupTagData(typeOrValue) {
  let copy = copyObject(typeOrValue);
  copy.string_table = JSON.stringify(typeOrValue.string_table);
  return copy;
}

export function fixupScheduleData(values) {
  let schedule = copyObject(values);

  schedule.shifts_attributes = schedule.shifts;
  delete schedule.shifts;
  schedule.shifts_attributes.forEach((shift) => {
    shift.breaks_attributes = shift.breaks;
    delete shift.breaks;
  });

  return schedule;
}

export function fixupScheduleGroupData(values) {
  let scheduleGroup = copyObject(values);

  scheduleGroup.schedules_attributes = [];
  scheduleGroup.schedules.forEach((schedule) => {
    scheduleGroup.schedules_attributes.push(fixupScheduleData(schedule));
  });

  delete scheduleGroup.schedules;

  return scheduleGroup;
}

export function fixupWorkflowData(workflowType, workflowData) {
  let workflow_data_string = JSON.stringify(workflowData);

  return {
    workflow_data_string: workflow_data_string,
    workflow_type: workflowType,
  };
}

export function fixupAssetTemplateData(template) {
  let copy = copyObject(template);

  copy.asset_data = JSON.stringify(template.asset_data);

  return copy;
}
