import { put, putResolve, take } from "redux-saga/effects";
import * as appActions from "../../actions";
import * as projectActions from "../../actions/project-actions";
import { strings } from "../../localization";
import { projectsApi } from "../../services/projects/projectsApi";

export function* watchSendResetEmail() {
  while (true) {
    const { payload: email } = yield take(
      projectActions.PROJECTS_SEND_PASSWORD_RESET_EMAIL
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(
      projectsApi.endpoints.sendPasswordResetEmail.initiate(email)
    );
    // yield call(sendPasswordResetEmail, email);
    yield put({
      type: appActions.SHOW_ALERT,
      payload: { msg: strings.emailHasBeenSent, type: "info" },
    });
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
