import * as projectActions from "../../actions/project-actions";
import { take, put, call, putResolve } from "redux-saga/effects";
import * as appActions from "../../actions";
import { projectsApi } from "../../services/projects/projectsApi";

const {
  getNewTeams,
  createNewTeam,
  updateNewTeam,
  deleteNewTeam,
  createNewTeamResource,
  deleteNewTeamResource,
} = projectsApi.endpoints;

export function* watchGetNewTeams() {
  while (true) {
    yield take(projectActions.PROJECTS_GET_NEW_TEAMS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put({ type: appActions.SET_DATA_REQUESTED, payload: `newTeams` });
    /* yield call(getNewTeams); */
    yield putResolve(getNewTeams.initiate());
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({ type: appActions.SET_DATA_RECEIVED, payload: `newTeams` });
  }
}

export function* watchCreateTeam() {
  while (true) {
    const { payload: team } = yield take(
      projectActions.PROJECTS_CREATE_NEW_TEAM
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createNewTeam.initiate(team));
    //yield call(createNewTeam, team);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateTeam() {
  while (true) {
    const { payload: team } = yield take(
      projectActions.PROJECTS_UPDATE_NEW_TEAM
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(updateNewTeam.initiate(team));
    // yield call(updateNewTeam, team);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteTeam() {
  while (true) {
    const { payload: id } = yield take(projectActions.PROJECTS_DELETE_NEW_TEAM);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteNewTeam.initiate(id));
    // yield call(deleteNewTeam, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateTeamResource() {
  while (true) {
    const { payload: teamResource } = yield take(
      projectActions.PROJECTS_CREATE_NEW_TEAM_RESOURCE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createNewTeamResource.initiate(teamResource));
    // yield call(createNewTeamResource, teamResource);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteTeamResource() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_NEW_TEAM_RESOURCE
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteNewTeamResource.initiate(id));
    // yield call(deleteNewTeamResource, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
