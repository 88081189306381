import {
  PROJECTS_RECEIVED_GET_USER_VIEWS,
  PROJECTS_RECEIVED_CREATE_USER_INTERFACE,
  PROJECTS_RECEIVED_UPDATE_USER_INTERFACE,
  PROJECTS_RECEIVED_CREATE_USER_DASHBOARD,
  PROJECTS_RECEIVED_UPDATE_USER_DASHBOARDS,
  PROJECTS_RECEIVED_DELETE_USER_DASHBOARD,
  PROJECTS_RECEIVED_CREATE_CLIENT_INTERFACE,
  PROJECTS_RECEIVED_DELETE_CLIENT_INTERFACE
} from "../actions/project-actions";
import { copyObject } from "../utility";

const initialState = {
  user_interfaces: [],
  user_dashboards: [],
  client_interfaces: [],
  system_interfaces: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_RECEIVED_GET_USER_VIEWS:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_USER_INTERFACE:
      return createUserInterface(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_USER_INTERFACE:
      return updateUserInterface(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_USER_DASHBOARD:
      return createUserDashboard(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_USER_DASHBOARDS:
      return updateUserDashboards(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_USER_DASHBOARD:
      return deleteUserDashboard(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_CLIENT_INTERFACE:
      return createClientInterface(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CLIENT_INTERFACE:
      return deleteClientInterface(copyObject(state), action.payload);

    default:
      return state;
  }
}

function createUserInterface(state, data) {
  state.user_interfaces.push(data);

  return state;
}

function updateUserInterface(state, data) {
  state.user_interfaces.forEach((user_interface, idx) => {
    if (user_interface.id === data.id) {
      state.user_interfaces[idx] = data;
    }
  });

  return state;
}

function createUserDashboard(state, data) {
  state.user_dashboards.push(data);

  return state;
}

function updateUserDashboards(state, data) {
  data.new_dashboards.forEach(dashboard => {
    state.user_dashboards.push(dashboard);
  });

  data.deleted_dashboards.forEach(dashboard => {
    const idx = state.user_dashboards.findIndex(d => d.id === dashboard.id);
    if (idx > -1) {
      state.user_dashboards.splice(idx, 1);
    }
  });

  return state;
}

function deleteUserDashboard(state, data) {
  state.user_dashboards.forEach((user_dashboard, idx) => {
    if (user_dashboard.id === data.id) {
      state.user_dashboards.splice(idx, 1);
    }
  });

  return state;
}

function createClientInterface(state, data) {
  state.client_interfaces.push(data);

  return state;
}

function deleteClientInterface(state, data) {
  state.client_interfaces.forEach((client_interface, idx) => {
    if (client_interface.id === data.id) {
      state.client_interfaces.splice(idx, 1);
    }
  });

  return state;
}
