import { configureStore } from "@reduxjs/toolkit";
import promise from "redux-promise";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import createIndexedDBStorage from "redux-persist-indexeddb-storage";
import { projectsApi } from "./services/projects/projectsApi";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { portalApi } from "./services/portal/portalApi";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create indexedDB storage
const storage = createIndexedDBStorage({
  name: "myApp",
  storeName: "persistStore",
});

// Persist config for redux-persist
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "authenticate",
    "clients",
    "newTeams",
    "assets",
    "users",
    "fleets",
    "powerLoggers",
    "portalGroupDefinitions",
    "alertConfigurations",
    "cacheTags",
    projectsApi.reducerPath,
    portalApi.reducerPath,
  ], // List reducers to be persisted
} as PersistConfig<any>;

export type StoreState = ReturnType<typeof rootReducer>;
// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      projectsApi.middleware,
      portalApi.middleware,
      promise,
      sagaMiddleware,
    ]),
});

// Create a persistor
export const persistor = persistStore(store);

// Run the root saga
sagaMiddleware.run(rootSaga);
