import {
  CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIGS,
  CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIG,
  CONFIG_RECEIVED_UPDATE_LEADACID_BESS_FACTORY,
  CONFIG_RECEIVED_UPDATE_LEADACID_BESS_USER,
} from '../actions/config-actions';
import {copyObject} from "../shared-components/src/utility";

export default function (state = {leadAcidBESS: []}, action) {

  switch (action.type) {
    case CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIGS:
      return action.payload;

    case CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIG:
      return updateConfig(state, action.payload);

    case CONFIG_RECEIVED_UPDATE_LEADACID_BESS_FACTORY:
    case CONFIG_RECEIVED_UPDATE_LEADACID_BESS_USER:
      return updateSetting(state, action.payload);

    default:
      return state;
  }
}

function updateSetting(state, data) {
  let copy = copyObject(state);

  copy.leadAcidBESS.forEach(leadAcid => {
    if (leadAcid.serial === data.serial) {
      if (data.user) {
        leadAcid.config.user = data.user;
        leadAcid.config.userHistory.push(data);
      } else if (data.factory) {
        leadAcid.config.factory = data.factory;
        leadAcid.config.factoryHistory.push(data);
      }
    }
  });

  return copy;
}

function updateConfig(state, data) {
  if (!data.leadAcidBESSs[0]) {
    return state;
  }
  let copy = copyObject(state);
  let found = false;
  copy.leadAcidBESS.forEach((leadAcid, idx) => {
    if (leadAcid.serial === data.leadAcidBESS[0].serial) {
      copy.leadAcidBESS[idx] = data.leadAcidBESS[0];
      found = true;
    }
  });
  if (!found) {
    copy.leadAcidBESS.push(data.leadAcidBESS[0])
  }

  return copy;
}
