import {
  SET_ADD_PROJECT_FLOW
} from '../actions';

export default function (state = {}, action) {

  switch(action.type) {
    case SET_ADD_PROJECT_FLOW:
      return action.payload;

    default:
      return state;
  }
}