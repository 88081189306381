import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { FlexRow } from '../layouts';
import { strings } from './localization';

export interface LoginAlertProps {
  onLogin(): void;
  onClose(): void;
  open: boolean;
  msg: string;
}
const LoginAlert = (props: LoginAlertProps) => {
  const { onLogin, onClose, open, msg } = props;
  const handleLogin = () => {
    onLogin();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <FlexRow
            style={{
              alignContent: 'center',
            }}
          >
            <FlexRow padTop={3} padLeft={5}>
              <span id="message-id">{msg}</span>
            </FlexRow>
          </FlexRow>
        }
        action={[
          <Button key="close" variant="contained" color="primary" onClick={handleLogin}>
            {strings.logIn}
          </Button>,
        ]}
      />
    </div>
  );
};

export default LoginAlert;
