import {
  PROJECTS_RECEIVED_GET_TEAMS,
  PROJECTS_RECEIVED_UPDATE_TEAM,
  PROJECTS_RECEIVED_CREATE_TEAM,
  PROJECTS_RECEIVED_DELETE_TEAM,
  PROJECTS_RECEIVED_CREATE_GOAL,
  PROJECTS_RECEIVED_UPDATE_GOAL,
  PROJECTS_RECEIVED_DELETE_GOAL,
  PROJECTS_RECEIVED_UPDATE_RESOURCE,
} from '../actions/project-actions';
import {copyObject} from "../utility";

const initialState = {
  teams: [],
  resources: [],
  users: [],
  structures: [],
  structure_nodes: [],
  goals: [],
};

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_TEAMS:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_TEAM:
      return createTeam(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_TEAM:
      return updateTeam(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_TEAM:
      return deleteTeam(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_GOAL:
      return createGoal(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_GOAL:
      return updateGoal(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_GOAL:
      return deleteGoal(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_RESOURCE:
      return updateResource(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateTeam(state, data) {
  state.teams.forEach((team, idx) => {
    if (team.id === data.id) {
      state.teams[idx] = data;
    }
  });

  return state;
}

function createTeam(state, data) {

  state.teams.push(data);

  return state;
}

function deleteTeam(state, data) {
  state.teams.forEach((team, idx) => {
    if (team.id === data.id) {
      state.teams.splice(idx, 1);
    }
  });

  return state;
}

function updateGoal(state, data) {
  state.goals.forEach((goal, idx) => {
    if (goal.id === data.id) {
      state.goals[idx] = data;
    }
  });

  return state;
}

function createGoal(state, data) {

  state.goals.push(data);

  return state;
}

function deleteGoal(state, data) {
  state.goals.forEach((goal, idx) => {
    if (goal.id === data.id) {
      state.goals.splice(idx, 1);
    }
  });

  return state;
}

function updateResource(state, data) {
  state.resources.forEach((res, idx) => {
    if (res.id === data.id) {
      state.resources[idx] = data;
    }
  });

  return state;
}