import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { legacyFulfill } from "../utils";
import {
  PROJECTS_RECEIVED_CREATE_TAG,
  PROJECTS_RECEIVED_CREATE_TAG_TYPE,
  PROJECTS_RECEIVED_CREATE_TAG_VALUE,
  PROJECTS_RECEIVED_DELETE_TAG,
  PROJECTS_RECEIVED_DELETE_TAG_TYPE,
  PROJECTS_RECEIVED_DELETE_TAG_VALUE,
  PROJECTS_RECEIVED_GET_TAGS,
  PROJECTS_RECEIVED_UPDATE_TAG_TYPE,
  PROJECTS_RECEIVED_UPDATE_TAG_VALUE,
} from "../../actions/project-actions";
import { cloneDeep } from "lodash";
export type Tag = {};
export const tagsApi = (builder: BuilderType) => ({
  getTags: builder.query<Tag, void>({
    query: () => `service/tags`,
    providesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_TAGS),
  }),
  createTagType: builder.mutation<any, any>({
    query: (type) => ({
      url: `service/tags/types`,
      method: "POST",
      body: fixupTagData(type),
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_TAG_TYPE),
  }),

  updateTagType: builder.mutation<any, any>({
    query: (type) => ({
      url: `service/tags/types/${type.id}`,
      method: "PUT",
      body: fixupTagData(type),
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_TAG_TYPE),
  }),

  deleteTagType: builder.mutation<any, number>({
    query: (id) => ({
      url: `service/tags/types/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_TAG_TYPE),
  }),

  createTagValue: builder.mutation<any, any>({
    query: (value) => ({
      url: `service/tags/values`,
      method: "POST",
      body: fixupTagData(value),
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_TAG_VALUE),
  }),

  updateTagValue: builder.mutation<any, any>({
    query: (value) => ({
      url: `service/tags/values/${value.id}`,
      method: "PUT",
      body: fixupTagData(value),
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_TAG_VALUE),
  }),

  deleteTagValue: builder.mutation<any, number>({
    query: (id) => ({
      url: `service/tags/values/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_TAG_VALUE),
  }),

  createTag: builder.mutation<any, any>({
    query: (tag) => ({
      url: `service/tags`,
      method: "POST",
      body: tag,
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_TAG),
  }),

  deleteTag: builder.mutation<any, number>({
    query: (id) => ({
      url: `service/tags/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.TAGS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_TAG),
  }),
});

export function fixupTagData(typeOrValue) {
  const copy = cloneDeep(typeOrValue);
  copy.string_table = JSON.stringify(typeOrValue.string_table);
  return copy;
}
