import { copyObject, momentFromTime } from './index';
import moment from 'moment-timezone';

export function calculateScheduleData(inSchedule, usableTimeValue, betweenShifts = 'charging') {
  let totalTimeRun = 0;
  let chargeBreakTotal = 0;
  let totalUsableIdle = 0;
  let totalUnusableIdle = 0;

  let schedule = copyObject(inSchedule);

  schedule.shifts.forEach((shift, idx) => {
    if (shift._destroy) {
      schedule.shifts.splice(idx, 1);
    } else {
      shift.breaks.forEach((brk, i) => {
        if (brk._destroy) {
          shift.breaks.splice(i, 1);
        }
      });
    }
  });

  schedule.shifts.forEach((shift) => {
    const start = momentFromTime(shift.start_time);
    let end = momentFromTime(shift.end_time);
    if (end < start) {
      end = momentFromTime(shift.end_time, 1);
    }

    totalTimeRun = totalTimeRun + end.diff(start, 'minutes');
    if (!shift._destroy && shift.breaks !== []) {
      shift.breaks.forEach((brk) => {
        if (!brk._destroy) {
          if (brk.break_type === 'charge') {
            chargeBreakTotal = chargeBreakTotal + brk.duration;
          } else if (brk.break_type === 'idle') {
            if (brk.duration < usableTimeValue) {
              totalUnusableIdle = totalUnusableIdle + brk.duration;
            } else {
              totalUsableIdle = totalUsableIdle + brk.duration;
            }
          }
        }
      });
    }
  });

  // have calculated total time run, now find out time charging
  let totalTimeCharge =
    (betweenShifts === 'charging' ? 24 * 60 - totalTimeRun : 0) + chargeBreakTotal;
  totalTimeRun = totalTimeRun - totalUsableIdle - totalUnusableIdle - chargeBreakTotal;
  if (betweenShifts === 'idle') {
    totalUsableIdle += 24 * 60 - totalTimeRun - totalTimeCharge - totalUnusableIdle;
  }

  return {
    run: totalTimeRun / 60,
    charge: totalTimeCharge / 60,
    idle: totalUsableIdle / 60,
    unusable: totalUnusableIdle / 60,
  };
}

export function sortBreaks(breaks, shift) {
  breaks.sort((a, b) => {
    if (a.start_time === '') {
      return -1;
    }
    if (momentFromTime(a.start_time).isBefore(momentFromTime(b.start_time))) {
      return -1;
    } else if (momentFromTime(b.start_time).isBefore(momentFromTime(a.start_time))) {
      return 1;
    } else {
      return 0;
    }
  });

  if (momentFromTime(shift.end_time).isBefore(momentFromTime(shift.start_time))) {
    // if shift ends before it starts (goes overnight), need to reorganize list
    let switchoverIDX = -1;
    for (let i = 1; i < breaks.length; i++) {
      if (
        momentFromTime(breaks[i - 1].start_time).isBefore(momentFromTime(shift.start_time)) &&
        momentFromTime(shift.start_time).isBefore(momentFromTime(breaks[i].start_time))
      ) {
        switchoverIDX = i;
      }
    }

    if (switchoverIDX !== -1) {
      let end = breaks.splice(0, switchoverIDX);
      breaks = breaks.concat(end);
    }
  }

  return breaks;
}

export function sortShifts(inShifts) {
  inShifts.sort((a, b) => {
    let aTime = momentFromTime(a.start_time);
    let bTime = momentFromTime(b.start_time);

    if (aTime < bTime) {
      return -1;
    }
    if (aTime > bTime) {
      return 1;
    }

    return 0;
  });
}

export function findShiftGaps(inShifts) {
  let gaps = [];
  let shifts = makeCleanShiftCopy(inShifts);

  // Sort the result
  sortShifts(shifts);

  // If we have some shifts still..
  if (shifts.length > 0) {
    let last = momentFromTime(shifts[0].start_time);
    let prevShiftNum = 0;

    for (let i = 0; i < shifts.length; i++) {
      const start = momentFromTime(shifts[i].start_time);
      let end = momentFromTime(shifts[i].end_time);
      if (end < start) {
        end = momentFromTime(shifts[i].end_time, 1);
      }

      let diff = start.diff(last, 'minutes');
      if (diff > 0) {
        gaps.push({
          start: last.format('HH:mm'),
          duration: diff,
          previousShiftNumber: prevShiftNum,
        });
      }

      prevShiftNum = shifts[i].shift_number;
      last = end;
    }
    // Now check between the beginning of the first shift and the last
    let start = momentFromTime(shifts[0].start_time);
    let diff;
    if (last < start) {
      diff = start.diff(last, 'minutes');
    } else {
      diff =
        momentFromTime('24:00').diff(last, 'minutes') +
        start.diff(momentFromTime('00:00'), 'minutes');
    }

    if (diff > 0) {
      gaps.push({
        start: last.format('HH:mm'),
        duration: diff,
        prevShiftNum: prevShiftNum,
      });
    }
  }

  return gaps;
}

export function findBreakGaps(inBreaks, shift) {
  let gaps = [];
  let breaks = makeCleanBreakCopy(inBreaks);

  // Sort the result
  breaks = sortBreaks(breaks, shift);

  // If we have some breaks still..
  if (breaks.length > 0) {
    // Insert the gap between the beginning of the shift
    // and the first break
    let start = momentFromTime(shift.start_time);
    let end = momentFromTime(breaks[0].start_time);
    if (end < start) {
      end = momentFromTime(breaks[0].start_time, 1);
    }

    let diff = end.diff(start, 'minutes');
    if (diff > -1) {
      gaps.push({
        start: start.format('HH:mm'),
        duration: diff,
      });
    }
    let last = momentFromTime(breaks[0].start_time);
    if (last < start) {
      last = momentFromTime(breaks[0].start_time, 1);
    }

    for (let i = 0; i < breaks.length; i++) {
      const start = momentFromTime(
        breaks[i].start_time === '00:00' ? '00:00:01' : breaks[i].start_time
      );
      end = moment(start).add(breaks[i].duration, 'minutes');

      diff = start.diff(last, 'minutes');
      if (diff > 0) {
        gaps.push({
          start: last.format('HH:mm'),
          duration: diff,
        });
      } else if (diff < 0) {
        gaps.push({
          start: last.format('HH:mm'),
          duration: diff + 1440,
        });
      }

      // Re-adjust back
      if (breaks[i].start_time === '00:00') {
        last = moment(momentFromTime(breaks[i].start_time)).add(breaks[i].duration, 'minutes');
      } else {
        last = end;
      }
    }

    // Now add the gap between the last break and shift end
    start = last;
    end = momentFromTime(shift.end_time);
    if (end < start) {
      end = momentFromTime(shift.end_time, 1);
    }

    diff = end.diff(start, 'minutes');
    if (diff > 0) {
      gaps.push({
        start: start.format('HH:mm'),
        duration: diff,
      });
    }
  } else {
    // There are no breaks
    let start = momentFromTime(shift.start_time);
    let end = momentFromTime(shift.end_time);
    if (end < start) {
      end = momentFromTime(shift.end_time, 1);
    }

    let diff = end.diff(start, 'minutes');
    gaps.push({
      start: start.format('HH:mm'),
      duration: diff,
    });
  }

  return gaps;
}

export function getNumWorkdaysPerWeek(scheduleGroup) {
  let days = 0;
  scheduleGroup.schedules.forEach((schedule) => {
    days += schedule.schedule_days.length;
  });

  return days;
}

const DAYS = ['mn', 'tu', 'wd', 'th', 'fr', 'st', 'su'];

export function getStartDay(scheduleGroup) {
  let minDay = 99;
  scheduleGroup.schedules.forEach((schedule) => {
    if (schedule.schedule_days.includes('mn')) {
      minDay = Math.min(minDay, 1);
    } else if (schedule.schedule_days.includes('tu')) {
      minDay = Math.min(minDay, 2);
    } else if (schedule.schedule_days.includes('wd')) {
      minDay = Math.min(minDay, 3);
    } else if (schedule.schedule_days.includes('th')) {
      minDay = Math.min(minDay, 4);
    } else if (schedule.schedule_days.includes('fr')) {
      minDay = Math.min(minDay, 5);
    } else if (schedule.schedule_days.includes('st')) {
      minDay = Math.min(minDay, 6);
    }
  });

  return minDay;
}

export function getStartTime(scheduleGroup, startDay) {
  let startTime = momentFromTime('23:59:59');
  scheduleGroup.schedules.forEach((schedule) => {
    if (schedule.schedule_days.includes(DAYS[startDay - 1])) {
      schedule.shifts.forEach((shift) => {
        const start = momentFromTime(shift.start_time);
        if (start.isBefore(startTime)) {
          startTime = start;
        }
      });
    }
  });

  return startTime.format('HH:mm');
}

export function cloneSchedules(scheduleGroup) {
  let copy = copyObject(scheduleGroup.schedules);

  copy.forEach((schedule) => {
    delete schedule.id;
    delete schedule.schedule_group_id;

    schedule.shifts.forEach((shift) => {
      delete shift.id;
      delete shift.schedule_id;
      shift.breaks.forEach((brk) => {
        delete brk.id;
        delete brk.shift_id;
      });
    });
  });

  return copy;
}

export function getDaysPerWeek(scheduleGroup) {
  let days = 0;

  scheduleGroup.schedules.forEach((schedule) => {
    days += schedule.schedule_days.length;
  });

  return days;
}

export function makeCleanShiftCopy(inShifts) {
  let copy = copyObject(inShifts);
  let shifts = copy.filter((s) => !s._destroy);

  shifts.forEach((shift, idx) => {
    shift.breaks = copy[idx].breaks.filter((b) => !b._destroy);
  });

  return shifts;
}

export function makeCleanBreakCopy(inBreaks) {
  let copy = copyObject(inBreaks);
  return copy.filter((b) => !b._destroy);
}

const collapseSequences = (input) => {
  const pushResult = (acc) => {
    const cs = acc.currSet;
    acc.out.push(cs.length > 2 ? `${cs.shift()}-${cs.pop()}` : cs.join(','));
  };
  const output = input.reduce(
    (acc, curr, i, arr) => {
      if (curr - 1 === arr[i - 1] || i === 0) {
        acc.currSet.push(curr);
      } else {
        pushResult(acc);
        acc.currSet = [curr];
      }
      if (i === arr.length - 1) {
        pushResult(acc);
      }
      return acc;
    },
    { out: [], currSet: [] }
  );
  return output.out;
};

export const getRelevantDaysString = (scheduleDays) => {
  const readableDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const dayAbbrevs = ['mn', 'tu', 'wd', 'th', 'fr', 'st', 'su'];
  const orderedDays = dayAbbrevs.reduce((obj, day, i) => ({ ...obj, [day]: i }), {});
  const dayNumbs = scheduleDays.map((d) => orderedDays[d]);
  const collapsedDayStr = collapseSequences(dayNumbs)
    .map((nStr) => {
      if (nStr.includes('-')) {
        return `${readableDays[nStr[0]]}-${readableDays[nStr[2]]}`;
      } else if (nStr.includes(',')) {
        return `${readableDays[nStr[0]]}, ${readableDays[nStr[2]]}`;
      } else {
        return readableDays[nStr];
      }
    })
    .join(', ');
  return collapsedDayStr;
};
