import {
  CONFIG_RECEIVED_GET_CHARGER_CONFIGS,
  CONFIG_RECEIVED_GET_CHARGER_CONFIG,
  CONFIG_RECEIVED_UPDATE_CHARGER_FACTORY,
  CONFIG_RECEIVED_UPDATE_CHARGER_USER,
} from '../actions/config-actions';
import {copyObject} from "../shared-components/src/utility";

export default function (state = {chargers: []}, action) {

  switch (action.type) {
    case CONFIG_RECEIVED_GET_CHARGER_CONFIGS:
      return action.payload;

    case CONFIG_RECEIVED_GET_CHARGER_CONFIG:
      return updateConfig(state, action.payload);

    case CONFIG_RECEIVED_UPDATE_CHARGER_FACTORY:
    case CONFIG_RECEIVED_UPDATE_CHARGER_USER:
      return updateSetting(state, action.payload);

    default:
      return state;
  }
}

function updateSetting(state, data) {
  let copy = copyObject(state);

  copy.chargers.forEach(charger => {
    if(charger.chargerSerial === data.chargerSerial) {
      if(data.user) {
        charger.config.user = data.user;
        charger.config.userHistory.push(data);
      } else if(data.factory) {
        charger.config.factory = data.factory;
        charger.config.factoryHistory.push(data);
      }
    }
  });

  return copy;
}

function updateConfig(state, data) {
  if(!data.chargers[0]) {
    return state;
  }
  let copy = copyObject(state);
  let found = false;
  copy.chargers.forEach((charger, idx) => {
    if (charger.chargerSerial === data.chargers[0].chargerSerial) {
      copy.chargers[idx] = data.chargers[0];
      found = true;
    }
  });
  if(!found) {
    copy.chargers.push(data.chargers[0]);
  }

  return copy;
}