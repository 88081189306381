import { call, put, putResolve, select, take } from "redux-saga/effects";
import * as appActions from "../../actions";
import { copyObject } from "../../utility";
import {
  getActiveClient,
  getActiveContact,
  getActiveFacility,
  getActiveProject,
  getActiveAddress,
  getNav,
  getTags,
} from "../../reducers/selectors";
import * as projectApi from "../api-projects";
import { projectsApi } from "../../services/projects/projectsApi";

export function* watchSetAddProjectFlow() {
  while (true) {
    const { payload: flow } = yield take(appActions.SET_ADD_PROJECT_FLOW);

    console.log(flow);
    if (flow.flowClient) {
      let copy = copyObject(flow);
      // Check for client save
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
      const client = yield select(getActiveClient);
      const project = yield select(getActiveProject);

      const { data: resultClient } = yield putResolve(
        projectsApi.endpoints.createClient.initiate(client)
      );

      let projectCopy = copyObject(project);
      projectCopy.client_id = resultClient.id;
      yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: projectCopy });

      if (flow.saveOwner) {
        const clientOwner = {
          client_id: flow.baseClientId,
          target_client_id: resultClient.id,
          user_id: flow.ownerId,
        };
        yield put(
          projectsApi.endpoints.createClientOwner.initiate(clientOwner)
        );
        //yield call(projectApi.createClientOwner, clientOwner);
      }
      // Check for tags
      if (flow.tags) {
        const tags = yield select(getTags);
        for (let i = 0; i < flow.tags.length; i++) {
          const value = tags.tag_values.find((v) => v.id === flow.tags[i]);
          let obj = {
            node_type: "client",
            node_id: resultClient.id,
            tag_value_id: value.id,
            tag_type_id: value.tag_type_id,
          };

          yield call(projectApi.createTag, obj);
        }
      }

      copy.flowClient = false;

      yield put({ type: appActions.SET_ADD_PROJECT_FLOW, payload: copy });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });

      return;
    } else if (flow.flowProjectContact) {
      let copy = copyObject(flow);
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

      const contact = yield select(getActiveContact);
      const project = yield select(getActiveProject);
      const resultContact = yield call(projectApi.createContact, contact);

      let projectCopy = copyObject(project);

      projectCopy.project_data.project_contact_ids = [resultContact.id];
      yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: projectCopy });

      copy.flowProjectContact = false;
      yield put({ type: appActions.SET_ADD_PROJECT_FLOW, payload: copy });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
      return;
    } else if (flow.flowProjectFacility) {
      let copy = copyObject(flow);
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

      const facility = yield select(getActiveFacility);
      const project = yield select(getActiveProject);
      const address = yield select(getActiveAddress);

      const resultAddress = yield call(
        projectApi.createFacilityAddress,
        address
      );
      let facilityCopy = copyObject(facility);
      facilityCopy.address_id = resultAddress.id;

      const resultFacility = yield call(
        projectApi.createFacility,
        facilityCopy
      );

      let projectCopy = copyObject(project);
      projectCopy.facility_id = resultFacility.id;
      yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: projectCopy });

      copy.flowProjectFacility = false;
      yield put({ type: appActions.SET_ADD_PROJECT_FLOW, payload: copy });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
      return;
    } else if (flow.flowFacilityContact) {
      let copy = copyObject(flow);
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

      const contact = yield select(getActiveContact);
      const project = yield select(getActiveProject);

      const resultContact = yield call(projectApi.createContact, contact);

      let projectCopy = copyObject(project);
      projectCopy.project_data.facility_contact_ids.push(resultContact.id);
      yield put({ type: appActions.SET_ACTIVE_PROJECT, payload: projectCopy });

      copy.flowFacilityContact = false;
      yield put({ type: appActions.SET_ADD_PROJECT_FLOW, payload: copy });
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
      return;
    } else if (flow.flowProjectSaveAndNavigate) {
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

      const project = yield select(getActiveProject);
      const resultProject = yield call(projectApi.createProject, project);
      const resultWorkflow = yield call(
        projectApi.createWorkflow,
        resultProject.id,
        flow.workflowType,
        {}
      );

      const nav = yield select(getNav);
      let newNav = {
        drawerOpen: nav.drawerOpen,
      };

      const navIndex =
        flow.workflowType === "measure" || flow.workflowType === "model"
          ? "measureModel"
          : "monitor";

      newNav.index = navIndex;
      newNav.activeNavId = navIndex;
      newNav.projectId = resultWorkflow.project_id;

      yield put({ type: appActions.SET_NAV, payload: newNav });

      yield put({
        type: appActions.SET_ADD_PROJECT_FLOW,
        payload: { inProgress: false },
      });

      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    } else if (flow.flowCreateWorkflowAndNavigate) {
      let copy = copyObject(flow);
      yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

      const resultWorkflow = yield call(
        projectApi.createWorkflow,
        copy.workflowParams.projectId,
        copy.workflowParams.workflowType,
        copy.workflowParams.workflowData
      );

      yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
      const nav = yield select(getNav);
      let newNav = {
        drawerOpen: nav.drawerOpen,
      };

      const navIndex =
        flow.workflowParams.workflowType === "measure" ||
        flow.workflowParams.workflowType === "model"
          ? "measureModel"
          : "monitor";

      newNav.index = navIndex;
      newNav.activeNavId = navIndex;
      newNav.projectId = resultWorkflow.project_id;

      yield put({
        type: appActions.SET_ADD_PROJECT_FLOW,
        payload: { inProgress: false },
      });

      //copyNav.drawerOpen = false;
      yield put({ type: appActions.SET_NAV, payload: newNav });
    }
  }
}
