import {
  LICENSE_GET_LICENSE_REPORT,
  LICENSE_RECEIVED_GET_LICENSE_REPORT,
  LICENSE_GET_LICENSE_REPORT_LIST,
  LICENSE_RECEIVED_GET_LICENSE_REPORT_LIST,
} from "../actions/license-actions";
import { copyObject } from "../utility";

export default function (
  state = {
    locations: {},
  },
  action
) {
  switch (action.type) {
    case LICENSE_GET_LICENSE_REPORT:
      return addLocation(copyObject(state), action.payload);
    case LICENSE_RECEIVED_GET_LICENSE_REPORT:
      return updateLocation(copyObject(state), action.payload);
    case LICENSE_GET_LICENSE_REPORT_LIST:
      return addLocations(copyObject(state), action.payload);
    case LICENSE_RECEIVED_GET_LICENSE_REPORT_LIST:
      return updateLocations(copyObject(state), action.payload);
    default:
      return state;
  }
}

function addLocation(state, locationId) {
  state.locations[locationId] = [];

  return state;
}

function addLocations(state, locationIds) {
  locationIds.forEach((locationId) => {
    state.locations[locationId] = [];
  });

  return state;
}

function updateLocation(state, data) {
  if (data.length > 0) {
    state.locations[data[0].location_id] = data;
  }

  return state;
}

function updateLocations(state, data) {
  data.forEach((dat) => {
    const index = state.locations[dat.location_id].findIndex(
      (l) => l.asset_serial === dat.asset_serial
    );
    if (index > -1) {
      state.locations[dat.location_id][index] = dat;
    } else {
      state.locations[dat.location_id].push(dat);
    }
  });

  return state;
}
