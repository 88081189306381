import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { legacyFulfill } from "../utils";
import {
  PROJECTS_RECEIVED_CREATE_CLIENT_OWNER,
  PROJECTS_RECEIVED_DELETE_CLIENT_OWNER,
  PROJECTS_RECEIVED_GET_CLIENT_OWNERS,
  PROJECTS_RECEIVED_UPDATE_CLIENT_OWNER,
} from "../../actions/project-actions";

export type ClientOwner = {};

export const clientOwnersApi = (builder: BuilderType) => ({
  getClientOwners: builder.query<ClientOwner, void>({
    query: () => `service/client-owners`,
    providesTags: [projectsCacheTags.CLIENT_OWNERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_CLIENT_OWNERS),
  }),

  createClientOwner: builder.mutation<ClientOwner, any>({
    query: (owner) => ({
      url: `service/client-owners`,
      method: "POST",
      body: owner,
    }),
    invalidatesTags: [projectsCacheTags.CLIENT_OWNERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_CLIENT_OWNER),
  }),

  updateClientOwner: builder.mutation<ClientOwner, any>({
    query: (owner) => ({
      url: `service/client-owners/${owner.id}`,
      method: "PUT",
      body: owner,
    }),
    invalidatesTags: [projectsCacheTags.CLIENT_OWNERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_CLIENT_OWNER),
  }),

  deleteClientOwner: builder.mutation<ClientOwner, number>({
    query: (id) => ({
      url: `service/client-owners/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.CLIENT_OWNERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_CLIENT_OWNER),
  }),
});
