import { call, put, select, take, putResolve } from "redux-saga/effects";
import * as projectActions from "../../actions/project-actions";
import * as appActions from "../../actions";
import {
  getActiveClient,
  getActiveAddress,
  getActiveFacility,
  getClients,
  getTags,
  getNav,
  getNewTeams,
} from "../../reducers/selectors";
import * as projectApi from "../api-projects";
import { copyObject } from "../../utility";
import { projectsApi } from "../../services/projects/projectsApi";

export function* watchCreateClientWithOwner() {
  while (true) {
    const { payload: data } = yield take(
      projectActions.PROJECTS_CREATE_CLIENT_WITH_OWNER
    );

    // Check for client save
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    const client = yield select(getActiveClient);

    const { data: resultClient } = yield putResolve(
      projectsApi.endpoints.createClient.initiate(client)
    );
    // const resultClient = yield call(projectApi.createClient, client);

    const nav = yield select(getNav);

    if (data.createOwner) {
      const clientOwner = {
        client_id: data.baseClientId,
        target_client_id: resultClient.id,
        user_id: data.userId,
      };
      yield put(projectsApi.endpoints.createClientOwner.initiate(clientOwner));
      //yield call(projectApi.createClientOwner, clientOwner);
    }

    // Check for tags
    if (data.tags) {
      const tags = yield select(getTags);
      for (let i = 0; i < data.tags.length; i++) {
        const value = tags.tag_values.find((v) => v.id === data.tags[i]);
        let obj = {
          node_type: "client",
          node_id: resultClient.id,
          tag_value_id: value.id,
          tag_type_id: value.tag_type_id,
        };

        yield call(projectApi.createTag, obj);
      }
    }

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
    yield put({
      type: appActions.SET_NAV,
      payload: {
        drawerOpen: nav.drawerOpen,
        index: resultClient.client_type === "client" ? "customers" : "dealers",
        clientId: resultClient.id,
      },
    });
  }
}

export function* watchUpdateClientWithOwner() {
  while (true) {
    const { payload: data } = yield take(
      projectActions.PROJECTS_UPDATE_CLIENT_WITH_OWNER
    );

    // Check for client save
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    const client = yield select(getActiveClient);

    const resultClient = yield call(projectApi.updateClient, client);

    /*
          updateOwner: !!data.owner_id && currentOwner?.id !== data.owner_id,
      currentOwner: currentOwner ? currentOwner.user_id : 0,
      newOwner: data.owner_id,
      tags: data.tags
     */
    if (data.updateOwner) {
      const teams = yield select(getNewTeams);
      if (data.currentOwner) {
        let clientOwner = teams.client_owners.find(
          (o) =>
            o.user_id === data.currentOwner &&
            o.target_client_id === resultClient.id
        );
        if (clientOwner) {
          clientOwner.user_id = data.newOwner;
          yield call(projectApi.updateClientOwner, clientOwner);
        }
      } else {
        const clientOwner = {
          client_id: data.baseClientId,
          target_client_id: resultClient.id,
          user_id: data.newOwner,
        };
        yield putResolve(
          projectsApi.endpoints.createClientOwner.initiate(clientOwner)
        );
        // yield call(projectApi.createClientOwner, clientOwner);
      }
    }
    // Check for tags
    if (data.tags) {
      const tags = yield select(getTags);
      const currentTags = tags.tags.filter(
        (t) => t.node_type === "client" && t.node_id === resultClient.id
      );
      for (let i = 0; i < data.tags.length; i++) {
        const find = currentTags.find((t) => t.tag_value_id === data.tags[i]);
        if (!find) {
          const value = tags.tag_values.find((t) => t.id === data.tags[i]);
          yield call(projectApi.createTag, {
            tag_type_id: value.tag_type_id,
            tag_value_id: value.id,
            node_type: "client",
            node_id: resultClient.id,
          });
        }
      }

      // Check for deleted tags
      for (let i = 0; i < currentTags.length; i++) {
        if (!data.tags.includes(currentTags[i].tag_value_id)) {
          yield call(projectApi.deleteTag, currentTags[i].id);
        }
      }
    }

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateFacilityWithAddress() {
  while (true) {
    yield take(projectActions.PROJECTS_CREATE_FACILITY_WITH_ADDRESS);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    const facility = yield select(getActiveFacility);
    const address = yield select(getActiveAddress);

    // First create the facility
    const { data: resultFacility } = yield putResolve(
      projectsApi.endpoints.createFacility.initiate(facility)
    );

    // Create the address
    const { data: resultAddress } = yield putResolve(
      projectsApi.endpoints.createFacilityAddress.initiate(address)
    );
    //const resultAddress = yield call(projectApi.createFacilityAddress, address);

    // Update the facility with the address ID
    yield put(
      projectsApi.endpoints.updateFacility.initiate({
        ...resultFacility,
        address_id: resultAddress.id,
      })
    );
    /*  projectApi.updateFacility, {
      ...resultFacility,
      address_id: resultAddress.id,
    });
  */
    yield call(updateActiveClient, resultFacility.client_id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateContactWithFacility() {
  while (true) {
    const {
      payload: { contact, facilityArray },
    } = yield take(projectActions.PROJECTS_CREATE_CONTACT_WITH_FACILITY);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    // First create the contact
    const { data: resultContact } = yield putResolve(
      projectsApi.endpoints.createContact.initiate(contact)
    );
    // const resultContact = yield call(projectApi.createContact, contact);

    // Update any facilities
    if (facilityArray.length > 0) {
      const clients = yield select(getClients);
      const client = clients.rawData.find(
        (c) => c.id === resultContact.client_id
      );
      if (client) {
        for (let i = 0; i < facilityArray.length; i++) {
          const facility = client.facilities.find(
            (f) => f.id === facilityArray[i]
          );
          if (facility) {
            let copy = copyObject(facility);
            copy.facility_contacts.push(resultContact.id);
            yield put(projectsApi.endpoints.updateFacility.initiate(copy));
            // yield call(projectApi.updateFacility, copy);
          }
        }
      }
    }

    yield call(updateActiveClient, resultContact.client_id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateContactWithFacility() {
  while (true) {
    const {
      payload: { contact, facilityArray },
    } = yield take(projectActions.PROJECTS_UPDATE_CONTACT_WITH_FACILITY);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    // First update the contact
    const { data: resultContact } = yield putResolve(
      projectsApi.endpoints.updateContact.initiate(contact)
    );
    // const resultContact = yield call(projectApi.updateContact, contact);

    // Update any facilities
    if (facilityArray.length > 0) {
      const clients = yield select(getClients);
      const client = clients.rawData.find(
        (c) => c.id === resultContact.client_id
      );
      if (client) {
        for (let i = 0; i < facilityArray.length; i++) {
          const facility = client.facilities.find(
            (f) => f.id === facilityArray[i]
          );
          if (
            facility &&
            !facility.facility_contacts.includes[facilityArray[i]]
          ) {
            let copy = copyObject(facility);
            copy.facility_contacts.push(resultContact.id);
            yield put(projectsApi.endpoints.updateFacility.initiate(copy));
            // yield call(projectApi.updateFacility, copy);
          }
        }

        // Now check for facilities where this contact has been removed
        for (let i = 0; i < client.facilities.length; i++) {
          if (
            client.facilities[i].facility_contacts.includes(resultContact.id) &&
            !facilityArray.includes(client.facilities[i].id)
          ) {
            let copy = copyObject(client.facilities[i]);
            for (let j = 0; j < copy.facility_contacts.length; j++) {
              if (copy.facility_contacts[i] === resultContact.id) {
                copy.facility_contacts.splice(i, 1);
              }
            }
            yield put(projectsApi.endpoints.updateFacility.initiate(copy));
            // yield call(projectApi.updateFacility, copy);
          }
        }
      }
    }

    yield call(updateActiveClient, resultContact.client_id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteContactWithFacility() {
  while (true) {
    const {
      payload: { contactId },
    } = yield take(projectActions.PROJECTS_DELETE_CONTACT_WITH_FACILITY);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    // First delete the contact
    const { data: resultContact } = yield putResolve(
      projectsApi.endpoints.deleteContact.initiate(contactId)
    );
    // const resultContact = yield call(projectApi.deleteContact, contactId);

    // Update any facilities and remove this contact
    const clients = yield select(getClients);
    const client = clients.rawData.find(
      (c) => c.id === resultContact.client_id
    );
    if (client) {
      for (let i = 0; i < client.facility_contacts.length; i++) {
        const facility = client.facilities[i];
        if (facility && facility.facility_contacts.includes[resultContact.id]) {
          let copy = copyObject(facility);
          for (let j = 0; j < copy.facility_contacts.length; j++) {
            if (copy.facility_contacts[i] === resultContact.id) {
              copy.facility_contacts.splice(i, 1);
            }
          }
          yield put(projectsApi.endpoints.updateFacility.initiate(copy));
          // yield call(projectApi.updateFacility, copy);
        }
      }

      yield call(updateActiveClient, client.id);
    }

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateFacilityWithAddress() {
  while (true) {
    const { payload: data } = yield take(
      projectActions.PROJECTS_UPDATE_FACILITY_WITH_ADDRESS
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    const facility = yield select(getActiveFacility);
    const address = yield select(getActiveAddress);

    // First update the facility
    const { data: resultFacility } = yield putResolve(
      projectsApi.endpoints.updateFacility.initiate(facility)
    );
    // const resultFacility = yield call(projectApi.updateFacility, facility);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    // update the address
    if (data.createAddress) {
      const resultAddress = yield call(
        projectApi.createFacilityAddress,
        address
      );
      yield put(
        projectsApi.endpoints.updateFacility.initiate({
          ...resultFacility,
          address_id: resultAddress.id,
        })
      );
      /* yield call(projectApi.updateFacility, {
        ...resultFacility,
        address_id: resultAddress.id,
      }); */
    } else {
      yield put(projectsApi.endpoints.updateFacilityAddress.initiate(address));
      //yield call(projectApi.updateFacilityAddress, address);
    }

    yield call(updateActiveClient, resultFacility.client_id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateAddress() {
  while (true) {
    const { payload: address } = yield take(
      projectActions.PROJECTS_UPDATE_ADDRESS
    );

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield put(projectsApi.endpoints.updateFacilityAddress.initiate(address));
    // yield call(projectApi.updateFacilityAddress, address);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateLocationWithFacility() {
  while (true) {
    const {
      payload: { clientId, location, facility, address },
    } = yield take(projectActions.PROJECTS_CREATE_LOCATION_WITH_FACILITY);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    // First create the location
    const { data: createdLocation } = yield putResolve(
      projectsApi.endpoints.createClient.initiate(location)
    );
    //const createdLocation = yield call(projectApi.createClient, location);

    // Now add the address
    const { data: createdAddress } = yield putResolve(
      projectsApi.endpoints.createFacilityAddress.initiate({
        ...address,
        client_id: clientId,
      })
    );
    /* const createdAddress = yield call(projectApi.createFacilityAddress, {
      ...address,
      client_id: clientId,
    }); */

    // Now create a facility
    yield putResolve(
      projectsApi.endpoints.createFacility.initiate({
        ...facility,
        client_id: clientId,
        address_id: createdAddress.id,
        portal_location_account_id: createdLocation.portal_account_id,
      })
    );
    /* const createdFacility = yield call(projectApi.createFacility, {
      ...facility,
      client_id: clientId,
      address_id: createdAddress.id,
      portal_location_account_id: createdLocation.portal_account_id,
    }); */

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

function* updateActiveClient(id) {
  const clients = yield select(getClients);
  const curActive = yield select(getActiveClient);
  if (curActive.id === id) {
    const client = clients.rawData.find((c) => c.id === id);
    if (client) {
      yield put({ type: appActions.SET_ACTIVE_CLIENT, payload: client });
    }
  }
}
