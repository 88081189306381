import * as configActions from "./config-actions";
import * as licenseActions from "./license-actions";
import * as portalActions from "./portal-actions";
import * as projectActions from "./project-actions";

// App Actions
export const LOGOUT_USER = "logout_user";
export const SET_NAV = "set_nav";
export const SET_DIMENSIONS = "set_dimensions";
export const SHOW_ALERT = "show_alert";
export const HIDE_ALERT = "hide_alert";
export const SET_DATA_REQUESTED = "set_data_requested";
export const SET_DATA_RECEIVED = "set_data_received";
export const CLEAR_DATA_REQUESTED = "clear_data_requested";
export const CLEAR_ALL_UPDATES = "clear_all_updates";
export const SET_APP_STATE = "set_app_state";

export const SET_ACTIVE_CLIENT = "set_active_client";
export const SET_ACTIVE_PROJECT = "set_active_project";
export const SET_ACTIVE_CONTACT = "set_active_contact";
export const SET_ACTIVE_FACILITY = "set_active_facility";
export const SET_ACTIVE_VEHICLE = "set_active_vehicle";
export const SET_ACTIVE_SCHEDULE = "set_active_schedule";
export const SET_ACTIVE_SCHEDULE_GROUP = "set_active_schedule_group";
export const SET_ACTIVE_LOGGER_ASSIGNMENT = "set_active_logger_assignment";
export const SET_ACTIVE_FLEET = "set_active_fleet";
export const SET_ACTIVE_RESOURCE = "set_active_resource";
export const SET_ACTIVE_ADDRESS = "set_active_address";

export const SET_ADD_PROJECT_FLOW = "set_add_project_flow";

export const SET_MEASURE_WORKFLOW = "set_measure_workflow";

export const SET_MODEL_WORKFLOW = "set_model_workflow";

// State Manipulation Actions

// Action Helper
function action(type, payload = {}) {
  return { type, payload };
}

// State Action Functions
export const authenticate = (auth) =>
  action(portalActions.PORTAL_AUTHENTICATE, auth);
export const logoutUser = () => action(LOGOUT_USER, {});
export const setNav = (nav) => action(SET_NAV, nav);
export const setDimensions = (dimensions) => action(SET_DIMENSIONS, dimensions);
export const setDataRequested = (key) => action(SET_DATA_REQUESTED, key);
export const setDataReceived = (key) => action(SET_DATA_RECEIVED, key);
export const showAlert = (alert) => action(SHOW_ALERT, alert);
export const hideAlert = () => action(HIDE_ALERT, {});
export const setAppState = (state) => action(SET_APP_STATE, state);
export const setActiveResource = (resource) =>
  action(SET_ACTIVE_RESOURCE, resource);

export const setActiveClient = (client) => action(SET_ACTIVE_CLIENT, client);
export const createClient = (client) =>
  action(projectActions.PROJECTS_CREATE_CLIENT, client);
export const updateClient = (client) =>
  action(projectActions.PROJECTS_UPDATE_CLIENT, client);
export const deleteClient = (id) =>
  action(projectActions.PROJECTS_DELETE_CLIENT, id);
export const getClients = () => action(projectActions.PROJECTS_GET_CLIENTS);

export const setActiveContact = (contact) =>
  action(SET_ACTIVE_CONTACT, contact);
export const createContact = (contact) =>
  action(projectActions.PROJECTS_CREATE_CONTACT, contact);
export const createFacilityContact = (contact) =>
  action(projectActions.PROJECTS_CREATE_FACILITY_CONTACT, contact);
export const createWorkflowContact = (contact) =>
  action(projectActions.PROJECTS_CREATE_WORKFLOW_CONTACT, contact);

export const setActiveAddress = (address) =>
  action(SET_ACTIVE_ADDRESS, address);
export const createFacilityAddress = (address) =>
  action(projectActions.PROJECTS_CREATE_FACILITY_ADDRESS, address);
export const updateContact = (contact) =>
  action(projectActions.PROJECTS_UPDATE_CONTACT, contact);
export const deleteContact = (id) =>
  action(projectActions.PROJECTS_DELETE_CONTACT, id);
export const updateAddress = (address) =>
  action(projectActions.PROJECTS_UPDATE_ADDRESS, address);

export const setActiveProject = (project) =>
  action(SET_ACTIVE_PROJECT, project);
export const createProject = (project) =>
  action(projectActions.PROJECTS_CREATE_PROJECT, project);
export const updateProject = (project) =>
  action(projectActions.PROJECTS_UPDATE_PROJECT, project);
export const deleteProject = (id) =>
  action(projectActions.PROJECTS_DELETE_PROJECT, id);

export const setActiveFacility = (facility) =>
  action(SET_ACTIVE_FACILITY, facility);
export const createFacility = (facility) =>
  action(projectActions.PROJECTS_CREATE_FACILITY, facility);
export const updateFacility = (facility) =>
  action(projectActions.PROJECTS_UPDATE_FACILITY, facility);
export const deleteFacility = (id) =>
  action(projectActions.PROJECTS_DELETE_FACILITY, id);

export const setActiveVehicle = (vehicle) =>
  action(SET_ACTIVE_VEHICLE, vehicle);
export const createVehicle = (vehicle) =>
  action(projectActions.PROJECTS_CREATE_VEHICLE, vehicle);
export const updateVehicle = (vehicle) =>
  action(projectActions.PROJECTS_UPDATE_VEHICLE, vehicle);
export const deleteVehicle = (id) =>
  action(projectActions.PROJECTS_DELETE_VEHICLE, id);

export const setActiveSchedule = (schedule) =>
  action(SET_ACTIVE_SCHEDULE, schedule);
export const createSchedule = (schedule) =>
  action(projectActions.PROJECTS_CREATE_SCHEDULE, schedule);
export const updateSchedule = (schedule) =>
  action(projectActions.PROJECTS_UPDATE_SCHEDULE, schedule);
export const deleteSchedule = (id) =>
  action(projectActions.PROJECTS_DELETE_SCHEDULE, id);

export const setActiveScheduleGroup = (scheduleGroup) =>
  action(SET_ACTIVE_SCHEDULE_GROUP, scheduleGroup);
export const createScheduleGroup = (scheduleGroup) =>
  action(projectActions.PROJECTS_CREATE_SCHEDULE_GROUP, scheduleGroup);
export const updateScheduleGroup = (scheduleGroup) =>
  action(projectActions.PROJECTS_UPDATE_SCHEDULE_GROUP, scheduleGroup);
export const deleteScheduleGroup = (id) =>
  action(projectActions.PROJECTS_DELETE_SCHEDULE_GROUP, id);

export const setActiveLoggerAssignment = (assignment) =>
  action(SET_ACTIVE_LOGGER_ASSIGNMENT, assignment);
export const createLoggerAssignment = (assignment) =>
  action(projectActions.PROJECTS_CREATE_LOGGER_ASSIGNMENT, assignment);
export const updateLoggerAssignment = (assignment) =>
  action(projectActions.PROJECTS_UPDATE_LOGGER_ASSIGNMENT, assignment);
export const deleteLoggerAssignment = (keys) =>
  action(projectActions.PROJECTS_DELETE_LOGGER_ASSIGNMENT, keys);

// to be deprecated
export const setAddProjectFlow = (flow) => action(SET_ADD_PROJECT_FLOW, flow);
export const setMeasureWorkflow = (flow) => action(SET_MEASURE_WORKFLOW, flow);
export const setModelWorkflow = (flow) => action(SET_MODEL_WORKFLOW, flow);

export const getOperationsSummary = (projectId, vehicleId) =>
  action(projectActions.PROJECTS_GET_OPERATIONS_SUMMARY, {
    projectId,
    vehicleId,
  });
export const getOperationsDetail = (projectId, vehicleId, date) =>
  action(projectActions.PROJECTS_GET_OPERATIONS_DETAIL, {
    projectId,
    vehicleId,
    date,
  });

export const createWorkflow = (projectId, workflowType, workflowData) =>
  action(projectActions.PROJECTS_CREATE_WORKFLOW, {
    projectId,
    workflowType,
    workflowData,
  });

export const createBatteryModel = (battery) =>
  action(projectActions.PROJECTS_CREATE_BATTERY, battery);
export const updateBatteryModel = (battery) =>
  action(projectActions.PROJECTS_UPDATE_BATTERY, battery);
export const deleteBatteryModel = (id) =>
  action(projectActions.PROJECTS_DELETE_BATTERY, id);

export const createChargerModel = (charger) =>
  action(projectActions.PROJECTS_CREATE_CHARGER, charger);
export const updateChargerModel = (charger) =>
  action(projectActions.PROJECTS_UPDATE_CHARGER, charger);
export const deleteChargerModel = (id) =>
  action(projectActions.PROJECTS_DELETE_CHARGER, id);

export const createTeam = (team) =>
  action(projectActions.PROJECTS_CREATE_TEAM, team);
export const updateTeam = (team) =>
  action(projectActions.PROJECTS_UPDATE_TEAM, team);
export const deleteTeam = (id) =>
  action(projectActions.PROJECTS_DELETE_TEAM, id);

export const createTask = (task) =>
  action(projectActions.PROJECTS_CREATE_TASK, task);
export const updateTask = (task) =>
  action(projectActions.PROJECTS_UPDATE_TASK, task);
export const completeTask = (id) =>
  action(projectActions.PROJECTS_COMPLETE_TASK, id);

export const setActiveFleet = (fleet) => action(SET_ACTIVE_FLEET, fleet);
export const getFleets = () => action(projectActions.PROJECTS_GET_FLEETS, {});
export const createFleet = (fleet) =>
  action(projectActions.PROJECTS_CREATE_FLEET, fleet);
export const getFleetData = (id) =>
  action(projectActions.PROJECTS_GET_FLEET_DATA, id);

export const getFleetZones = (fleetId) =>
  action(projectActions.PROJECTS_GET_FLEET_ZONES, fleetId);
export const createFleetZone = (fleetId, zone) =>
  action(projectActions.PROJECTS_CREATE_FLEET_ZONE, { fleetId, zone });
export const updateFleetZone = (fleetId, zone) =>
  action(projectActions.PROJECTS_UPDATE_FLEET_ZONE, { fleetId, zone });
export const deleteFleetZone = (fleetId, zoneId) =>
  action(projectActions.PROJECTS_DELETE_FLEET_ZONE, {
    fleetId,
    zoneId,
  });

export const getFleetAssetTemplates = (fleetId) =>
  action(projectActions.PROJECTS_GET_FLEET_ASSET_TEMPLATES, fleetId);
export const createFleetAssetTemplate = (fleetId, assetTemplate) =>
  action(projectActions.PROJECTS_CREATE_FLEET_ASSET_TEMPLATE, {
    fleetId,
    assetTemplate,
  });
export const updateFleetAssetTemplate = (fleetId, assetTemplate) =>
  action(projectActions.PROJECTS_UPDATE_FLEET_ASSET_TEMPLATE, {
    fleetId,
    assetTemplate,
  });
export const deleteFleetAssetTemplate = (fleetId, assetTemplateId) =>
  action(projectActions.PROJECTS_DELETE_FLEET_ASSET_TEMPLATE, {
    fleetId,
    assetTemplateId,
  });

export const getFleetAssociationTemplates = (fleetId) =>
  action(projectActions.PROJECTS_GET_FLEET_ASSOCIATION_TEMPLATES, fleetId);
export const createFleetAssociationTemplate = (fleetId, associationTemplate) =>
  action(projectActions.PROJECTS_CREATE_FLEET_ASSOCIATION_TEMPLATE, {
    fleetId,
    associationTemplate,
  });
export const updateFleetAssociationTemplate = (fleetId, associationTemplate) =>
  action(projectActions.PROJECTS_UPDATE_FLEET_ASSOCIATION_TEMPLATE, {
    fleetId,
    associationTemplate,
  });
export const deleteFleetAssociationTemplate = (
  fleetId,
  associationTemplateId
) =>
  action(projectActions.PROJECTS_DELETE_FLEET_ASSOCIATION_TEMPLATE, {
    fleetId,
    associationTemplateId,
  });

export const getFleetAssociations = (fleetId) =>
  action(projectActions.PROJECTS_GET_FLEET_ASSOCIATIONS, fleetId);
export const createFleetAssociation = (fleetId, association) =>
  action(projectActions.PROJECTS_CREATE_FLEET_ASSOCIATION, {
    fleetId,
    association,
  });
export const updateFleetAssociation = (fleetId, association) =>
  action(projectActions.PROJECTS_UPDATE_FLEET_ASSOCIATION, {
    fleetId,
    association,
  });
export const deleteFleetAssociation = (fleetId, associationId) =>
  action(projectActions.PROJECTS_DELETE_FLEET_ASSOCIATION, {
    fleetId,
    associationId,
  });

export const getFleetAssets = (fleetId) =>
  action(projectActions.PROJECTS_GET_FLEET_ASSETS, fleetId);
export const createFleetAsset = (fleetId, asset) =>
  action(projectActions.PROJECTS_CREATE_FLEET_ASSET, {
    fleetId,
    asset,
  });
export const updateFleetAsset = (fleetId, asset) =>
  action(projectActions.PROJECTS_UPDATE_FLEET_ASSET, {
    fleetId,
    asset,
  });
export const retireFleetAsset = (fleetId, assetId, date) =>
  action(projectActions.PROJECTS_RETIRE_FLEET_ASSET, {
    fleetId,
    assetId,
    date,
  });
export const deleteFleetAsset = (fleetId, assetId) =>
  action(projectActions.PROJECTS_DELETE_FLEET_ASSET, {
    fleetId,
    assetId,
  });

export const getTelemetryAssociations = (fleetId) =>
  action(projectActions.PROJECTS_GET_TELEMETRY_ASSOCIATIONS, fleetId);
export const createTelemetryAssociation = (fleetId, association) =>
  action(projectActions.PROJECTS_CREATE_TELEMETRY_ASSOCIATION, {
    fleetId,
    association,
  });
export const updateTelemetryAssociation = (fleetId, association) =>
  action(projectActions.PROJECTS_UPDATE_TELEMETRY_ASSOCIATION, {
    fleetId,
    association,
  });
export const endTelemetryAssociation = (fleetId, associationId, date) =>
  action(projectActions.PROJECTS_END_TELEMETRY_ASSOCIATION, {
    fleetId,
    associationId,
    date,
  });
export const deleteTelemetryAssociation = (fleetId, associationId) =>
  action(projectActions.PROJECTS_DELETE_TELEMETRY_ASSOCIATION, {
    fleetId,
    associationId,
  });

export const getAlertConfigurations = () =>
  action(projectActions.PROJECTS_GET_ALERT_CONFIGURATIONS, {});
export const createAlertConfiguration = (config) =>
  action(projectActions.PROJECTS_CREATE_ALERT_CONFIGURATION, config);
export const updateAlertConfiguration = (config) =>
  action(projectActions.PROJECTS_UPDATE_ALERT_CONFIGURATION, config);

export const uploadFleet = (fleetId, fleetData) =>
  action(projectActions.PROJECTS_BULK_UPLOAD_FLEET, {
    fleetId,
    fleetData,
  });
export const generateMeasureReport = () =>
  action(projectActions.PROJECTS_GENERATE_MEASURE_REPORT, {});

export const createNote = (note) =>
  action(projectActions.PROJECTS_CREATE_NOTE, note);
export const updateNote = (note) =>
  action(projectActions.PROJECTS_UPDATE_NOTE, note);
export const deleteNote = (id) =>
  action(projectActions.PROJECTS_DELETE_NOTE, id);

export const checkModelVehicleForPowerStudy = () =>
  action(projectActions.PROJECTS_MODEL_CHECK_FOR_POWER_STUDY, {});
export const getModelDetailData = (projectId, vehicleId, date) =>
  action(projectActions.PROJECTS_MODEL_GET_DETAIL_DATA, {
    projectId,
    vehicleId,
    date,
  });
export const createModelVehicleWithScheduleGroup = () =>
  action(projectActions.PROJECTS_MODEL_CREATE_VEHICLE_WITH_SCHEDULE_GROUP, {});
export const updateModelScheduleGroup = () =>
  action(projectActions.PROJECTS_MODEL_UPDATE_SCHEDULE_GROUP, {});

export const getDeviceConfig = (device) =>
  action(configActions.CONFIG_GET_DEVICE_CONFIG, device);
export const getChargerConfigs = (list) =>
  action(configActions.CONFIG_GET_CHARGER_CONFIGS, { ids: list });

export const updateChargerFactorSetting = (chargerSerial, settings) =>
  action(configActions.CONFIG_UPDATE_CHARGER_FACTORY, {
    chargerSerial,
    settings,
  });
export const updateChargerUserSetting = (chargerSerial, settings) =>
  action(configActions.CONFIG_UPDATE_CHARGER_USER, {
    chargerSerial,
    settings,
  });
export const updateChargerSettings = (chargerSerial, settings) =>
  action(configActions.CONFIG_UPDATE_CHARGER_SETTINGS, {
    chargerSerial,
    settings,
  });

export const uploadVersionFile = (file) =>
  action(configActions.CONFIG_UPLOAD_VERSION_FILE, { file });
export const downloadVersionFile = (version) =>
  action(configActions.CONFIG_DOWNLOAD_VERSION_FILE, { version });
export const deleteVersionMeta = (versionId) =>
  action(configActions.CONFIG_DELETE_VERSIONS_META, { versionId });

export const updateTeamStructure = (structure, structureId) =>
  action(projectActions.PROJECTS_UPDATE_TEAM_STRUCTURE, {
    ...structure,
    structureId,
  });

export const getEncryptedChargerPackage = (chargerSerial) =>
  action(configActions.CONFIG_GET_CHARGER_ENCRYPTED_PACKAGE, { chargerSerial });

export const updateClientGoals = ({ newGoals, updatedGoals, deletedGoals }) =>
  action(projectActions.PROJECTS_UPDATE_CLIENT_GOALS, {
    newGoals,
    updatedGoals,
    deletedGoals,
  });

export const createClientWithOwner = (payload) =>
  action(projectActions.PROJECTS_CREATE_CLIENT_WITH_OWNER, payload);
export const updateClientWithOwner = (payload) =>
  action(projectActions.PROJECTS_UPDATE_CLIENT_WITH_OWNER, payload);
export const createFacilityWithAddress = (payload) =>
  action(projectActions.PROJECTS_CREATE_FACILITY_WITH_ADDRESS, payload);
export const updateFacilityWithAddress = (payload) =>
  action(projectActions.PROJECTS_UPDATE_FACILITY_WITH_ADDRESS, payload);

export const updateVehicleArray = (payload) =>
  action(projectActions.PROJECTS_UPDATE_VEHICLE_ARRAY, payload);

export const createTagType = (payload) =>
  action(projectActions.PROJECTS_CREATE_TAG_TYPE, payload);
export const updateTagType = (payload) =>
  action(projectActions.PROJECTS_UPDATE_TAG_TYPE, payload);
export const deleteTagType = (payload) =>
  action(projectActions.PROJECTS_DELETE_TAG_TYPE, payload);

export const createTagValue = (payload) =>
  action(projectActions.PROJECTS_CREATE_TAG_VALUE, payload);
export const updateTagValue = (payload) =>
  action(projectActions.PROJECTS_UPDATE_TAG_VALUE, payload);
export const deleteTagValue = (payload) =>
  action(projectActions.PROJECTS_DELETE_TAG_VALUE, payload);

export const createTag = (payload) =>
  action(projectActions.PROJECTS_CREATE_TAG, payload);
export const deleteTag = (payload) =>
  action(projectActions.PROJECTS_DELETE_TAG, payload);

export const doBatchTags = (payload) =>
  action(projectActions.PROJECTS_DO_BATCH_TAGS, payload);

export const createContactWithFacility = (payload) =>
  action(projectActions.PROJECTS_CREATE_CONTACT_WITH_FACILITY, payload);
export const updateContactWithFacility = (payload) =>
  action(projectActions.PROJECTS_UPDATE_CONTACT_WITH_FACILITY, payload);
export const deleteContactWithFacility = (payload) =>
  action(projectActions.PROJECTS_DELETE_CONTACT_WITH_FACILITY, payload);

export const getChargerConfig = (id) =>
  action(configActions.CONFIG_GET_CHARGER_CONFIG, id);

export const getLoggerMostRecent = (id) =>
  action(projectActions.PROJECTS_GET_LOGGER_MOST_RECENT, id);

export const getGatewayConfigs = (list) =>
  action(configActions.CONFIG_GET_GATEWAY_CONFIGS, { ids: list });
export const getGatewayConfig = (id) =>
  action(configActions.CONFIG_GET_GATEWAY_CONFIG, id);
export const updateGatewayFactorSetting = (gatewaySerial, settings) =>
  action(configActions.CONFIG_UPDATE_GATEWAY_FACTORY, {
    gatewaySerial,
    settings,
  });
export const updateGatewayUserSetting = (gatewaySerial, settings) =>
  action(configActions.CONFIG_UPDATE_GATEWAY_USER, {
    gatewaySerial,
    settings,
  });
export const updateGatewaySettings = (gatewaySerial, settings) =>
  action(configActions.CONFIG_UPDATE_GATEWAY_SETTINGS, {
    gatewaySerial,
    settings,
  });

export const getBatteryConfigs = (list) =>
  action(configActions.CONFIG_GET_BATTERY_CONFIGS, { ids: list });
export const getBatteryConfig = (id) =>
  action(configActions.CONFIG_GET_BATTERY_CONFIG, id);
export const updateBatteryFactorSetting = (batterySerial, settings) =>
  action(configActions.CONFIG_UPDATE_BATTERY_FACTORY, {
    batterySerial,
    settings,
  });
export const updateBatteryUserSetting = (batterySerial, settings) =>
  action(configActions.CONFIG_UPDATE_BATTERY_USER, {
    batterySerial,
    settings,
  });
export const updateBatterySettings = (batterySerial, settings) =>
  action(configActions.CONFIG_UPDATE_BATTERY_SETTINGS, {
    batterySerial,
    settings,
  });
export const createAssetAndConfiguration = (asset, settings) =>
  action(configActions.CONFIG_CREATE_ASSET_AND_CONFIGURATION, {
    asset,
    settings,
  });

export const getVanadiumBESSConfigs = (list) =>
  action(configActions.CONFIG_GET_VANADIUM_BESS_CONFIGS, { ids: list });
export const getVanadiumBESSConfig = (id) =>
  action(configActions.CONFIG_GET_VANADIUM_BESS_CONFIG, id);
export const updateVanadiumBESSFactorySetting = (serial, settings) =>
  action(configActions.CONFIG_UPDATE_VANADIUM_BESS_FACTORY, {
    serial,
    settings,
  });
export const updateVanadiumBESSUserSetting = (serial, settings) =>
  action(configActions.CONFIG_UPDATE_VANADIUM_BESS_USER, {
    serial,
    settings,
  });
export const updateVanadiumBESSSettings = (serial, settings) =>
  action(configActions.CONFIG_UPDATE_VANADIUM_BESS_SETTINGS, {
    serial,
    settings,
  });

export const getLeadAcidBESSConfigs = (list) =>
  action(configActions.CONFIG_GET_LEADACID_BESS_CONFIGS, { ids: list });
export const getLeadAcidBESSConfig = (id) =>
  action(configActions.CONFIG_GET_LEADACID_BESS_CONFIG, id);
export const updateLeadAcidBESSFactorySetting = (serial, settings) =>
  action(configActions.CONFIG_UPDATE_LEADACID_BESS_FACTORY, {
    serial,
    settings,
  });
export const updateLeadAcidBESSUserSetting = (serial, settings) =>
  action(configActions.CONFIG_UPDATE_LEADACID_BESS_USER, {
    serial,
    settings,
  });
export const updateLeadAcidBESSSettings = (serial, settings) =>
  action(configActions.CONFIG_UPDATE_LEADACID_BESS_SETTINGS, {
    serial,
    settings,
  });

export const createNewTeam = (team) =>
  action(projectActions.PROJECTS_CREATE_NEW_TEAM, team);
export const updateNewTeam = (team) =>
  action(projectActions.PROJECTS_UPDATE_NEW_TEAM, team);
export const deleteNewTeam = (id) =>
  action(projectActions.PROJECTS_DELETE_NEW_TEAM, id);

export const createNewTeamResource = (resource) =>
  action(projectActions.PROJECTS_CREATE_NEW_TEAM_RESOURCE, resource);
export const deleteNewTeamResource = (id) =>
  action(projectActions.PROJECTS_DELETE_NEW_TEAM_RESOURCE, id);

export const createUserInterface = (userInterface) =>
  action(projectActions.PROJECTS_CREATE_USER_INTERFACE, userInterface);
export const updateUserInterface = (userInterface) =>
  action(projectActions.PROJECTS_UPDATE_USER_INTERFACE, userInterface);

export const createUserDashboard = (userDashboard) =>
  action(projectActions.PROJECTS_CREATE_USER_DASHBOARD, userDashboard);
export const updateUserDashboards = (update) =>
  action(projectActions.PROJECTS_UPDATE_USER_DASHBOARDS, update);
export const deleteUserDashboard = (id) =>
  action(projectActions.PROJECTS_DELETE_USER_DASHBOARD, id);

export const createClientInterface = (clientInterface) =>
  action(projectActions.PROJECTS_CREATE_CLIENT_INTERFACE, clientInterface);
export const deleteClientInterface = (clientInterfaceId) =>
  action(projectActions.PROJECTS_DELETE_CLIENT_INTERFACE, clientInterfaceId);

export const createClientOwner = (clientOwner) =>
  action(projectActions.PROJECTS_CREATE_CLIENT_OWNER, clientOwner);
export const updateClientOwner = (clientOwner) =>
  action(projectActions.PROJECTS_UPDATE_CLIENT_OWNER, clientOwner);
export const deleteClientOwner = (id) =>
  action(projectActions.PROJECTS_DELETE_CLIENT_OWNER, id);

export const createCustomerGroup = (customerGroup) =>
  action(projectActions.PROJECTS_CREATE_CUSTOMER_GROUP, customerGroup);
export const updateCustomerGroup = (customerGroup) =>
  action(projectActions.PROJECTS_UPDATE_CUSTOMER_GROUP, customerGroup);
export const deleteCustomerGroup = (id) =>
  action(projectActions.PROJECTS_DELETE_CUSTOMER_GROUP, id);

export const createCustomerGroupMember = (customerGroupMember) =>
  action(
    projectActions.PROJECTS_CREATE_CUSTOMER_GROUP_MEMBER,
    customerGroupMember
  );
export const deleteCustomerGroupMember = (id) =>
  action(projectActions.PROJECTS_DELETE_CUSTOMER_GROUP_MEMBER, id);

export const createThirdPartyUser = (thirdPartyUser) =>
  action(projectActions.PROJECTS_CREATE_THIRD_PARTY_USER, thirdPartyUser);
export const updateThirdPartyUser = (thirdPartyUser) =>
  action(projectActions.PROJECTS_UPDATE_THIRD_PARTY_USER, thirdPartyUser);
export const deleteThirdPartyUser = (id) =>
  action(projectActions.PROJECTS_DELETE_THIRD_PARTY_USER, id);

export const sendPasswordResetEmail = (email) =>
  action(projectActions.PROJECTS_SEND_PASSWORD_RESET_EMAIL, email);

export const ignoreAMAssetList = (ids) =>
  action(projectActions.PROJECTS_IGNORE_AM_ASSET_LIST, ids);
export const unIgnoreAMAssetList = (ids) =>
  action(projectActions.PROJECTS_UN_IGNORE_AM_ASSET_LIST, ids);

export const clearAMUnexpectedAssetList = (ids) =>
  action(projectActions.PROJECTS_CLEAR_AM_UNEXPECTED_ASSET_LIST, ids);
export const createLocationWithFacility = (
  clientId,
  location,
  facility,
  address
) =>
  action(projectActions.PROJECTS_CREATE_LOCATION_WITH_FACILITY, {
    clientId,
    location,
    facility,
    address,
  });

export const consumeLicenses = (licenses) =>
  action(licenseActions.LICENSE_CONSUME_LICENSE, { licenses });

export const getLicenseReport = (locationId) =>
  action(licenseActions.LICENSE_GET_LICENSE_REPORT, locationId);
export const getLicenseReportList = (list) =>
  action(licenseActions.LICENSE_GET_LICENSE_REPORT_LIST, list);

export const updateLicenses = (licenses) =>
  action(licenseActions.LICENSE_UPDATE_LICENSES, licenses);
