import createIndexedDBStorage from "redux-persist-indexeddb-storage";
import { persistCache } from "apollo3-cache-persist";
import { store } from "./store";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ApolloClient } from "@apollo/client";

const FLEET_ANALYSIS_URL = import.meta.env.VITE_APP_FLEET_ANALYSIS_SERVICE;

const storage = createIndexedDBStorage({
  name: "stryten",
  storeName: "apolloStore",
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

const link = new HttpLink({
  uri: FLEET_ANALYSIS_URL,
});

const authLink = setContext((_, { headers }) => {
  const auth = store.getState().authenticate;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: auth.auth_token ? `Bearer ${auth.auth_token}` : "",
    },
  };
});

export const setupApolloStrytenClient = async () => {
  await persistCache({
    cache,
    storage: storage,
  });

  return new ApolloClient({
    cache,
    link: authLink.concat(link),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
};
