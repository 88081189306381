import {
    PORTAL_RECEIVED_GET_POLICY_DOCUMENT,
} from '../actions/portal-actions';

export default function (state = {}, action) {

    switch(action.type) {
        case PORTAL_RECEIVED_GET_POLICY_DOCUMENT:
            return action.payload;

        default:
            return state;
    }
}