import {
  PROJECTS_RECEIVED_GET_NEW_TEAMS,
  PROJECTS_RECEIVED_UPDATE_NEW_TEAM,
  PROJECTS_RECEIVED_CREATE_NEW_TEAM,
  PROJECTS_RECEIVED_DELETE_NEW_TEAM,
  PROJECTS_RECEIVED_CREATE_GOAL,
  PROJECTS_RECEIVED_UPDATE_GOAL,
  PROJECTS_RECEIVED_DELETE_GOAL,
  PROJECTS_RECEIVED_CREATE_NEW_TEAM_RESOURCE,
  PROJECTS_RECEIVED_DELETE_NEW_TEAM_RESOURCE,
  PROJECTS_RECEIVED_CREATE_CLIENT_OWNER,
  PROJECTS_RECEIVED_UPDATE_CLIENT_OWNER,
  PROJECTS_RECEIVED_DELETE_CLIENT_OWNER,
} from '../actions/project-actions';
import {copyObject} from "../utility";

const initialState = {
  new_teams: [],
  new_team_resources: [],
  users: [],
  goals: [],
  client_owners: [],
};

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_NEW_TEAMS:
      return action.payload;

    case   PROJECTS_RECEIVED_CREATE_NEW_TEAM:
      return createTeam(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_NEW_TEAM:
      return updateTeam(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_NEW_TEAM:
      return deleteTeam(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_GOAL:
      return createGoal(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_GOAL:
      return updateGoal(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_GOAL:
      return deleteGoal(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_NEW_TEAM_RESOURCE:
      return createResource(copyObject(state), action.payload)

    case PROJECTS_RECEIVED_DELETE_NEW_TEAM_RESOURCE:
      return deleteResource(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_CREATE_CLIENT_OWNER:
      return createClientOwner(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_UPDATE_CLIENT_OWNER:
      return updateClientOwner(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_CLIENT_OWNER:
      return deleteClientOwner(copyObject(state), action.payload);

    default:
      return state;
  }
}

function updateTeam(state, data) {
  state.new_teams.forEach((team, idx) => {
    if (team.id === data.id) {
      state.new_teams[idx] = data;
    }
  });

  return state;
}

function createTeam(state, data) {

  state.new_teams.push(data);

  return state;
}

function deleteTeam(state, data) {
  state.new_teams.forEach((team, idx) => {
    if (team.id === data.id) {
      state.new_teams.splice(idx, 1);
    }
  });

  return state;
}

function updateGoal(state, data) {
  state.goals.forEach((goal, idx) => {
    if (goal.id === data.id) {
      state.goals[idx] = data;
    }
  });

  return state;
}

function createGoal(state, data) {

  state.goals.push(data);

  return state;
}

function deleteGoal(state, data) {
  state.goals.forEach((goal, idx) => {
    if (goal.id === data.id) {
      state.goals.splice(idx, 1);
    }
  });

  return state;
}

function createResource(state, data) {
  state.new_team_resources.push(data);

  return state;
}
function deleteResource(state, data) {
  state.new_team_resources.forEach((res, idx) => {
    if (res.id === data.id) {
      state.new_team_resources.splice(idx, 1);
    }
  });

  return state;
}

function createClientOwner(state, data) {
  state.client_owners.push(data);

  return state;
}

function updateClientOwner(state, data) {
  const idx = state.client_owners.findIndex(o => o.id === data.id);
  if (idx > -1) {
    state.client_owners[idx] = data;
  }

  return state;
}

function deleteClientOwner(state, data) {
  const idx = state.client_owners.findIndex(o => o.id === data.id);
  if (idx > -1) {
    state.client_owners.splice(idx, 1);
  }

  return state;
}