import axios from 'axios';
import {put, call, select} from 'redux-saga/effects';
import * as appActions from '../actions';
import * as selectors from '../reducers/selectors';
import {strings} from '../localization';

export function* makeAPICall(verb, baseURL, path, data, successAction, authRequired = true, wasLogin = false) {
  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if (authRequired) {
    const auth = yield select(selectors.getAuth);
    config.headers.Authorization = `Bearer: ${auth.auth_token}`
  }

  let request;

  try {
    switch (verb) {
      case 'get':
      default:
        request = yield call(axios.get, `${baseURL}${path}`, config);
        break;

      case 'delete':
        request = yield call(axios.delete, `${baseURL}${path}`, config);
        break;

      case 'post':
        request = yield call(axios.post, `${baseURL}${path}`, data, config);
        break;

      case 'put':
        request = yield call(axios.put, `${baseURL}${path}`, data, config);
        break;
    }

    yield put({type: successAction, payload: request.data});

    return request.data;
  } catch (error) {
    yield handleAPIError(error, wasLogin);
    throw error;
  }
}

export function* handleAPIError(error, wasLogin) {
  yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 401) {
      yield put({type: appActions.SET_NAV, payload: {index: 'login', drawerOpen: false, activeStepperDepth: 0}});
      if (wasLogin) {
        yield put({type: appActions.SHOW_ALERT, payload: {msg: strings.loginFailed, type: 'error'}});
      } else {
        yield put({type: appActions.SHOW_ALERT, payload: {msg: strings.loginRequired, type: 'error'}});
      }
    } else {
      const msg = error.response.data.message ? error.response.data.message : strings.serverError;
      yield put({type: appActions.SHOW_ALERT, payload: {msg: msg, type: 'error'}});
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log('Request failed: ', error.request);
    const msg = error.response.data.message ? error.response.data.message : strings.serverError;
    yield put({type: appActions.SHOW_ALERT, payload: {msg: msg, type: 'error'}});
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    const msg = error.response && error.response.date && error.response.data.message ? error.response.data.message : strings.serverError;
    yield put({type: appActions.SHOW_ALERT, payload: {msg: msg, type: 'error'}});
  }

  yield put({type: appActions.CLEAR_ALL_UPDATES, payload: {}});
}
