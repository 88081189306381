export const CONFIG_GET_DEVICE_CONFIG = 'config_get_device_config';
export const CONFIG_GET_CHARGER_CONFIGS = 'config_get_charger_configs';
export const CONFIG_GET_CHARGER_CONFIG = 'config_get_charger_config';
export const CONFIG_UPDATE_CHARGER_SETTINGS = 'config_update_charger_settings';
export const CONFIG_UPDATE_CHARGER_FACTORY = 'config_update_charger_factory';
export const CONFIG_UPDATE_CHARGER_USER = 'config_update_charger_user';
export const CONFIG_UPLOAD_VERSION_FILE = 'config_upload_version_file';
export const CONFIG_DOWNLOAD_VERSION_FILE = 'config_download_version_file';
export const CONFIG_GET_VERSIONS_META = 'config_get_versions_meta';
export const CONFIG_GET_GATEWAY_CONFIGS = 'config_get_gateway_configs';
export const CONFIG_GET_GATEWAY_CONFIG = 'config_get_gateway_config';
export const CONFIG_UPDATE_GATEWAY_SETTINGS = 'config_update_gateway_settings';
export const CONFIG_UPDATE_GATEWAY_FACTORY = 'config_update_gateway_factory';
export const CONFIG_UPDATE_GATEWAY_USER = 'config_update_gateway_user';
export const CONFIG_GET_BATTERY_CONFIGS = 'config_get_battery_configs';
export const CONFIG_GET_BATTERY_CONFIG = 'config_get_battery_config';
export const CONFIG_UPDATE_BATTERY_SETTINGS = 'config_update_battery_settings';
export const CONFIG_UPDATE_BATTERY_FACTORY = 'config_update_battery_factory';
export const CONFIG_UPDATE_BATTERY_USER = 'config_update_battery_user';
export const CONFIG_GET_VANADIUM_BESS_CONFIGS = 'config_get_vanadium_bess_configs';
export const CONFIG_GET_VANADIUM_BESS_CONFIG = 'config_get_vanadium_bess_config';
export const CONFIG_UPDATE_VANADIUM_BESS_SETTINGS = 'config_update_vanadium_bess_settings';
export const CONFIG_UPDATE_VANADIUM_BESS_FACTORY = 'config_update_vanadium_bess_factory';
export const CONFIG_UPDATE_VANADIUM_BESS_USER = 'config_update_vanadium_bess_user';
export const CONFIG_GET_LEADACID_BESS_CONFIGS = 'config_get_leadacid_bess_configs';
export const CONFIG_GET_LEADACID_BESS_CONFIG = 'config_get_leadacid_bess_config';
export const CONFIG_UPDATE_LEADACID_BESS_SETTINGS = 'config_update_leadacid_bess_settings';
export const CONFIG_UPDATE_LEADACID_BESS_FACTORY = 'config_update_leadacid_bess_factory';
export const CONFIG_UPDATE_LEADACID_BESS_USER = 'config_update_leadacid_bess_user';

export const CONFIG_CREATE_ASSET_AND_CONFIGURATION = 'config_create_asset_and_configuration';

export const CONFIG_RECEIVED_GET_DEVICE_CONFIG = 'config_received_get_device_config';
export const CONFIG_RECEIVED_GET_CHARGER_CONFIGS = 'config_received_get_charger_configs';
export const CONFIG_RECEIVED_GET_CHARGER_CONFIG = 'config_received_get_charger_config';
export const CONFIG_RECEIVED_UPDATE_CHARGER_FACTORY = 'config_received_update_charger_factory';
export const CONFIG_RECEIVED_UPDATE_CHARGER_USER = 'config_received_update_charger_user';
export const CONFIG_RECEIVED_UPLOAD_VERSION_FILE = 'config_received_upload_version_file';
export const CONFIG_RECEIVED_DOWNLOAD_VERSION_FILE = 'config_received_download_version_file';
export const CONFIG_RECEIVED_GET_VERSIONS_META = 'config_received_get_versions_meta';
export const CONFIG_DELETE_VERSIONS_META = 'config_delete_versions_meta';
export const CONFIG_RECEIVED_DELETE_VERSIONS_META = 'config_received_delete_versions_meta';

export const CONFIG_GET_CHARGER_ENCRYPTED_PACKAGE = 'config_get_charger_encrypted_package';
export const CONFIG_RECEIVED_GET_CHARGER_ENCRYPTED_PACKAGE = 'config_received_get_charger_encrypted_package';

export const CONFIG_RECEIVED_GET_GATEWAY_CONFIGS = 'config_received_get_gateway_configs';
export const CONFIG_RECEIVED_GET_GATEWAY_CONFIG = 'config_received_get_gateway_config';
export const CONFIG_RECEIVED_UPDATE_GATEWAY_FACTORY = 'config_received_update_gateway_factory';
export const CONFIG_RECEIVED_UPDATE_GATEWAY_USER = 'config_received_update_gateway_user';

export const CONFIG_GET_GATEWAY_ENCRYPTED_PACKAGE = 'config_get_gateway_encrypted_package';
export const CONFIG_RECEIVED_GET_GATEWAY_ENCRYPTED_PACKAGE = 'config_received_get_gateway_encrypted_package';

export const CONFIG_RECEIVED_GET_BATTERY_CONFIGS = 'config_received_get_battery_configs';
export const CONFIG_RECEIVED_GET_BATTERY_CONFIG = 'config_received_get_battery_config';
export const CONFIG_RECEIVED_UPDATE_BATTERY_FACTORY = 'config_received_update_battery_factory';
export const CONFIG_RECEIVED_UPDATE_BATTERY_USER = 'config_received_update_battery_user';

export const CONFIG_RECEIVED_GET_VANADIUM_BESS_CONFIGS = 'config_received_get_vanadium_bess_configs';
export const CONFIG_RECEIVED_GET_VANADIUM_BESS_CONFIG = 'config_received_get_vanadium_bess_config';
export const CONFIG_RECEIVED_UPDATE_VANADIUM_BESS_SETTINGS = 'config_received_update_vanadium_bess_settings';
export const CONFIG_RECEIVED_UPDATE_VANADIUM_BESS_FACTORY = 'config_received_update_vanadium_bess_factory';
export const CONFIG_RECEIVED_UPDATE_VANADIUM_BESS_USER = 'config_received_update_vanadium_bess_user';

export const CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIGS = 'config_received_get_leadacid_bess_configs';
export const CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIG = 'config_received_get_leadacid_bess_config';
export const CONFIG_RECEIVED_UPDATE_LEADACID_BESS_SETTINGS = 'config_received_update_leadacid_bess_settings';
export const CONFIG_RECEIVED_UPDATE_LEADACID_BESS_FACTORY = 'config_received_update_leadacid_bess_factory';
export const CONFIG_RECEIVED_UPDATE_LEADACID_BESS_USER = 'config_received_update_leadacid_bess_user';
