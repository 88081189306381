import { cloneDeep } from "lodash";
import {
  PROJECTS_RECEIVED_CREATE_FACILITY,
  PROJECTS_RECEIVED_CREATE_FACILITY_ADDRESS,
  PROJECTS_RECEIVED_DELETE_ADDRESS,
  PROJECTS_RECEIVED_DELETE_FACILITY,
  PROJECTS_RECEIVED_UPDATE_ADDRESS,
  PROJECTS_RECEIVED_UPDATE_FACILITY,
} from "../../actions/project-actions";
import { cacheTag, legacyFulfill } from "./../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

type Facility = {};
type FacilityAddress = {};

export const facilitiesApi = (builder: BuilderType) => ({
  createFacility: builder.mutation<Facility, Partial<Facility>>({
    query: (facility) => ({
      url: `service/facilities`,
      method: "POST",
      body: fixupFacilityData(facility),
    }),
    invalidatesTags: [projectsCacheTags.FACILITIES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_FACILITY),
  }),

  updateFacility: builder.mutation<Facility, Partial<Facility>>({
    query: (facility) => ({
      url: `service/facilities/${facility.id}`,
      method: "PUT",
      body: fixupFacilityData(facility),
    }),
    invalidatesTags: [projectsCacheTags.FACILITIES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_FACILITY),
  }),

  deleteFacility: builder.mutation<Facility, number>({
    query: (facilityId) => ({
      url: `service/facilities/${facilityId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FACILITIES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_FACILITY),
  }),

  createFacilityAddress: builder.mutation<Facility, Partial<FacilityAddress>>({
    query: (address) => ({
      url: `service/addresses`,
      method: "POST",
      body: address,
    }),
    invalidatesTags: [projectsCacheTags.FACILITY_ADDRESSES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_FACILITY_ADDRESS),
  }),

  updateFacilityAddress: builder.mutation<Facility, Partial<FacilityAddress>>({
    query: (address) => ({
      url: `service/addresses/${address.id}`,
      method: "PUT",
      body: address,
    }),
    invalidatesTags: [projectsCacheTags.FACILITY_ADDRESSES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_ADDRESS),
  }),

  deleteFacilityAddress: builder.mutation<Facility, number>({
    query: (addressId) => ({
      url: `service/addresses/${addressId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.FACILITY_ADDRESSES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_ADDRESS),
  }),
});

export function fixupFacilityData(facility) {
  const copy = cloneDeep(facility);
  copy.facility_audit_data = JSON.stringify(facility.facility_audit_data);
  copy.facility_size_data = JSON.stringify(facility.facility_size_data);
  copy.facility_environment_data = JSON.stringify(
    facility.facility_environment_data
  );
  copy.facility_electrical_data = JSON.stringify(
    facility.facility_electrical_data
  );
  copy.facility_workforce_data = JSON.stringify(
    facility.facility_workforce_data
  );
  copy.facility_infrastructure_data = JSON.stringify(
    facility.facility_infrastructure_data
  );

  return copy;
}
