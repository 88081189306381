import {
  PROJECTS_RECEIVED_CREATE_BATTERY,
  PROJECTS_RECEIVED_CREATE_CHARGER,
  PROJECTS_RECEIVED_DELETE_BATTERY,
  PROJECTS_RECEIVED_DELETE_CHARGER,
  PROJECTS_RECEIVED_GET_BATTERIES,
  PROJECTS_RECEIVED_GET_CHARGERS,
  PROJECTS_RECEIVED_GET_MOTIVE_POWER,
  PROJECTS_RECEIVED_UPDATE_BATTERY,
  PROJECTS_RECEIVED_UPDATE_CHARGER,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type Battery = {};
export type Charger = {};
export type MotivePower = {};

export const motivePowerApi = (builder: BuilderType) => ({
  getMotivePower: builder.query<MotivePower, void>({
    query: () => `service/motive-power`,
    providesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_MOTIVE_POWER),
  }),

  getBatteries: builder.query<Battery[], void>({
    query: () => `service/motive-power/batteries`,
    providesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_BATTERIES),
  }),

  createBattery: builder.mutation<Battery, Battery>({
    query: (battery) => ({
      url: `service/motive-power/batteries`,
      method: "POST",
      body: battery,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_BATTERY),
  }),

  updateBattery: builder.mutation<Battery, any>({
    query: (battery) => ({
      url: `service/motive-power/batteries/${battery.id}`,
      method: "PUT",
      body: battery,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_BATTERY),
  }),

  deleteBattery: builder.mutation<Battery, number>({
    query: (batteryId) => ({
      url: `service/motive-power/batteries/${batteryId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_BATTERY),
  }),

  getChargers: builder.query<Charger, void>({
    query: () => `service/motive-power/chargers`,
    providesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_CHARGERS),
  }),

  createCharger: builder.mutation<Charger, Partial<Charger>>({
    query: (charger) => ({
      url: `service/motive-power/chargers`,
      method: "POST",
      body: charger,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_CHARGER),
  }),

  updateCharger: builder.mutation<Charger, Partial<Charger>>({
    query: (charger) => ({
      url: `service/motive-power/chargers/${charger.id}`,
      method: "PUT",
      body: charger,
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_CHARGER),
  }),

  deleteCharger: builder.mutation<null, number>({
    query: (chargerId) => ({
      url: `service/motive-power/chargers/${chargerId}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.MOTIVE_POWER],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_CHARGER),
  }),
});
