import {
  PROJECTS_RECEIVED_CREATE_CLIENT_INTERFACE,
  PROJECTS_RECEIVED_CREATE_USER_DASHBOARD,
  PROJECTS_RECEIVED_CREATE_USER_INTERFACE,
  PROJECTS_RECEIVED_DELETE_CLIENT_INTERFACE,
  PROJECTS_RECEIVED_DELETE_USER_DASHBOARD,
  PROJECTS_RECEIVED_GET_USER_VIEWS,
  PROJECTS_RECEIVED_UPDATE_USER_DASHBOARDS,
  PROJECTS_RECEIVED_UPDATE_USER_INTERFACE,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export type View = {};
export type UserInterface = {};
export type UserDashboard = {};
export type ClientInterface = {};

export const viewsApi = (builder: BuilderType) => ({
  getUserViews: builder.query<View[], void>({
    query: () => `service/views`,
    providesTags: [projectsCacheTags.VIEWS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_USER_VIEWS),
  }),

  createUserInterface: builder.mutation<UserInterface, any>({
    query: (userInterface) => ({
      url: `service/views/user-interfaces`,
      method: "POST",
      body: userInterface,
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_USER_INTERFACE),
  }),

  updateUserInterface: builder.mutation<UserInterface, any>({
    query: (userInterface) => ({
      url: `service/views/user-interfaces/${userInterface.id}`,
      method: "PUT",
      body: userInterface,
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_USER_INTERFACE),
  }),

  createUserDashboard: builder.mutation<UserDashboard, any>({
    query: (userDashboard) => ({
      url: `service/views/user-dashboards`,
      method: "POST",
      body: userDashboard,
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_USER_DASHBOARD),
  }),

  updateUserDashboards: builder.mutation<UserDashboard, any>({
    query: (update) => ({
      url: `service/views/user-dashboards`,
      method: "PUT",
      body: update,
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_USER_DASHBOARDS),
  }),

  deleteUserDashboard: builder.mutation<UserDashboard, number>({
    query: (id) => ({
      url: `service/views/user-dashboards/${id}`,
      method: "DELETE",
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_USER_DASHBOARD),
  }),

  createClientInterface: builder.mutation<ClientInterface, any>({
    query: (clientInterface) => ({
      url: `service/views/client-interfaces`,
      method: "POST",
      body: clientInterface,
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_CLIENT_INTERFACE),
  }),

  deleteClientInterface: builder.mutation<ClientInterface, number>({
    query: (id) => ({
      url: `service/views/client-interfaces/${id}`,
      method: "DELETE",
    }),
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_CLIENT_INTERFACE),
  }),
});
