import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";

export enum portalCacheTags {
  ASSETS = "Asset",
  USERS = "User",
  GROUP_DEFINITIONS = "GroupDefinition",
  ROLE_DEFINITIONS = "RoleDefinition",
  THIRD_PARTY_USERS = "ThirdPartyUser",
  USER_PROFILE = "UserProfile",
}

export type BuilderType = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  portalCacheTags,
  "portalApi"
>;
