import { cloneDeep } from "lodash";
import {
  PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP,
  PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP,
  PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { fixupScheduleData } from "./schedulesApi";

export type ScheduleGroup = {};

export const scheduleGroupsApi = (builder: BuilderType) => ({
  createScheduleGroup: builder.mutation<ScheduleGroup, Partial<ScheduleGroup>>({
    query: (scheduleGroup) => ({
      url: `service/schedule-groups.json`,
      method: "POST",
      body: fixupScheduleGroupData(scheduleGroup),
    }),
    invalidatesTags: [projectsCacheTags.SCHEDULE_GROUPS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_SCHEDULE_GROUP),
  }),

  updateScheduleGroup: builder.mutation<ScheduleGroup, Partial<ScheduleGroup>>({
    query: (scheduleGroup) => ({
      url: `service/schedule-groups/${scheduleGroup.id}.json`,
      method: "PUT",
      body: fixupScheduleGroupData(scheduleGroup),
    }),
    invalidatesTags: [projectsCacheTags.SCHEDULE_GROUPS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_SCHEDULE_GROUP),
  }),

  deleteScheduleGroup: builder.mutation<ScheduleGroup, number>({
    query: (scheduleGroupId) => ({
      url: `service/schedule-groups/${scheduleGroupId}.json`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.SCHEDULE_GROUPS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_SCHEDULE_GROUP),
  }),
});

export function fixupScheduleGroupData(values) {
  const scheduleGroup = cloneDeep(values);

  scheduleGroup.schedules_attributes = [];
  scheduleGroup.schedules.forEach((schedule) => {
    scheduleGroup.schedules_attributes.push(fixupScheduleData(schedule));
  });

  delete scheduleGroup.schedules;

  return scheduleGroup;
}
