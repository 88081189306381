import * as configActions from "../actions/config-actions";
import { makeAPICall } from "./api-builder";

const CONFIG_URL = import.meta.env.VITE_APP_CONFIG_SERVICE;

// ********************** API **********************************

export function* getDeviceConfig(device) {
  yield makeAPICall(
    "get",
    CONFIG_URL,
    `/device-configs/${device}`,
    {},
    configActions.CONFIG_RECEIVED_GET_DEVICE_CONFIG
  );
}

export function* getChargerConfigs(idList) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/chargers/settings?showHistory=true",
    idList,
    configActions.CONFIG_RECEIVED_GET_CHARGER_CONFIGS
  );
}

export function* getChargerConfig(id) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/chargers/settings?showHistory=true",
    { ids: [id] },
    configActions.CONFIG_RECEIVED_GET_CHARGER_CONFIG
  );
}

export function* uploadVersionFile(file) {
  const data = new FormData();
  data.append("files", file.file);
  data.append("fileName", file.file.name);
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/versions/upload",
    data,
    configActions.CONFIG_RECEIVED_UPLOAD_VERSION_FILE
  );
}

export function* getVersionMetas() {
  yield makeAPICall(
    "get",
    CONFIG_URL,
    "/versions",
    {},
    configActions.CONFIG_RECEIVED_GET_VERSIONS_META
  );
}

export function* deleteVersionMeta(id) {
  yield makeAPICall(
    "delete",
    CONFIG_URL,
    `/versions/${id}`,
    {},
    configActions.CONFIG_RECEIVED_DELETE_VERSIONS_META
  );
}

export function* getEncryptedChargerPackage(chargerSerial) {
  yield makeAPICall(
    "get",
    CONFIG_URL,
    `/chargers/encrypted-package/${chargerSerial}`,
    {},
    configActions.CONFIG_RECEIVED_GET_CHARGER_ENCRYPTED_PACKAGE
  );
}

export function* updateChargerFactorySettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/chargers/factory-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_CHARGER_FACTORY
  );
}

export function* updateChargerUserSettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/chargers/user-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_CHARGER_USER
  );
}

export function* getEncryptedGatewayPackage(gatewaySerial) {
  yield makeAPICall(
    "get",
    CONFIG_URL,
    `/gateways/encrypted-package/${gatewaySerial}`,
    {},
    configActions.CONFIG_RECEIVED_GET_GATEWAY_ENCRYPTED_PACKAGE
  );
}

export function* updateGatewayFactorySettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/gateways/factory-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_GATEWAY_FACTORY
  );
}

export function* updateGatewayUserSettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/gateways/user-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_GATEWAY_USER
  );
}

export function* getGatewayConfigs(idList) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/gateways/settings?showHistory=true",
    idList,
    configActions.CONFIG_RECEIVED_GET_GATEWAY_CONFIGS
  );
}

export function* getGatewayConfig(id) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/gateways/settings?showHistory=true",
    { ids: [id] },
    configActions.CONFIG_RECEIVED_GET_GATEWAY_CONFIG
  );
}

export function* updateBatteryFactorySettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/batteries/factory-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_BATTERY_FACTORY
  );
}

export function* updateBatteryUserSettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/batteries/user-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_BATTERY_USER
  );
}

export function* getBatteryConfigs(idList) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/batteries/settings?showHistory=true",
    idList,
    configActions.CONFIG_RECEIVED_GET_BATTERY_CONFIGS
  );
}

export function* getBatteryConfig(id) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/batteries/settings?showHistory=true",
    { ids: [id] },
    configActions.CONFIG_RECEIVED_GET_BATTERY_CONFIG
  );
}

export function* updateVanadiumBESSFactorySettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/vanadium-bess/factory-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_VANADIUM_BESS_FACTORY
  );
}

export function* updateVanadiumBESSUserSettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/vanadium-bess/user-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_VANADIUM_BESS_USER
  );
}

export function* getVanadiumBESSConfigs(idList) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/vanadium-bess/settings?showHistory=true",
    idList,
    configActions.CONFIG_RECEIVED_GET_VANADIUM_BESS_CONFIGS
  );
}

export function* getVanadiumBESSConfig(id) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/vanadium-bess/settings?showHistory=true",
    { ids: [id] },
    configActions.CONFIG_RECEIVED_GET_VANADIUM_BESS_CONFIG
  );
}

export function* updateLeadAcidBESSFactorySettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/leadacid-bess/factory-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_LEADACID_BESS_FACTORY
  );
}

export function* updateLeadAcidBESSUserSettings(settings, serial) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    `/leadacid-bess/user-settings/${serial}`,
    settings,
    configActions.CONFIG_RECEIVED_UPDATE_LEADACID_BESS_USER
  );
}

export function* getLeadAcidBESSConfigs(idList) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/leadacid-bess/settings?showHistory=true",
    idList,
    configActions.CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIGS
  );
}

export function* getLeadAcidBESSConfig(id) {
  yield makeAPICall(
    "post",
    CONFIG_URL,
    "/leadacid-bess/settings?showHistory=true",
    { ids: [id] },
    configActions.CONFIG_RECEIVED_GET_LEADACID_BESS_CONFIG
  );
}
