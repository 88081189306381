import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import { Client } from "../types/Client";
import { legacyFulfill } from "../utils";
import {
  PROJECTS_RECEIVED_CREATE_CLIENT,
  PROJECTS_RECEIVED_GET_CLIENTS,
  PROJECTS_RECEIVED_UPDATE_CLIENT,
} from "../../actions/project-actions";

export const providedTags = ["Client"];
export const clientAssociatedTags = [
  "Facilities",
  "Contacts",
  "Projects",
  "Addresses",
  "NewTeams",
  "NewTeamResources",
  "ClientOwner",
  "ClientInterfaces",
  "UserInterfaces",
  "UserDashboards",
  "CustomerGroups",
  "Notes",
];

export const clientsApi = (builder: BuilderType) => ({
  getClients: builder.query<Client, void>({
    query: () => `service/clients`,
    providesTags: [
      projectsCacheTags.FACILITIES,
      projectsCacheTags.CONTACTS,
      projectsCacheTags.PROJECTS,
      projectsCacheTags.FACILITY_ADDRESSES,
      projectsCacheTags.NEW_TEAMS,
      projectsCacheTags.NEW_TEAM_RESOURCES,
      projectsCacheTags.CLIENT_OWNERS,
      projectsCacheTags.CLIENT_INTERFACES,
      projectsCacheTags.USER_INTERFACES,
      projectsCacheTags.USER_DASHBOARDS,
      projectsCacheTags.CUSTOMER_GROUPS,
      projectsCacheTags.NOTES,
    ],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_CLIENTS),
  }),

  getClient: builder.query<Client, number>({
    query: (id) => `service/clients/${id}`,
    providesTags: [projectsCacheTags.CLIENTS],
  }),
  createClient: builder.mutation<Client, Partial<Client>>({
    query: (body) => ({
      url: `service/clients`,
      method: "POST",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CLIENTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_CLIENT),
  }),

  updateClient: builder.mutation<Client, Partial<Client>>({
    query: (body) => ({
      url: `service/clients/${body.id}`,
      method: "PUT",
      body,
    }),
    invalidatesTags: [projectsCacheTags.CLIENTS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_CLIENT),
  }),
});
