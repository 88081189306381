import { put, putResolve, take } from "redux-saga/effects";
import * as appActions from "../../actions";
import * as projectActions from "../../actions/project-actions";
import { projectsApi } from "../../services/projects/projectsApi";
const {
  getTags,
  createTagType,
  updateTagType,
  deleteTagType,
  createTagValue,
  updateTagValue,
  deleteTagValue,
  createTag,
  deleteTag,
} = projectsApi.endpoints;

export function* watchGetTags() {
  while (true) {
    yield take(projectActions.PROJECTS_GET_TAGS);
    yield put({ type: appActions.SET_DATA_REQUESTED, payload: "tags" });
    yield putResolve(getTags.initiate());
    // yield call(getTags);
    yield put({ type: appActions.SET_DATA_RECEIVED, payload: "tags" });
  }
}

export function* watchCreateTagType() {
  while (true) {
    const { payload: type } = yield take(
      projectActions.PROJECTS_CREATE_TAG_TYPE
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createTagType.initiate(type));
    // yield call(createTagType, type);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateTagType() {
  while (true) {
    const { payload: type } = yield take(
      projectActions.PROJECTS_UPDATE_TAG_TYPE
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(updateTagType.initiate(type));
    //yield call(updateTagType, type);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteTagType() {
  while (true) {
    const { payload: id } = yield take(projectActions.PROJECTS_DELETE_TAG_TYPE);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteTagType.initiate(id));
    // yield call(deleteTagType, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateTagValue() {
  while (true) {
    const { payload: value } = yield take(
      projectActions.PROJECTS_CREATE_TAG_VALUE
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createTagValue.initiate(value));
    // yield call(createTagValue, value);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateTagValue() {
  while (true) {
    const { payload: value } = yield take(
      projectActions.PROJECTS_UPDATE_TAG_VALUE
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(updateTagValue.initiate(value));
    // yield call(updateTagValue, value);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteTagValue() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_TAG_VALUE
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteTagValue.initiate(id));
    // yield call(deleteTagValue, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchCreateTag() {
  while (true) {
    const { payload: tag } = yield take(projectActions.PROJECTS_CREATE_TAG);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(createTag.initiate(tag));
    // yield call(createTag, tag);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteTag() {
  while (true) {
    const { payload: id } = yield take(projectActions.PROJECTS_DELETE_TAG);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield putResolve(deleteTag.initiate(id));
    // yield call(deleteTag, id);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDoBatchTags() {
  while (true) {
    const {
      payload: { new_tags, deleted_tags },
    } = yield take(projectActions.PROJECTS_DO_BATCH_TAGS);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    for (let i = 0; i < new_tags.length; i++) {
      yield putResolve(createTag.initiate(new_tags[i]));
      // yield call(createTag, new_tags[i]);
    }

    for (let i = 0; i < deleted_tags.length; i++) {
      yield putResolve(deleteTag.initiate(deleted_tags[i]));
      // yield call(deleteTag, deleted_tags[i]);
    }

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
