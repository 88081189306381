import React from 'react';
import { SvgIcon } from '@mui/material';
import IconProps from "./IconProps";

export default function Handshake (props: IconProps) {
  return (
      <SvgIcon width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
        <path
            d="M16.3013 2.40002H13.08C12.78 2.40002 12.4913 2.51252 12.27 2.71502L8.58375 6.09002C8.58 6.09377 8.57625 6.10127 8.5725 6.10502C7.95 6.69002 7.96125 7.62377 8.49375 8.20502C8.97 8.72627 9.97125 8.86502 10.5975 8.30627C10.6013 8.30252 10.6088 8.30252 10.6125 8.29877L13.6088 5.55377C13.8525 5.33252 14.235 5.34752 14.4563 5.59127C14.6813 5.83502 14.6625 6.21377 14.4188 6.43877L13.44 7.33502L18.9 11.7675C19.0088 11.8575 19.1063 11.955 19.1963 12.0563V4.80002L17.1488 2.75252C16.9275 2.52752 16.62 2.40002 16.3013 2.40002ZM20.4 4.80752V13.2038C20.4 13.8675 20.9363 14.4038 21.6 14.4038H24V4.80752H20.4ZM0 14.4H2.4C3.06375 14.4 3.6 13.8638 3.6 13.2V4.80752H0V14.4ZM18.1462 12.7013L12.5475 8.15627L11.4225 9.18752C10.3088 10.2038 8.6025 10.1063 7.60875 9.02252C6.6 7.92002 6.67875 6.21377 7.77375 5.20877L10.8413 2.40002H7.69875C7.38 2.40002 7.07625 2.52752 6.85125 2.75252L4.8 4.80002V13.1963H5.48625L8.88 16.2675C9.9075 17.1038 11.4188 16.9463 12.255 15.9188L12.2625 15.9113L12.9338 16.4925C13.53 16.98 14.4113 16.8863 14.895 16.29L16.0725 14.8425L16.275 15.0075C16.7888 15.4238 17.5463 15.3488 17.9625 14.8313L18.3188 14.3925C18.7388 13.875 18.66 13.1213 18.1462 12.7013Z"
            fill={props.fill}/>
      </SvgIcon>
  );
}

