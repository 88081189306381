import {
  PORTAL_RECEIVED_GET_GROUP_DEFINITIONS
} from '../actions/portal-actions';

const initialState = [];

export default function (state = initialState, action) {

  switch(action.type) {
    case PORTAL_RECEIVED_GET_GROUP_DEFINITIONS:
      return action.payload;

    default:
      return state;
  }
}