import * as portalActions from "../../actions/portal-actions";
import {take, put, call} from 'redux-saga/effects';
import * as appActions from "../../actions";
import {getPolicyDocument} from "../api-portal";
import {checkForDataUpdate} from '../index'


export function* watchGetPolicyDocument() {
  while (true) {
    yield take(portalActions.PORTAL_GET_POLICY_DOCUMENT);
    yield put({type: appActions.SET_DATA_REQUESTED, payload: `policyDocument`});
    yield call(getPolicyDocument);
    yield put({type: appActions.SET_DATA_RECEIVED, payload: `policyDocument`});
    yield call(checkForDataUpdate);
  }
}