import {
  PORTAL_RECEIVED_GET_ASSETS,
  PORTAL_RECEIVED_UPDATE_ASSET,
  PORTAL_RECEIVED_CREATE_ASSET,
  PORTAL_RECEIVED_DELETE_ASSET,
  PORTAL_RECEIVED_MOVE_ASSET_LIST,
} from '../actions/portal-actions';

import {copyObject} from "../shared-components/src/utility";

export default function (state = [], action) {

  switch (action.type) {
    case PORTAL_RECEIVED_GET_ASSETS:
      return action.payload;

    case PORTAL_RECEIVED_CREATE_ASSET:
      return addAsset(copyObject(state), action.payload);

    case PORTAL_RECEIVED_UPDATE_ASSET:
      return updateAsset(copyObject(state), action.payload);

    case PORTAL_RECEIVED_DELETE_ASSET:
      return removeAsset(copyObject(state), action.payload);

    case PORTAL_RECEIVED_MOVE_ASSET_LIST:
      return updateAssets(copyObject(state), action.payload);

    default:
      return state;
  }
}

function addAsset(state, user) {
  state.push(user);
  return state;
}

function updateAsset(state, updatedAsset) {
  const idx = state.findIndex(a => a.id === updatedAsset.id);
  if(idx > -1) {
    state[idx] = updatedAsset;
  }

  return state;
}

function updateAssets(state, updatedAssets) {
  updatedAssets.forEach(updatedAsset => {
    const idx = state.findIndex(a => a.id === updatedAsset.id);
    if(idx > -1) {
      state[idx] = updatedAsset;
    }
  })

  return state;
}

function removeAsset(state, id) {
  for (let i = 0; i < state.length; i++) {
    if (state[i].id === id) {
      state.splice(i, 1);
      break;
    }
  }

  return state;
}