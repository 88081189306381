import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

import {
  PROJECTS_RECEIVED_CREATE_NOTE,
  PROJECTS_RECEIVED_DELETE_NOTE,
  PROJECTS_RECEIVED_UPDATE_NOTE,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
export type Note = {};

export const notesApi = (builder: BuilderType) => ({
  createNote: builder.mutation<Note, any>({
    query: (note) => ({
      url: `service/notes`,
      method: "POST",
      body: note,
    }),
    invalidatesTags: [projectsCacheTags.NOTES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_NOTE),
  }),

  updateNote: builder.mutation<Note, any>({
    query: (note) => ({
      url: `service/notes/${note.id}`,
      method: "PUT",
      body: note,
    }),
    invalidatesTags: [projectsCacheTags.NOTES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_NOTE),
  }),

  deleteNote: builder.mutation<Note, number>({
    query: (id) => ({
      url: `service/notes/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.NOTES],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_NOTE),
  }),
});
