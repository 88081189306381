import { BuilderType, portalCacheTags } from "./portalApiHelpers";
import { legacyFulfill } from "../utils";
import {
  PORTAL_RECEIVED_GET_USERS,
  PORTAL_RECEIVED_UPDATE_USER,
} from "../../actions/portal-actions";

export type User = {};
export type ResetData = {};

export const usersApi = (builder: BuilderType) => ({
  getUsers: builder.query<any, void>({
    query: () => `service/users.json`,
    providesTags: [portalCacheTags.USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_GET_USERS),
  }),

  updateUser: builder.mutation<User, User>({
    query: (user) => ({
      method: "PUT",
      url: `/service/users/${user.id}.json`,
      body: user,
    }),
    invalidatesTags: [portalCacheTags.USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_USER),
  }),

  updateUserPassword: builder.mutation<User, User>({
    query: (user) => ({
      method: "PUT",
      url: `/service/users/${user.id}/update-password.json`,
      body: user,
    }),
    invalidatesTags: [portalCacheTags.USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_USER),
  }),

  updateChargerUserSettings: builder.mutation<User, User>({
    query: (user) => ({
      method: "PUT",
      url: `/service/users/${user.id}/update-settings.json`,
      body: user,
    }),
    invalidatesTags: [portalCacheTags.USERS],
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_USER),
  }),

  resetUserPassword: builder.mutation<void, ResetData>({
    query: (resetData) => ({
      method: "PUT",
      url: `/service/reset-password.json`,
      body: resetData,
    }),
    onQueryStarted: legacyFulfill(PORTAL_RECEIVED_UPDATE_USER),
  }),
});
