import { call, put, take } from "redux-saga/effects";
import * as projectActions from "../../actions/project-actions";
import * as appActions from "../../actions";
import { api_getGroupDefinitions } from "../api-portal";
import * as projectApi from "../api-projects";

export function* watchCreateThirdPartyUser() {
  while (true) {
    const { payload: thirdPartyUser } = yield take(
      projectActions.PROJECTS_CREATE_THIRD_PARTY_USER
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield call(projectApi.createThirdPartyUser, thirdPartyUser);
    yield call(updateOtherData);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateThirdPartyUser() {
  while (true) {
    const { payload: thirdPartyUser } = yield take(
      projectActions.PROJECTS_UPDATE_THIRD_PARTY_USER
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield call(projectApi.updateThirdPartyUser, thirdPartyUser);
    yield call(updateOtherData);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchDeleteThirdPartyUser() {
  while (true) {
    const { payload: id } = yield take(
      projectActions.PROJECTS_DELETE_THIRD_PARTY_USER
    );
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });
    yield call(projectApi.deleteThirdPartyUser, id);
    yield call(updateOtherData);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

function* updateOtherData() {
  // Update teams and groups
  yield call(projectApi.getNewTeams);
  yield call(projectApi.getUserViews);
  yield call(api_getGroupDefinitions);
}
