import {
  PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT,
  PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT,
  PROJECTS_RECEIVED_GET_LOGGER_MOST_RECENT,
  PROJECTS_RECEIVED_GET_POWER_LOGGERS,
  PROJECTS_RECEIVED_UPDATE_LOGGER_ASSIGNMENT,
} from "../../actions/project-actions";
import { legacyFulfill } from "../utils";
import { BuilderType, projectsCacheTags } from "./projectsApiHelpers";

export const powerLoggersApi = (builder: BuilderType) => ({
  getPowerLoggers: builder.query<any, void>({
    query: () => `service/power-loggers`,
    providesTags: [projectsCacheTags.POWER_LOGGERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_GET_POWER_LOGGERS),
  }),

  assignPowerLogger: builder.mutation<any, Partial<Assignment>>({
    query: (assignment) => ({
      url: `service/power-loggers/${assignment.power_logger_id}/assign`,
      method: "POST",
      body: assignment,
    }),
    invalidatesTags: [projectsCacheTags.POWER_LOGGERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT),
  }),

  updatePowerLoggerAssignment: builder.mutation<any, Partial<Assignment>>({
    query: (assignment) => ({
      url: `service/power-loggers/${assignment.power_logger_id}/assign/${assignment.id}`,
      method: "PUT",
      body: assignment,
    }),
    invalidatesTags: [projectsCacheTags.POWER_LOGGERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_UPDATE_LOGGER_ASSIGNMENT),
  }),

  // prettier-ignore
  deletePowerLoggerAssignment: builder.mutation<any,{ id: number; powerLoggerId: number }>({
    query: ({ id, powerLoggerId }) => ({
      url: `service/power-loggers/${powerLoggerId}/assign/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: [projectsCacheTags.POWER_LOGGERS],
    onQueryStarted: legacyFulfill(PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT),
  }),

  // prettier-ignore
  getLoggerMostRecent: builder.query<any,{ id: number; timeZoneOffset: string }>({
    query: ({ id, timeZoneOffset }) =>
      `service/power-loggers/${id}/most-recent/${timeZoneOffset}`,
    providesTags: [projectsCacheTags.POWER_LOGGERS],
    onQueryStarted: legacyFulfill(
      PROJECTS_RECEIVED_GET_LOGGER_MOST_RECENT
    ),
  })
});
