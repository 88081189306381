import moment from "moment-timezone";

export const omit = (
  obj: {
    [key: string]: any;
  },
  ...keysToOmit: string[]
) => {
  const arrayified = Object.keys(obj).map((key) => [key, obj[key]]);
  const filtered = arrayified.filter((item) => !keysToOmit.includes(item[0]));
  return Object.fromEntries(filtered);
};

export const getOptionLabel = (optionList: any[], value: any) => {
  let ret = '';

  optionList.forEach((option) => {
    if (option.value === value) {
      ret = option.label;
    }
  });

  return ret;
};

export const isEmpty = (obj: any) => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const truncateString = (str: string, length: number, ending?: string) => {
  if (!length) {
    length = 100;
  }
  if (!ending) {
    ending = '...';
  }

  if (str && str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const flatten = (
  obj: {
    [key: string]: any;
  },
  previousKey: string = '',
  flattened: {
    [key: string]: any;
  } = {}
) =>
  Object.entries(obj).reduce((prev, [key, value]) => {
    if (typeof value === 'object') {
      flatten(value, `${previousKey}${key}.`, prev);
    } else {
      flattened[`${previousKey}${key}`] = value;
    }
    return prev;
  }, flattened);

// Return number with only X decimal places
export const truncateFloat = (num: number, places: number = 2) => {
  const d = 10^places;
  return parseFloat((Math.round(num * d) / d).toFixed(places));
}

// Comma separate into a string an array of values
export const commaString = (array: any[]) => {
  let str = '';
  array.forEach((value, idx) => {
    str += value;
    if(idx !== array.length - 1) {
      str += ', ';
    }
  });

  return str;
}

export function alphaSortObjectArray(array: {[key: string]: any}, sortKey: string) {
  return array.sort((a: any, b: any) => {
    let nameA = a[sortKey].toUpperCase(); // ignore upper and lowercase
    let nameB = b[sortKey].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export function numericSortObjectArray(array: {[key: string]: any}, sortKey: string) {
  return array.sort((a: any, b: any) => {
    if (a[sortKey] < b[sortKey]) {
      return -1;
    }
    if (a[sortKey] > b[sortKey]) {
      return 1;
    }
    return 0;
  });
}

export function dateSortObjectArray(array: {[key: string]: any}, sortKey: string) {
  return array.sort((a: any, b: any) => {
    if (moment(a[sortKey]).isBefore(moment(b[sortKey]))) {
      return -1;
    }
    if (moment(b[sortKey]).isBefore(moment(a[sortKey]))) {
      return 1;
    }
    return 0;
  });
}

export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const groupByValue = (array: any[], value: any) => {
  return array.reduce((group, entry) => {
    const key = entry[value];
    group[key] = group[key] ?? [];
    group[key].push(entry);
    return group;
  }, {})
};


