import {
  PROJECTS_RECEIVED_GET_POWER_LOGGERS,
  PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT,
  PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT,
} from '../actions/project-actions';

import {copyObject} from "../utility";

const initialState = {
  power_loggers: [],
  assignments: []
};

export default function (state = initialState, action) {

  switch (action.type) {
    case PROJECTS_RECEIVED_GET_POWER_LOGGERS:
      return action.payload;

    case PROJECTS_RECEIVED_CREATE_LOGGER_ASSIGNMENT:
      return addLoggerAssignment(copyObject(state), action.payload);

    case PROJECTS_RECEIVED_DELETE_LOGGER_ASSIGNMENT:
      return removeLoggerAssignment(copyObject(state), action.payload);

    default:
      return state;
  }
}

function addLoggerAssignment(state, assignment) {
  state.assignments.push(assignment);
  return state;
}

function removeLoggerAssignment(state, assignment) {
  state.assignments.forEach((assign, index) => {
    if(assign.id === assignment.id) {
      state.assignments.splice(index, 1);
    }
  });

  return state;
}