import {
  PORTAL_RECEIVED_AUTHENTICATE,
  PORTAL_AUTHENTICATE,
  PORTAL_RECEIVED_UPDATE_USER_PROFILE_IMAGE,
  PORTAL_RECEIVED_UPDATE_USER,
  PORTAL_SHOW_AUTHENTICATING,
  PORTAL_HIDE_AUTHENTICATING,
} from "../actions/portal-actions";
import { LOGOUT_USER } from "../actions";
import jwtDecode from "jwt-decode";
import { copyObject } from "../utility";

/**
 * @name authenticateState
 * @param {Object} authenticate
 * @param {string} authenticate.email email of the current user
 * @param {string} authenticate.rememberMe Should the login data be remembered on this computer
 * @param {string} authenticate.auth_token JWT token for the current user
 * @param {Object} authenticate.userInfo Structure containing the decoded payload of the JWT token
 * @param {Object} authenticate.userInfo.user Structure containing information about the current user
 * @param {number} authenticate.userInfo.user.id The Portal ID of the current user
 * @param {string} authenticate.userInfo.user.email The email of the current user
 * @param {string} authenticate.userInfo.user.first_name The first name of the current user
 * @param {string} authenticate.userInfo.user.last_name The last name of the current user
 * @param {string} authenticate.userInfo.user.locale The locale of the current user
 * @param {number} authenticate.userInfo.user.compartment_id The Portal compartment ID of the current user
 * @param {Array.<Object>} authenticate.userInfo.policies An array of policies for the current user
 * @param {number} authenticate.userInfo.policies.id The Portal ID of the policy
 * @param {string} authenticate.userInfo.policies.name The name of the policy
 */

const defaultState = {
  email: "",
  rememberMe: false,
  auth_token: "",
  userInfo: {},
};
export default function (state = defaultState, action) {
  switch (action.type) {
    case PORTAL_AUTHENTICATE: {
      let copy = copyObject(state);
      copy.email = action.payload.email.toLowerCase().trim();
      copy.rememberMe = action.payload.rememberMe;
      return copy;
    }
    case PORTAL_SHOW_AUTHENTICATING:
    {
      return {...state, loading: true};
    }
    case PORTAL_HIDE_AUTHENTICATING:
    {
      return {...state, loading: false};
    }
    case PORTAL_RECEIVED_AUTHENTICATE:
    {
      return createInfo(copyObject(state), action.payload);
    }

    case PORTAL_RECEIVED_UPDATE_USER_PROFILE_IMAGE: {
      return updateInfo(copyObject(state), action.payload);
    }

    case PORTAL_RECEIVED_UPDATE_USER: {
      return updateUser(copyObject(state), action.payload);
    }

    case LOGOUT_USER: {
      return defaultState;
    }

    default:
      return state;
  }
}

function createInfo(state, data) {
  state.auth_token = data.auth_token;
  state.email = data.email.toLowerCase().trim();
  state.userInfo = jwtDecode(data.auth_token);
  return state;
}

function updateInfo(state, data) {
  state.userInfo.user.profile_image_url = data.profile_image_url;

  return state;
}

/*
          :id,
          :email,
          :first_name,
          :last_name,
          :locale,
          :temp_unit,
 */
function updateUser(state, data) {
  if (state.userInfo?.user?.id === data.id) {
    state.userInfo.user.id = data.id;
    state.userInfo.user.email = data.email;
    state.userInfo.user.first_name = data.first_name;
    state.userInfo.user.last_name = data.last_name;
    state.userInfo.user.locale = data.locale;
    state.userInfo.user.temp_unit = data.temp_unit;
  }

  return state;
}
