import {call, put, take} from "redux-saga/effects";
import {getLoggerMostRecent} from "../api-projects";
import * as projectActions from "../../actions/project-actions";
import * as appActions from "../../actions";

export function* watchGetLoggerMostRecent() {
  while (true) {
    const {payload: id} = yield take(projectActions.PROJECTS_GET_LOGGER_MOST_RECENT);
    const date = new Date();
    yield put({type: appActions.SET_APP_STATE, payload: {saving: true}});
    yield put({type: appActions.SET_DATA_REQUESTED, payload: `loggerMostRecent-${id}`});
    yield call(getLoggerMostRecent, id, date.getTimezoneOffset());
    yield put({type: appActions.SET_DATA_RECEIVED, payload: `loggerMostRecent-${id}`});
    yield put({type: appActions.SET_APP_STATE, payload: {saving: false}});
  }
}