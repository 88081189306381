import {
  CONFIG_RECEIVED_GET_DEVICE_CONFIG,
} from '../actions/config-actions';

export default function (state = {}, action) {

  switch(action.type) {
    case CONFIG_RECEIVED_GET_DEVICE_CONFIG:
      return action.payload;

    default:
      return state;
  }
}