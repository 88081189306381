import * as projectActions from "../../actions/project-actions";
import { take, put, call, putResolve } from "redux-saga/effects";
import * as appActions from "../../actions";
import { projectsApi } from "../../services/projects/projectsApi";

const {
  deleteTeam,
  createTeam,
  updateTeam,
  updateTeamStructure,
  getTeams,
  createNewGoal,
  updateNewGoal,
  deleteNewGoal,
} = projectsApi.endpoints;

export function* watchUpdateTeamStructure() {
  while (true) {
    const {
      payload: {
        deletedTeams,
        newTeams,
        updatedTeams,
        newNodes,
        updatedNodes,
        deletedNodes,
        structureId,
      },
    } = yield take(projectActions.PROJECTS_UPDATE_TEAM_STRUCTURE);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    // First save off the new teams
    for (let i = 0; i < deletedTeams.length; i++) {
      yield putResolve(deleteTeam.initiate(deletedTeams[i].id));
      // yield call(deleteTeam, deletedTeams[i].id)
    }

    // Now add new teams
    for (let i = 0; i < newTeams.length; i++) {
      const oldId = newTeams[i].id;
      const newTeam = yield call(createTeam, newTeams[i]);

      // Update the nodes to be sure they reference the correct Team ID
      newNodes.forEach((node) => {
        if (node.node_type === "team" && node.node_id === oldId) {
          node.node_id = newTeam.id;
        }
      });

      updatedNodes.forEach((node) => {
        if (node.node_type === "team" && node.node_id === oldId) {
          node.node_id = newTeam.id;
        }
      });
    }

    // Update any changed teams
    for (let i = 0; i < updatedTeams.length; i++) {
      yield putResolve(updateTeam.initiate(updatedTeams[i]));
      //yield call(updateTeam, updatedTeams[i]);
    }

    // Now update the structure nodes
    yield putResolve(
      updateTeamStructure.initiate({
        structure: {
          new_nodes: newNodes,
          updated_nodes: updatedNodes,
          deleted_nodes: deletedNodes,
        },
        structureId,
      })
    );
    /* yield call(updateTeamStructure, {
      new_nodes: newNodes,
      updated_nodes: updatedNodes,
      deleted_nodes: deletedNodes
    }, structureId) */

    // Refetch the Teams
    yield putResolve(getTeams.initiate());
    // yield call(getTeams);

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}

export function* watchUpdateClientGoals() {
  while (true) {
    const {
      payload: { newGoals, updatedGoals, deletedGoals },
    } = yield take(projectActions.PROJECTS_UPDATE_CLIENT_GOALS);
    yield put({ type: appActions.SET_APP_STATE, payload: { saving: true } });

    for (let i = 0; i < newGoals.length; i++) {
      yield putResolve(createNewGoal.initiate(newGoals[i]));
      //yield call(createNewGoal, newGoals[i]);
    }

    for (let i = 0; i < updatedGoals.length; i++) {
      yield putResolve(updateNewGoal.initiate(updatedGoals[i]));
      // yield call(updateNewGoal, updatedGoals[i]);
    }

    for (let i = 0; i < deletedGoals.length; i++) {
      yield putResolve(deleteNewGoal.initiate(deletedGoals[i].id));
      // yield call(deleteNewGoal, deletedGoals[i].id);
    }

    yield put({ type: appActions.SET_APP_STATE, payload: { saving: false } });
  }
}
