import {
  BaseQueryFn,
  EndpointBuilder,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";

export enum projectsCacheTags {
  ALERT_CONFIGURATIONS = "AlertConfiguration",
  CLIENT_OWNERS = "ClientOwner",
  CLIENTS = "Client",
  CONTACTS = "Contact",
  CUSTOMER_GROUPS = "CustomerGroup",
  FACILITIES = "Facility",
  FLEETS = "Fleet",
  GOALS = "Goal",
  MISC = "Misc",
  MOTIVE_POWER = "MotivePower",
  NEW_GOALS = "NewGoal",
  NEW_TEAMS = "NewTeam",
  NOTES = "Note",
  POWER_LOGGERS = "PowerLogger",
  PROJECTS = "Project",
  RESOURCES = "Resource",
  SCHEDULE_GROUPS = "ScheduleGroup",
  SCHEDULES = "Schedule",
  STRUCTURES = "Structure",
  TAGS = "Tags",
  TASK_DATA = "TaskData",
  TASKS = "Task",
  TEAMS = "Team",
  THIRD_PARTY = "ThirdParty",
  VEHICLES = "Vehicle",
  VIEWS = "Views",
  FACILITY_ADDRESSES = "FacilityAddress",
  NEW_TEAM_RESOURCES = "NewTeamResource",
  CLIENT_INTERFACES = "ClientInterface",
  USER_INTERFACES = "UserInterface",
  USER_DASHBOARDS = "UserDashboard",
  FLEET_ASSET_TEMPLATES = "FleetAssetTemplate",
}

export type BuilderType = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  projectsCacheTags,
  "projectsApi"
>;
