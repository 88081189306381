import {
  SHOW_ALERT,
  HIDE_ALERT
} from '../actions';

/**
 * @name alertsState
 * @param {Object} alerts
 * @param {boolean} alerts.show Set to true to show the alert
 * @param {string} alerts.msg The message to show in the alert snackbar
 * @param {string} alerts.type Enumerated type, value 'info' shows only the message, value
 * 'error' shows an error icon before the message
 */

const initialState = {
  show: false,
  msg: '',
  type: 'info',
};
export default function (state = initialState, action) {

  switch (action.type) {
    case SHOW_ALERT:
      return {
        show: true,
        msg: action.payload.msg,
        type: action.payload.type
      };

    case HIDE_ALERT:
      return initialState;
    default:
      return state;
  }
}